import React from 'react'
import Block from '../../Block'
import Accordion from '../../../common/Accordion'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const FrequentQuestions = () => {
  return <>
        <header className="pd-header">
            <Block>
                <div className="pd-header__inner tw-pb-8">
                    <h2 className="mb-4">
                    <strong>FAQs</strong>
                    </h2>
                    <p>
                    We know that you have questions about CertPLM, so we've
                    answered the most common ones for you already below. If you have
                    other questions that you need answers to, please don't hesitate to
                    email{" "}
                    <a className="p-link" href="mailto:info@practiceunbound.org.uk">
                        info@practiceunbound.org.uk
                    </a>{" "}
                    </p>
                </div>
            </Block>
      </header>

      <Block largePadding color="white">
        <Accordion noBorder heading="What are the benefits of the Certificate in Lifestyle Medicine?">
            <ul>
                <li>Post nominal letters: CertLM</li> 
                <li>An additional recommended 15 CPD Points</li> 
                <li>Completion certificate demonstrating distinct skills and learning within the field of Lifestyle Medicine</li>
                <li>Assessment feedback, certification tools, resources and materials</li>
            </ul>
        </Accordion>
        <Accordion noBorder heading="Is the Certificate in Lifestyle Medicine accredited?">
            <p>The Certificate in Lifestyle Medicine is accredited by Crossfields Institute as a Quality Mark Programme, successful completion will provide you with post-nominals: CertLM.</p>
            <p>The Crossfields Institute (CFI) is an awarding organisation regulated by Ofqual (the Office of Qualifications and Examinations Regulation). They monitor and review the Prescribing Lifestyle Medicine Online Course underpinning the quality and delivery of the programme.</p>
            <p>As part of the Certificate in Lifestyle Medicine, you will need to complete PLM Online. PLM Online is accredited by The Royal College of General Practitioners (RCGP), The Personalised Care Institute (PCI) and The British Society of Lifestyle Medicine (BSLM). Imperial College London use our course content for their undergraduate Lifestyle Medicine and Prevention course (LMAP) course.</p>
        </Accordion>
        <Accordion noBorder heading="Does the Certificate in Lifestyle Medicine or PLM Online count towards the BSLM Maintenance of Certification?">
            <p>CPD points from completing PLM Online (a recommended 15 CPD points) and attendance at  PLM Live (recommended 6 CPD points) will count toward the BSLM Maintenance of Certification. We are working toward the Certificate in Lifestyle Medicine to also count (a recommended 15 CPD points).</p>            
        </Accordion>
        <Accordion noBorder heading="I've already completed PLM Online. Can I study the Certificate in Lifestyle Medicine?">
            <p>Yes, you can. By signing up to the Certificate in Lifestyle Medicine, you will also gain access to PLM Online so that you can complete your certificate. Contact the team on <a href='mailto:info@practiceunbound.org.uk'>info@practiceunbound.org.uk</a> and we can walk you through the sign-up process and special rates.</p>            
        </Accordion>
        <Accordion noBorder heading="I've already completed PLM Online. What is the cost to study the Certificate in Lifestyle Medicine?">
            <p>For those that have already completed PLM Online, there is a special rate to enrol onto the Certificate in Lifestyle Medicine. Contact the team on <a href='mailto:info@practiceunbound.org.uk'>info@practiceunbound.org.uk</a> for further details.</p>            
        </Accordion>
        <Accordion noBorder heading="How many hours of study is expected with for the Certificate in Lifestyle Medicine? ">
            <p>For those that have not yet completed PLM Online:</p>            
            <ul>
                <li>PLM Online Course Study Time: 15 hours</li>
                <li>Using PLM in practice: 85 hours</li>
                <li>Assessment and Case Study: 20 hours</li>
            </ul>
            <p>For those that have completed PLM Online:</p>            
            <ul>
                <li>Using PLM in practice: 85 hours</li>
                <li>Assessment and Case Study: 20 hours</li>
            </ul>
        </Accordion>
        <Accordion noBorder heading="How do we demonstrate 85 hours of using PLM in practice? What form of evidence is required?">
            <p>You will need to log 85 hours of using PLM in practice within the PLM Online Platform. Here, you can download an excel sheet to log the following information:</p>            
            <ul>
                <li>Date</li>
                <li>Consultation type</li>
                <li>Reason for consultation</li>
                <li>Consultation summary</li>
                <li>Time allocated (in minutes)</li>  
            </ul>
            <p>Your log will need to be countersigned by a colleague prior to submission.</p>
        </Accordion>
        <Accordion noBorder heading="How long is the final written case study?">
            <p>There is no official word count.</p>      
        </Accordion>
        <Accordion noBorder heading="How do I enrol?">
            <p>Visit the <Link to="/product/prescribinglifestylemedicine/enrol-plm">Enrol page</Link>, complete the payment process and we will send you will receive your login details right away, so you can get started. </p>      
        </Accordion>
      </Block>

        {/* <section className='tw-pt-10'>
            <div className=" tw-pb-5">
                <h4 class='tw-font-bold tw-text-[#EB6915]'>Why is the sky blue?</h4>
                <p class='tw-font-bold'>Because it's trying to match the oceans, of course!</p>
            </div>

            <div className=" tw-pb-5">
                <h4 class='tw-font-bold tw-text-[#EB6915]'>How do I turn off my neighbor's lawnmower at 6 AM?</h4>
                <p class='tw-font-bold'>We recommend diplomacy first, but if that fails, consider a disguise and some advanced ninja skills.</p>
            </div>

            <div className=" tw-pb-5">
                <h4 class='tw-font-bold tw-text-[#EB6915]'>Can I upload my cat's resume?</h4>
                <p class='tw-font-bold'>Absolutely! Just make sure it includes their extensive experience in napping and knocking things off shelves.</p>
            </div>

            <div className=" tw-pb-5">
                <h4 class='tw-font-bold tw-text-[#EB6915]'>Is this website compatible with my time machine?</h4>
                <p class='tw-font-bold'>We only support time machines built after 2030. Sorry, Doc Brown.</p>
            </div>

            <div className=" tw-pb-5">
                <h4 class='tw-font-bold tw-text-[#EB6915]'>What's the meaning of life, the universe, and everything?</h4>
                <p class='tw-font-bold'>42. Next question!</p>
            </div>

            <div className=" tw-pb-5">
                <h4 class='tw-font-bold tw-text-[#EB6915]'>Can I use this service to find my lost socks?</h4>
                <p class='tw-font-bold'>Sure, but be warned: it may lead to the discovery of a sock dimension filled with mismatched pairs.</p>
            </div>

            <div className=" tw-pb-5">
                <h4 class='tw-font-bold tw-text-[#EB6915]'>How do I become a professional procrastinator?</h4>
                <p class='tw-font-bold'>Well, first, you should probably start by not starting anything, but we'll get back to you on that... eventually.</p>
            </div>

            <div className=" tw-pb-5">
                <h4 class='tw-font-bold tw-text-[#EB6915]'>Can I microwave a burrito so hot that even I can't eat it?</h4>
                <p class='tw-font-bold'>That's a philosophical question. We suggest pondering it while you enjoy a comfortably warm burrito.</p>
            </div>
        </section> */}
  </>
      
  
}

export default FrequentQuestions