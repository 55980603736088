import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getBlogs } from '../../actions/blogActions'
import Card from '../layout/Card'
import Block from '../layout/Block'
import { Helmet } from "react-helmet";
import Grid from '../layout/Grid'
import Spinner from '../common/Spinner'
import detectIe from '../../utils/detectIe'


class BlogScreen extends Component {
    componentDidMount() {
        if (Object.keys(this.props.blogs.blogs).length === 0 && this.props.blogs.blogsLoading === false) {
            this.props.getBlogs()
        }

    }

    render() {
        const { blogs, blogsLoading } = this.props.blogs
        const ieVersion = detectIe();

        if (blogsLoading) { return <Spinner fullScreen color="yellow" large /> }
        return (
            <div>
                <Helmet>
                    <title>Practice Unbound - Blog</title>
                    <meta name="description" content="Get the latest from the Practice Unbound Team" />
                </Helmet>
                <Block backsplash>
                    <h1 className="text-center"><strong>Practice Unbound Blog</strong></h1>
                </Block>

                <div className="container pt-5">
                    <Grid>
                        {blogs.items && blogs.items.map(i =>
                            <div
                                key={i.sys.id}
                                data-aos={(ieVersion === false || ieVersion > 10) && "fade"}
                            >
                                <Card

                                    image={i.fields.cardImage}
                                    colour={i.fields.colour}
                                    link={'/blog/' + i.fields.slug}
                                >
                                    <h4 className="text-center"><strong>{i.fields.header}</strong></h4>
                                    <p className="text-muted text-right">{i.fields.author && ("by " + i.fields.author)}</p>
                                </Card>
                            </div>
                        )}
                    </Grid>
                    <div className="row justify-content-center">
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    blogs: state.blogs
})
export default connect(mapStateToProps, { getBlogs })(BlogScreen);