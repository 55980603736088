import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getPurchaseProduct, getCrmOrganisation, getCrmContract, getOrganisationMemberships, clearPurchaseError } from '../../actions/purchaseActions'
import ContactInfo from './ContactInfo'
import PUPanel from '../layout/PUPanel'
import BillsbyCheckout from './BillsbyCheckout'
import { Link } from 'react-router-dom'

class PurchaseBillsbyNonOrganisationHome extends Component {
    constructor() {
        super()
        this.state = {
            contactComplete: false,
        }
    }


    render() {
        const { product } = this.props.purchase

        return (
            <div className="container py-5">
                <PUPanel title={product.title}>
                    {product.name === "prescribinglifestylemedicine" && (
                        <p>
                            You are about to purchase an annual subscription to Prescribing Lifestyle Medicine. <Link to='/contact-us'>Call our sales team</Link> for support.
</p>
                    )}

                    <p>
                        Please complete each section of this page using the Edit, Save and Next buttons to right hand side of each section.
                    </p>

                </PUPanel>
                <ContactInfo
                    locked={false}
                    isComplete={this.state.contactComplete}
                    onComplete={() => {
                        this.setState({ contactComplete: true })
                        this.props.clearPurchaseError('contact')
                    }}
                />
                <BillsbyCheckout
                    locked={!this.state.contactComplete}
                    isComplete={true}

                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    purchase: state.purchase,
})
export default connect(mapStateToProps, { getPurchaseProduct, getCrmOrganisation, getCrmContract, getOrganisationMemberships, clearPurchaseError })(PurchaseBillsbyNonOrganisationHome);