import React, { useState } from 'react'
import ChevronBox from './ChevronBox'
import styles from './TextExpand.module.css'

const TextExpand = ({title, text, children, chevronColour}) => {
    const [expand, setExpand] = useState(false)
    return (
        <div onClick={() => {
            setExpand(current => !current)
        }}>
            
            <div 
                className='d-flex align-items-center'
            >
                {/* <div> */}
                    <h5 
                        style={{
                            fontSize: title.fontSize || '', 
                            fontWeight:'bold',
                            minWidth: '250px'                                                
                        }}
                        className='mt-2'
                    >
                        {title}
                    </h5>
                {/* </div> */}
                <div  style={{width: '35px',height: '35px', cursor: 'pointer'}}>
                    <div
                        className={`${styles.chevronCircle} mb-5`}
                        style={{
                            paddingTop: '100%',
                            backgroundColor: 'grey',
                            borderRadius: '30%',
                            position: 'relative'
                        }}
                    >
                        <div
                            className={`${expand && styles.chevron}`}
                            style={{color: chevronColour}}
                        >
                            <ChevronBox
                                alt="Next"
                                aria-label="Next"
                                direction='right'
                                position='absolute'
                                top='3%'
                                left='33%'
                                textExpand
                            />
                        </div>

                    </div>
                </div>
            </div>
            <div style={{background: '#E8E8E8', borderRadius: '15px', paddingTop: expand && '1rem', paddingBottom: expand && '1rem', paddingLeft: '1rem', paddingRight: '1rem',color: ''}} className={`${!expand ? styles.textCollapsed : styles.textExpanded} ${styles.text} ${expand && 'mt-2 mb-4'}`}>
                {text.map((paragraph, index) => {
                    const key = `${index}: ${paragraph.split(" ").filter((item, i) => i < 10)}`
                    return (
                        paragraph.split(" ")[0] === "<li>" ? <ul key={key} className='mb-0'><li>{paragraph.split(" ").filter((item, index) => index > 0).join(" ")}</li></ul> : 
                        paragraph.split(" ")[0] === "<strong>" ? <p key={key}><strong>{paragraph.split(" ").filter((item, index) => index > 0).join(" ")}</strong></p> :
                        <p key={key}>{paragraph}</p>
                    )
                })}                
                {children}
            </div>
        </div>
    )
}

TextExpand.defaultProps = {
    title: "Place title here",
    text: ["Put array of strings here.", "One item for each paragraph"],
    chevronColour: 'white'
}

export default TextExpand