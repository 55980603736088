import React from 'react'
import DivFade from '../../DivFade'
export default function LogoBanner(props) {

    return (
        <DivFade className='bg-white' style={{ paddingTop: props.paddingTop, paddingBottom: props.paddingBottom }}>

            {props.header &&
                <h2 className='text-center' style={{ fontWeight: 'bold' }}>A partnership between</h2>
            }

            <div className='d-flex flex-column flex-sm-row justify-content-around align-items-center mx-auto pt-4' style={{ maxWidth: props.maxWidth, marginTop: props.header && "40px" }}>
                {props.images.map((image, index) => (

                    <div key={`Partnership between image: ${index}`} className={`${props.mbImage} pb-3`}>
                        <div style={{ maxWidth: image.maxWidth }} className="mx-auto">
                            <a rel="noopener noreferrer" href={image.link} target="_blank">
                                <img className="img-fluid" alt={image.alt} src={image.src} />
                            </a>
                        </div>
                    </div>

                ))}
            </div>

        </DivFade>
    )
}
