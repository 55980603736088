import russellFoster from "../../../img/RussellFoster.jpg"
import mike from "../../../img/Mike-Headshot.jpg"
import stephanie from "../../../img/Stephanie-Romiszewski-Bio-Photo.jpg"
import mo from "../../../img/Mo-Sekeram.jpg"
import hannah from "../../../img/hannah-short.jpg"
import mel from "../../../img/melanie-thompson.jpg"
import puColours from "../../../utils/puColours"

const images = {
  gabor:
    "https://practiceunbound.blob.core.windows.net/stock-images/gabor-mate-2022-min-circle.png",
  rangan: "https://practiceunbound.blob.core.windows.net/stock-images/RanganHomepageTopImage_2017_07_Chatterjee-3852-Edit-3Cropped-min.jpg",
  ayan:
    "https://practiceunbound.blob.core.windows.net/stock-images/AyanHompageTopAndBottomImageCropped-min.jpg",
  susanna:
    "https://practiceunbound.blob.core.windows.net/stock-images/Petche-Susanna-min-circle.png",
  enh: "https://practiceunbound.blob.core.windows.net/stock-images/ENH-cropped-min.jpg"
}

const sessions = [
//   {
//     id: 1,
//     title: "registration and welcome breakfast",
//     time: "8:00 - 8:45",
//     // duration: 1,
//     // synopsis: ["Start the day with a delicious, healthy breakfast and meet some of your fellow peers."],
//     // component: 'ImageTextBlock'
//     // component: "TopBannerPlain",
//   },
  {
    id: 1,
    title: "Registration and Welcome Breakfast",
    // synopsis: "Start the day with a delicious, healthy breakfast and meet some of your fellow peers.",
    time: "08:00 - 08:45",
    component: "Block",
    contentBackgroundColor: puColours.darkBlue,
  },
  {
    id: 2,
    title: "Setting the landscape for the day",
    time: "8:45 - 9:00",
    speakers: ["Dr Rangan Chatterjee", "Dr Ayan Panja"],
    speakerImages: [
      "https://practiceunbound.blob.core.windows.net/stock-images/RanganHomepageTopImage_2017_07_Chatterjee-3852-Edit-3Cropped-min.jpg",
      "https://practiceunbound.blob.core.windows.net/stock-images/AyanHompageTopAndBottomImageCropped-min.jpg",
    ],
    excludedCircles: [1, 3],
    // synopsis: [''],
    component: "TopBannerPlain",
    // collapse: true
  },
  {
    id: 3,
    title: "Keynote: Trauma, Illness and Healing in a Toxic Culture",
    time: "9:00 - 10:30",
    speakers: ["Dr Gabor Maté"],
    speakerImages: [
      // 'https://practiceunbound.blob.core.windows.net/stock-images/gabor (min).png',
      images.gabor,
    ],
    // speakerLinks: [
    //     speakers.russellFoster.links
    // ],
    synopsis: [
      "Trauma, Illness and Healing in a Toxic Culture",

      "Over half of adults suffer from chronic illness-a fact Western medicine views largely in terms of individual predispositions and habits. Western medicine imposes two separations, neither tenable scientifically.",

      "First, it separates the mind from the body, largely assuming that most chronic illnesses have nothing to do with people's emotional and psychological experiences. And yet, a large and irrefutable body of research has clearly shown that physiologic and behavioural functioning of human beings can be understood only if we integrate our body functions with those of the mind: functions such as awareness, emotions, our interpretations of and responses to events, and our relationships with other people.",

      "Second, Western practice views people's health as separate from the social environment, ignoring social determinants of health such as class, gender, economic status, and race.  Such factors in reality are more important influences on health and longevity than individual predispositions and personal factors such as genes, cholesterol levels, blood pressure and so on.",

      "This talk shows how a society dedicated to material pursuits rather than genuine human needs and spiritual values stresses its members, undermines healthy child development and dooms many to chronic illness, from diabetes to heart disease, from autoimmune conditions to cancer.",

      "You will learn to:",

      "<li> Identify two separations imposed by Western Medicine on the health and well-being of the population",

      "<li> Name three chronic conditions that are correlated with stressful social environments",

      "<li> Describe one shift in focus that would support a healthier population",
    ],
    // speakerBiog: speakers.russellFoster.biog,
    component: "TopBannerPlain",
    contentRight: true,
    greyBackground: true,
    excludedCircles: [1, 2, 3],
  },
  {
    id: 4,
    title: "Refreshment and stretch break",
    time: "10:30 - 11:00",
    component: "ImageTextBlock",
    contentBackgroundColor: puColours.darkOrange,
  },
  {
    id: 5,
    title: "Compassionate Inquiry™ Workshop (Part 1)",
    time: "11:00 - 12:30",
    speakers: ["Dr Gabor Maté"],
    speakerImages: [images.gabor],
    // speakerLinks: [
    //     speakers.stephanieRomiszewski.links
    // ],
    synopsis: [
      "Compassionate Inquiry™ is a psychotherapeutic method developed by Dr. Gabor Maté that reveals what lies beneath the appearance we present to the world.",

      "Using Compassionate Inquiry, the therapist unveils the level of consciousness, mental climate, hidden assumptions, implicit memories and body states that form the real message that words both express and conceal.",

      "Through Compassionate Inquiry, the client can recognise the unconscious dynamics that run their lives and how to liberate themselves from them.",

      "Dr. Maté will model the process of Compassionate Inquiry with course participants and instruct therapists in the practice of this powerful technique to help clients access deep healing and transformation.  This will be both an experiential, participatory workshop where participants will be guided through their own personal process, as well as an introduction to the training to teach the method of Compassionate Inquiry to health professionals, therapists and social workers.",

      "Topics covered:",

      "<li> How to cultivate presence, being with what is",

      "<li> How to bring attention to body signals in clients and yourself",

      "<li> How to create and maintain a safe sacred space between client and therapist",

      "<li> How to keep a client engaged in present moment experience",

      "<li> How to decode the unconscious beliefs that contribute to personal suffering in self and others",
    ],
    // speakerBiog: speakers.stephanieRomiszewski.biog,
    component: "TopBannerPlain",
    excludedCircles: [1],
  },
  //   {
  //     id: 6,
  //     title: "Social Prescribing",
  //     time: "12:30 - 13:00",
  //     duration: 0.75,
  //     speakers: ["Dr Mohan Sekeram"],
  //     speakerImages: [mo],
  //     speakerLinks: [speakers.mohanSekeram.links],
  //     synopsis: [
  //       "Join Dr Mohan Sekeram for a session on social prescribing, what it is, what it’s impact is and ideas on how to implement in practice.",
  //     ],
  //     speakerBiog: speakers.mohanSekeram.biog,
  //     component: "TopBannerPlain",
  //     contentRight: true,
  //     greyBackground: true,
  //     excludedCircles: [2, 3],
  //   },
  {
    id: 6,
    title: "Lunch",
    synopsis: "Enjoy a delicious and healthy lunch, whilst chatting to peers and enjoying book signings from Dr Gabor Maté, Dr Rangan Chatterjee and Dr Ayan Panja",
    time: "12:30 - 14:00",
    component: "Block",
    contentBackgroundColor: puColours.darkBlue,
  },
  {
    id: 7,
    title: "Compassionate Inquiry™ Workshop (Part 2)",
    time: "14:00 - 15:30",
    speakers: ["Dr Gabor Maté"],
    speakerImages: [images.gabor],
    synopsis: [
      "Compassionate Inquiry™ is a psychotherapeutic method developed by Dr. Gabor Maté that reveals what lies beneath the appearance we present to the world.",

      "Using Compassionate Inquiry, the therapist unveils the level of consciousness, mental climate, hidden assumptions, implicit memories and body states that form the real message that words both express and conceal.",

      "Through Compassionate Inquiry, the client can recognise the unconscious dynamics that run their lives and how to liberate themselves from them.",

      "Dr. Maté will model the process of Compassionate Inquiry with course participants and instruct therapists in the practice of this powerful technique to help clients access deep healing and transformation.  This will be both an experiential, participatory workshop where participants will be guided through their own personal process, as well as an introduction to the training to teach the method of Compassionate Inquiry to health professionals, therapists and social workers.",

      "Topics covered:",

      "<li> How to cultivate presence, being with what is",

      "<li> How to bring attention to body signals in clients and yourself",

      "<li> How to create and maintain a safe sacred space between client and therapist",

      "<li> How to keep a client engaged in present moment experience",

      "<li> How to decode the unconscious beliefs that contribute to personal suffering in self and others",
    ],
    component: "TopBannerPlain",
    contentRight: true,
    greyBackground: true,
    excludedCircles: [2],
  },
  {
    id: 8,
    title: "Refreshment and stretch break",
    time: "15:30 - 16:00",
    component: "ImageTextBlock",
    contentBackgroundColor: puColours.darkOrange,
  },
  {
    id: 9,
    title:
      "Introduction to Case Studies and Bringing Prescribing Lifestyle Medicine to Life",
    time: "16:00 - 16:15",
    speakers: ["Dr Ayan Panja"],
    speakerImages: [images.ayan],
    component: "TopBannerPlain",
    excludedCircles: [1],
  },
  {
    id: 10,
    title: "Case Study",
    time: "16:15 - 17:10",
    speakers: ["Dr Susanna Petche", "Emma Nicholas-Hernandez"],
    speakerImages: [images.susanna, images.enh],
    component: "TopBannerPlain",
    synopsis: ["Dr Susannah Petche will present a case study highlighting how trauma can present in primary care and how the Prescribing Lifestyle Medicine approach can be incredibly effective. "],
    excludedCircles: [1,3],
    speakerBiog: [
      ["Dr Petche is committed to the creation of a trauma-informed world. As a lifestyle and functional medicine GP specialising in psychological trauma, she combines 24 years of clinical practice, with personal experience and academic knowledge of trauma.",
      
      "Dr Petche works directly with those dealing with the physical, mental and emotional consequences of trauma - helping them to find a path to healing. And, in addition to her work in a busy A&E and UTC on the South Coast, she has spent the past 2 years leading a hugely successful NHS-funded programme of holistic healing for those with Complex PTSD. The results of this innovative programme were recently presented at the IPM congress in London.", 
      
      "Dr Petche is also an award-winning teacher - teaching the next generation of medical and healthcare professionals about the impacts of trauma. She lectures at Brighton & Sussex Medical School on secondary traumatic stress within medicine and the impact of psychological trauma on health & social care leadership.",

      "In 2022, Dr Petche founded her business: Trauma-Sense and developed the Trauma-Sense programme - helping coaches, therapists and other well-being specialists to become more trauma-informed."],
      [
        "Emma has worked in the field of public health for over eight years in local authority settings and has a Master’s in Public Health.  Her expertise focusses on reducing health and wealth inequalities through community centred approaches.", 
        "She coordinates city wide community engagement and co-production activities, is Public Health lead for Financial Inclusion and established and manages the Be Well Health Coaching service.", 
        "Emma is passionate about the role communities have in reducing health and wealth inequalities and firmly believe that if we look at what keeps us well, listen to and work with residents and build on the strengths of each person and community, we will see better health outcome for all and stronger, more resilient communities."
      ]
    ],
    contentRight: true,
    greyBackground: true,    
  },
  {
    id: 11,
    title:
      "Reflections on the Day",
    time: "17:10 - 17:30",
    speakers: ["Dr Rangan Chatterjee"],
    speakerImages: [images.rangan],
    component: "TopBannerPlain",
    excludedCircles: [1],
  },
  {
    id: 12,
    title: "Celebratory Drinks Reception ",
    time: "17:30 - 18:30",
    component: "Block",
    contentBackgroundColor: puColours.darkBlue,
  },
]

export default sessions
