import React from 'react'
import Spinner from './components/common/Spinner'
import parameters from './config/parameters'

export default function PortalRedirect() {
    const validateToken = (token) => {
        if (!token) {
            return false
        }
        if (token === undefined) {
            return false
        }
        if (token === "") {
            return false
        }
        if (token.length < 7) {
            return false
        }
        return true
    }

    //if no token then redirect to home
    const tokenValid = validateToken(localStorage.jwtToken)
    if (tokenValid === false) {
        window.location.replace(parameters.portalURL);
    } else {
        const token = localStorage.jwtToken.substring(7, localStorage.jwtToken.length)
        window.location.replace(`${parameters.portalURL}/singleSign/verify?token=${encodeURIComponent(token)}​`);
    }

    return (
        <Spinner fullScreen />
    )
}
