import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { getBlogs } from '../../actions/blogActions'
import Carousel from '../common/Carousel';
import DivFade from './DivFade'


class LatestBlogs extends Component {
    componentDidMount() {
        if (Object.keys(this.props.blogs.blogs).length === 0 && this.props.blogs.blogsLoading === false) {
            this.props.getBlogs()
        }
    }
    render() {

        const { blogs, blogsLoading } = this.props.blogs
        // const filteredBlogs = blogs.items.filter((blog, index) => index <= 5)
        // console.log("🚀 ~ file: LatestBlogs.js ~ line 17 ~ LatestBlogs ~ render ~ filteredBlogs", filteredBlogs)
        if (blogsLoading) { return null }
        if (!blogs.items) { return null }
        if (blogs.items.length < 3) { return null }
        // let content = []
        // let i = 0
        // for (i === 0; i < 3; i++) {
        //     content.push(
        //         <div className="col-sm-4" key={blogs.items[i].fields.slug}>
        //             <Card
        //                 image={blogs.items[i].fields.cardImage}
        //                 colour={blogs.items[i].fields.colour}
        //                 link={'/blog/' + blogs.items[i].fields.slug}
        //             >
        //                 <h6 className="text-center"><strong>{blogs.items[i].fields.header}</strong></h6>
        //                 <p className="text-muted text-right"><small>{blogs.items[i].fields.author && ("by " + blogs.items[i].fields.author)}</small></p>
        //             </Card>
        //         </div>
        //     )
        // }
        return (
            <DivFade>
                <Carousel
                    centreHeader
                    backgroundColour={this.props.backgroundColour}
                    header={this.props.title}
                    cardColor="green"
                    height='290px'
                    titleHeight='70px'
                    paragraphHeight='115px'
                    items={
                        blogs.items.filter((blog, index) => index <= 5).map(p => {

                            return {
                                key: p.fields.slug,
                                paragraph: p.fields.pageDescription,
                                title: p.fields.header,
                                image: p.fields.cardImage,
                                linkUrl: '/blog/' + p.fields.slug,
                                linkName: 'Read Blog',
                                navigateFunction: () => this.props.history.push('/blog/' + p.fields.slug)
                            }
                        })
                    }
                />
            </DivFade>
        )

    }
}
const mapStateToProps = state => ({
    blogs: state.blogs
})
export default withRouter(connect(mapStateToProps, { getBlogs })(LatestBlogs));