import ImageTextBlock from '../../PLM/Comps/ImageTextBlock'
import greyCircles from '../../../../img/slightly-dark-grey-bubbles-right.svg'
import Quote from '../../../common/Quote'
import BottomBanner from '../../PLM/Comps/BottomBanner'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import Carousel from '../../../common/Carousel'
import {carouselItems} from './SuccessStories'
import hallGreenImg from '../../../../img/02 Success Stories/image_hall_green_health@3x.jpg'
import DivFade from '../../DivFade'
import { useHistory } from 'react-router-dom'
import womanWritingOffice from '../../../../img/clinical_system_integration_min.jpg'

const carouselItemsFiltered = carouselItems.filter(i => i.title !== 'Hall Green Health')

export default function HallGreen() {
    const history = useHistory()  
    const handleNav = (url) => () => history.push(url)
  return (

    <div>

        <ImageTextBlock
            imageUrl={hallGreenImg}
            contentSide="left"
            contentBackgroundColor="white"
            contentBackgroundImage={greyCircles}
            contentBackgroundSize='40%'
            contentBackgroundPosition='top left'
            contentBackgroundRight='5%'
            contentBackgroundTop='-15%'
        >
            <div className='py-5'>
                <h3 className='font-weight-bold mb-3'>Hall Green Health</h3>
                <p>Hall Green Health is a practice based in Birmingham serving 26,500 patients. The practice covers a wide area across both inner city and suburban demographics, offering a wide range of health and care services to a diverse mix of ethnicities and cultures.</p>
                <p>With 44 clinical staff and 60 non-clinical team members supporting the practice including a larger than average patient list size, the practice has sought to implement a variety of digital processes in order to free-up time and make their operations more efficient by increasing their synergy with smart IT solutions.</p>
            </div>
        </ImageTextBlock>


        <DivFade className='px-4'>
            <div className='container py-4'>
                <Quote
                    // key={`${index}: ${item}`} 
                    author={testimonials[0].author} 
                    quote={testimonials[0].quote} 
                    // iconColour={props.quoteIconColour}
                />
            </div>
        </DivFade>


        <DivFade className='bg-white'>
            <div className='container p-5 bg-white'>
                <div className='mb-5'>
                    <h2 className='font-weight-bold mb-4'>Transforming billing with GP-Billing</h2>
                    <p>Prior to implementing GP-Billing, the practice was reliant on paper forms and spreadsheets to capture, report and track payments for private work and third-party services. Now, a core team of 6 within the practice effectively use the GP-Billing software in their daily work.</p>
                    <p>A centralised billing system has been pivotal in ensuring data accuracy and giving the practice a 360 degree view of payments across all system users which range from Secretaries and Finance Personnel right through to Facilities Assistants. </p>
                </div>
                <h2 className='font-weight-bold mb-4'>Time saving with EMIS Integration and Single Sign On</h2>
                <p>Integrating with EMIS Web clinical system has saved the practice a substantial amount of time across receiving and raising payments. </p>
            </div>
        </DivFade>

        <DivFade className='px-4'>
            <div className='container p-5'>
                <Quote
                    // key={`${index}: ${item}`} 
                    author={testimonials[1].author} 
                    quote={testimonials[1].quote} 
                    // iconColour={props.quoteIconColour}
                />
            </div>
        </DivFade>

        <ImageTextBlock
            imageUrl={womanWritingOffice}
            contentSide="right"
            contentBackgroundColor="#0E3A5C"
            contentBackgroundSize='40%'
            contentBackgroundPosition='top left'
            contentBackgroundRight='5%'
            contentBackgroundTop='-15%'
        >
            <div className='py-5 pl-5 text-white'>
                <p><span className="font-weight-bold">Before GP-Billing:</span> 14 Minutes Bill raising process</p>
                <p><span className="font-weight-bold">Before GP-Billing:</span> 13 Minutes Receiving payment process</p>
                <p><span className="font-weight-bold">With GP-Billing:</span> 2 Minutes Bill raising process</p>
                <p><span className="font-weight-bold">With GP-Billing:</span> 2 Minutes Receiving payment process </p>
            </div>
        </ImageTextBlock>

        <DivFade className='bg-white p-5'>
            <div className='container p-5 bg-white'>
                <p>Hall Green Health has witnessed incredible savings in time by overhauling dated and manual processes that were previously out of sync with their clinical system which has resulted in released time for care and improving the patient experience. This has helped the practice work towards the NHS High Impact actions as set out in the Long Term Plan.</p>
            </div>
        </DivFade>

        <Carousel
            gpBillingTestimonials={true}
            topHalfBackgroundColour='#F3F3F3'
            loading={false}
            cardColor='pink'
            // height='290px'
            titleHeight='50px'
            // paragraphHeight='135px'
            buttonColour='leafGreen'
            quoteIconColour='leafGreen'
            items={testimonialCarouselItems}
        />
        
        <div className='d-block d-lg-none'>
            <Carousel
            // carouselContentType='courseCreators'
            backgroundColour={'blue'}
            titleHeight='28px'
            height='310px'
            items={carouselItemsFiltered.map(e => ({...e, navigateFunction: handleNav(e.linkUrl)}))}
            cardColor='leafGreen'            
            />
        </div>

        <DivFade className='bg-white'>
            <div className='container p-5 bg-white'>
                <div className='mb-5'>
                    <h2 className='font-weight-bold mb-4'>The importance of security and on-demand reporting</h2>
                    <p>By eliminating out of sync data and reliance on spreadsheets and networked documents, the practice has increased security and governance measures. As Jamal goes on to say "Having a system that encompasses security, reporting, integration and usability has proved invaluable, which has been born out by the uptake of the impressive system usage by Hall Green Health personnel."</p>
                    <p>Multiple team members are able to report and record payment information more accurately than ever before. Usage of the system has also given the means for the practice to identify new chargeable private services such as travel shots for Rabies and Yellow Fever. Having the data easily available in an intuitive dashboard means that the practice is "confident that payments are not slipping through the net and we are only completing the work we are paid for", IT Manager Jamal Syed.</p>
                </div>
            </div>
        </DivFade>

        <DivFade className='px-4'>
            <div className='container p-5'>
                <Quote
                    // key={`${index}: ${item}`} 
                    author={testimonials[2].author} 
                    quote={testimonials[2].quote} 
                    // iconColour={props.quoteIconColour}
                />
            </div>
        </DivFade>

        <div className='d-none d-lg-block'>
            <Carousel
                // carouselContentType='courseCreators'
                backgroundColour={'blue'}
                titleHeight='28px'
                height='310px'
                slidesToShow={2}
                items={carouselItemsFiltered.filter((e,i) => i >=0 && i <=1).map(e => ({...e, navigateFunction: handleNav(e.linkUrl)}))}
                cardColor='leafGreen'    
                paddingBottom='20px'            
            />
            <Carousel
                // carouselContentType='courseCreators'
                backgroundColour={'blue'}
                titleHeight='28px'
                height='310px'
                items={carouselItemsFiltered.filter((e,i) => i >=2 && i <=3).map(e => ({...e, navigateFunction: handleNav(e.linkUrl)}))}
                cardColor='leafGreen'  
                slidesToShow={2}   
                topHalfBackgroundColour='rgb(25, 57, 88)'   
                // items={carouselItems}        
            />
        </div>

        <BottomBanner backgroundColour="leafGreen" header="Questions?">
            {/* <p style={{ fontSize: '18px' }}>Whether you are interested in one of our products or would like to know more about working with Practice Unbound, please give us a call on 0300 303 8145 or email <a style={{ fontWeight: 'bold', color: 'white' }} href="mailto:info@practiceunbound.org.uk">info@practiceunbound.org.uk</a></p> */}
            <p style={{ fontSize: '18px' }}><Link style={{ color: 'white' }} to="/contact-us">Contact the team</Link> or call us on <strong>0300 303 8145</strong></p>
        </BottomBanner>

    </div>    

 ) 
  
}

const testimonials = [
    {
        quote: 'Technology plays a key role here at Hall Green Health in all departments both in helping patients to get the best possible quality of care and helping the staff to work smarter."',
        author: 'Jamal Syed | IT Manager'
    },    
    {
        quote: 'My favourite feature is the integration to EMIS because it reduces the duplication of the work capture the same information in multiple locations, it helps us to reduce errors, track outstanding payments and it is one less password to remember."',
        author: 'Jamal Syed | IT Manager'
    }   ,   
    {
        quote: 'Myself and the private team feel it will be a great asset and working tool for private work ensuring accurate record keeping."',
        author: 'Sharon Friel | Office Manager'
    }   ,   
]

const testimonialCarouselItems = [
    {
        key: 1,
        title: 'Jamal Syed | IT Manager',
        paragraph: 'Staff have managed to clear the backlog that we used to maintain while also using the time to assist other departments at peak times such as helping reception for the first hour of the day"'
    },
    {
        key: 2,
        title: 'Kayleigh Maund | Finance & Facilities Assistant',
        paragraph: 'It\'s brilliant and saves me over a day and a half a month, before I was going through folders and paperwork and now it is all in one place."'
    },
]