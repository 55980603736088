import DivFade from '../../../layout/DivFade'
import backdropImage from '../../../../img/gp-billing-backdrop-1@1000w-min.png'

function Header({children}) {
  return (
    <DivFade>
      <div 
          className='bg-primary text-white d-flex flex-column justify-content-center align-items-center text-center pb-4 pb-md-0' 
          style={{
              // backgroundImage: `url(${backdropImage})`,
              backgroundImage: 'url(https://practiceunbound.blob.core.windows.net/stock-images/gp-billing-backdrop.jpg)',
              backgroundRepeat: '',
              backgroundSize: 'cover',
              height: '475px',
              backgroundPosition: '0% 40%'
          }}
      >
        <div 
          style={{
            position: 'absolute',
            inset: '0px 0px 0px 0px',
            backgroundColor: 'rgba(0, 0, 0, .35)',
          }}
        />
        <div
          style={{
            zIndex: '0'
          }}
        >
          {children}
        </div>        
      </div>
      <div style={{position: 'absolute', inset: 'calc(100% - 5px) 0px 0px 0px' , background: '#60AE4C'}}/>
    </DivFade>
  )
}

export default Header