import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getProduct, clearProduct } from '../../actions/productActions'
import Button from '../common/Button'
import Modal from '../common/Modal'
import Card from '../layout/Card'
//import Description from '../layout/Description'

class ProductDetailsModal extends Component {
    componentDidMount() {
        const productCode = this.props.match.params.product
        this.props.getProduct(productCode)
    }

    render() {
        const { product, productLoading } = this.props.products
        if (productLoading || product === null) {
            return (
                <Modal
                    noPadding
                    size="small"
                    onRequestClose={() => {
                        this.props.history.push('/browse')
                        this.props.clearProduct()
                    }}
                >
                    <Card
                        loading={true}
                    >
                    </Card>
                </Modal >
            )
        }
        let button = < Button center inline fixedWidth link={product.productLink}>Open</Button>
        return (
            <Modal
                noPadding
                size="small"
                onRequestClose={() => {
                    this.props.history.push('/browse')
                    this.props.clearProduct()
                }}
            >
                <Card
                    image={product.image}
                    title={product.title}
                    color={product.color}
                    noMargin
                >
                    <div className="mb-4">
                        {button}
                    </div>


                </Card>
            </Modal >
        )
    }
}

const mapStateToProps = state => ({
    products: state.products,
    buy: state.buy,
    auth: state.auth
})

export default connect(mapStateToProps, { getProduct, clearProduct })(ProductDetailsModal);