import React from "react";
import Block from "../Block";
import Accordion from "../../common/Accordion";

export default function PathFAQs() {
  return (
    <div>
      <header className="pd-header">
        <Block>
          <div className="pd-header__inner">
            <h2 className="mb-4">
              <strong>FAQs</strong>
            </h2>
            <p>
              We know that you have questions about Pathology Go, so we’ve
              answered the most common ones for you already below. If you have
              other questions that you need answers to, please don’t hesitate to
              email{" "}
              <a className="p-link" href="mailto:info@practiceunbound.org.uk">
                info@practiceunbound.org.uk
              </a>{" "}
              quoting “PathGoQ2” in your email title or complete our enquiry
              form
            </p>
          </div>
        </Block>
      </header>
      <Block largePadding color="white">
        <Accordion heading="Is it safe?">
          <p>Our programme is built around these GMC principles:</p>
          <ul>
            <li>Training for staff whom you delegate to</li>
            <li>GP oversight of the performance in the form of audit</li>
            <li>
              Data to understand activity and maintain clinical responsibility
            </li>
          </ul>
          <p>
            These principles are well understood, and we are confident that our
            governance structure meets these requirements.
          </p>
          <p>
            The Pathology Go governance structure is using the same building
            blocks as our Workflow Go programme which supports the delegation of
            clinical correspondence processing, and has been implemented by 3000
            practices across the country.
          </p>
          <p>
            All delegation in the Pathology Go Programme is covered by the
            Clinical Negligence Scheme GP (CNSGP) as long as it conforms to the
            GMC Duties of a Doctor Guidance. NHS Resolution no longer agree to
            meet with individual organisations or programmes of work as the MDU
            etc. did before because of this principle.
          </p>

          <p>
            It is also worth noting, that prior to the changes in clinical
            negligence cover, in setting up the Pathology Go programme pilot, we
            met with the MDU and MPS about our pilot. They reviewed the
            governance structures and included cover for the pilot under routine
            indemnity.
          </p>
        </Accordion>
        <Accordion heading="Has it been tested?">
          <p>
            Yes. We ran a pilot for Pathology Optimisation in an NHS Practice in
            England. In that Practice we trained 3 administrators to safely
            process pathology results. We also created an agreed governance
            framework for them to follow, which had been agreed by the GPs.
            During the first 2 weeks of the pilot, the administrators filed
            patient pathology results, which when audited by a clinician were
            found to have a 100% success rate - meaning an administrator has
            filed and completed results exactly in line with the Pathology Go
            decision aid clinical flow chart.
          </p>
          <p>
            After the pilot we rolled out to an additional 25 Practices in
            England and Wales to further test the programme ahead of its
            official launch in May 2020.
          </p>
        </Accordion>
        <Accordion heading="Who uses Pathology Go?">
          <p>
            Pathology Go is specifically designed for use in GP Surgeries and
            NHS practices in the UK.
          </p>
          <p>
            There are already a large number of Practices which have purchased
            and implemented Pathology Go ahead of the official launch in May
            2020.
          </p>
        </Accordion>
        <Accordion heading="How do I implement it?">
          <p>
            Pathology Go is very easy to implement. Pathology Go can be
            implemented in as little as two weeks, or as long as your practice
            needs to feel comfortable. It’s an out-of-the-box solution, which
            means that everything you need from guides and e-learning to data
            dashboards is included in the product.{" "}
          </p>
          <p>
            If you need support in achieving your implementation the Practice
            Unbound Support team are available to answer email enquiries on{" "}
            <a href="mailto:support@practiceunbound.org.uk" className="p-link">
              support@practiceunbound.org.uk
            </a>
          </p>
        </Accordion>
        {/* <Accordion heading="How much does it cost?">
          <p>
            Pathology Go is available at a price of 30p per enrolled patient per year.
          </p>
        </Accordion> */}
        <Accordion heading="When can I expect results?">
          <p>
            Immediately! As soon as you go “live” with Pathology Go, your
            Practice GPs will immediately start saving time in their days to
            spend on more complex test responses, and other clinical priorities.
          </p>
        </Accordion>
        <Accordion heading="How do I track ROI?">
          <p>
            Pathology Go’s intuitive dashboards mean that Practice Managers and
            GPs can monitor performance and delivery at any time as well as how
            many GP hours you are saving in your practice. This makes governance
            easier and ensures greater transparency of both processes and
            outcomes.
          </p>
        </Accordion>
      </Block>
    </div>
  );
}
