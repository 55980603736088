import React from 'react'
import Dropdown from '../common/DropDown'
import NavItem from './NavItem'


export default function ProductMenu(props) {
    const { products } = props
    return (
        <Dropdown
            title="Products"
            buttonColour={props.colour}
        >
            <NavItem
                // width={540}
                items={products.map(p => {
                    return {
                        link: `/product/${p.name}`,
                        image: p.iconUrl,
                        title: p.title,
                        subtitle: p.subtitle,
                        purchased: p.purchased
                    }
                })} />

        </Dropdown>
    )
}


