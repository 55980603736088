import Button from "../../common/Button"
import BannerFlex from "../BannerFlex"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import puColours from "../../../utils/puColours"
import ImageTextBlock from "../PLM/Comps/ImageTextBlock"
import DivFade from '../../../components/layout/DivFade'
import Block from "../Block"
import Calculator from '../../../components/layout/NLS/Calculator'
import SocialsIcon from "../../common/SocialsIcon"
import topBannerShapes from "./pricingBubbles"
const closeupresearchermicroscope = "https://practiceunbound.blob.core.windows.net/stock-images/close-up-researcher-working-with-microscope.jpg"
const doctorwork = "https://practiceunbound.blob.core.windows.net/stock-images/doctor-work.jpg"

const darkBlue = puColours.darkBlue

const pharmacistImg = "https://practiceunbound.blob.core.windows.net/stock-images/african-american-pharmacist-working-drugstore-hospital-pharmacy-african-healthcare.jpg"
const pathgodatadashboard = "https://practiceunbound.blob.core.windows.net/stock-images/pathgodatadashboard.png"

const PathGoImplementation = () => {
  return <>
    <BannerFlex
        backgroundCirclesObj={{
            width: '600px',
            left: '-310px',
            className: 'tw-animate-pulse-slow'
        }}
        shapes={topBannerShapes}
        // classNames={{
            //     shapeSection: ''
        // }}
        classNames={{shapesSection: 'tw-pb-36 md:tw-pb-28'}} 
    >
        <div className="tw-flex tw-flex-col tw-gap-3 tw-py-10">
            <section className="tw-pl-2">
                <h4 className="tw-font-bold tw-mb-5">Implementing Pathology Go</h4>
                <p>The implementation process has been designed in a specific way to ensure that practice teams are guided through each step and can gain confidence as they move through the programme. The volume of delegation is only increased in-line with verification of compliance and competence.</p>
                <p>Clinical teams always retain overall control of the whole process.</p>
            </section>
            <div className="tw-flex tw-flex-col sm:tw-flex-row tw-gap-4">
            <Button inline large colour="yellow-outline" link="/contact-us?contactType=sales">enquire now</Button>
            </div>
        </div>
    </BannerFlex>

    <ImageTextBlock
        imageUrl={closeupresearchermicroscope}
        contentSide="right"
        contentBackgroundColor={puColours.darkMint}
        // contentBackgroundImage={greyCircles}
        contentBackgroundSize='40%'
        contentBackgroundPosition='top left'
        contentBackgroundRight='5%'
        contentBackgroundTop='-15%'
    >
        <div className="tw-py-10"/>
        <div className="tw-pl-6 tw-text-white">
          <h4 className="tw-font-bold" style={{textWrap:'balance'}}>1. Review Flow and Patient Experience</h4>
          <div className="py-2"/>
          <p>Before implementing Pathology Go, it is really important to understand the current internal flow of pathology testing through the Practice. Review how engagement and communication with patients is maintained and gain an understand what the current reality of pathology testing is like from a patient perspective.</p>
          <div className="py-2"/>
          <h4 className="tw-font-bold" style={{textWrap:'balance'}}>2. Engage wider team</h4>
          <div className="py-2"/>
          <p>Ensure all members of the practice team are briefed in the change in process that is due to take place. Share with them the purpose, benefits, roles and timelines.</p>
          <div className="py-2"/>
          <h4 className="tw-font-bold" style={{textWrap:'balance'}}>3. Training</h4>
          <div className="py-2"/>
          <p>Admin staff, Practice Manager and GP Lead complete eLearning modules. </p>
          <div className="py-2"/>
        </div>
        <div className="tw-py-10"/>
    </ImageTextBlock>

    <ImageTextBlock
        imageUrl={doctorwork}
        contentSide="left"
        // contentBackgroundImage={greyCircles}
        contentBackgroundSize='40%'
        contentBackgroundPosition='top left'
        contentBackgroundRight='5%'
        contentBackgroundTop='-15%'
    >
        <div className="tw-py-10"/>
        <div className="tw-pl-6">
          <h4 className="tw-font-bold" style={{textWrap:'balance'}}>4. Clinical Governance</h4>
          <div className="py-2"/>
          <p>The GP Lead needs to understand the governance framework outlined in the training and share this with the wider clinical team. Key areas include adapting and signing off decision aids within your practice and understanding the early implementation phase and audit process.</p>
          <div className="py-2"/>
          <h4 className="tw-font-bold" style={{textWrap:'balance'}}>5. Go Live</h4>
          <div className="py-2"/>
          <p>Choose a “go live” date. The "go live" date is the day when the administrators will begin processing some Pathology results in line with their training. Review the decision aids and agree which one or ones will be implemented first. Start slowly, to build the administrator’s confidence and ensure the audit and feedback process is running smoothly.</p>
          <div className="py-2"/>
          <h4 className="tw-font-bold" style={{textWrap:'balance'}}>6. Audit</h4>
          <div className="py-2"/>
          <p>The audit and feedback process is a critical part of the governance framework and also enables the administrator to build confidence in their abilities.</p>
          <div className="py-2"/>
        </div>
        <div className="tw-py-10"/>
    </ImageTextBlock>

    
    <ImageTextBlock
        imageUrl={pharmacistImg}
        contentSide="right"
        contentBackgroundColor={puColours.darkMint}
        // contentBackgroundImage={greyCircles}
        contentBackgroundSize='40%'
        contentBackgroundPosition='top left'
        contentBackgroundRight='5%'
        contentBackgroundTop='-15%'
    >
        <div className="tw-py-10"/>
        <div className="tw-pl-6 tw-text-white">
          <h4 className="tw-font-bold" style={{textWrap:'balance'}}>7. Know your numbers with the Pathology Go dashboard</h4>
          <div className="py-2"/>
          <p>Pathology Go can quickly deliver Return on investment (ROI) through more efficient pathology test processing using evidence-based decision aids. Pathology Go’s handy data dashboards demonstrate how the Programme is performing at-a-glance, showing:</p>
          <div className="py-2"/>
          <ul>
            <li>Number of test results received  </li>
            <li>Number of test results filed and completed by practice admin staff</li>
            <li>Number of test results filed by GPs</li>
            <li>Breakdown of test by type (FBC, UE etc)</li>
            <li>No. of GP hours saved through implementation</li>
          </ul>
        </div>
        <div className="tw-py-10"/>
    </ImageTextBlock>

    <DivFade className="tw-bg-[#ECECED] tw-flex tw-flex-col tw-justify-center tw-items-center tw-py-32">
      <div className="tw-w-3/4">
        <img src={pathgodatadashboard} className=" tw-w-full"/>
        <div className="tw-py-3"/>
        <h6 className="tw-font-bold tw-text-center" style={{textWrap:'balance'}}>This dashboard displays in real time the positive impact that Pathology Go is having in making more efficient use of your GPs time</h6>
      </div>      
    </DivFade>

    <DivFade className="tw-bg-[#169F7B] text-white">
        <div className="container" style={{ paddingTop: '72px', paddingBottom: '72px' }}>
            <div className="row justify-content-between">
                <div className="col-sm-8 mb-3">
                    <h3 className="mb-4" style={{ fontWeight: 'bold' }}>Got questions? Talk to us…</h3>
                    <p>Whether you are interested in one of our products or would like to know more about working with Practice Unbound, please give us a call on 0300 303 8145 or email <a className="tw-text-white tw-underline" href="mailto:info@practiceunbound.org.uk">info@practiceunbound.org.uk</a></p>
                </div>
                <div className="col-sm-2 d-flex justify-content-center align-items-center">
                    <span style={{ fontSize: '32px', marginRight: '16px' }}><SocialsIcon className={`tw-text-white hover:tw-text-[#06523D] tw-mx-2`} link={"https://twitter.com/PracticeUnbound"} social="twitter" /></span>
                    <span style={{ fontSize: '32px' }}><SocialsIcon className={`tw-text-white hover:tw-text-[#06523D] tw-mx-2`} link={"https://www.linkedin.com/company/practice-unbound"} social="linkedin" /></span>
                </div>
            </div>
        </div>
    </DivFade >
  </>
}

export default PathGoImplementation