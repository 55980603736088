import { useState } from 'react'
import ImageTextBlock from "../../PLM/Comps/ImageTextBlock"
import circles from '../../../../img/dark-grey-bubbles.svg'

const IncomeCaptureCalc = (props) => {

  const [listSize, setListSize] = useState(0)

  return (
    <ImageTextBlock
        imageUrl={'https://practiceunbound.blob.core.windows.net/stock-images/AboutPage_WhoIsPLMForBlock_shutterstock_1477336916-min.jpg'}
        contentSide="left"
        contentBackgroundColor="#193958"
        contentBackgroundImage={circles}
        contentBackgroundSize='50%'
        contentBackgroundPosition='top right'
        contentBackgroundRight='-20%'
        contentBackgroundTop='-15%'
    >
      <div className='text-white'>
        <div className='pt-5 pb-2 pr-5'>
          <h2 className='font-weight-bold'>Income Capture Calculator</h2>
          <div className="py-3">
            <h4 className='font-weight-bold'>Please enter your patient list size below:</h4>
            <input className='w-100' type='number' onChange={(e) => setListSize(e.target.value)} value={listSize} />
          </div>
          <div className="py-3">
            <h4 className='font-weight-bold'>Potential annual revenue* from private work</h4>
            <p className='p-1' style={{background: 'white', color: 'black'}}>£{(listSize * 3.132).toFixed(2)}</p>         
          </div>
          <div className="py-3">           
            <p>*Based on the Average Value (per 1000 Patients) of Non-NHS and Private GP work billed across the GP Billing user base over 12 months.</p>
          </div>
        </div>
      </div>
    </ImageTextBlock>
  )
}

export default IncomeCaptureCalc