import React from "react";

const Accordion = (props) => {
  return (
    <>
      <div className="accordion" style={{border: props.noBorder ? 'none' : '', backgroundColor: props.backgroundColour ? props.backgroundColour : ''}}>
        <div className="accordion__heading">
          {props.heading ? props.heading : ""}
          <button
            className="accordion__button"
            onClick={e => e.target.parentNode.parentNode.classList.toggle("open")}
          />
        </div>
        <div className="accordion__content pt-4">{props.children}</div>
      </div>
    </>
  );
};

export default Accordion;
