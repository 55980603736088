import React from 'react'
import Check from '../common/Check'
import Button from '../common/Button'

export default function GCPricingTable() {
    return (
        <>
            <div id='gc-pricing'
                // This is for the offset of the anchor tag links
                style={{
                    display: 'block',
                    position: 'relative',
                    top: '-160px',
                    visibility: 'hidden'
                }}
            />
            <div className="table-responsive" >
                <table className="table table-striped" >
                    <thead>
                        <tr>
                            <th style={{ borderTop: 'none' }}>
                                <h4 className="font-weight-bold">Our programmes are designed to help you make a positive impact</h4>
                            </th>
                            <th style={{ backgroundColor: '#00abed', verticalAlign: 'middle', textAlign: 'center' }}>
                                <h6 className="font-weight-bold text-white">Group Consultations GO</h6>
                            </th>
                            <th style={{ backgroundColor: '#00abed', verticalAlign: 'middle', textAlign: 'center' }}>
                                <h6 className="font-weight-bold text-white">Group Consultations Implementation Accelerator</h6>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr >
                            <td>
                                <p>Comprehensive online and blended learning programme for 12 months, 10 module eLearning, templates and resources for both virtual and face-to-face Group Consultations</p>
                            </td>
                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                <Check className="tc-dark-blue" medium />
                            </td>
                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                <Check className="tc-dark-blue" medium />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p> Group Consultations Coaching and Support Helpdesk, 9-5 Monday-Friday</p>
                            </td>
                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                <Check className="tc-dark-blue" medium />
                            </td>
                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                <Check className="tc-dark-blue" medium />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p> Patient Feedback, Satisfaction Reports and Impact Dashboards</p>
                            </td>
                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                <Check className="tc-dark-blue" medium />
                            </td>
                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                <Check className="tc-dark-blue" medium />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p> Live Training and Planning Event for your whole team</p>
                            </td>
                            <td>

                            </td>
                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                <Check className="tc-dark-blue" medium />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p> Team Check-in and Coaching session before your first Group Consultation</p>
                            </td>
                            <td>

                            </td>
                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                <Check className="tc-dark-blue" medium />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>Team Check-in and Coaching session after your first Group Consultation</p>
                            </td>
                            <td>

                            </td>
                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                <Check className="tc-dark-blue" medium />
                            </td>
                        </tr>
                        <tr>
                            <td >
                                <p>Price</p>
                            </td>
                            <td className="text-center font-weight-bold">
                                <div className="mb-2">£1999 + VAT</div>
                                <Button link='/purchase/groupconsultations' colour="yellow">Buy Now</Button>
                            </td>
                            <td className="text-center font-weight-bold">
                                <div className="mb-2">£2999 + VAT</div>
                                <Button link='/contact-us?contactType=sales&product=groupconsultations' colour="yellow">Enquire Now</Button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}
