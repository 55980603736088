import React from 'react'
import classnames from 'classnames'
import Button from '../../../common/Button'

export default function BenefitsInclude(props) {

    return (
        <div
            className='px-5 relative' style={{ paddingTop: '110px', paddingBottom: '110px' }}
        >
            <div className="row justify-content-center">
                <div className="col-12">
                    <h1
                        className={`mb-3 ml-0`}
                        style={{
                            fontSize: 'clamp(26px,2vw,30px)',
                            color: props.whiteText && 'white'
                        }}
                    ><strong>Course Benefits Include:</strong></h1>
                </div>
            </div>
            {/* Paragraph */}
            <div style={{}} className={classnames("row justify-content-center"
                // { 'text-center': fields.textAlign === "Center" },
                // { 'text-right': fields.textAlign === "Right" }
            )}>
                <div className="col-12">
                    {/* <span>{fields.paragraph && processRichText(fields.paragraph.content)}</span> */}
                    <p style={{
                        color: props.whiteText && 'white'
                    }}>
                        Prescribing Lifestyle Medicine is the best value for money lifestyle course available. For just £395 per annum (plus local taxes), the course includes:
                    </p>
                </div>
            </div>
            <div style={{}} className={classnames("row justify-content-center"
                // { 'text-center': fields.textAlign === "Center" },
                // { 'text-right': fields.textAlign === "Right" }
            )}
            >
                <div className="col-12" style={{ color: props.whiteText && 'white' }}>
                    {/* <span>{fields.paragraph && processRichText(fields.paragraph.content)}</span> */}
                    <span>
                        <ul className={`tick-list`} style={{ fontWeight: 'bold', marginBottom: '2rem' }}
                            // style={{
                            //     fontWeight: 'bold', 
                            //     marginBottom: '2.5rem'
                            // }}                                            
                        >
                            {props.children}
                        </ul>
                    </span>
                    <div>
                        <Button inline link='/product/prescribinglifestylemedicine/enrol-plm' colour="orange">enrol now</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
