import TopBannerPlain from '../../layout/PLM/Comps/TopBannerPlain'
import IncomeCaptureCalculator from '../../layout/GPBilling/components/IncomeCaptureCalc'
import BottomBanner from '../PLM/Comps/BottomBanner'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import letterIcon from '../../../img/03 Top Billable GP Services 2020/icon_tb2020_01_letter.svg'
import insuranceIcon from '../../../img/03 Top Billable GP Services 2020/icon_tb2020_02_insurance_medical.svg'
import drivingIcon from '../../../img/03 Top Billable GP Services 2020/icon_tb2020_03_driving_medical.svg'
import firearmsLicenceIcon from '../../../img/03 Top Billable GP Services 2020/icon_tb2020_04_firearms.svg'
import dwpIcon from '../../../img/03 Top Billable GP Services 2020/icon_tb2020_05_dwp_pip.svg'
import gpbillingLogo from '../../../img/03 Top Billable GP Services 2020/gp_billing_logo_circle.png'
import Table from 'react-table'
import DivFade from '../DivFade'


export default function TopBillable() {

  
  return (
    <>
      <TopBannerPlain 
        backgroundCircles
        circleColours={{
          one:"#60AE4C",
          two:"#113B5B",
          three:"#2372B5",
        }}  
        circleLogo={gpbillingLogo}
      >
        <h3 className='font-weight-bold mt-4 text-capitalize'>top billable gP services 2023</h3>
        <div className='py-2'/>
        <p>The top 5 billable income revenues below have been taken from GP Billing customers using GP Billing during the period of March 2022 - March 2023.</p>
        <p>Your practice could be missing out on valuable income so benchmark your organisation against our top 5 billable revenues and use the income calculator to see what your billing revenue could look like using GP Billing</p>
      </TopBannerPlain>

      <DivFade className='bg-white px-5 pt-5'>
        <div className='container pb-1 bg-white'>
            <div className='mb-5'>
                <p className="font-weight-bold text-center">The top 5 billable items equate to, on average, 70% of private, third party and insurance billing:</p>
            </div>
        </div>
      </DivFade>


      <div className='bg-white pb-5'>

        <div className='container bg-white' style={{maxWidth: '650px'}}>
          <div className='row'>
            <Service colSpan={4} icon={letterIcon} circleNumber={1}>
              <h3 className='font-weight-bold my-1 text-center'>Letter</h3>
              <p className='mb-0'><span className='font-weight-bold'>Total</span>: 24.7%</p>
              <p className='text-center'><span className='font-weight-bold'>Average cost per item</span>: £26</p>
            </Service>
            <Service colSpan={4} icon={insuranceIcon} circleNumber={2}>
              <h3 className='font-weight-bold my-1 text-center'>Insurance Medical</h3>
              <p className='mb-0'><span className='font-weight-bold'>Total</span>: 19.5%</p>
              <p className='text-center'><span className='font-weight-bold'>Average cost per item</span>: £70</p>
            </Service>
            <Service colSpan={4} icon={drivingIcon} circleNumber={3}>
              <h3 className='font-weight-bold my-1 text-center'>Driving Medical</h3>
              <p className='mb-0'><span className='font-weight-bold'>Total</span>: 12.9%</p>
              <p className='text-center'><span className='font-weight-bold'>Average cost per item</span>: £76</p>
            </Service>
          </div>
        </div>

        <div className='py-4 bg-white d-none d-md-block'/>

        <div className='container bg-white' style={{maxWidth: '650px'}}>
          <div className='row mx-0'>
            <Service colSpan={6} icon={firearmsLicenceIcon} circleNumber={4}>
              <h3 className='font-weight-bold my-1 text-center'>Firearms Medical Licensing Certificate</h3>
              <p className='mb-0'><span className='font-weight-bold'>Total</span>: 8.2%</p>
              <p className='text-center'><span className='font-weight-bold'>Average cost per item</span>: £41</p>
            </Service>
            <Service colSpan={6} icon={dwpIcon} circleNumber={5}>
              <h3 className='font-weight-bold my-1 text-center' style={{height: '65px'}}>DWP PIP</h3>
              <p className='mb-0'><span className='font-weight-bold'>Total</span>: 5.6%</p>
              <p className='text-center'><span className='font-weight-bold'>Average cost per item</span>: £32</p>
            </Service>
          </div>
        </div>
      </div>

      {/* <DivFade className='bg-white px-5'>
        <div>
            <p className="font-weight-bold text-center">The table below shows the 25 top billable items from GP Billing Practices:</p>
            </div>
          </DivFade> */}
      {/* <IncomeCaptureCalculator /> */}

      <div className="bg-white pb-1 pt-5 pb-3">
        <div className="container bg-white">
          <p className="font-weight-bold text-center">The table below shows the 25 top billable items from GP Billing Practices:</p>          
        </div>
      </div>

      <div className="bg-white pb-5">
        <div className="container bg-white">
          <Table data={data} columns={columns} {...tableProps} />
        </div>
      </div>

      <BottomBanner backgroundColour="leafGreen" header="Questions?">
        {/* <p style={{ fontSize: '18px' }}>Whether you are interested in one of our products or would like to know more about working with Practice Unbound, please give us a call on 0300 303 8145 or email <a style={{ fontWeight: 'bold', color: 'white' }} href="mailto:info@practiceunbound.org.uk">info@practiceunbound.org.uk</a></p> */}
        <p style={{ fontSize: '18px' }}><Link style={{ color: 'white' }} to="/contact-us">Contact the team</Link> or call us on <strong>0300 303 8145</strong></p>
      </BottomBanner>
    </>
  )
}

function Service(props) {
  const colours = {
    blue: 'rgb(25, 57, 88)'
  }
  return (
    <>

      
      <div className={`col-12 col-sm-${props.colSpan}`}>
        <div className='d-flex flex-column align-items-center'>
          <IconShape colour={colours.blue} circleNumber={props.circleNumber}>
            <img src={props.icon} />          
          </IconShape>
          {props.children}
        </div>
      </div>
    </>
  )
}

function IconShape(props) {
  return (
    <div style={{width: '100px', height: '100px', position: 'relative'}} >
      <div className='position-absolute'
        style={{width:'30px',height:'30px', top:'-10px',left:'0px'}}
      >
        <div 
          style={{
            backgroundColor: '#60AE4C', 
            width:'100%', 
            height:'100%',
            color:'white', 
            borderRadius:'50%',
            textAlign: 'center',
            fontWeight: 'bold',
          }} className='flex-column justify-content-center align-items-center'               
        >{props.circleNumber}
        </div>
      </div>
      <div style={{
        width: '100%',
        height: '100%',
          borderRadius: '50%',
          backgroundColor: props.colour,
          position: 'relative'
        }}
        >
        <div className='position-absolute d-flex justify-content-center align-items-center' style={{inset: '0'}}>
          {props.children}
        </div>        
      </div>
    </div>

)
}

const {format} = new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' })

const data = [
  { category: 'Letter', percentage: 24.7, averageCost: format(25.95) },
  { category: 'Insurance Medical', percentage: 19.4, averageCost: format(70.12) },
  { category: 'Driving Medical', percentage: 12.9, averageCost: format(75.64) },
  { category: 'Firearms', percentage: 8.21, averageCost: format(41.10) },
  { category: 'DWP PIP', percentage: 5.58, averageCost: format(31.58) },
  { category: 'Travel', percentage: 5, averageCost: format(32.18) },
  { category: 'Vaccinations', percentage: 4.07, averageCost: format(57.90) },
  { category: 'Other Ad hoc Bills', percentage: 3.71, averageCost: format(373.52) },
  { category: 'Cremation Certificate', percentage: 3.34, averageCost: format(81.07) },
  { category: 'Employment', percentage: 2.41, averageCost: format(86.55) },
  { category: 'Access to Records', percentage: 2.21, averageCost: format(36.62) },
  { category: 'Student', percentage: 1.74, averageCost: format(20.57) },
  { category: 'Form', percentage: 1.6, averageCost: format(32.46) },
  { category: 'Adoption', percentage: 1.57, averageCost: format(68.33) },
  { category: 'Room Hire', percentage: 0.79, averageCost: format(230.30) },
  { category: 'Certificate', percentage: 0.57, averageCost: format(28.32) },
  { category: 'Army', percentage: 0.48, averageCost: format(64.68) },
  { category: 'Driving', percentage: 0.39, averageCost: format(54.88) },
  { category: 'Medical', percentage: 0.34, averageCost: format(50.40) },
  { category: 'Admin', percentage: 0.33, averageCost: format(67.15) },
  { category: 'Council ?', percentage: 0.2, averageCost: format(14.76) },
  { category: 'Private Services', percentage: 0.19, averageCost: format(31.12) },
  { category: 'Power of Attorney', percentage: 0.15, averageCost: format(77.00) },
  { category: 'Blue Badge', percentage: 0.11, averageCost: format(33.22) }
]

const columns = [
  {
    Header: 'Category',
    accessor: 'category' // String-based value accessors!
  }, 
  {
    Header: '%',
    accessor: 'percentage',
    style: {
      textAlign: 'center'
    }
  }, 
  {
    Header: 'Average cost per item',
    accessor: 'averageCost',
    style: {
      textAlign: 'center'
    }
  }
]

const tableProps = {
  className:"-striped -highlight",
  showPagination: false,
  // minRows: 4,
}