import React, { Component } from 'react'
import Button from '../../../common/Button'
import TextFieldGroup from '../../../common/TextFieldGroup'
import api from '../../../../config/axios'
import parameters from '../../../../config/parameters'
import Circles from '../../../../img/circles.svg'
import DivFade from '../../DivFade'

export default class SignupForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            country: '',
            organisation: '',
            role: '',
            errors: {},
            modalOpen: false,
            success: false,
            loading: false
        }
    }


    onSubmit = (e) => {
        e.preventDefault();
        this.setState({ errors: {}, loading: true })
        api.platformAPI.post(`${parameters.platformApiURI}/api/platform/contact/plm`, this.state)
            .then(response => {
                this.setState({ success: true, loading: false }, () => { setTimeout(this.props.onCompletion, 1000) })
            })
            .catch(err => {
                console.dir(err)
                if (err.response) {

                    if (Object.prototype.toString.call(err.response.data) === '[object Object]') {
                        console.log(err.response.data)
                        return this.setState({ errors: err.response.data, loading: false })
                    } else {
                        return this.setState({ errors: { error: err.response.data }, loading: false })
                    }


                } else {
                    return this.setState({ errors: { error: err.message }, loading: false })
                }

            })
    }
    onChange = (e) => {
        if (e.target.type === "checkbox") {
            this.setState({ [e.target.name]: e.target.checked })
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }
    render() {
        const { errors, success, loading } = this.state

        return (
            <DivFade className="bg-dark-teal" style={{ position: 'relative', overflow: 'hidden' }}>
                <img alt="" src={Circles} style={{ bottom: '-300px', left: '-160px', position: 'absolute', filter: 'brightness(0) invert(61%) sepia(18%) saturate(1353%) hue-rotate(133deg) brightness(94%) contrast(82%)' }} />
                <img alt="" src={Circles} style={{ top: '-300px', right: '-160px', position: 'absolute', filter: 'brightness(0) invert(61%) sepia(18%) saturate(1353%) hue-rotate(133deg) brightness(94%) contrast(82%)' }} />
                <div className="container" style={{ position: 'relative', paddingTop: '64px', paddingBottom: '64px' }}>

                    <div className="d-flex flex-column align-items-center">
                        <h3 className="text-white mb-4" style={{ fontWeight: 'bold' }}>Hear about upcoming events</h3>
                        <p className="text-white mb-4">Sign up to our mailing list</p>
                        <form style={{ maxWidth: '320px', minWidth: '280px' }} onSubmit={this.onSubmit}>


                            <TextFieldGroup
                                name='email'
                                placeholder='Email'
                                type='email'
                                autoComplete='email'
                                value={this.state.email}
                                onChange={this.onChange}
                                error={errors.email}
                                errorColor="white"
                            />



                            <TextFieldGroup
                                placeholder="Name*"
                                name='name'
                                value={this.state.name}
                                onChange={this.onChange}
                                error={errors.name}
                                autoComplete='name'
                                errorColor="white"
                            />

                            <TextFieldGroup
                                placeholder="Job Role*"
                                name='role'
                                value={this.state.role}
                                onChange={this.onChange}
                                error={errors.role}
                                errorColor="white"
                            />

                            <TextFieldGroup
                                placeholder="Organisation*"
                                name='organisation'
                                value={this.state.organisation}
                                onChange={this.onChange}
                                error={errors.organisation}
                                errorColor="white"
                            />

                            <TextFieldGroup
                                placeholder="Country*"
                                name='country'
                                value={this.state.country}
                                onChange={this.onChange}
                                error={errors.country}
                                autoComplete='country-name'
                                errorColor="white"
                            />




                            <Button
                                fixedWidth
                                colour="none-outline-blue"
                                outline
                                type="submit"
                                center
                                large
                                done={success}
                                loading={loading}
                            >Sign Up</Button>
                            <div className="text-center">
                                {errors.error ? <small className="text-danger">{errors.error}</small> : <small>&nbsp;</small>}
                            </div>

                        </form>
                    </div>
                </div>

            </DivFade>
        )
    }
}
