import React from 'react'
import styles from './HomepageBannerTwo.module.css'
import Button from '../common/Button'
// import Chevron from '../common/Chevron'
// import buttonStyles from '../common/Button.module.css'
// import bubbleBlue from '../../img/bubble-blue.svg'
// import { header } from 'express/lib/request'
// import CarouselButtons from '../common/CarouselButtons'
// import CarouselButtonsDev from '../common/CarouselButtonsDev'
import hereLogo from '../../img/powered-by-here-logo.png'
import DivFade from '../../components/layout/DivFade'

export default function HomePageBanner(props) {

    const pageContent = {
        headerText: 'Programmes Supporting Primary Care',
        description: '',
        buttons: [
            { label: 'why choose us?', colour: 'green-outline', link: '/our-story' },
            // { label: 'see our programmes', colour: 'none-outline' },
        ]
    }

    // const [picture, setPicture] = useState(0)

    const content = pageContent
    const headerIdealFontSize = '12vw'
    const headerMaxFontSize = '2.7rem'

    return (
        <DivFade
            style={{
                backgroundColor: '#193958',
                color: 'white',
                width: '100%',
                overflow: 'hidden'
            }}
        >
            <div
                className='container position-relative'
                style={{
                    // border: 'solid red 2px'
                }}
            >
                <div className={styles.gradient}></div>
                <div
                    className={`row ${!props.secondaryContent && 'pt-5'}`}
                    style={{
                        // border: 'solid blue 5px',
                        height: '100%',
                        position: 'relative'
                    }}
                >
                    {/* ============================================================
                        ============================COL-1===========================
                        ============================================================ */}
                    <div
                        className={`col-lg-8 pt-lg-5`}
                        style={{
                            // border: 'solid yellow 2px'
                        }}
                    >
                        {/* ============================================================
                        ===========================HEADER===========================
                        ============================================================ */}
                        <h1
                            className='ml-0 pt-5'
                            style={{
                                fontSize: `clamp(1.3rem, ${headerIdealFontSize}, ${headerMaxFontSize})`,
                                width: 'clamp(10px, 75%, 1500px)',
                                // fontSize: '5vw',
                                fontWeight: 'bold',
                                letterSpacing: '.05rem'
                                // marginLeft: '-.5rem'
                            }}
                        >
                            {content.headerText}
                        </h1>
                        {/* ============================================================
                        ===========================DESCRIPTION==========================
                        ============================================================ */}
                        <p
                            className={`ml-0 ${styles.description}`}
                            style={{
                                // maxWidth: '400px'
                            }}
                        >
                            {content.description}
                        </p>
                        {/* ============================================================
                        =======================NAVIGATION BUTTONS=======================
                        ============================================================ */}
                        <div className='container'>
                            <div className='row'>
                                {content.buttons.map((button, index) => (
                                    <div
                                        key={`${index} ${button.label}`}
                                        className='col-xs-4 mb-2 mr-2 mt-2'
                                    >
                                        <Button colour={button.colour} link={button.link} >{button.label}</Button>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/* ======================================================== */}
                        {
                            props.secondaryContent &&
                            <SecondaryContent>
                                {/* ============================================================
                                ===========================HEADER===========================
                                ============================================================ */}
                                <h3
                                    className='ml-0 pt-5 font-weight-bold text-capitalize'
                                    style={{
                                        // fontSize: `clamp(1.3rem, ${headerIdealFontSize}, ${headerMaxFontSize})`,
                                        width: 'clamp(10px, 75%, 1500px)',
                                        // fontSize: '5vw',
                                        fontWeight: 'bold',
                                        letterSpacing: '.05rem'
                                        // marginLeft: '-.5rem'
                                    }}
                                >
                                    Get your ticket for PLM LIVE today
                                </h3>
                                <p>Saturday 4th March, County Hall, London</p>
                                {/* ============================================================
                                =======================NAVIGATION BUTTONS=======================
                                ============================================================ */}
                                <div className='container'>
                                    <div className='row'>
                                        <Button colour='none-outline' link='https://www.tickettailor.com/events/practiceunbound/760168' >book your place here</Button>
                                    </div>
                                </div>
                            </SecondaryContent>
                        }
                    </div>
                    {/* ============================================================
                        ============================COL-2===========================
                        ============================================================ */}
                    <div className='col-lg-4 mb-4'>
                        {/* ============================================================
                        ==============================IMAGE=============================
                        ============================================================ */}
                        <div
                            className={`${styles.parentContainer} ${props.secondaryContent && 'mt-lg-5 mt-xl-5'}`}
                        >

                            <div className={`${styles.bubbleBlue}`}>
                                <div className={styles.relativeContainer}>
                                    <div className={styles.indianWorkerSittingAtDesk}>
                                        <span role='img' aria-label='Indian worker sitting at desk'></span>
                                    </div>
                                    <div className={styles.bubbleOrange}></div>
                                    <div className={styles.bubbleGreen}></div>
                                    <div
                                        className={styles.bubblePink}
                                        // pink bubble goes below laptop image but above other image
                                        style={{ zIndex: 1 }}
                                    ></div>
                                    {/* <div className={styles.bubbleOfferText}>
                                        <div>
                                            <div style={{ fontSize: '1rem' }}>15% off</div>
                                            <br /> Workflow Go<br />
                                            until 23rd September.
                                            (new customers only)
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ============================================================
                    ==============================COL-3=============================
                    ============================================================ */}
                    <div
                        className='col-6'
                        style={{
                            // border: 'solid yellow 1px'
                        }}
                    >
                        {/* ============================================================
                        ============================CHEVRONS============================
                        ============================================================ */}
                        <div className='' style={{ minHeight: '75px' }}>
                            {/* <CarouselButtonsDev setPicture={setPicture} picture={picture} /> */}
                        </div>
                    </div>
                    <div
                        className={`col-6 p-0 mb-5 ${styles.hereLogoContainer}`}
                    >
                        {/* <p className='mx-0 mb-0' style={{ fontSize: '.7rem' }}>Part of</p> */}
                        <img className={styles.hereLogo} src={hereLogo} alt='Here logo'></img>
                        {/* <p className='mx-0 mb-1' style={{ fontSize: '.7rem', wordWrap: 'noWrap' }}>A Not For Profit Social Enterprise</p> */}
                    </div>
                </div>
            </div>
        </DivFade>
    )
}

function SecondaryContent(props) {
    return (
        <>
            {props.children}
        </>
    )
}