import React from 'react'
import Carousel from '../../../common/Carousel'
import DivFade from '../../DivFade'

export default function CreatorsCarousel() {
    return (
        <DivFade className="bg-white">
            <div className="container py-4">
                <div className="row">
                    <div className="col-md-12 px-4">
                        <h3 style={{ fontWeight: 'bold' }}>Course Creators</h3>
                        <p>
                            This programme is the result of a partnership between Clinical Education, Practice Unbound and leading international experts in lifestyle medicine Dr Rangan Chatterjee and Dr Ayan Panja. It brings together the science behind lifestyle medicine and the practical application to enable implementation in a standard Primary Care appointment system. The programme is accredited by the RCGP.
                        </p>
                    </div>
                </div>
            </div>

            <Carousel
                carouselContentType='courseCreators'
                backgroundColour={'blue'}
                titleHeight='28px'
                height='385px'
                items={
                    [
                        {
                            key: 1,
                            title: 'Dr Ayan Panja',
                            subtitle: 'FRCGP',
                            image: 'https://practiceunbound.blob.core.windows.net/stock-images/AyanHompageTopAndBottomImageCarousel-min.jpg',
                            paragraph: 'Ayan has been qualified for 25 years, and has been working as an NHS GP partner since 2003. He has a longstanding interest in lifestyle medicine having undertaken further education in both the UK and the USA. His other roles have included being clinical assurance adviser to NHS Digital and an expert presenter for the BBC Radio Science Unit and BBC World News.  He is a Fellow of the RCGP and is a Visiting Lecturer in Public Health at The University of Hertfordshire.'
                        },
                        {
                            key: 2,
                            title: 'Dr Rangan Chatterjee',
                            subtitle: 'BSc (Hons) MRCP MRCGP',
                            image: 'https://practiceunbound.blob.core.windows.net/stock-images/RanganHomepageTopImage_2017_07_Chatterjee-3852-Edit-3Carousel-min.jpg',
                            paragraph: 'He has a keen interest in the relationship of the gut microbiome to human health and his BSc Honours degree in Immunology helps him navigate this complex and exciting new field. As a leader in this field, he advocates internationally about lifestyle medicine which he believes should be personalised, participatory and progressive.'
                        },
                        {
                            key: 3,
                            title: 'Michael Ash',
                            subtitle: 'Director of Clinical Education',
                            image: 'https://practiceunbound.blob.core.windows.net/stock-images/MEAImage-min.jpg',
                            paragraph: 'For 25 years he has specialised in the mucosal immune system, in particular, that related to the gastrointestinal tract. He has developed and refined a number of effective non-pharmacological clinical strategies and protocols. He is a well-published scientific author and lead educator of Clinical Education.'
                        },
                    ]
                }
            />
        </DivFade>
    )
}
