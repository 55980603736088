import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
//import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { checkForToken, logoutUser } from './actions/authActions'
import { getProducts } from './actions/productActions'
import { getAnonProfile } from './actions/profileActions'
import { Provider } from 'react-redux';
import store from './store';
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { createBrowserHistory as createHistory } from 'history'
import ReactGA from 'react-ga4';
import { Helmet } from "react-helmet";
import TagManager from 'react-gtm-module'


//css
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
// import './Nls.scss'; //run the build file if needed
import './Nls.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from 'aos'
import 'aos/dist/aos.css'

//Components
import AdminRoute from './components/common/AdminRoute'
import Navbar from './components/layout/Navbar'
import Footer from './components/layout/Footer'
// import LoginIframe from './components/auth/LoginIframe'
import ForgotResetScreen from './components/auth/ForgotResetScreen'
// import EmailVerifyScreen from './components/auth/EmailVerifyScreen'
import LoginScreen from './components/auth/LoginScreen'
import LogInAs from './components/auth/LogInAs'
import LogoutScreen from './components/auth/LogoutScreen'
import AccountScreen from './components/account/AccountScreen'
import BrowseRouter from './components/browse/BrowseRouter'
import BlogRouter from './components/blog/BlogRouter'
import LearnerRouter from './components/learner/LearnerRouter'
import OrganisationRouter from './components/admin/RouterOrganisationRouter';
// import BuyRouter from './components/buy/BuyRouter'
import PurchaseRouter from './components/purchase/PurchaseRouter'
import Error404Screen from './components/errors/Error404Screen';
import PrivateRoute from './components/common/PrivateRoute'
import AuthRoute from './components/common/AuthRoute'
import BigFooter from './components/layout/BigFooter'
import ScrollUp from './components/common/ScrollUp'
import OurStoryScreen from './components/pages/OurStoryScreen';
import ContactUsScreen from './components/pages/ContactUsScreen';
import CareersScreen from './components/pages/CareersScreen';
import CustomerStoriesScreen from './components/pages/CustomerStoriesScreen';
import SubPageScreen from './components/pages/SubPageScreen'
// import NominateScreen from './components/nominate-owner/NominateScreen'
import FAQScreen from './components/pages/FAQScreen';
import TermsConditionsScreen from './components/pages/TermsConditionsScreen';
import PrivacyNoticeScreen from './components/pages/PrivacyNoticeScreen';
import PortalRedirect from './PortalRedirect'
import CookieConsent from './CookieConsent'
// import FloatingPrompt from './components/common/FloatingPrompt'
// import PLMPopup from './components/common/PLMPopup'
import PageScreen from './components/pages/PageScreen'
import PathForm from './components/layout/NLS/PathForm'
import Banner from './Banner'
// import PathGoPopup from './components/common/PathGoPopup'
// import VGCPopup from './components/common/VGCPopup'
import BillsbyThankyou from './components/purchase/BillsbyThankyou'
import { billsbyEnv } from './config/parameters'

//font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faSpinner,
  faAngleRight,
  faCheckCircle,
  faAngleLeft,
  faArrowLeft,
  faArrowRight,
  faUserCircle,
  faCheck,
  faEllipsisV,
  faEdit,
  faSave,
  faTasks,
  faLock,
  faGraduationCap,
  // faChevronLeft,
  // faChevronRight,
  // faChevronCircleLeft,
  // faChevronCircleRight
} from '@fortawesome/free-solid-svg-icons'
import NewsLetterScreen from './components/pages/NewsLetterScreen';
import PersonalContractScreen from './components/account/PersonalContractScreen';
import CancelBillsbyScreen from './components/purchase/CancelBillsbyScreen';
import PLMBursaryForm from './components/common/PLMBursaryForm';

import detectIdle from './utils/detectIdle'
import StripeOneTime from './components/purchase/StripeOneTime';
import StripeSuccess from './components/purchase/StripeSuccess';
import ContactScreen from './components/pages/ContactScreen';
detectIdle(
  () => {

    if (store.getState().auth.isAuthenticated) {
      window.location.href = '/logout'
      store.dispatch(logoutUser())
    }

  }, 1200 //20 mins
)

library.add(faSpinner)
library.add(faAngleRight)
library.add(faAngleLeft)
library.add(faArrowLeft)
library.add(faArrowRight)
library.add(faUserCircle)
library.add(faCheck)
library.add(faEllipsisV)
library.add(faEdit)
library.add(faSave)
library.add(faCheckCircle)
library.add(faTasks)
library.add(faLock)
library.add(faGraduationCap)
// library.add(faChevronLeft)
// library.add(faChevronRight)
// library.add(faChevronCircleLeft)
// library.add(faChevronCircleRight)

//Initialise Google Analytics
if (process.env.REACT_APP_GOOGLE_ANALYTICS === "true") {
  ReactGA.initialize('G-S2SWYK96PZ');
}
const history = createHistory()
history.listen(location => {

  if (process.env.REACT_APP_GOOGLE_ANALYTICS === "true") {
    ReactGA.set({ page: location.pathname })
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }
})

const tagManagerArgs = {
  gtmId: 'GTM-K5JZPQ3'
}
TagManager.initialize(tagManagerArgs)

//load token from localstorage
store.dispatch(checkForToken())
store.dispatch(getAnonProfile())
store.dispatch(getProducts())

AOS.init({
  delay: '100',
  offset: '200px',
  duration: '800',
  once: true
});


// have to do this here as company name is dynamic
var billsbyScript = document.createElement('script');
billsbyScript.src = "https://checkoutlib.billsby.com/checkout.min.js";
billsbyScript.setAttribute('data-billsby-company', billsbyEnv)
document.getElementsByTagName('head')[0].appendChild(billsbyScript);


class App extends Component {
  componentDidMount() {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS === "true") {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
  }

  render() {

    if (window.top !== window.self) {
      return <div>Iframing is blocked</div>
    }

    return (
      <Provider store={store}>
        <Router history={history}>
          <Switch>
            <Route exact path="/portal-redirect" component={PortalRedirect} />
            {/* <Route exact path="/login-iframe" component={LoginIframe} /> */}
            <Route exact path="/navbar-iframe" component={Navbar} />
            <PrivateRoute exact path={'/bb/cancel'} component={CancelBillsbyScreen} />
            <Route exact path={'/purchase/:product/bb/thankyou'} component={BillsbyThankyou} />
            <Route exact path="/pathology-go-form" component={PathForm} />
            <Route exact path="/plm-bursary" component={PLMBursaryForm} />
            {/* <Route exact path={'/pathology-go-form'} render={props => {
              return <PageScreen match={{ params: { pageSlug: "pathology-go-form" } }} />
            }} /> */}

            <Route path="/" render={({ location }) =>
              <div id="app">
                <Helmet>
                  <meta name="description" content="We’ve helped over 1,700 practices and Primary Care Networks in England, Scotland and Wales save time, money and improve patient outcomes."
                  />
                  <meta name="twitter:card" content="summary" />
                  <title>Practice Unbound - Primary Care Solutions, Innovation & GP Consultancy</title>
                </Helmet>
                <Navbar />
                <Route path="/" component={ScrollUp} />
                <div id="main-wrapper" role="main" aria-label="main content area">
                  <TransitionGroup appear={true}>
                    <CSSTransition
                      key={location.pathname.split('/')[1]}
                      classNames="fadeRouter-zeros"
                      timeout={150}
                    >
                      <Switch location={location}>
                        <Route exact path="/" component={BrowseRouter} />
                        {/* <PrivateRoute exact path="/verify-email/:token" component={EmailVerifyScreen} /> */}
                        {/* <PrivateRoute exact path="/setup-organisation" component={NominateScreen} /> */}
                        <Route exact path="/terms-conditions" component={TermsConditionsScreen} />
                        <Route exact path="/privacy-notice" component={PrivacyNoticeScreen} />
                        <Route path="/blog" component={BlogRouter} />
                        <Route path="/our-story" component={OurStoryScreen} />
                        <Route path="/careers" component={CareersScreen} />
                        <Route path="/customer-stories" component={CustomerStoriesScreen} />
                        <Route path="/faq" component={FAQScreen} />
                        <Route path="/contact-us/:campaign" component={ContactScreen} />
                        {/* <Route path="/contact-us" component={ContactUsScreen} /> */}
                        <Route path="/contact-us" component={ContactScreen} />
                        <Route path="/newsletter" component={NewsLetterScreen} />
                        <Route path="/product/:product" component={LearnerRouter} />
                        {/* <PrivateRoute path="/buy" component={BuyRouter} /> */}
                        <Route exact path="/purchase/prescribinglifestylemedicine"
                          render={props => {
                            return <StripeOneTime {...props} product="prescribinglifestylemedicine" />
                          }}
                        />
                        <Route exact path="/purchase/plmaccreditationonly"
                          render={props => {
                            return <StripeOneTime {...props} product="plmaccreditationonly" dob={true} />
                          }}
                        />
                        <Route exact path="/purchase/plmaccreditationandcourse"
                          render={props => {
                            return <StripeOneTime {...props} product="plmaccreditationandcourse" dob={true} />
                          }}
                        />
                        <Route exact path="/purchase/plmaccreditationandcourseandlive"
                          render={props => {
                            return <StripeOneTime {...props} product="plmaccreditationandcourseandlive" dob={true} />
                          }}
                        />
                        <Route exact path="/purchase/prescribinglifestylemedicine/success"
                          render={props => {
                            return <StripeSuccess {...props} product="prescribinglifestylemedicine" />
                          }}
                        />
                        <Route exact path="/purchase/plmaccreditationonly/success"
                          render={props => {
                            return <StripeSuccess {...props} product="prescribinglifestylemedicine" />
                          }}
                        />
                        <Route exact path="/purchase/plmaccreditationandcourse/success"
                          render={props => {
                            return <StripeSuccess {...props} product="prescribinglifestylemedicine" />
                          }}
                        />
                        <Route exact path="/purchase/plmaccreditationandcourseandlive/success"
                          render={props => {
                            return <StripeSuccess {...props} product="prescribinglifestylemedicine" />
                          }}
                        />
                        <PrivateRoute path="/purchase" component={PurchaseRouter} />
                        <Route exact path="/login" component={LoginScreen} />
                        <AdminRoute exact path="/l/i/a/:user" component={LogInAs} />
                        <Route exact path="/logout" component={LogoutScreen} />
                        <AuthRoute exact path="/forgotten-reset/:token" component={ForgotResetScreen} />
                        <PrivateRoute exact path="/account" component={AccountScreen} />
                        <PrivateRoute exact path="/account/contracts" component={PersonalContractScreen} />
                        <PrivateRoute path="/admin" component={OrganisationRouter} />
                        <Route path={'/:pageType/:subPage'} component={SubPageScreen} />
                        <Route path={'/:pageSlug'} component={PageScreen} />
                        <Route component={Error404Screen} />
                      </Switch>
                    </CSSTransition>
                  </TransitionGroup>

                </div>


                {/* <Route path="/" component={FloatingPrompt} /> */}
                {/* <PathGoPopup />
                <VGCPopup /> */}
                <div id="footers">
                  <Switch>
                    <Route path="/admin" />
                    <Route path="/product/:product/data" />
                    <Route path="/product/:product/data-signup" />
                    <Route path="/">
                      <BigFooter nlsRedesign />
                    </Route>
                  </Switch>
                  <Switch>
                    <Route path="/product/:product/data" />
                    <Route path="/">
                      <Footer />
                    </Route>
                  </Switch>
                </div>

                {/* <Route path="/" component={ProductFooter} /> */}
                <CookieConsent />
                <Banner />
                {/* <Route path="/" component={PLMPopup} /> */}
              </div>
            } />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;
