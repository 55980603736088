import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import Button from '../common/Button'
import classnames from 'classnames'
import styles from './LearnerNavbar.module.css'
import { setOrganisation } from '../../actions/organisationActions'
import { getLNA, clearLNA, getOpmData, clearOpmData } from '../../actions/productActions'
import Select from '../common/Select'
import Hero from '../layout/Hero'
import Modal from '../common/Modal'
import HoverLinkButton from './HoverLinkButton'
import processRichText from '../../utils/processRichText'
import Block from '../layout/Block';
// import ImageCircleCard from '../layout/ImageCircleCard'

class LearnerNavbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            promptLna: true,
        }
        this.updateOrganisation = this.updateOrganisation.bind(this)
        this.organisationProductMembershipRequests = this.organisationProductMembershipRequests.bind(this)
    }
    componentDidMount() {
        this.organisationProductMembershipRequests()
        if (sessionStorage.promptLna === "false") {
            this.setState({ promptLna: false })
        }
        this.props.clearLNA()
        this.props.clearOpmData()
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.organisations.organisation !== this.props.organisations.organisation
        ) {
            this.organisationProductMembershipRequests()
        }
    }
    organisationProductMembershipRequests() {
        const { product } = this.props.products
        if (product.purchased && this.props.organisations.organisation) {
            // this.props.getLNA(
            //     this.props.organisations.organisation.code,
            //     this.props.products.product.name)
            this.props.getOpmData(
                this.props.organisations.organisation.code,
                this.props.products.product.name
            )
        }
    }
    updateOrganisation(organisation) {
        const filterArr = this.props.organisations.organisations.filter(o => o.code === organisation.value)
        if (filterArr.length === 1) {
            sessionStorage.setItem('organisation', JSON.stringify(filterArr[0]))
            this.props.setOrganisation(filterArr[0])
        }
    }

    render() {
        const { product, lna, lnaLoading, learnerOpmData, learnerOpmDataLoading } = this.props.products
        const { organisations, organisation } = this.props.organisations

        let isPLM = ['prescribinglifestylemedicine', 'prescribinglifestylemedicinepractice'].includes(product.name)
        let isGPBilling = ['gpbilling'].includes(product.name)
        let isPathGo = ['pathologygo'].includes(product.name)
        
        // console.log("🚀 ~ file: LearnerNavbar.js:67:", isPLM ? 'isPLM' : isGPBilling ? 'isGPBilling' : isPathGo ? 'isPathGo': 'no product name')
        

        let isOwner = null
        if (organisation) {
            if (this.props.auth.ownedOrganisations) {
                if (this.props.auth.ownedOrganisations.includes(organisation.code)) {
                    isOwner = true
                } else {
                    isOwner = false
                }
            }
        }

        let promptLna = false
        if (isOwner && this.state.promptLna && product.purchased && product.enforceLna && !lnaLoading && lna === null) {
            promptLna = true
        }

        let launchUrl
        if (product.productLink.length >= 4) {
            if (product.productLink.substring(0, 4) !== "/pro") {
                launchUrl = product.productLink
            }
        }

        let organisationOptions = []
        organisations.forEach(org => {
            organisationOptions.push({ label: org.name, value: org.code })
        })

        let organisationSelection = {}
        if (organisation) {
            organisationSelection = { label: organisation.name, value: organisation.code }
        }

        let shrink = true
        const { pathname } = this.props.location
        if (pathname.length > 4) {
            if (pathname.substring(pathname.length - 4) === "home") {
                shrink = false
            }
        }

        let currentOwner = false
        if (this.props.organisations.organisation) {
            if (this.props.auth.ownedOrganisations.includes(this.props.organisations.organisation.code)) {
                currentOwner = true
            }
        }

        const navDisplay = isPLM || isGPBilling || isPathGo || (this.props.auth.isAuthenticated && (product.showGetStartedTab === true || product.showElearningTab === true || product.showResourcesTab === true || product.showDataTab === true))

        const bannerButtonColour = product.productBannerIconUrl ? 'yellow' : 'green'

        const bannerContent = <>
            {product.productBannerIconUrl ? (<>
                <h1 className="mb-4"
                    style={{ fontWeight: 'bold' }}
                >{product.subtitle}</h1>
                <p className="lead mb-4">{product.description}</p>
            </>
            ) : (
                <>
                    <h1 className="display-4 my-0"
                        style={{ fontSize: '56px' }}
                    >{product.title}</h1>
                    <p className="lead mt-0">{product.subtitle}</p>
                </>
            )}

            {/* remember to also look at the homescreen redirects. 3 scenarios - anon, logged in not purchased, and purchased, this cover those */}
            {(launchUrl && product.purchased === true) ? (<Button className="mr-3 mb-2" inline link={launchUrl} large>Get Started</Button>
            ) : product.showGetStartedTab === true ? (
                <Button className="mr-3 mb-2" inline link={this.props.match.path + '/get-started'} large colour={bannerButtonColour}>Get Started</Button>
            ) : product.showResourcesTab === true ? (
                <Button className="mr-3 mb-2" inline link={this.props.match.path + '/resources'} large colour={bannerButtonColour}>Get Started</Button>
            ) : product.showElearningTab && (
                <Button className="mr-3 mb-2" inline link={this.props.match.path + '/course'} large colour={bannerButtonColour}>Get Started</Button>
            )}
            {product.purchased !== true && (
                (product.allowOnlineContracting && !product.preorderMode) ? (
                    <Button inline link={`/purchase/${product.name}`} large colour={bannerButtonColour}>Buy Now</Button>
                ) : (
                    <Button inline link='/contact-us' large colour={bannerButtonColour}>Enquire</Button>
                )
            )}
        </>

        let checkPLMHideTab = ((this.props.auth.isAuthenticated && product.purchased === true))

        return (
            <>
                {/* Navbar section */}
                {/* this moves everything down for position fixed */}
                <div style={{ height: navDisplay && '46px' }} />
                <div className="bg-white" style={{
                    zIndex: '1001',
                    top: '54px',
                    left: 0,
                    right: 0,
                    // position: 'sticky',
                    position: 'fixed',
                    height: navDisplay && '46px'//this allows for the dropdown without resizing
                }} >
                    {navDisplay && (
                        <div className="container">
                            <nav >

                                <ul style={{ overflowX: 'auto' }} className={classnames("nav d-flex flex-nowrap mr-auto py-1", styles.navbar)}>
                                    {
                                        this.props.auth.isAuthenticated && product.purchased === true ? null : (navDisplay && !isPLM) && (
                                            <li className="nav-item ">
                                                <Button colour="none" className="mr-3" link={`/product/${product.name}/home`}>Overview</Button>
                                            </li>
                                        )
                                    }
                                    {
                                        this.props.auth.isAuthenticated && product.purchased === true ? null : isPLM && (
                                            <>
                                                <li className="nav-item ">
                                                    <Button colour="none" className="mr-3" link={`/product/${product.name}/home`}>Overview</Button>
                                                </li>
                                                <li className="nav-item ">
                                                    <Button colour="none" className="mr-3" link={`/product/${product.name}/about-plm`}>plm online course</Button>
                                                </li>
                                                <li className="nav-item ">
                                                    <Button colour="none" className="mr-3" link={`/product/${product.name}/accreditation`}>certification</Button>
                                                    {/* <Button colour="none" className="mr-3" link={`/product/${product.name}/accreditation`}>Crossfields Certification</Button> */}
                                                </li>
                                                {/* <li className="nav-item ">
                                                    <Button colour="none" className="mr-3" link={`/product/${product.name}/event-plm`}>PLM Live</Button>
                                                </li> */}
                                                <li className="nav-item ">
                                                    <Button colour="none" className="mr-3" link={`/product/${product.name}/enrol-plm`}>enrol now</Button>
                                                </li>
                                                <li className="nav-item ">
                                                    <Button colour="none" className="mr-3" link={`/product/${product.name}/organisations-plm`}>For Organisations</Button>
                                                </li>
                                            </>
                                        )
                                    }
                                        {this.props.auth.isAuthenticated && product.purchased === true ? null : isPathGo && (
                                            <>
                                                <li className="nav-item ">
                                                    <Button colour="none" className="mr-3" link={`/product/${product.name}/pathgo-benefits`}>benefits</Button>
                                                </li>
                                                {/* <li className="nav-item ">
                                                    <Button colour="none" className="mr-3" link={`/product/${product.name}/pathgo-pricing`}>pricing</Button>
                                                </li> */}
                                                <li className="nav-item ">
                                                    <Button colour="none" className="mr-3" link={`/product/${product.name}/pathgo-implementation`}>implementation</Button>
                                                </li>
                                                <li className="nav-item ">
                                                    <Button colour="none" className="mr-3" link={`/product/${product.name}/pathgo-whatsincluded`}>whats included</Button>
                                                </li>
                                                <li className="nav-item ">
                                                    <Button colour="none" className="mr-3" link={`/product/${product.name}/pathgo-faqs`}>faqs</Button>
                                                </li>
                                            </>
                                        )}
                                    {
                                        this.props.auth.isAuthenticated && product.purchased === true ? null : isGPBilling && (
                                            <>
                                                <li className="nav-item ">
                                                    <Button colour="none" className="mr-3" link={`/product/${product.name}/system-features`}>system features</Button>
                                                </li>
                                                <li className="nav-item ">
                                                    <Button colour="none" className="mr-3" link={`/product/${product.name}/success-stories`}>success stories</Button>
                                                </li>
                                                <li className="nav-item ">
                                                    <Button colour="none" className="mr-3" link={`/product/${product.name}/25-top-billable`}>25 top billable</Button>
                                                </li>
                                            </>
                                        )
                                    }

                                    {product.showGetStartedTab === true && checkPLMHideTab && (
                                        <li className="nav-item">
                                            <Button colour="none" className="mr-3" link={`/product/${product.name}/get-started`}>Get Started</Button>
                                        </li>
                                    )}

                                    {['prescribinglifestylemedicinepractice', 'prescribinglifestylemedicine'].includes(product.name) && checkPLMHideTab && (
                                        <li className="nav-item">
                                            <Button colour="none" className="mr-3" link={`/product/${product.name}/implementation`}>Implementation</Button>
                                        </li>
                                    )}

                                    {currentOwner && product.showLeadTab === true && checkPLMHideTab && (
                                        <li className="nav-item">
                                            <Button colour="none" className="mr-3" link={`/product/${product.name}/setup`}>Setup</Button>
                                        </li>
                                    )}

                                    {product.showAssessmentTab === true && checkPLMHideTab && (
                                        product.purchased ? (
                                            <li className="nav-item">
                                                <Button colour="none" className="mr-3" link={`/product/${product.name}/assessment`}>Self-Assessment</Button>
                                            </li>

                                        ) : (
                                            <HoverLinkButton
                                                buttonTitle={'Self-Assessment'}
                                                hoverDisable
                                                hoverBoxText={'Purchase Prescribing Lifestyle Medicine to access this page'}
                                            />
                                        )
                                    )}

                                    {['prescribinglifestylemedicine', 'prescribinglifestylemedicinepractice'].includes(product.name) && checkPLMHideTab && (
                                        product.purchased ? (
                                            <li className="nav-item">
                                                <Button colour="none" className="mr-3" link='https://plm-community.practiceunbound.org.uk/' target="_blank">Community</Button>
                                            </li>
                                        ) : (
                                            <HoverLinkButton
                                                buttonTitle={'Community'}
                                                hoverDisable
                                                hoverBoxText={'Purchase Prescribing Lifestyle Medicine to access this community'}
                                            // modalTitle={"Get started with data"}
                                            // modalContent={""}
                                            // onChildClick={this.handleChildClick}
                                            />
                                        )
                                    )}

                                    {product.showElearningTab === true && checkPLMHideTab &&
                                        (product.purchased && product.enforceDataBeforeCourse && (learnerOpmDataLoading || (learnerOpmData && learnerOpmData.submittedData !== true))) ? (
                                            <HoverLinkButton
                                                buttonTitle={'Course'}
                                                hoverDisable
                                                hoverBoxText={'You aren\'t quite ready to start your course yet - click to find out more'}
                                                modalTitle={"Get started with data"}
                                                modalContent={processRichText(product.enforeDataBeforeCourseText && product.enforeDataBeforeCourseText.content)}
                                                onChildClick={this.handleChildClick}
                                            />
                                        ) :
                                        checkPLMHideTab && (product.purchased && product.enforceAuditCheckBeforeCourse && (learnerOpmDataLoading || (learnerOpmData && learnerOpmData.auditChecked !== true))) ? (
                                            <HoverLinkButton
                                                buttonTitle={'Course'}
                                                hoverDisable
                                                hoverBoxText={'You aren\'t quite ready to start your course yet - click to find out more'}
                                                modalTitle={"Check your audit"}
                                                modalContent={
                                                    <div>
                                                        In order to start your learning journey you must make sure that the audit process works for your organisation. <Link to={`/product/${product.name}/resources/auditing`}>Click here</Link> for instructions on how to get started with this.
                                                        {
                                                            currentOwner ? (
                                                                <>
                                                                    Once you are confident in your process, head to the admin area and confirm you are happy and unlock the course section.
                                                                    <Button colour="yellow" inline center link={`/admin/${product.name}`}>Admin Area</Button>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    Once you are confident in your process, the lead member of your organisation will be able to unlock the course section.
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                }
                                                onChildClick={this.handleChildClick}
                                            />
                                        ) : product.showElearningTab === true && checkPLMHideTab &&
                                            (
                                                <li className="nav-item">
                                                    <Button colour="none" className="mr-3" link={`/product/${product.name}/course`}>Course</Button>
                                                </li>
                                            )
                                    }

                                    {product.showResourcesTab === true && checkPLMHideTab && (
                                        <li className="nav-item">
                                            <Button colour="none" className="mr-3" link={`/product/${product.name}/resources`}>Resources</Button>
                                        </li>
                                    )}

                                    {['workflowgo'].includes(product.name) && checkPLMHideTab && (
                                        product.purchased ? (
                                            <li className="nav-item">
                                                <Button colour="none" className="mr-3" link={`/product/${product.name}/protocol`} target="_blank">Protocol</Button>
                                            </li>
                                        ) : (
                                            <HoverLinkButton
                                                buttonTitle={'Protocol'}
                                                hoverDisable
                                                hoverBoxText={'Purchase Workflow Go to configure your protocol'}
                                            />
                                        )
                                    )}

                                    {['workflowgo'].includes(product.name) && checkPLMHideTab && (
                                        product.purchased ? (
                                            <li className="nav-item">
                                                <Button colour="none" className="mr-3" link={`/product/${product.name}/timeline`} target="_blank">Timeline</Button>
                                            </li>
                                        ) : (
                                            <HoverLinkButton
                                                buttonTitle={'Timeline'}
                                                hoverDisable
                                                hoverBoxText={'Purchase Workflow Go to configure your timeline'}
                                            />
                                        )
                                    )}
                                    {product.showDataTab === true && checkPLMHideTab && (
                                        <li className="nav-item">
                                            <Button colour="none" className="mr-3" link={`/product/${product.name}/data`}>Data</Button>
                                        </li>
                                    )}
                                    {['prescribinglifestylemedicine'].includes(product.name) && checkPLMHideTab && product.purchased && (
                                        <li className="nav-item">
                                            <Button colour="none" className="mr-3" link={`/product/${product.name}/data-signup`} >Data</Button>
                                        </li>
                                    )}
                                    {product.showResourcesTab === true && checkPLMHideTab && isPLM && (
                                        <li className="nav-item">
                                            <Button colour="none" className="mr-3" link={`/product/${product.name}/accreditation`}>certification</Button>
                                        </li>
                                    )}
                                    {/* {
                                        this.props.auth.isAuthenticated && product.purchased === true && isPLM && (
                                            <li className="nav-item ">
                                                <Button colour="none" className="mr-3" link={`/product/${product.name}/event-plm`}>PLM Live</Button>
                                            </li>
                                        )
                                    } */}
                                </ul>
                                {product.purchased && (
                                    <ul className={classnames("nav d-flex flex-row-reverse", styles.organisations)}>
                                        <li className="nav-item" style={{ width: '300px' }}>
                                            {organisationOptions.length > 1 && (
                                                <Select
                                                    hideError
                                                    className="ml-auto"
                                                    style={{
                                                        zIndex: '601'
                                                    }}
                                                    value={organisationSelection}
                                                    onChange={e => {
                                                        this.updateOrganisation(e)
                                                    }}
                                                    options={organisationOptions}
                                                />
                                            )}
                                        </li>
                                    </ul>
                                )}
                            </nav>
                        </div>
                    )}
                </div>

                {/* Image section */}
                {product.newUi !== true && (
                    product.productBannerIconUrl ? (
                        <Block backcircles>
                            <div className="row">
                                <div className="col-md-7">
                                    {bannerContent}
                                </div>
                                <div className="col-md-5">
                                    <div style={{
                                        backgroundImage: `url("${product.productBannerIconUrl}")`,
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'contain',
                                        width: '100%',
                                        height: '100%'
                                    }} />
                                </div>
                            </div>
                        </Block>
                    ) : (
                        <>
                            {/* <div>
                                <Hero
                                    color={product.color}
                                    shrunk={shrink}
                                    image={product.image2}
                                >
                                    <div style={{ height: '450px', position: 'relative' }}>
                                        <div style={{
                                            position: 'absolute',
                                            top: '50%',
                                            MsTransform: 'translateY(-50%)',
                                            transform: 'translateY(-50%)'
                                        }}>
                                            {bannerContent}
                                        </div>
                                    </div>
                                </Hero>
                            </div> */}
                        </>
                    ))}

                {/* Product name section */}
                {['gpbilling'].includes(product.name) ? null : isPLM ? (
                    <div style={{
                        zIndex: '1000',
                        top: navDisplay ? '100px' : '54px',
                        position: 'sticky',
                        height: '2px',
                        backgroundColor: '#f3f3f3'
                        // boxShadow: '0 8px 12px -5px rgba(0,0,0,.4)',
                    }} />
                ) : (
                    <div className="bg-white" style={{
                        zIndex: '1000',
                        top: navDisplay ? '100px' : '54px',
                        position: 'sticky',
                        // boxShadow: '0 8px 12px -5px rgba(0,0,0,.4)',
                    }} >
                        <div
                            style={{
                                height: product.newUi ? '28px' : shrink ? '26px' : (product.productBannerIconUrl ? '0px' : '3px'),
                                WebkitTransition: 'height 0.15s',
                                transition: 'height 0.15s',
                                boxShadow: '0 1px 4px 0 rgba(0,0,0,.15)',
                                overflow: 'hidden',
                            }}
                            className={classnames(
                                { "bg-dark-mint": product.color === "green" || !product.color },
                                { "bg-dark-pink": product.color === "pink" },
                                { "bg-dark-yellow": product.color === "yellow" },
                                { "bg-dark-teal": product.color === "teal" },
                            )} >
                            {(shrink || product.newUi) && (
                                <div
                                    className={classnames(
                                        "container",
                                        "tc-white",
                                        { "bg-dark-mint": product.color === "green" || !product.color },
                                        { "bg-dark-pink": product.color === "pink" },
                                        { "bg-dark-yellow": product.color === "yellow" },
                                    )} >
                                    <h6 style={{
                                        lineHeight: product.newUi ? '28px' : '26px',
                                        margin: 0,
                                        fontWeight: 'bold',
                                        letterSpacing: '1.1px'
                                    }}>{product.title}</h6>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {/* {product.preorderMode && product.purchased === true && (
                    <div className="bg-white">
                        <div className="container p-4">
                            <div className="row justify-content-center">
                                <div className="col-md-8">
                                    <ImageCircleCard color="green" header="Thank you"
                                        subHeader="Your preorder has been received" />
                                </div>
                            </div>

                        </div>
                    </div>
                )} */}

                <Modal
                    isOpen={promptLna}
                    onRequestClose={() => {
                        sessionStorage.promptLna = false
                        this.setState({ promptLna: false })
                    }}
                >
                    <h2><strong>Help us personalise your organisation's experience</strong></h2>
                    <p className="lead">You can complete a short questionnaire to help us suggest useful material to you and the users of your practice. Why not complete it before continuing?</p>
                    {organisation && <Button inline link={`/admin/${product.name}/learning-needs-analysis?organisation=${organisation.code}`} center large>OK, Lets go!</Button>}
                </Modal>
            </>
        )
    }
}
const mapStateToProps = state => ({
    products: state.products,
    organisations: state.organisations,
    auth: state.auth
})
export default connect(mapStateToProps, { setOrganisation, getLNA, clearLNA, getOpmData, clearOpmData })(LearnerNavbar);
