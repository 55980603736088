import ProductBanner from "../ProductBanner"
import Button from "../../common/Button"
import BannerFlex from "../BannerFlex"
import puColours from "../../../utils/puColours"
import DivFade from "../../../components/layout/DivFade"
import ImageTextBlock from "../../../components/layout/PLM/Comps/ImageTextBlock"
import Calculator from "../../../components/layout/NLS/Calculator"
import Block from "../Block"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import SocialsIcon from "../../common/SocialsIcon"
import Circles from "../../common/Circles"
import topBannerShapes from "./pricingBubbles"

const bannerImage =
  "https://practiceunbound.blob.core.windows.net/stock-images/pg_banner.jpg"
const logo =
  "https://practiceunbound.blob.core.windows.net/stock-images/logo_pathlogy_go@2x.png"
const htnLogo =
  "https://practiceunbound.blob.core.windows.net/stock-images/htn-logo.png"

export default function PathGoHomepage(props) {
  return (
    <>
      <ProductBanner backgroundImage={bannerImage} logo={logo}>
        <div className='tw-text-white'>
          <h1
            className='tw-font-bold'
            style={{
              fontSize: "clamp(30px,2.5vw,50px)",
              textWrap: "balance",
            }}>
            Save GP time with safe delegation of pathology tests using Pathology
            Go
          </h1>
          <div className='tw-py-6' />
          <Button center large inline colour='yellow' link="/contact-us?contactType=sales">
            enquire now
          </Button>
        </div>
      </ProductBanner>

      <BannerFlex
        backgroundCirclesObj={{
          width: "600px",
          left: "-310px",
          className: "tw-animate-pulse-slow",
        }}
        shapes={whatIsPathGoShapes}
        // classNames={{
        //     shapeSection: ''
        // }}
        classNames={{ shapesSection: "tw-pb-36 md:tw-pb-28" }}>
        <div className='tw-flex tw-flex-col tw-gap-3 tw-py-10'>
          <section className='tw-pl-2'>
            <h4 className='tw-font-bold tw-mb-5'>What is Pathology Go?</h4>
            <p>
             Pathology Go enables the safe delegation of low-risk routine tests using 12 evidence-based decision aids and ensures results are filed and communicated to patients in a timely way. A combination of e-learning, data, and governance tools allow practices to train administrators to handle many low-risk test results. 
            </p>
          </section>
          <div className='tw-flex tw-flex-col sm:tw-flex-row tw-gap-4'>
            <Button inline large colour='hollow-yellow' link="/product/pathologygo/pathgo-whatsincluded">
              what's included?
            </Button>
          </div>
        </div>
      </BannerFlex>

      <DivFade
        style={{
          backgroundColor: puColours.darkMint,
          color: "white",
          position: "relative",
          overflow: "hidden",
        }}>
        {/* <img className='d-none d-md-block tw-animate-slow-spin' alt="" src={circles} style={{ bottom: '-300px', left: '-160px', position: 'absolute', filter: 'brightness(0) invert(87%) sepia(26%) saturate(7240%) hue-rotate(324deg) brightness(99%) contrast(90%)' }} /> */}
        {/* <img className='tw-animate-slow-reverse-spin' alt="" src={circles} style={{ top: '-300px', right: '-160px', position: 'absolute', filter: 'brightness(0) invert(87%) sepia(26%) saturate(7240%) hue-rotate(324deg) brightness(99%) contrast(90%)' }} /> */}
        <div className={`tw-absolute tw-top-[120px] tw-left-[-117px] tw-z-0`}>
          <Circles width='325' colour={puColours.lightMint} />
        </div>
        <div className={`tw-absolute tw-top-[-130px] tw-right-[-120px] tw-z-0`}>
          <Circles width='325' colour={puColours.lightMint} />
        </div>
        <div
          className='container'
          style={{
            position: "relative",
            paddingTop: "75px",
            paddingBottom: "75px",
          }}>
          <div className='row'>
            <div className='col-1' />
            <div className='col-12 col-md-10'>
              <h3 className='text-center mb-4 font-weight-bold'>
                Free Access to Pathology Go: Module 1
              </h3>
              <p className='text-center mb-4' style={{textWrap:"balance"}}>
                An introduction to the Pathology Go programme that will provide you with an overall product understanding, as well as supporting you in using the e-learning modules
              </p>
              <Button inline center colour='none-outline' link="/product/pathologygo/course">
                access module 1
              </Button>
            </div>
            <div className='col-1' />
          </div>
        </div>
      </DivFade>

      <ImageTextBlock
        imageUrl={
          "https://practiceunbound.blob.core.windows.net/stock-images/she-s-looking-forward-day-spa-min.jpg"
        }
        contentSide='right'
        // contentBackgroundImage={circles}
        contentBackgroundSize='30%'
        contentBackgroundPosition='top right'
        contentBackgroundRight='-15%'
        contentBackgroundTop='3%'
        contentColWidth='7'>
        <div className='tw-pl-10'>
          <div className='tw-py-10' />
          <h3 className='tw-font-bold'>Benefits Include:</h3>
          <p>
            Pathology Go is priced on a per-patient model, and contains all the
            resources, training and help you need to get started with improved
            pathology test processing. Benefits include:
          </p>
          <ul className='tick-list'>
            <li className='tw-font-bold' style={{ lineHeight: "1.5" }}>
              Reduce the time your GPs spend looking at low-risk test results
            </li>
            <li className='tw-font-bold' style={{ lineHeight: "1.5" }}>
              Enhance clinical quality
            </li>
            <li className='tw-font-bold' style={{ lineHeight: "1.5" }}>
              Increase focus on complex cases
            </li>
            <li className='tw-font-bold' style={{ lineHeight: "1.5" }}>
              Improve processes in your Practice
            </li>
            <li className='tw-font-bold' style={{ lineHeight: "1.5" }}>
              Upskill your staff
            </li>
          </ul>
          <div className='tw-py-5' />
          <Button colour='yellow' inline link="/product/pathologygo/pathgo-benefits">benefits</Button>
          <div className='tw-py-10' />
        </div>
      </ImageTextBlock>

      <div className='tw-bg-[#169F7B] tw-overflow-hidden'>
        <DivFade className='tw-pt-20 tw-pb-20 container-medium tw-bg-[#169F7B] tw-relative '>
          <div className='tw-absolute tw-top-[-142px] tw-left-[-666px] '>
            <Circles width='400' colour={puColours.lightMint} />
          </div>
          <div className='tw-absolute tw-top-[363px] tw-left-[1080px] '>
            <Circles width='400' colour={puColours.lightMint} />
          </div>

          <section className='text-center tw-text-white'>
            <h2 className='mb-5'>
              <strong>A 2 minute introduction to Pathology Go</strong>
            </h2>
            <div className='video-wrap'>
              <iframe
                src='https://player.vimeo.com/video/459364121'
                title='An introduction to Pathology Go'
                frameBorder='0'
                allow='autoplay; fullscreen'
              />
            </div>
          </section>
        </DivFade>
      </div>

      {/* <BannerFlex
        backgroundCirclesObj={{
          width: "600px",
          left: "-310px",
          className: "tw-animate-pulse-slow",
        }}
        classNames={{ shapesSection: "tw-pb-36 md:tw-pb-28" }}>
        <div className='tw-flex tw-gap-3 tw-py-10'>
          <section className='tw-pl-2'>
            <h4
              className='tw-font-bold tw-mb-5 wrap'
              style={{ textWrap: "balance" }}>
              Process test results safely and save GP time
            </h4>
            <p>
              Pathology Go is a programme of efficiency tools that can be
              implemented easily and quickly into any GP Practice. By training
              and enabling administrators to process test results consistently
              well, Pathology Go saves GP Practices significant GP time and
              delivers better patient communications and outcomes.
            </p>
              <ul className='tick-list'>
                <li className='tw-font-bold' style={{ lineHeight: "1.5" }}>
                  <strong>Reduce</strong> the admin requirement on GPs for simple
                  test results
                </li>
                <li className='tw-font-bold' style={{ lineHeight: "1.5" }}>
                  <strong>Upskill</strong> your admin team, and drive employee
                  engagement and retention
                </li>
                <li className='tw-font-bold' style={{ lineHeight: "1.5" }}>
                  <strong>Give</strong> GPs more oppurtunity to spend time on the
                  more complex results
                </li>
                <li className='tw-font-bold' style={{ lineHeight: "1.5" }}>
                  <strong>Measure</strong> ROI using the Pathology Go Data Dashboard
                </li>
                <li className='tw-font-bold' style={{ lineHeight: "1.5" }}>
                  <strong>Embed</strong> consistent and patient-friendly
                  communication of results and next steps
                </li>
              </ul>
            <div className='tw-py-5' />
          </section>
        </div>
      </BannerFlex> */}

      <Block color="white">
        <section className="tw-py-4 lg:tw-py-8 xl:tw-py-11">
          <div className="tw-container">
            <div className="tw-flex tw-flex-col tw-gap-7 lg:tw-max-w-2xl xl:tw-flex-row xl:tw-max-w-none xl:tw-gap-36">
              <div className="xl:tw-w-2/4">
                <h4
                  className='tw-font-bold tw-mb-5 wrap'
                  style={{ textWrap: "balance" }}>
                  Process test results safely and save GP time
                </h4>
                <p>
                  Pathology Go is a programme of efficiency tools that can be
                  implemented easily and quickly into any GP Practice. By training
                  and enabling administrators to process test results consistently
                  well, Pathology Go saves GP Practices significant GP time and
                  delivers better patient communications and outcomes.
                </p>
              </div>   
              <div>
              <ul className='tick-list'>
                <li className='tw-font-bold' style={{ lineHeight: "1.5" }}>
                  <strong>Reduce</strong> the admin requirement on GPs for simple
                  test results
                </li>
                <li className='tw-font-bold' style={{ lineHeight: "1.5" }}>
                  <strong>Upskill</strong> your admin team, and drive employee
                  engagement and retention
                </li>
                <li className='tw-font-bold' style={{ lineHeight: "1.5" }}>
                  <strong>Give</strong> GPs more oppurtunity to spend time on the
                  more complex results
                </li>
                <li className='tw-font-bold' style={{ lineHeight: "1.5" }}>
                  <strong>Measure</strong> ROI using the Pathology Go Data Dashboard
                </li>
                <li className='tw-font-bold' style={{ lineHeight: "1.5" }}>
                  <strong>Embed</strong> consistent and patient-friendly
                  communication of results and next steps
                </li>
              </ul>
              </div>           
            </div>
          </div>

        </section>
      </Block>
{/* 
      <Block color='#169F7B' textColour='white'>
        <Calculator textColour='white' />
      </Block>

      <Block color='white'>
        <div className='col-12 my-4 text-center'>
          <h2>
            <strong>Testimonials</strong>
          </h2>
        </div>
        <div className='row'>
          <blockquote
            style={{ marginTop: "12px" }}
            className='blockquote mb-4 col-12 col-md-6'>
            <p style={{ fontSize: "18px", fontWeight: "bold" }}>
              <FontAwesomeIcon
                style={{ color: "#169F7B" }}
                className='mr-3 mb-2'
                size='3x'
                icon='quote-left'
                role='presentation'
              />
              The course was excellent, and I cannot recommend it highly enough.
              It gave me a new toolkit to use every day in a very useable format
              that was easy to apply right away. The course left me hungry to
              explore this important part of medicine further and inspired me in
              a way I have not felt about my work for some time. Thank you”
            </p>
            <div
              style={{ fontWeight: "bold" }}
              className='blockquote-footer text-right'>
              Dr Dan Jenkinson | GP and Workflow GP Lead
            </div>
          </blockquote>
          <blockquote
            style={{ marginTop: "12px" }}
            className='blockquote mb-4 col-12 col-md-6'>
            <p style={{ fontSize: "18px", fontWeight: "bold" }}>
              <FontAwesomeIcon
                style={{ color: "#169F7B" }}
                className='mr-3 mb-2'
                size='3x'
                icon='quote-left'
                role='presentation'
              />
              Excellent and transformative course. It should be part of the
              undergraduate medical curriculum and compulsory for all GPs
            </p>
            <div
              style={{ fontWeight: "bold" }}
              className='blockquote-footer text-right'>
              Dr Dan Jenkinson | GP and Workflow GP Lead
            </div>
          </blockquote>
        </div>
      </Block> */}

      <DivFade className='tw-bg-[#169F7B] text-white'>
        <div
          className='container'
          style={{ paddingTop: "72px", paddingBottom: "72px" }}>
          <div className='row justify-content-between'>
            <div className='col-sm-8 mb-3'>
              <h3 className='mb-4' style={{ fontWeight: "bold" }}>
                Got questions? Talk to us…
              </h3>
              <p>
                Whether you are interested in one of our products or would like
                to know more about working with Practice Unbound, please give us
                a call on 0300 303 8145 or email{" "}
                <a
                  className='tw-text-white tw-underline'
                  href='mailto:info@practiceunbound.org.uk'>
                  info@practiceunbound.org.uk
                </a>
              </p>
            </div>
            <div className='col-sm-2 d-flex justify-content-center align-items-center '>
              <span style={{ fontSize: "32px", marginRight: "16px" }}>
                <SocialsIcon
                  className={`tw-text-white hover:tw-text-[#06523D] tw-mx-2`}
                  link={"https://twitter.com/PracticeUnbound"}
                  social='twitter'
                />
              </span>
              <span style={{ fontSize: "32px" }}>
                <SocialsIcon
                  className={`tw-text-white hover:tw-text-[#06523D] tw-mx-2`}
                  link={"https://www.linkedin.com/company/practice-unbound"}
                  social='linkedin'
                />
              </span>
            </div>
          </div>
        </div>
      </DivFade>
    </>
  )
}

// const Quote = (props) => {
//     return <blockquote style={{ marginTop: '12px' }} className="blockquote mb-4">
//         <p style={{ fontSize: '18px', fontWeight: 'bold' }}>
//             <FontAwesomeIcon style={{ color: 'rgb(25, 57, 88)' }} className="mr-3 mb-2" size="3x" icon="quote-left" role="presentation" />
//             {props.quote}
//         </p>
//         <div style={{ fontWeight: 'bold' }} className="blockquote-footer text-right">{props.author}</div>
//     </blockquote>
// }

const quotes = [
  {
    quote:
      "The Prescribing Lifestyle Medicine course has been invaluable to me and the team. It covers all the common symptoms/conditions that residents are coming to us for support with such as low mood, anxiety, excess weight and long-term health conditions. I really like the strength-based tone of the programme focussing on improving health and wellbeing rather than treating illness and The Personal Framework is a really effective tool in creating person centred lifestyle prescriptions for residents. Would highly recommend!",
    author:
      "Hannah Short | Public Health Improvement Officer, Doncaster Borough Council",
  },
  {
    quote:
      "I love the Prescribing Lifestyle Medicine course. I have been attempting to work within the scope of lifestyle medicine for months now, and the course has provided me with some additional tools that I find very helpful. My job satisfaction has definitely improved since using tools learnt in the course. Thank you.",
    author: "Eliska Felcmanova | Nurse Practitioner, Sullivan Way Surgery",
  },
]

const HtnLogoComp = () => (
  <div>
    <a
      href='https://htn.co.uk/2020/10/22/htn-awards-2020-meet-the-winners/'
      target='_blank'
      rel='noreferrer'>
      <img alt='htn awards logo' className='img-fluid' src={htnLogo} />
    </a>
    {/* <h2 className="tw-font-bold">Efficiency Savings of the Year</h2> */}
  </div>
)

const HtnText = () => (
  <h3 className='tw-font-bold tw-text-center'>
    Efficiency Savings of the Year
  </h3>
)

const ticklistShapes = [
  {
    color: "white",
    parentClassName: "position-absolute tw-group tw-w-[179px]",
    childClassName:
      "group-hover:tw-drop-shadow-xl tw-rounded-lg tw-transition-all tw-duration-500",
    content: <HtnLogoComp />,
    top: "18%",
    left: "25%",
    // width: screenSize > 768 ? '45%' : screenSize > 550 ? '45%' : '40%',
    // width: 'tw-w-[125px]'
  },
  {
    // color: `${puColours.blue}`,
    parentClassName: "position-absolute tw-group tw-w-[245px]",
    childClassName:
      "group-hover:tw-drop-shadow-xl tw-rounded-lg tw-transition-all tw-duration-500",
    top: "102%",
    left: "13%",
    content: <HtnText />,
    // width: screenSize > 768 ? '45%' : screenSize > 550 ? '45%' : '40%',
    // width: 'tw-w-[125px]'
  },
]

const whatIsPathGoShapes = [
  {
    // color: puColours.lightGrey,
    parentClassName: "position-absolute tw-group tw-w-[326px]",
    childClassName: "",
    top: "2%",
    left: "6%",
    content: (
      <div style={{
        backgroundImage: `url(https://practiceunbound.blob.core.windows.net/stock-images/htn-healthtechawards-23-min.png)`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        width: '80%',
        height: '100%',
        backgroundPosition:'center'
      }} />
    ),
  },
  // top left group
  {
    color: puColours.lightGrey,
    parentClassName:
      "position-absolute tw-group tw-w-[70px] md:tw-hidden lg:tw-block tw-animate-throb",
    childClassName: "",
    top: "31%",
    left: "-36%",
  },
  {
    color: puColours.lightGrey,
    parentClassName: "position-absolute tw-group tw-w-[16px] sm:tw-hidden ",
    childClassName: "",
    top: "34%",
    left: "-48%",
  },
  {
    color: puColours.lightGrey,
    parentClassName: "position-absolute tw-group tw-w-[11px] ",
    childClassName: "",
    top: "21%",
    left: "-18%",
  },
  {
    color: puColours.lightGrey,
    parentClassName: "position-absolute tw-group tw-w-[51px] tw-animate-throb",
    childClassName: "",
    top: "-3%",
    left: "-11%",
  },
  // top right group
  {
    color: puColours.lightGrey,
    parentClassName: "position-absolute tw-group tw-w-[20px] ",
    childClassName: "",
    top: "22%",
    left: "105%",
  },
  // bottom right group
  {
    color: puColours.lightGrey,
    parentClassName: "position-absolute tw-group tw-w-[60px] tw-animate-throb",
    childClassName: "",
    top: "115%",
    left: "106%",
  },
  {
    color: puColours.lightGrey,
    parentClassName: "position-absolute tw-group tw-w-[26px] ",
    childClassName: "",
    top: "96%",
    left: "117%",
  },
]
