import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getNewsLetterContent } from '../../actions/pagesActions'
import ContentBlockPage from '../layout/ContentBlockPage'
import Spinner from '../common/Spinner'

class NewsLetterScreen extends Component {
    componentDidMount() {
        if (Object.keys(this.props.pages.newsLetterContent).length === 0 && this.props.pages.newsLetterContentLoading === false) {
            this.props.getNewsLetterContent()
        }
    }
    render() {
        const { newsLetterContentLoading, newsLetterContent } = this.props.pages

        return newsLetterContentLoading ? <Spinner fullScreen color="yellow" large /> : <ContentBlockPage content={newsLetterContent} />
    }
}
const mapStateToProps = state => ({
    pages: state.pages
})
export default connect(mapStateToProps, { getNewsLetterContent })(NewsLetterScreen);