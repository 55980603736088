import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import Button from '../common/Button'
import TextFieldGroup from '../common/TextFieldGroup'
import Select from '../common/Select'
import { sendContact } from '../../actions/pagesActions'
import queryString from 'query-string'

class Contact extends Component {
    constructor() {
        super()
        this.state = {
            contact: '',
            organisation: '',
            message: '',
            email: '',
            name: '',
            howDidYouHear: '',
            otherSpecify: '',
            product: {},
            contactType: { label: 'Helpdesk Support', value: 'support' },
            showPleaseSpecify: false
        }
        this.onChange = this.onChange.bind(this)
        this.sendContact = this.sendContact.bind(this)
    }

    products = [
        { label: 'Workflow Go', value: 'workflowgo' },
        { label: 'Prescribing Lifestyle Medicine', value: 'prescribinglifestylemedicine' },
        { label: 'Pathology Go', value: 'pathologygo' },
        { label: 'GP Billing', value: 'gpbilling' },
        { label: 'N/A', value: 'na' },
    ]

    componentDidMount() {
        const parsed = queryString.parse(this.props.location.search);

        if (parsed.contactType === 'sales') {
            this.setState({ contactType: { label: 'Product Enquiry', value: 'sales' }, })
        }
        if (parsed.product) {
            const selectedProduct = this.products.filter(p => p.value === parsed.product)
            if (selectedProduct.length > 0) {
                this.setState({ product: selectedProduct[0] })
            }

        }
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    
    sendContact(e) {
        e.preventDefault()
        const { campaign } = this.props.match.params
        let contactType = this.state.contactType.value
        let message = this.state.message
        
        if (this.state.contactType.value === 'mail') {
            contactType = 'sales'
            message = 'Request to be on mailing list ' + message
        }
        
        message = 'Product: ' + this.state.product.label + ' ' + message
        
        const data = {
            contact: this.state.contact,
            organisation: this.state.organisation,
            message: message,
            email: this.props.auth.isAuthenticated ? this.props.auth.user.email : this.state.email,
            name: this.props.auth.isAuthenticated && this.props.auth.user.firstName && this.props.auth.user.lastName ? `${this.props.auth.user.firstName} ${this.props.auth.user.lastName}` : this.state.name,
            campaign,
            contactType: contactType,
            howDidYouHear: this.state.howDidYouHear.value,
            otherSpecify: this.state.otherSpecify
        }
        // console.log(data)
        this.props.sendContact(data)
    }

    render() {

        const { firstName, lastName, email } = this.props.auth.user
        const { isAuthenticated } = this.props.auth
        const { errors, contactUsLoading, contactUsSuccess } = this.props.pages
        return (
            <form onSubmit={this.sendContact}>
                <div className="row">
                    <div className="col-md-6">
                        {isAuthenticated && firstName && lastName ? (
                            <TextFieldGroup
                                label="Name"
                                name='name'
                                value={`${firstName} ${lastName}`}
                                readOnly
                            />
                        ) : (
                                <TextFieldGroup
                                    label="Name"
                                    name='name'
                                    value={this.state.name}
                                    onChange={this.onChange}
                                    error={errors.name}
                                />
                            )}

                    </div>
                    <div className="col-md-6">
                        {isAuthenticated ? (
                            <TextFieldGroup
                                label="Email"
                                name='email'
                                value={email}
                                readOnly
                            />
                        ) : (
                                <TextFieldGroup
                                    label="Email"
                                    name='email'
                                    value={this.state.email}
                                    onChange={this.onChange}
                                    error={errors.email}
                                />
                            )}

                    </div>
                    <div className="col-md-6">
                        <TextFieldGroup
                            label="Contact Number"
                            name='contact'
                            value={this.state.contact}
                            onChange={this.onChange}
                            error={errors.contact}
                        />
                    </div>
                    <div className="col-md-6">
                        <TextFieldGroup
                            label="Organisation"
                            name='organisation'
                            value={this.state.organisation}
                            onChange={this.onChange}
                            error={errors.organisation}
                        />
                    </div>
                    <div className="col-md-6">
                        <Select
                            label="Enquiry Type"
                            value={this.state.contactType}
                            error={errors.contactType}
                            onChange={e => { this.setState({ contactType: e }) }}
                            options={[
                                { label: 'Helpdesk Support', value: 'support' },
                                { label: 'Product Enquiry', value: 'sales' },
                                { label: 'Join our mailing list', value: 'mail' },
                            ]}
                        />
                    </div>

                    {this.state.contactType && ['sales', 'support'].includes(this.state.contactType.value) && (
                        <div className="col-md-6">
                            <Select
                                label="Product"
                                value={this.state.product}
                                error={errors.product}
                                onChange={e => { this.setState({ product: e }) }}
                                options={this.products}
                            />
                        </div>
                    )}

                    {this.state.contactType && ['sales', 'mail'].includes(this.state.contactType.value) && (
                        <div className="col-md-6">
                            <Select
                                label="How did you hear about us?"
                                value={this.state.howDidYouHear}
                                error={errors.howDidYouHear}
                                onChange={e => {
                                    if (['Another website', 'Other'].includes(e.value)) {
                                        this.setState({
                                            showPleaseSpecify: true,
                                            howDidYouHear: e
                                        })
                                    } else {
                                        this.setState({
                                            showPleaseSpecify: false,
                                            howDidYouHear: e
                                        })
                                    }

                                }}
                                options={[
                                    { label: 'Friend or colleague', value: 'Friend or colleague' },
                                    { label: 'Browsing online', value: 'Browsing online' },
                                    { label: 'Conference', value: 'Conference' },
                                    { label: 'Workshop', value: 'Workshop' },
                                    { label: 'Email from us', value: 'Email from us' },
                                    { label: 'Social media', value: 'Social media' },
                                    { label: 'Another website', value: 'Another website' },
                                    { label: 'Other', value: 'Other' },
                                ]}
                            />
                        </div>
                    )}
                    {this.state.contactType && this.state.contactType.value === 'sales' && this.state.showPleaseSpecify && (
                        <div className="col-md-6">
                            <TextFieldGroup
                                label="Please specify"
                                name='otherSpecify'
                                value={this.state.otherSpecify}
                                onChange={this.onChange}
                                error={errors.otherSpecify}
                            />
                        </div>

                    )}

                    <div className="col-12">
                        <TextFieldGroup
                            label="Message"
                            placeholder="enter your message here"
                            type="textarea"
                            name='message'
                            value={this.state.message}
                            onChange={this.onChange}
                            error={errors.message}
                        />
                    </div>
                    <div className="col-12">
                        <Button
                            fixedWidth
                            type="submit"
                            center
                            large
                            done={contactUsSuccess}
                            loading={contactUsLoading}
                        >send</Button>
                        <div>
                            <small className="text-danger">{errors.error}</small>
                        </div>
                    </div>

                </div>
            </form>

        )

    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    pages: state.pages
})
export default connect(mapStateToProps, { sendContact })(withRouter(Contact));