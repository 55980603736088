// import React from './node_modules/reacte_modules/react'
// import { CSSTransition, TransitionGroup } from './node_modules/react-transition-groupransition-group'
// import GoogleChart from '../../../common/GoogleChart'
// import Moment from './node_modules/moment_modules/moment'

import React from 'react'
import GoogleChart from '../../../common/GoogleChart'
import Moment from 'moment'

const PrevAssessmentsBarGraph = ({ assessments }) => {

    const displayQuestion = assessments.filter(ass => ass.questionnumber === 2)[0].questiontext
    const qTwoData = assessments.filter(ass => ass.questionnumber === 2).map(foo => {
        return (
            [Moment(foo.eventdate).format("DD MM YYYY"),  parseInt(foo.answervalue)]
        )
    })

    return (
        <GoogleChart
            card={false}
            data={qTwoData}
            legend
            dataHeaders={['',displayQuestion]}
            type="LineChart"
            colors={['#16c194', '#e04f9a', '#b33f7b', '#be8c1a', '#f4b321', '#169f7b',]}
        />
    )
}

export default PrevAssessmentsBarGraph



