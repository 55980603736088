import React from "react";
import { connect } from 'react-redux';
import Logo from "../../../img/logo_PLM3x.png";
import TabbedContent from "../../common/TabbedContent";
import Tab from "../../common/Tab";
import Card from "../../layout/Card";
import Block from "../../layout/Block";
import icon_reduce_time_plm from "../../../img/icon_reduce_time_plm.svg";
import icon_approved from "../../../img/icon_approved.svg";
import icon_empower_patient from "../../../img/icon_empower_patient.svg";
import icon_patient_outcomes from "../../../img/icon_patient_outcomes.svg";
import icon_satisfaction from "../../../img/icon_satisfaction.svg";


// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons'
import Button from "../../common/Button";
library.add(faQuoteLeft)
library.add(faQuoteRight)



function PathProductDetails(props) {
    return (
        <div>
            <header className="pd-header">
                <Block>
                    <div className="pd-header__inner">
                        <h2 className="mb-4">
                            <strong>Prescribing Lifestyle Medicine Details</strong>
                        </h2>
                        <p>
                            Prescribing Lifestyle Medicine is a subscription product that contains all the resources, training and peer-support you need to get started with a lifestyle approach to prescribing.
            </p>
                    </div>
                    <img src={Logo} className="pd-header__logo" alt="Product Details" />
                </Block>
            </header>
            <TabbedContent
                color="teal"
                nav={["Benefits", "What’s included?"]}
            >
                <Tab className="text-center">
                    <Block largePadding color="white" >
                        <section className="gridlist text-center">
                            <h2 className="mb-5">
                                <strong>Benefits of Prescribing Lifestyle Medicine</strong>
                            </h2>
                            <ul className="gridlist__wrap">
                                <li className="gridlist__item">
                                    <img src={icon_reduce_time_plm} alt="" />
                                    <h4>
                                        Reduces time and cost of unnecessary appointments, prescriptions and referrals
            </h4>
                                    <p>
                                        By getting to the root cause of patients’ symptoms, Prescribing Lifestyle Medicine reduces the need for follow ups and prescription medication, saving time and money across the system.
            </p>
                                </li>
                                <li className="gridlist__item">
                                    <img src={icon_empower_patient} alt="" />
                                    <h4>Empowers patients to control their health care journey and receive tailored care</h4>
                                    <p>
                                        Prescribing Lifestyle Medicine puts patient care firmly back in patients’ hands – and enables practitioners to apply personalised care principles. This approach encourages greater patient choice and involvement, shared decision making and supported self-management.
            </p>
                                </li>
                                <li className="gridlist__item">
                                    <img src={icon_patient_outcomes} alt="" />
                                    <h4>Delivers better patient outcomes</h4>
                                    <p>
                                        With chronic disease on the rise globally, never has the need for lifestyle medicine been more necessary. Comprehensive lifestyle changes are used to prevent, treat and reverse the progression of chronic diseases by addressing their underlying causes.
            </p>
                                </li>
                                <li className="gridlist__item">
                                    <img src={icon_satisfaction} alt="" />
                                    <h4>Increases satisfaction for practitioners</h4>
                                    <p>
                                        Course attendees have reported that the skills they learned increased their job satisfaction and significantly improved the practitioner-patient relationship.
            </p>
                                </li>
                                <li className="gridlist__item">
                                    <img src={icon_approved} alt="" />
                                    <h4>Supports learning with evidence-based content</h4>
                                    <p>
                                        Developed from the latest available evidence from research, clinical outcomes and patient experience, the course translates findings from science and clinical intervention into useful tips and tools. Accredited by the RCGP, an identifiable symbol of professionalism, expertise and commitment to the highest standards of general practice.
            </p>
                                </li>

                            </ul>
                        </section>
                        {props.products.product.preorderMode ? (
                            <Button colour="yellow" link="/contact-us?pre=plm" large inline className='mt-4'>
                                Pre-Register
          </Button>
                        ) : (
                                <Button colour="yellow" link="/plm-next-step" large inline className='mt-4'>
                                    Buy Now
</Button>
                            )}

                    </Block>

                </Tab>

                <Tab className="text-center">
                    <Block size="medium" largePadding color="white">
                        <h3 className="mb-4">
                            <strong>What’s included in the product:</strong>
                        </h3>

                        <Card subtle className="mb-5">
                            <ul className="tick-list">

                                <li>
                                    <strong>eLearning modules</strong> to support practitioners to implement Prescribing Lifestyle Medicine
</li>
                                <li>
                                    <strong>Self Assessment reporting tool</strong> to enable user to track how their confidence, knowledge and behaviour around PLM is developing over time
</li>
                                <li>
                                    <strong>Patient resources</strong> around various lifestyle interventions
</li>
                                <li>
                                    <strong>Practitioner resources</strong> to support your implementation of PLM
</li>
                                <li>
                                    <strong>Access to community</strong> of like-minded peers also implementing PLM
</li>
                                <li>
                                    <strong>Technical helpdesk</strong> Mon – Fri, 9am – 5pm GMT
</li>
                                <li>
                                    <strong>Data Dashboard Pilot</strong> (for UK GP Practices only) to track implementation and impact*
</li>
                                <small className="d-block mt-4">*dependent on clinical system used</small>
                            </ul>

                        </Card>

                        {props.products.product.preorderMode ? (
                            <Button colour="yellow" link="/contact-us?pre=plm" large inline className='mt-4'>
                                Pre-Register
          </Button>
                        ) : (
                                <Button colour="yellow" link="/plm-next-step" large inline className='mt-4'>
                                    Buy Now
</Button>
                            )}


                    </Block>

                </Tab>
            </TabbedContent>

        </div>
    );
}

const mapStateToProps = state => ({
    products: state.products,
})
export default connect(mapStateToProps, {})(PathProductDetails);