import Button from "../../common/Button"
import BannerFlex from "../BannerFlex"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import puColours from "../../../utils/puColours"
import DivFade from '../../../components/layout/DivFade'
import Block from "../Block"
import SocialsIcon from "../../common/SocialsIcon"
import Accordion from "../../common/Accordion"
import topBannerShapes from "./pricingBubbles"

const PathGoFaqs = () => {
  return <>
    <BannerFlex
        backgroundCirclesObj={{
            width: '600px',
            left: '-310px',
            className: 'tw-animate-pulse-slow'
        }}
        shapes={topBannerShapes}
        // classNames={{
            //     shapeSection: ''
        // }}
        classNames={{shapesSection: 'tw-pb-36 md:tw-pb-28'}} 
    >
        <div className="tw-flex tw-flex-col tw-gap-3 tw-py-10">
            <section className="tw-pl-2">
                <h4 className="tw-font-bold tw-mb-5">FAQs</h4>
                <p>We know that you have questions about Pathology Go, so we've answered the most common ones for you already below. If you have other questions that you need answers to, please don't hesitate to email info@practiceunbound.org.uk quoting “PathGoQ2” in your email title or complete our enquiry form</p>
            </section>
            <div className="tw-flex tw-flex-col sm:tw-flex-row tw-gap-4">
                <Button center large colour="yellow-outline">enquire now</Button>
            </div>
        </div>
    </BannerFlex>

    <Block largePadding color="#ECECED">
        <Accordion noBorder backgroundColour="white" heading="Is the Pathology Programme safe? ">
          <p>All delegation in the Pathology Programme is covered by Clinical Negligence Scheme GP (CNSGP) as long as it confirms to GMC Duties of a Doctor Guidance.</p> 
          <p>NHS Resolution no longer agree to meet with individual organisations or programmes of work as the MDU etc did before because of this principle. Our programme is built around these GMC principles:</p> 
            <ul>
                <li>Training for staff whom you delegate to</li>
                <li>Oversight of the performance in the form of audit</li>
                <li>Data to understand activity and maintain clinical responsibility</li>
                <li>These principle are well understood and we are confident of our governance structure.</li>
                <li>It is also worth noting, that prior to the changes in clinical negligence cover, in setting up the pilot, we met with the MDU and MPS about our pilot. They reviewed the governance structures and included cover for the pilot under routine indemnity.</li>
            </ul>
        </Accordion>
        <Accordion noBorder backgroundColour="white" heading="What is Clinical Variation? ">
            <p>Clinical variation is an observed phenomena where in one scenario there may be a number of different outcomes.</p>
            <p>Clinical variation is intrinsically is not a bad thing. You would expect that if a doctor was employing the NHS charter around personalised care and involving patients in decision making that you'd expect to see variation. It would be odd to be employing a personalised approach and not see variation. So clinical variation in its very nature is not a bad thing.</p> 
            <p>We break clinical variation down into two domains. One is warranted clinical variation. One is unwarranted clinical variation. The warranted is as above - about employing person centred decision making personalized care presented with evidence to allow them to make a decision.</p>
            <p>Then also warranted variation may be things like pilots, innovation, early adopters of new technology, new treatments, novel treatments that would produce variation in care which is appropriate. Those clinician taking on that learning would then scale and spread that learning and that's how innovation often spreads in the NHS. So that's all good.</p>
            <p>Unwarranted variation is where there is a variation in clinical practice or outcome which cannot be accounted for by patient preference demographics or access to technology.</p>  
            <p>So this is where there is a significant amount of variation that you might see in day to day practice. This is endemic across the NHS and there's been some really interesting articles around it. The NHS Atlas of Variation in Health Care demonstrates variation, and there's numerous articles written about the variable outcomes for certain socioeconomic groups and other other groups that have a significantly worse outcome compared to other groups in society.</p>        
        </Accordion>
        <Accordion noBorder backgroundColour="white" heading="How long does it take to implement the Pathology Programme?">
            <p>Implementation timelines of the Pathology Programme can vary by GP practice. However, the below graphic should give you an idea of how long it will take to implement Pathology Optimisation.</p> 
            <p>Approximately 8 weeks before the planned go-live date of the programme, administrators begin the e-learning modules, which helps them to define the purpose and role allocation.</p>
            <p>Once the training is complete the Pathology team will hold a meeting to discuss next steps and complete the flow exercise. 2 weeks prior to go-live clinical governance steps are completed and signed off at the GP Partner meeting. Phase 1 of go-live should last approximately 4-6 weeks, when phase 2 begins with audits of high volume, abnormal results.</p>         
        </Accordion>
        <Accordion noBorder backgroundColour="white" heading="Are Locums included in the clinical variation data? ">
            <p>Yes, Locum clinical variation data on Pathology requests will be visible in your Data Dashboard.</p> 
            <p>The only caveat is that if a Practice stores all Locum data under one log on. In this case you will not be able to see variations between different locums.</p> 
            <p>However, if locums each log in with an individual user profile then you will be able to see this on an individual level.</p>          
        </Accordion>
        <Accordion noBorder backgroundColour="white" heading="Is there a decision aid for Urine included in the Pathology Programme? ">
            <p>There is currently no urine decision aid as part of the Pathology Programme as the focus has initially been on bloods.</p>
            <p>However, we are always interested in developing additional resources for practices if useful so do get in touch if you feel it would be beneficial.</p> 
            <p>The current list of decisions aids included in the Pathology Programme is as follows:</p> 
            <ul>
                <li>Prostate-specific antigen (PSA) </li>
                <li>Blood glucose level (Hba1c)</li>
                <li>Vitamin B12 Folate </li>
                <li>Full blood count (FBC ) </li>
                <li>Liver function (LFTs ) </li>
                <li>Lipids </li>
                <li>Renal </li>
                <li>Thyroid function (TFT ) </li>
                <li>VitD </li>
            </ul>
        </Accordion>
    </Block>

    <DivFade className="tw-bg-[#169F7B] text-white">
        <div className="container" style={{ paddingTop: '72px', paddingBottom: '72px' }}>
            <div className="row justify-content-between">
                <div className="col-sm-8 mb-3">
                    <h3 className="mb-4" style={{ fontWeight: 'bold' }}>Got questions? Talk to us…</h3>
                    <p>Whether you are interested in one of our products or would like to know more about working with Practice Unbound, please give us a call on 0300 303 8145 or email <a className="tw-text-white tw-underline" href="mailto:info@practiceunbound.org.uk">info@practiceunbound.org.uk</a></p>
                </div>
                <div className="col-sm-2 d-flex justify-content-center align-items-center">
                    <span style={{ fontSize: '32px', marginRight: '16px' }}><SocialsIcon className={`tw-text-white hover:tw-text-[#06523D] tw-mx-2`} link={"https://twitter.com/PracticeUnbound"} social="twitter" /></span>
                    <span style={{ fontSize: '32px' }}><SocialsIcon className={`tw-text-white hover:tw-text-[#06523D] tw-mx-2`} link={"https://www.linkedin.com/company/practice-unbound"} social="linkedin" /></span>
                </div>
            </div>
        </div>
    </DivFade >
  </>
}

export default PathGoFaqs