import React from 'react'
import tick from '../../../../img/orange-tick-small.png'

export default function OrangeChecklist({ ticks, className, circles }) {
    if (circles) {
        return (
            <ul className='tick-list' style={{ fontWeight: 'bold', marginBottom: '2rem' }}>
                {ticks.map(t => (
                    <li
                        className={"orange-tick"}
                        style={{
                            marginBottom: '12px',
                            lineHeight: '24px'
                        }}
                        key={t}
                    >{t}</li>
                ))}
            </ul>
        )
    }

    return (
        <ul className={className} style={{ marginLeft: 0, paddingLeft: 0, listStylePosition: 'inside', fontSize: '15px', fontWeight: 'bold' }}>
            {ticks.map(t => (
                <li
                    key={t}
                    style={{
                        paddingLeft: '32px',
                        listStyleType: 'none',
                        marginBottom: '14px',
                        backgroundImage: `url(${tick})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '2px 5px'
                    }}
                >{t}</li>
            ))}
        </ul>
    )
}
