import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getSubPages } from '../../actions/pagesActions'
import ImageCircleCard from '../layout/ImageCircleCard'
import Spinner from '../common/Spinner'
import Grid from '../layout/Grid'
import detectIe from '../../utils/detectIe'

class SubPageList extends Component {
    componentDidMount() {
        this.props.getSubPages(this.props.pageType)
    }

    render() {
        const { subPagesLoading, subPages } = this.props.pages
        if (subPagesLoading) { return <Spinner block color="yellow" large /> }
        const items = subPages.items || []
        const ieVersion = detectIe();
        if (items.length === 0) {
            return (<div className="py-5">
                <h4 className="text-muted text-center">
                    Nothing here at the moment!
                </h4>
            </div>
            )
        }
        return (
            <Grid>
                {items && items.map(i =>
                    <div
                        key={i.sys.id}
                        data-aos={(ieVersion === false || ieVersion > 10) && "fade"}
                    >
                        <ImageCircleCard
                            buttonText={i.fields.buttonText}
                            buttonLink={this.props.pageType + '/' + i.fields.slug}
                            header={i.fields.header}
                            image={i.fields.imageUrl}
                            color="white"
                            paragraph={i.fields.description}
                        />

                    </div>
                )}
            </Grid>

        )
    }
}
const mapStateToProps = state => ({
    pages: state.pages
})
export default connect(mapStateToProps, { getSubPages })(SubPageList);