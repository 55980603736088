import React, { Component } from 'react'
import { connect } from 'react-redux';
import Carousel from '../common/Carousel'

class ProductCarousel extends Component {
    render() {
        const { productsLoading, products } = this.props.products
        let productRange = []
        if (this.props.range === "coming soon") {
            productRange = products.filter(p => (p.range === this.props.range || p.tags.includes(this.props.range)))
        } else if (this.props.range === "my products") {
            productRange = products.filter(p => (p.purchased))
        } else if (this.props.range === "active") {
            productRange = products.filter(p => !p.comingSoon)
        } else {
            productRange = products.filter(p => (!p.tags.includes("coming soon") && (p.range === this.props.range || p.tags.includes(this.props.range))))
        }

        if (productRange.length === 0 && productsLoading === false) {
            return (
                <div className="text-center">
                    <h6 className="text-muted my-5">Nothing here yet!</h6>
                </div>
            )
        }
        return (
            <Carousel
                infinite={this.props.infinite}
                autoplay={this.props.autoplay}
                loading={productsLoading}
                fixedWidth={this.props.fixedWidth}
                arrows={!this.props.hideArrows}
                small
                items={
                    productRange.map(p => {
                        return { key: p.name, title: p.title, image: p.image, link: p.productLink, color: p.color, enquiry: p.pending, check: p.purchased, comingSoon: p.comingSoon }
                    })
                } />
        )
    }
}


const mapStateToProps = state => ({
    products: state.products,
})
export default connect(mapStateToProps, {})(ProductCarousel);