import { useRef, useEffect, useState } from 'react'
import Divfade from './DivFade'


export default function VideoTextBlock(props) {

    const [windowSize, setWindowSize] = useState(getWindowSize());
    
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
      }
  
      window.addEventListener('resize', handleWindowResize);
  
      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }, []);

    function getWindowSize() {
        const {innerWidth} = window;
        return {innerWidth};
      }

  return (

        <Divfade className="pt-5 pb-3 bg-white">
            <div className="container ">
                <div className="row">
                    <div className={`col-12 col-lg-6 ${props.contentRight && 'order-lg-last'} mb-3 pt-4`}>
                       {props.children}
                    </div>
                    <div className='col-12 col-lg-6 mb-3 d-flex flex-column justify-content-center'>
                        <div
                            style={{
                                // outline: 'solid red 2px',
                                // height: '200px',
                                // width: '100%'
                            }}
                            className='d-flex flex-column justify-content-center'
                        >
                            <div style={{ position: 'relative' }} className='d-flex flex-column justify-content-center align-items-center'>
                                <iframe 
                                    src={props.src} 
                                    title={props.src}
                                    style={{maxWidth: '690px'}}
                                    // width="434" height="244"
                                    width={windowSize.innerWidth >= 992 ? "434" : windowSize.innerWidth * .9} 
                                    height={windowSize.innerWidth >= 992 ? "244" : (windowSize.innerWidth * .9) * .56}
                                    // height="100%" 
                                    position='absolute'                                    
                                    top= '0' 
                                    left= '0'
                                    frameborder="0" 
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                    allowfullscreen
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Divfade> 
    )   
}