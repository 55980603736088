import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import BottomBanner from './Comps/BottomBanner'
import ChooseProductCards from './Comps/ChooseProductCards'
import TopBannerPlain from './Comps/TopBannerPlain'
// import DivFade from '../DivFade'
import { Link } from 'react-router-dom';
import Circles from '../../../img/circles.svg'
import DivFade from '../../layout/DivFade'
import puColours from '../../../utils/puColours';


class Enrol extends Component {
    componentDidMount() {
        this.checkPurchasedAndRedirect()
    }
    componentDidUpdate() {
        this.checkPurchasedAndRedirect()
    }
    checkPurchasedAndRedirect = () => {
        const { product } = this.props.products
        const { auth } = this.props
        let launchUrl
        if (product.productLink.length >= 4) {
            if (product.productLink.substring(0, 4) !== "/pro") {
                launchUrl = product.productLink
            }
        }

        if (auth.isAuthenticated && product.purchased === true) {

            if (launchUrl) {
                this.props.history.push(launchUrl)
            } else if (product.showGetStartedTab === true) {
                this.props.history.push('/product/' + product.name + '/get-started')
            } else if (product.showElearningTab) {
                this.props.history.push('/product/' + product.name + '/course')
            } else if (product.showResourcesTab === true) {
                this.props.history.push('/product/' + product.name + '/resources')
            } else if (product.showDataTab === true) {
                this.props.history.push('/product/' + product.name + '/data')

            }
        }

    }
    render() {
        const pageDescription = 'Please select the product version you wish to purchase. Once you\'ve selected a product you will be asked to log into your Practice Unbound account or create a new login if you do not already have an account. You will then be asked to enter your payment details.'
        return (
            <>
                <Helmet>
                    <title>Prescribing Lifestyle Medicine | Practice UNBOUND | Enrol Now</title>
                    <meta name="description" content={pageDescription} />
                    <meta name="twitter:description" content={pageDescription} />
                    <meta
                        name="twitter:image"
                        content='https://practiceunbound.blob.core.windows.net/stock-images/AboutPage_TheCourseBlock_shutterstock_1323802892-min.jpg' />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>
                <TopBannerPlain>
                    <h3 className='mb-4' style={{ fontWeight: 'bold', textWrap:'balance' }}>Enrol onto PLM Online Course or the Certificate in Lifestyle Medicine (which includes the course) today.</h3>
                    <p>Prescribing Lifestyle Medicine Online is aimed at clinicians and specialist non clinicians delivering effective lifestyle interventions for patients and service users.</p>                    
                    {/* <p>
                        <strong>Please note:</strong> If you are funding the PLM license for someone else, please request the intended license holder to follow the enrol now process. Once they have created or logged into their existing Practice Unbound account please provide them with the billing details via your preferred method.
                    </p> */}
                </TopBannerPlain>
                <ChooseProductCards />

                <DivFade style={{
                    backgroundColor: puColours.darkOrange,
                    color: "white",
                    position: 'relative',
                    overflow: 'hidden'
                }}>
                    <img className='d-none d-md-block tw-animate-slow-spin' alt="" src={Circles} style={{ bottom: '-300px', left: '-160px', position: 'absolute', filter: 'brightness(0) invert(87%) sepia(26%) saturate(7240%) hue-rotate(324deg) brightness(99%) contrast(90%)' }} />
                    <img className='tw-animate-slow-reverse-spin' alt="" src={Circles} style={{ top: '-300px', right: '-160px', position: 'absolute', filter: 'brightness(0) invert(87%) sepia(26%) saturate(7240%) hue-rotate(324deg) brightness(99%) contrast(90%)' }} />
                    <div className="container" style={{ position: 'relative', paddingTop: '50px', paddingBottom: '50px' }}>
                        <div className="row">
                            <div className="col-1" />
                            <div className="col-12 col-md-10">
                                <h2 className='text-center mb-4 font-weight-bold'>Group Discounts Available</h2>
                                <h4 className='text-center mb-4'>If you would like to purchase multiple licences, get in touch with the sales team at <a href="mailto:info@practiceunbound.org.uk" style={{ color: 'rgb(25, 57, 88)' }}>info@practiceunbound.org.uk</a></h4>
                            </div>
                            <div className="col-1" />
                        </div>
                    </div>
                </DivFade>

                {/* <DivFade className="bg-white">
                    <div className="container" style={{ paddingTop: '80px', paddingBottom: '80px' }}>
                        <h3 className="mb-4" style={{ fontWeight: 'bold', textAlign: 'center' }}>Why does it matter?</h3>
                        <div className="row">
                            <div className="col-sm-6">
                                <p style={{ fontWeight: 'bold', fontSize: '20px' }}>I’m employed by a UK GP Practice</p>
                                <p>If you are employed by a UK GP practice you will be able to purchase up to 10 individual licenses for yourself and/or other practice staff. These accounts are all linked to your chosen organisation and are offered at a reduced price per license when purchased together. You will be asked how many licenses you wish to purchase for yourself and / or other practice staff employed by your organisation. </p>
                                <p>If you are employed by a UK GP Practice, and would like to purchase more than 10 licenses, please <Link to='/contact-us'>contact our sales team.</Link></p>
                            </div>
                            <div className="col-sm-6">
                                <p style={{ fontWeight: 'bold', fontSize: '20px' }}> I’m not employed by a UK GP Practice</p>
                                <p>If you are not employed by a UK GP practice you will be purchasing an individual license that is not linked to any particular organisation.</p>
                                <p>If you are not employed by a UK GP Practice and would like to purchase more than 1 license, please <Link to='/contact-us'>contact our sales team.</Link></p>
                            </div>
                        </div>
                    </div>
                </DivFade> */}
                <BottomBanner header="Have more questions? Talk to us" iconHoverColour="#034F52">
                    {/* <p style={{ fontSize: '18px' }}>Whether you are interested in one of our products or would like to know more about working with Practice Unbound, please give us a call on 0300 303 8145 or email <a style={{ fontWeight: 'bold', color: 'white' }} href="mailto:info@practiceunbound.org.uk">info@practiceunbound.org.uk</a></p> */}
                    <p style={{ fontSize: '18px' }}>Whether you are interested in one of our products or would like to know more about working with Practice Unbound, please<Link style={{ color: 'white' }} to="/contact-us"> contact the team</Link></p>
                </BottomBanner>
            </>
        )
    }
}
const mapStateToProps = state => ({
    products: state.products,
    auth: state.auth
})
export default connect(mapStateToProps, {})(Enrol);