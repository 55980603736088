import { useState, useRef, scrollIntoView } from 'react'
import styles from './GPBillingHomePage.module.css'
import { Link } from 'react-router-dom'
import processContent from '../../../utils/processRichText'
import Button from '../../common/Button'
import Header from './components/Header'
import ImageTextBlock from '../PLM/Comps/ImageTextBlock'
import Benefits from './components/Benefits'
// import GPDoubleBlock from './GPDoubleBlock'
import Carousel from '../../common/Carousel'
import circles from '../../../img/dark-grey-bubbles.svg'
import LogoBanner from '../PLM/Comps/LogoBanner'
import HomePageContact from '../HomePageContact'
import IncomeCaptureCalc from './components/IncomeCaptureCalc'
import WhatIs from './components/WhatIs'
import emisLogo from '../../../img/emis-accredited-parner-logo-min.png'
import tppLogo from '../../../img/tpp-logo-min.png'
import pay360Logo from '../../../img/pay-360-logo.png'
import BottomBanner from '../PLM/Comps/BottomBanner'

export default function GPBillingHomePage(props) {

    // TODO - error handling on this function
    const getContent = input => props.content.filter(c => c.sectionId === input)[0].sectionContent.content

    const headerContent = processContent(getContent('Header'))
    const whatIsGPBillingContent = getContent('What is GP-Billing?')
    const benefitsContent = getContent('Benefits')

    const benefitsRef = useRef(null)

    function handleScrollToBenefits() {
        benefitsRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center'
        });
      }

    function toElemnt() {
        const element = benefitsRef.current.getBoundingClientRect().top + window.scrollY
        window.scroll({
            top: element,
            behavior: "smooth"
          })
    }

    return (
        <>    
            {/* Header    */}
            <Header>
                <div>             
                    <img style={{width: '400px'}} src='https://practiceunbound.blob.core.windows.net/stock-images/GP-Billing-Logo.png' />                
                    <h4 className='text-capitalize font-weight-bold' style={{fontSize: '44px', textWrap:'balance'}}>improve your practice's billing efficiency</h4>
                    <div className='py-1'/>
                    <p className=''>Enabling practices to bill non-NHS and private services saving you time and revenue.</p>
                    <div className='py-2'/>
                </div>
                {/* <span className='font-weight-bold'>{processContent(headerContent)}</span> */}
                <div className='d-flex justify-content-center'>
                    <Button 
                        link="/contact-us"
                        large 
                        colour="otherGreen"
                    >enquire now
                    </Button>
                    <div className='px-2'/>
                    <Button 
                        large 
                        colour="std-outline-blue" 
                        // onClick={toElemnt}
                        onClick={handleScrollToBenefits}
                    >view benefits                    
                    </Button>
                </div>
            </Header>

            <WhatIs>
                {processContent(whatIsGPBillingContent)}
            </WhatIs>

            <Benefits>
                <div ref={benefitsRef} className='py-5'>
                    <div className='text-white'>
                        {processContent(benefitsContent, 'leaf-green-tick-list')}
                    </div>
                </div>
            </Benefits>             


            <Carousel                
                gpBillingReports={true}                
                backgroundColour='white'
                header='Dashboards'
                headerMarginBottom='5'
                // headerAlign='centre'
                centreHeader
                loading={false}
                cardColor='pink'
                height='290px'
                titleHeight='50px'
                paragraphHeight='135px'
                buttonColour='leafGreen'
                items={dashboardCarouselItems}
            />

            <IncomeCaptureCalc />

            <Carousel
                gpBillingTestimonials={true}
                topHalfBackgroundColour='#F3F3F3'
                loading={false}
                cardColor='pink'
                height='290px'
                titleHeight='50px'
                paragraphHeight='135px'
                buttonColour='leafGreen'
                quoteIconColour='leafGreen'
                items={testimonialCarouselItems}
            />

            <LogoBanner images={bannerLogos} mbImage="mb-3" maxWidth="992px" />

            {/* <HomePageContact gpbillingVersion backgroundColour="leafGreen"/> */}
            <BottomBanner backgroundColour="leafGreen" header="Questions?" iconHoverColour="#2E6121">
                {/* <p style={{ fontSize: '18px' }}>Whether you are interested in one of our products or would like to know more about working with Practice Unbound, please give us a call on 0300 303 8145 or email <a style={{ fontWeight: 'bold', color: 'white' }} href="mailto:info@practiceunbound.org.uk">info@practiceunbound.org.uk</a></p> */}
                <p style={{ fontSize: '18px' }}><Link style={{ color: 'white' }} to="/contact-us">Contact the team</Link> or call us on <strong>0300 303 8145</strong></p>
            </BottomBanner>

        </>
    )
}

const dashboardCarouselItems = [
    {
        key: 1,
        title: 'Home Dashboard',
        subtitle: '',
        image: 'https://practiceunbound.blob.core.windows.net/stock-images/gp-billing-home-dashboard-min.png',
        paragraph: 'An easy to navigate Home Dashboard with quick access views including draft and outstanding bills.'
    },
    {
        key: 2,
        title: 'Report Dashboard',
        subtitle: '',
        image: 'https://practiceunbound.blob.core.windows.net/stock-images/gp-billing-report-dashboard-min.png',
        paragraph: 'Ensuring and providing accuracy of the information recorded, analysis of income sources, management of aged debtors and VAT information.'
    },
    {
        key: 3,
        title: 'Reconciliation Dashboard',
        subtitle: '',
        image: 'https://practiceunbound.blob.core.windows.net/stock-images/gp-billing-report-dashboard-min.png',
        image: 'https://practiceunbound.blob.core.windows.net/stock-images/gp-billing-reconciliation-dashboard-min.png',
        paragraph: 'Ensuring and providing accuracy of the information recorded, analysis of income sources, management of aged debtors and VAT information.'
    },
]
const testimonialCarouselItems = [
    {
        key: 1,
        title: 'Lisa Chevalier-Crampton | Reception Manager at Hama Medical Centre',
        subtitle: '',
        image: '',
        paragraph: 'I would highly recommend GP-Billing to other practices, the team love using the system… It works brilliantly alongside our clinical system with client integration and ease of use.”'
    },
    {
        key: 2,
        title: 'David Hale | Finance and Corporate Manager at PICS',
        subtitle: '',
        image: '',
        paragraph: 'Everything is done electronically and managed without difficulty… This also facilitates the use of debit and credit card payment which means in these Covid times we are not having to deal with physically going to the bank.”'
    },
    {
        key: 3,
        title: 'Dean Fisher | technical admin support, The Deepings Practice',
        subtitle: '',
        image: '',
        paragraph: 'The best thing about GP-Billing is how slick and easy the process of billing has become.'
    },
    {
        key: 4,
        title: 'Chris Swales | Pheonix Surgery',
        subtitle: '',
        image: '',
        paragraph: 'Saves so much time, so easy to use. Great to be able dive into accounts and see their status.'
    },
    {
        key: 5,
        title: 'Carol Dunkley | Cranleigh Medical Practice IT Manager',
        subtitle: '',
        image: '',
        paragraph: 'We really like the fact we have a lot less paper. Keeping track of any outstanding work is so much quicker and easier.'
    },
]

const bannerLogos = [  
    {
        src: 'https://practiceunbound.blob.core.windows.net/stock-images/emis-accredited-parner-logo-min.png',
        alt: 'Emis',
        maxWidth: '150px',
        link: 'https://www.emishealth.com/partners'
    },
    {
        src: 'https://practiceunbound.blob.core.windows.net/stock-images/CityPay_Logo.png',
        alt: 'CityPay',
        maxWidth: '150px',
        link: 'https://www.citypay.com/'
    },
    {
        src: 'https://practiceunbound.blob.core.windows.net/stock-images/tpp-logo-min.png',
        alt: 'TPP',
        maxWidth: '150px',
        link: 'https://tpp-uk.com/'
    },
    {
        src: 'https://practiceunbound.blob.core.windows.net/stock-images/vision3-logo.png',
        alt: 'Vision3',
        maxWidth: '150px',
        // link: '#'
    },
]