import React from 'react'
import laptop from '../../../img/home_header_image@2x.png';
import Button from "../../common/Button";

export default function columnbanner() {
    return (
        <header className='columnbanner'>
            <div className="container columnbanner__inner">
                <article className='columnbanner__left'>
                    <h1 className="columnbanner__title mb-4">
                        <strong>New Pathology Go time-saving tool enables safe delegation of low risk pathology tests</strong>
                    </h1>
                    <p>Pathology Go enables your practice to safely delegate the processing of low risk pathology tests to administrators using evidence-based tools and resources.</p>
                    <Button colour='yellow' large link='/product/pathologygo/home' inline>
                        Find out more
                    </Button>
                </article>
                <div className='columnbanner__right'>
                    <figure>
                        <img className='columnbanner__laptop' src={laptop} alt='data dashboard' />
                        <figcaption>Image shows sample data from the data dashboard</figcaption>
                    </figure>
                </div>
            </div>
        </header>
    )
}