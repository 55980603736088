import React, { Component } from 'react'
import { Route } from 'react-router-dom';
import BrowseScreen from './BrowseScreen'
import ProductDetailsModal from './ProductDetailsModal';

export default class BrowseRouter extends Component {
    render() {
        const { match } = this.props;
        return (
            <div>
                <Route
                    path={match.url + '/'}
                    component={BrowseScreen}
                />
                <Route exact path={match.url + '/:product'} component={ProductDetailsModal} />
            </div>
        )
    }
}