const TicklistItem = props => (
    <>
      <li
        className='tw-relative tw-list-none tw-text-[0.9375rem]'
        style={{ lineHeight: "1.5", marginBottom: "0.5em" }}>
        <svg
          className='tw-block tw-absolute -tw-left-[40px] tw-top-[4px]'
          xmlns='http://www.w3.org/2000/svg'
          width='18'
          height='18'
          viewBox='0 0 18 18'>
          <path
            id='np_tick_2311214_000000'
            d='M9,0a9,9,0,1,0,9,9A9,9,0,0,0,9,0ZM7.187,13.326l-3.45-3.7L5.316,8.15l1.92,2.059L12.73,4.677,14.263,6.2Z'
            fill={props.iconColour ? props.iconColour : "white"}
          />
        </svg>
        {props.children}
      </li>
    </>
  )
  
  // Example usage
  // const Example = () => (
  //   <>
  //     <TicklistItem iconColour={puColours.lightMint}>
  //       <span className='tw-font-bold'>Subtitle??</span> - here's some content!
  //     </TicklistItem>
  //   </>
  // )
  
  export default TicklistItem
  