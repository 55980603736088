import React from 'react'
import Button from '../common/Button'
import circles from '../../img/circles.svg'
import DivFade from './DivFade'

export default function Slogan() {

  return (
    <DivFade
      style={{
        backgroundColor: "#EB6915",
        color: "white",
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      <img className='d-none d-md-block tw-animate-slow-spin' alt="" src={circles} style={{ bottom: '-300px', left: '-160px', position: 'absolute', filter: 'brightness(0) invert(87%) sepia(26%) saturate(7240%) hue-rotate(324deg) brightness(99%) contrast(90%)' }} />
      <img className='tw-animate-slow-reverse-spin' alt="" src={circles} style={{ top: '-300px', right: '-160px', position: 'absolute', filter: 'brightness(0) invert(87%) sepia(26%) saturate(7240%) hue-rotate(324deg) brightness(99%) contrast(90%)' }} />
      <div className="container" style={{ position: 'relative', paddingTop: '96px', paddingBottom: '96px' }}>
        <h3 className='text-center text-uppercase mb-4'><strong>educate. inspire. transform.</strong></h3>
        <Button link='/product/prescribinglifestylemedicine/enrol-plm' colour="none-outline-blue" center inline>enrol now</Button>
      </div>

    </DivFade>
  )
}
