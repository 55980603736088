import React, { Component } from 'react'
import { connect } from 'react-redux';
import { logInAs } from '../../actions/authActions'

class LogInAs extends Component {
    componentDidMount() {

        this.props.logInAs({ user: this.props.match.params.user })
    }
    render() {
        return (
            <div>
                {JSON.stringify(this.props.auth.errors)}
            </div>
        )
    }
}



const mapStateToProps = state => ({
    auth: state.auth,
})

export default connect(mapStateToProps, { logInAs, })(LogInAs);