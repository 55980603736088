import DivFade from '../layout/DivFade'
import backgroundCirclesImg from '../../img/circles.svg'
import Circles from '../common/Circles'
import puColours from '../../utils/puColours'


const BannerFlex = ({
    children,
    shapes,
    backgroundCirclesObj,
    backgroundColour,
    contentRight,
    colSpan,
    classNames
}) => {

    return (
        <DivFade
            style={{
                position: 'relative',
                overflow: 'hidden',
                backgroundColor: backgroundColour || 'white',
            }}
            className={classNames.block}
        >
            {backgroundCirclesObj &&
            <div className={`${backgroundCirclesObj.className}`} style={{
                position: 'absolute',
                backgroundRepeat: 'no-repeat', 
                backgroundSize: 'contain',
                width: backgroundCirclesObj?.width || '500px', 
                left: backgroundCirclesObj?.left || '-180px', 
                top: backgroundCirclesObj?.top || '-330px',
            }}>
                <div style={{ paddingTop: '100%', backgroundImage: `url(${backgroundCirclesObj?.image ?? backgroundCirclesImg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }} />
            </div>
            }
            <div className="container">
                <div className="row">
                    <>
                        <ContentSection
                            contentRight={contentRight}
                            colSpan={colSpan?.contentSection}
                            content={children}
                            classNames={classNames?.contentSection}
                            />
                        <ShapesSection
                            colSpan={colSpan?.shapesSection}
                            shapes={shapes}
                            classNames={classNames?.shapesSection}
                        />
                    </> 
                </div>
            </div>
        </DivFade>
    )
}

const ContentSection = ({ content, colSpan, contentRight, classNames }) => (
    <div className={`${colSpan ? colSpan : 'col-12 col-md-7'} ${contentRight ? 'order-last' : ''} ${classNames}`}>
        <div className={`h-100 align-items-center px-4 py-4`}>
            <div>
                {content}
            </div>
        </div>
    </div>
)

const ShapesSection = ({ shapes, colSpan, classNames }) => {
    return (
        <div className={`${colSpan ? colSpan : 'col-12 col-md-5'} ${classNames}`}>
            <div style={{ maxWidth: '280px' }} className="mx-auto d-flex flex-column h-100 align-items-center justify-content-center">                    
                <div style={{ position: 'relative', width: '100%', paddingTop: '80%' }}>
                    {shapes?.map((shape, index) => (
                    <Shape
                        key={`shape ${index}`}
                        width={shape.width}
                        color={shape.color}
                        top={shape.top}
                        left={shape.left}
                        content={shape.content} 
                        borderRadius={shape.borderRadius}
                        shadowSize={shape.shadowSize}
                        parentClassName={shape.parentClassName}
                        childClassName={shape.childClassName}
                    >
                        {shape.content && shape.content}
                    </Shape>
                    ))}
                </div>
            </div>
        </div>
    )
}

const Shape = (props) => {    
    return (
        <div 
            className={`${props.parentClassName}`}
            style={{
                top: props.top,
                left: props.left,
            }}
        >
            <div 
                className={`tw-rounded-full ${props.childClassName}`} 
                style={{
                    width: '100%',
                    paddingTop: '100%',
                    backgroundColor: props.color,
                }} 
            />
                {props.children &&
                <div style={{
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    position: 'absolute',
                }}>
                    {props.children}
                </div>
                }
        </div>
    ) 
}

export default BannerFlex