import React, { Component } from 'react'

export default class TicketTailor extends Component {
    componentDidMount() {
        var ticketTailorScript = document.createElement('script');
        ticketTailorScript.src = "https://cdn.tickettailor.com/js/widgets/min/widget.js";
        document.getElementsByTagName('body')[0].appendChild(ticketTailorScript);
    }
    render() {
        return (
            <div style={{ minHeight: '1200px' }}>
                <div className="tt-widget"><div className="tt-widget-fallback"></div><script src="https://cdn.tickettailor.com/js/widgets/min/widget.js" data-url="https://www.tickettailor.com/checkout/new-session/id/1078453/chk/fe14/" data-type="inline" data-inline-minimal="false" data-inline-show-logo="false" data-inline-bg-fill="false" data-inline-inherit-ref-from-url-param="" data-inline-ref="website_widget"></script></div>
            </div>
        )
    }
}
