import ProductBanner from "../ProductBanner"
import Button from "../../common/Button"
import BannerFlex from "../BannerFlex"
import puColours, { green } from "../../../utils/puColours"
import DivFade from "../../../components/layout/DivFade"
import ImageTextBlock from "../../../components/layout/PLM/Comps/ImageTextBlock"
import SocialsIcon from "../../common/SocialsIcon"
import TicklistItem from "../../common/TicklistItem"
import Circles from "../../common/Circles"
import Block from "../Block"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import WorkflowCapacityCalc from "../../common/WorkflowCapacityCalc"

const bannerImage =
  "https://practiceunbound.blob.core.windows.net/stock-images/wfg_banner.jpg"
const benefitsImage =
  "https://practiceunbound.blob.core.windows.net/stock-images/workflowgo_benefits.jpg"
const smileyElderFemaleDoctor =
  "https://practiceunbound.blob.core.windows.net/stock-images/smiley-elder-covid-recovery-center-female-doctor-with-tablet-stethoscope.jpg"
const gpHoursReleased =
  "https://practiceunbound.blob.core.windows.net/stock-images/wf-chart-gphoursreleased.png"
const correspondenceChart =
  "https://practiceunbound.blob.core.windows.net/stock-images/wf-chart-patientcorrespondence.png"
const doctorWithPatient =
  "https://practiceunbound.blob.core.windows.net/stock-images/doctor_with_patient.jpg"
const lettertypesChart =
  "https://practiceunbound.blob.core.windows.net/stock-images/wf-chart-patientlettertypes.png"
const htnLogo =
  "https://practiceunbound.blob.core.windows.net/stock-images/htn-logo.png"

export default function WorkflowGoHomepage(props) {
  return (
    <>
      <ProductBanner
        backgroundImage={bannerImage}
        logo={
          "https://practiceunbound.blob.core.windows.net/stock-images/workflow-icon.png"
        }>
        <div className='tw-text-white'>
          <h1 className='tw-font-bold'>
            Safely delegate clinical correspondence using Workflow Go
          </h1>
          <div className='tw-py-6' />
          <Button inline center large colour='yellow' link="/contact-us?contactType=sales">
            enquire now
          </Button>
        </div>
      </ProductBanner>


      <BannerFlex
        backgroundCirclesObj={{
          width: "600px",
          left: "-310px",
          className: "tw-animate-pulse-slow",
        }}
        shapes={whatIsWorkflowGoShapes}
        // classNames={{
        //     shapeSection: ''
        // }}
        classNames={{ shapesSection: "tw-pb-36 md:tw-pb-28" }}>
        <div className='tw-flex tw-flex-col tw-gap-3 tw-py-10 tw-pt-16'>
          <section className='tw-pl-2'>
            <h4 className='tw-font-bold tw-mb-5'>What is Workflow Go?</h4>
            <p>
             Workflow Go provides a suite of digital resources, tools and eLearning that enables the safe and systematic delegation of up to 80% of clinical correspondence processing to administrators, saving 40 minutes per WTE GP, per day
            </p>
          </section>
          {/* <div className='tw-flex tw-flex-col sm:tw-flex-row tw-gap-4'>
            <Button center large colour='yellow-outline'>
              enquire now
            </Button>
            <Button center large colour='hollow-yellow'>
              get started
            </Button>
          </div> */}
        </div>
      </BannerFlex>

      <ImageTextBlock
        imageUrl={benefitsImage}
        contentSide='right'
        // contentBackgroundImage={circles}
        contentBackgroundColor={puColours.darkPink}
        contentBackgroundSize='30%'
        contentBackgroundPosition='top right'
        contentBackgroundRight='-15%'
        contentBackgroundTop='3%'
        contentColWidth='7'>
        <div className='tw-pl-10 tw-text-white'>
          <div className='tw-py-10' />
          <h3 className='tw-font-bold'>Benefits Include:</h3>
          <div className='tw-py-2' />
          <ul>
            <TicklistItem>
              A trained Workflow Administrator can code and action up to 80% of
              clinical correspondence
            </TicklistItem>
            <TicklistItem>
              By implementing our safe and effective system, an average sized
              practice can expect to release 8 hours of GP time a week, 40mins
              per GP per day
            </TicklistItem>
            <TicklistItem>
              Clinical records are updated in a more consistent manner leading
              to enhanced data quality, a better patient journey, and empowered
              staff
            </TicklistItem>
            <TicklistItem>
              Improved work-life balance and retention of GPs
            </TicklistItem>
            <TicklistItem>
              Learn from our extensive experience from supporting 3,000
              practices since November 2014
            </TicklistItem>
          </ul>
          <div className='tw-py-10' />
        </div>
      </ImageTextBlock>

      <div className={`tw-bg-white tw-grid tw-grid-cols-12`}>
        <div
          className='
          tw-col-span-12 tw-px-6 tw-py-6 tw-max-w-6xl
            md:tw-py-10 md:tw-px-20
            lg:tw-col-span-7 lg:tw-pl-16 lg:tw-py-20
            xl:tw-col-end-8
            2xl:tw-col-start-3 2xl:tw-pl-0
            qhd:tw-col-start-4
        '>
                  <div className=''>
          {/* <div className='tw-py-10' /> */}
          <h3 className='tw-font-bold'>What's Included:</h3>
          <div className='tw-py-2' />
          <ul>
            <TicklistItem iconColour={puColours.lightMint}>
              <span className='tw-font-bold'>9 eLearning modules</span> - and video tutorials on coding, assessing and actioning clinical correspondence
            </TicklistItem>
            <TicklistItem iconColour={puColours.lightMint}>
              <span className='tw-font-bold'>Workflow Protocol</span> - the
              workflow protocol describes the letter types that can be delegated
              to a trained administrator for review and sign off within your
              practice
            </TicklistItem>
            <TicklistItem iconColour={puColours.lightMint}>
              <span className='tw-font-bold'>Clinical System Template</span> -
              enabling consistent coding and data capture
            </TicklistItem>
            <TicklistItem iconColour={puColours.lightMint}>
              <span className='tw-font-bold'>Audit tools and reports</span> - to
              support the auditing of delegation activities within your clinical
              system and enable GPs to check that clinical correspondence is
              being processed safely
            </TicklistItem>
            <TicklistItem iconColour={puColours.lightMint}>
              <span className='tw-font-bold'>Data dashboard</span> - showing
              implementation activity and impact of the work
            </TicklistItem>
            <TicklistItem iconColour={puColours.lightMint}>
              <span className='tw-font-bold'>Supporting implementation</span> -
              a range of detailed step by step guides and support materials to
              ensure clear understanding of roles, responsibilities and
              implementation steps
            </TicklistItem>
            <TicklistItem iconColour={puColours.lightMint}>
              <span className='tw-font-bold'>Helpdesk</span> - a team of
              transformation coaches to support you with any technical and
              implementation challenges you face, Monday - Friday 9am - 5pm
            </TicklistItem>
          </ul>
          {/* <div className='tw-py-10' /> */}
        </div>
        </div>
        <div
            className='
                tw-relative tw-hidden tw-bg-cover tw-bg-no-repeat
                lg:tw-block lg:tw-col-start-8 lg:tw-col-end-13
                qhd:tw-col-end-11
                before:tw-block before:tw-absolute before:tw-h-full before:tw-w-full before:tw-z-20 before:tw-bg-pink-500
                
            '
          style={{ backgroundImage: `url(${smileyElderFemaleDoctor})` }}>
          <div
            className={`tw-hidden qhd:tw-block tw-w-24 tw-absolute tw-h-full -tw-right-1 tw-bg-gradient-to-l tw-from-[#ECECED]`}
          />
        </div>
      </div>

      {/* <ImageTextBlock
        imageUrl={smileyElderFemaleDoctor}
        contentSide='left'
        // contentBackgroundImage={circles}
        // contentBackgroundColor={puColours.darkPink}
        contentBackgroundSize='30%'
        contentBackgroundPosition='top right'
        contentBackgroundRight='-15%'
        contentBackgroundTop='3%'
        contentColWidth='7'>
        <div className=''>
          <div className='tw-py-10' />
          <h3 className='tw-font-bold'>What's Included:</h3>
          <div className='tw-py-2' />
          <ul>
            <TicklistItem iconColour={puColours.lightMint}>
              <span className='tw-font-bold'>eLearning modules</span> - To train
              your admin staff, Practice Managers and GPs in the safe delegation
              of Clinical Correspondence
            </TicklistItem>
            <TicklistItem iconColour={puColours.lightMint}>
              <span className='tw-font-bold'>Workflow Protocol</span> - The
              workflow protocol describes the letter types that can be delegated
              to a trained administrator for review and sign off within your
              practice
            </TicklistItem>
            <TicklistItem iconColour={puColours.lightMint}>
              <span className='tw-font-bold'>Clinical System Template</span> -
              Enabling consistent coding and data capture
            </TicklistItem>
            <TicklistItem iconColour={puColours.lightMint}>
              <span className='tw-font-bold'>Audit tools and reports</span> - To
              support the auditing of delegation activities within your clinical
              system and enable GPs to check that clinical correspondence is
              being processed safely
            </TicklistItem>
            <TicklistItem iconColour={puColours.lightMint}>
              <span className='tw-font-bold'>Data dashboard</span> - Showing
              implementation activity and impact of the work
            </TicklistItem>
            <TicklistItem iconColour={puColours.lightMint}>
              <span className='tw-font-bold'>Supporting implementation</span> -
              A range of detailed step by step guides and support materials to
              ensure clear understanding of roles, responsibilities and
              implementation steps
            </TicklistItem>
            <TicklistItem iconColour={puColours.lightMint}>
              <span className='tw-font-bold'>Helpdesk</span> - A team of
              transformation coaches to support you with any technical and
              implementation challenges you face, Monday - Friday 9am - 5pm
            </TicklistItem>
          </ul>
          <div className='tw-py-10' />
        </div>
      </ImageTextBlock> */}

      <ReportBlock
        className='tw-bg-[#B33F7B] tw-text-white'
        imgSrc={gpHoursReleased}
        header={
          <h3
            className='tw-font-bold tw-mb-3 tw-wrap'
            style={{
              textWrap: "balance",
              fontSize: "clamp(32px, 3vw, 32px)",
            }}>
            See how much GP time Workflow is freeing up
          </h3>
        }
        backgroundImage={
          <div
            className={`tw-hidden lg:tw-block tw-absolute tw-top-[-197px] tw-left-[707px]`}>
            <Circles width='325' colour='#E04F9A' />
          </div>
        }>
        <p>
          With Workflow, you can see how many hours the GPs in your practice are
          saving just like in the graph.
        </p>
        <p>
          You are also shown how many additional hours you could save by having
          more Administrators processing patient letters than GPs, Hours saved
          is caclulated using the number of letters shifted away from a GP x 1.5
          mins (average number of minutes a GP takes to process a letter).
        </p>
        <p>
          If some letters do not go through the workflow process, this graph
          also shows how many hours you could proportionally be saving if all
          letters did go through the process.
        </p>
      </ReportBlock>

      <ReportBlock
        className='tw-bg-[#F3F3F3]'
        contentLeft
        imgSrc={correspondenceChart}
        header={
          <h3
            className='tw-font-bold tw-mb-3 tw-wrap'
            style={{
              textWrap: "balance",
              fontSize: "clamp(32px, 3vw, 32px)",
            }}>
            Track the journey of patient correspondence
          </h3>
        }>
        <span className=''>
          <p>
            In Workflow you can see what is happening to the letters going
            through the practice, and how it changes through time.
          </p>
          <p>
            As your Workflow team grows in confidence we would expect to start
            to see more letters being completed by the{" "}
            <strong>workflow administrators</strong>. This means fewer letters
            being sent to the GP.
          </p>
          <p>
            We typically see Practices shift 50% of letters away from GPs in 4-6
            weeks.
          </p>
        </span>
      </ReportBlock>

      <ReportBlock
        className='tw-bg-[#B33F7B] tw-text-white'
        imgSrc={lettertypesChart}
        header={
          <h3
            className='tw-font-bold tw-mb-3 tw-wrap'
            style={{
              textWrap: "balance",
              fontSize: "clamp(32px, 3vw, 32px)",
            }}>
            Quickly analyse Workflow by patient letter types
          </h3>
        }
        backgroundImage={
          <div
            className={`tw-hidden lg:tw-block tw-absolute tw-top-[131px] tw-left-[-644px] tw-z-0`}>
            <Circles width='325' colour='#E04F9A' />
          </div>
        }>
        <p>
          In Workflow you can see your practice's total volumes and breakdown of
          activities on the most common letter types.
        </p>
        <p>
          Once you have been Workflowing for a few months this graph will tell
          you where your main workload sits for both you admin teamss and GPs.
          Good practice in data quality should also mean that your top letter
          types are now cleanly categorised with no generic 'clinic letters' of
          duplicated 'A and E' and 'Accident and Emergency' types.
        </p>
        <p>
          This makes everything easier to track and additional workload easier
          to plan for.
        </p>
      </ReportBlock>

      <Block color='white'>
        <div className='col-12 my-4 text-center'>
          <h2>
            <strong>Testimonials</strong>
          </h2>
        </div>
        <div className='row'>
          <blockquote
            style={{ marginTop: "12px" }}
            className='blockquote mb-4 col-12 col-md-6 lg:tw-px-10'>
            <p style={{ fontSize: "18px", fontWeight: "bold" }}>
              <FontAwesomeIcon
                style={{ color: "#B33F7B" }}
                className='mr-3 mb-2'
                size='3x'
                icon='quote-left'
                role='presentation'
              />
              This is an opportunity to up skill your staff and empower them a
              little bit. Staff get trained all the way.”
            </p>
            <div
              style={{ fontWeight: "bold" }}
              className='blockquote-footer text-right'>
              Jane Collins | Locum Practice Manager
            </div>
          </blockquote>
          <blockquote
            style={{ marginTop: "12px" }}
            className='blockquote mb-4 col-12 col-md-6 lg:tw-px-10'>
            <p style={{ fontSize: "18px", fontWeight: "bold" }}>
              <FontAwesomeIcon
                style={{ color: "#B33F7B" }}
                className='mr-3 mb-2'
                size='3x'
                icon='quote-left'
                role='presentation'
              />
              This is so exciting; it can make such a difference to your
              day-to-day. I think it's really rewarding to talk to practices
              that don't have anything yet, because they have so much to gain
              from Workflow.”
            </p>
            <div
              style={{ fontWeight: "bold" }}
              className='blockquote-footer text-right'>
              Dr Fiona Bellringer | GP and Workflow GP Lead
            </div>
          </blockquote>
        </div>
      </Block>

      {/* <ImageTextBlock
        imageUrl={doctorWithPatient}
        contentSide="left"
        // contentBackgroundImage={circles}
        contentBackgroundColor={puColours.lightGrey}
        contentBackgroundSize='30%'
        contentBackgroundPosition='top right'
        contentBackgroundRight='-15%'
        contentBackgroundTop='3%'
        contentColWidth='7'
    >
    <div className="">
    <h3 className="tw-font-bold">How much GP capacity could Workflow Go release in your practice?</h3>
    <p>Enter your patient population size and the Capacity Calculator will provide an estimation of the GP capacity that will be released by using Workflow Go, as well as the administrator capacity required to reach that level.</p>
    <p>Use the Capacity Calculator as a guide to what your practice requirements are likely to be.</p>
    <WorkflowCapacityCalc />
    </div>
  </ImageTextBlock> */}

      <div className={`tw-bg-[${puColours.lightGrey}] tw-grid tw-grid-cols-12`}>
        <div
          className='
          tw-col-span-12 tw-px-6 tw-py-6 tw-max-w-6xl
            md:tw-py-10 md:tw-px-20
            lg:tw-col-span-7 lg:tw-pl-16 lg:tw-py-20
            xl:tw-col-end-8
            2xl:tw-col-start-3 2xl:tw-pl-0
            qhd:tw-col-start-4
        '>
          <h3 className='tw-font-bold tw-mb-6' style={{ textWrap: "balance" }}>
            How much GP capacity could Workflow Go release in your practice?
          </h3>
          <p>
            Enter your patient population size and the Capacity Calculator will
            provide an estimation of the GP capacity that will be released by
            using Workflow Go, as well as the administrator capacity required to
            reach that level.
          </p>
          {/* <p>
            Use the Capacity Calculator as a guide to what your practice
            requirements are likely to be.
          </p> */}
          <div className='tw-py-4' />
          <WorkflowCapacityCalc />
        </div>
        <div
            className='
                tw-relative tw-hidden tw-bg-cover tw-bg-no-repeat
                lg:tw-block lg:tw-col-start-8 lg:tw-col-end-13
                qhd:tw-col-end-11
                before:tw-block before:tw-absolute before:tw-h-full before:tw-w-full before:tw-z-20 before:tw-bg-pink-500
                
            '
          style={{ backgroundImage: `url(${doctorWithPatient})` }}>
          <div
            className={`tw-hidden qhd:tw-block tw-w-24 tw-absolute tw-h-full -tw-right-1 tw-bg-gradient-to-l tw-from-[#ECECED]`}
          />
        </div>
      </div>

      <DivFade className={`tw-bg-[${puColours.darkPink}] text-white`}>
        <div
          className='container'
          style={{ paddingTop: "72px", paddingBottom: "72px" }}>
          <div className='tw-flex tw-flex-col tw-gap-6 lg:tw-justify-between'>
            <h3
              className=' tw-text-center sm:tw-text-left'
              style={{ fontWeight: "bold", textWrap: "balance" }}>
              Got questions? Talk to us…
            </h3>
            <div className='tw-flex tw-flex-col tw-gap-7 sm:tw-flex-row'>
              <div className='tw-max-w-2xl tw-flex tw-flex-col tw-justify-center'>
                <p>
                  Whether you are interested in one of our products or would
                  like to know more about working with Practice Unbound, please
                  give us a call on 0300 303 8145 or email{" "}
                  <a
                    className='tw-text-white tw-underline'
                    href='mailto:info@practiceunbound.org.uk'>
                    info@practiceunbound.org.uk
                  </a>
                </p>
              </div>
              <div className='tw-flex tw-grow sm:tw-flex-col tw-justify-center tw-items-center lg:tw-flex-row lg:tw-justify-end lg:tw-items-start'>
                <span style={{ fontSize: "32px" }}>
                  <SocialsIcon
                    className={`tw-text-white hover:tw-text-[#06523D] tw-mx-2`}
                    link={"https://twitter.com/PracticeUnbound"}
                    social='twitter'
                  />
                </span>
                <span style={{ fontSize: "32px" }}>
                  <SocialsIcon
                    className={`tw-text-white hover:tw-text-[#06523D] tw-mx-2`}
                    link={"https://www.linkedin.com/company/practice-unbound"}
                    social='linkedin'
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </DivFade>
    </>
  )
}

const ReportBlock = props => {
  console.log("🚀 ~ file: WorkflowGoHomepage.js:440 ~ Thing ~ props:", props)
  return (
    <div
      className={`tw-flex lg:tw-gap-8 tw-flex-col lg:tw-flex-row lg:tw-justify-center tw-px-3 md:tw-px-6 tw-py-12 lg:tw-py-6 xl:tw-py-6 tw-overflow-hidden ${props.className}`}>
      <span className='tw-inline lg:tw-hidden tw-text-center'>
        {props.header}
      </span>

      <div
        className={`${
          props.contentLeft && "lg:tw-order-last"
        } lg:tw-flex lg:tw-justify-${
          props.contentLeft ? "start" : "end"
        } tw-flex tw-justify-center tw-items-center tw-py-3 lg:tw-aspect-video tw-z-10`}>
        {/* <div className={`${props.contentLeft && 'lg:tw-order-last'} lg:tw-flex lg:tw-flex-col lg:tw-justify-center tw-flex tw-justify-center tw-items-center tw-py-3 lg:tw-aspect-video`}> */}
        <img
          src={props.imgSrc}
          className='tw-w-full sm:tw-w-8/12 md:tw-w-7/12 lg:tw-w-10/12 tw-pr-4'
        />
      </div>

      <div
        className={`tw-relative lg:tw-w-10/12 lg:tw-py-8 ${
          props.contentLeft ? "lg:tw-pl-24" : "lg:tw-pr-20"
        } lg:tw-flex lg:tw-flex-col lg:tw-justify-center tw-max-w-4xl`}>
        {props.backgroundImage}
        <span className='tw-hidden lg:tw-inline tw-mb-4'>{props.header}</span>
        <div className='tw-max-w-2xl tw-mx-auto lg:tw-mx-0 '>
          {props.children}
        </div>
      </div>
    </div>
  )
}

const quotes = [
  {
    quote:
      "The Prescribing Lifestyle Medicine course has been invaluable to me and the team. It covers all the common symptoms/conditions that residents are coming to us for support with such as low mood, anxiety, excess weight and long-term health conditions. I really like the strength-based tone of the programme focussing on improving health and wellbeing rather than treating illness and The Personal Framework is a really effective tool in creating person centred lifestyle prescriptions for residents. Would highly recommend!",
    author:
      "Hannah Short | Public Health Improvement Officer, Doncaster Borough Council",
  },
  {
    quote:
      "I love the Prescribing Lifestyle Medicine course. I have been attempting to work within the scope of lifestyle medicine for months now, and the course has provided me with some additional tools that I find very helpful. My job satisfaction has definitely improved since using tools learnt in the course. Thank you.",
    author: "Eliska Felcmanova | Nurse Practitioner, Sullivan Way Surgery",
  },
]

const HtnLogoComp = () => (
  <div>
    <a
      href='https://htn.co.uk/2020/10/22/htn-awards-2020-meet-the-winners/'
      target='_blank'
      rel='noreferrer'>
      <img alt='htn awards logo' className='img-fluid' src={htnLogo} />
    </a>
    {/* <h2 className="tw-font-bold">Efficiency Savings of the Year</h2> */}
  </div>
)

const HtnText = () => (
  <h3 className='tw-font-bold tw-text-center'>
    Efficiency Savings of the Year
  </h3>
)

const whatIsWorkflowGoShapes = [
  {
    color: puColours.lightGrey,
    parentClassName: "position-absolute tw-group tw-w-[50px] tw-animate-throb",
    childClassName: "",
    top: "5%",
    left: "12%",
  },
  {
    color: puColours.lightGrey,
    parentClassName: "position-absolute tw-group tw-w-[35px] ",
    childClassName: "",
    top: "75%",
    left: "75%",
  },
  {
    color: puColours.darkPink,
    parentClassName: "position-absolute tw-group tw-w-[60px] tw-animate-throb",
    childClassName: "",
    top: "95%",
    left: "76%",
  },
  {
    // color: puColours.turqoise,
    parentClassName: "position-absolute tw-group tw-w-[223px]",
    childClassName: "",
    top: "23%",
    left: "9%",
    content: (
      <div style={{
        backgroundImage: `url(https://practiceunbound.blob.core.windows.net/stock-images/htn-healthtechawards-23-min.png)`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        width: '80%',
        height: '100%',
        backgroundPosition:'center'
      }} />
    ),
  },
  {
    color: puColours.darkPink,
    parentClassName: "position-absolute tw-group tw-w-[64px]  tw-animate-throb",
    childClassName: "",
    top: "36%",
    left: "-15%",
  },
  {
    color: puColours.darkPink,
    parentClassName: "position-absolute tw-group tw-w-[11px] ",
    childClassName: "",
    top: "21%",
    left: "-5%",
  },
]

const ticklistShapes = [
  {
    color: "white",
    parentClassName: "position-absolute tw-group tw-w-[179px]",
    childClassName:
      "group-hover:tw-drop-shadow-xl tw-rounded-lg tw-transition-all tw-duration-500",
    content: <HtnLogoComp />,
    top: "18%",
    left: "25%",
    // width: screenSize > 768 ? '45%' : screenSize > 550 ? '45%' : '40%',
    // width: 'tw-w-[125px]'
  },
  {
    // color: `${puColours.blue}`,
    parentClassName: "position-absolute tw-group tw-w-[245px]",
    childClassName:
      "group-hover:tw-drop-shadow-xl tw-rounded-lg tw-transition-all tw-duration-500",
    top: "102%",
    left: "13%",
    content: <HtnText />,
    // width: screenSize > 768 ? '45%' : screenSize > 550 ? '45%' : '40%',
    // width: 'tw-w-[125px]'
  },
]
