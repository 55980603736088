import React from "react";
import pathology from "../../../img/logo_pathlogy_go@3x.png";
import Button from "../../common/Button";
import Block from '../Block'
import Card from '../Card'
import Calculator from './Calculator'
import ImageCircleCard from '../ImageCircleCard'
import htn from '../../../img/Efficiency Savings of the Year (3).png'

export default function PathHomeB1() {
  return (
    <>
      <Block backcircles>
        <div className="row justify-content-center">
          <div className="col-md-7 col-sm-12 pt-4">
            <h1 className="mb-4">
              <strong>
                Save GP time with safe delegation of pathology tests using Pathology Go
              </strong>
            </h1>
            <p>
              Pathology Go is a suite of digital resources, tools and eLearning that enables the safe delegation of low-risk pathology tests using evidence-based decision aids. Our data dashboards support you to track change and learning and monitor implementation. Pathology Go saves GP time, improves patient outcomes and drives efficiency in your Practice.
            </p>
            <Button colour="yellow" link="/contact-us" large inline className='mb-3 mr-3'>
              ENQUIRE NOW
            </Button>
            <Button colour="yellow" link="/product/pathologygo/product-details#benefits" large inline>
              Benefits
            </Button>
          </div>
          <div className="col-md-5 col-sm-6">
            <div className="h-100 d-flex align-items-center">
              <img
                className="img-fluid p-lg-5 p-3"
                src={pathology}
                alt="data dashboard"
              />
            </div>

          </div>
        </div>
      </Block>

      <Block color="white">
        <section className="text-center">
          <h2 className="mb-4">
            <strong>
              Free Access to Pathology Go: Module 1
            </strong>
          </h2>
          <p className="mb-4 px-4">
            An introduction to the Pathology programme that will provide you with an overall product understanding, as well as supporting you in using the e-learning modules.
          </p>
          <Button colour="yellow" link="/product/pathologygo/course" large inline>
            Access Module 1
          </Button>
          <h4 className="mt-4 text-left"><strong>You will:</strong></h4>
          <ul className="tick-list">
            <li>Understand the purpose of pathology delegation</li>
            <li>Identify the key learning points from each module</li>
            <li>Navigate easily through the e-learning content</li>
            <li>Hear from our Clinical Director on how we developed the programme</li>


          </ul>
        </section>
      </Block>

      <Block
        largePadding
        size='medium'
      // color="white"
      >
        <section className="text-center">
          <h2 className="mb-5">
            <strong>
              A 2 minute introduction to Pathology Go
            </strong>
          </h2>

          <div className="video-wrap">
            <iframe
              src="https://player.vimeo.com/video/459364121"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
              title="An introduction to Pathology Go"
            ></iframe>
          </div>
        </section>
      </Block >

      <Block
        largePadding
        size='medium'
        color='white'
      >
        <section className="text-center">
          <h2 className="mb-5">
            <strong>
              Process test results safely and save GP time
            </strong>
          </h2>
          <p className="mb-5">
            Pathology Go is a programme of efficiency tools that can be implemented easily and quickly into any GP Practice. By training and enabling administrators to process test results consistently well, Pathology Go saves GP Practices significant GP time and delivers better patient communications and outcomes.
            We are delighted to be awarded a 'highly commended' in the 2020 HTN Awards for Efficiency Savings of the year.
          </p>
          <div className="row">
            <div className="col-lg-7">
              <Card subtle className="mb-5">
                <ul className="tick-list">
                  <li>
                    <strong>Reduce</strong> the admin requirement on GPs for simple test results
                  </li>
                  <li>
                    <strong>Upskill</strong> your admin team, and drive employee engagement and retention
                  </li>
                  <li>
                    <strong>Give</strong> GPs more opportunity to spend time on the more complex results
                  </li>

                  <li>
                    <strong>Measure</strong> ROI using the Pathology Go dashboard
                  </li>
                  <li>
                    <strong>Ensure</strong>  consistency in processing results
                  </li>
                  <li>
                    <strong>Embed</strong> consistent and patient-friendly communications of results and next steps.
                  </li>
                </ul>
              </Card>
            </div>

            <div className="col-lg-5">
              <div className="w-100 d-flex justify-content-center">
                <img src={htn} className="img-fluid  mb-3" alt="HTN Awards" />
              </div>

            </div>

          </div>

          <p className="px-4">
            Find out more about{" "}
            <a className="p-link" href="/product/pathologygo/product-details#benefits">
              benefits
            </a>{", "}
            <a className="p-link" href="/product/pathologygo/product-details#pricing">
              pricing
            </a>
            ,{" "}
            <a
              className="p-link"
              href="/product/pathologygo/product-details#implementation"
            >
              implementation times
            </a>{" "}
            and{" "}
            <a
              className="p-link"
              href="/product/pathologygo/product-details#whatsincluded"
            >
              what’s included
            </a>{" "}
            in Pathology Go, or see our{" "}
            <a href="/product/pathologygo/faqs" className="p-link">
              Frequently Asked Questions
            </a>{" "}
            for more information.
          </p>
        </section>
      </Block>

      <Block >
        <Calculator />
      </Block>
    </>
  );
}
