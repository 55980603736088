import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import ImageTextBlock from "./Comps/ImageTextBlock"
import Button from "../../common/Button"
import TopBannerPlain from "./Comps/TopBannerPlain"
import truncate from "../../../utils/truncateString"
import sessions from "./PLMConfPageData24"
import Modal from "../../common/Modal"
import SignupForm from "./Comps/SignupForm"
import styles from "./PLMConfPage.module.css"
import TextExpand from "../../common/TextExpand"
import DivFade from "../DivFade"
import Circles from "../../../img/circles.svg"
import SocialsIcon from "../../common/SocialsIcon"
import Block from "../Block"
import puColours from "../../../utils/puColours"

export default function PLMConfPage() {
  const [expanded, setExpanded] = useState([])

  useEffect(() => {
    setExpanded(sessions.map((session, index) => ({ isExpanded: false })))
  }, [])

  const fontSizes = {
    title: "22px",
    time: "18px",
    speakers: "18px",
    synopsis: "16px",
  }

  const maxTextLength = 100
  return (
    <div>
      <Helmet>
        <title>Prescribing Lifestyle Medicine | Practice UNBOUND | About</title>
      </Helmet>

      <TopBannerPlain
        backgroundCircles
        isPLMConferenceTop
        backgroundColour='#F5F5F5'
        secondImage='CPDLogo'>
        <div className='py-5'>
          <h3
            className='mb-3 text-capitalize'
            style={{ fontWeight: "bold", textWrap: "balance" }}>
            PLM LIVE: Mental Health And Trauma With Dr Gabor Maté
          </h3>
          <p className='mb-4'>
            We invite you to join Dr Rangan Chatterjee and Dr Ayan Panja as they
            welcome world renowned trauma and addiction expert Dr Gabor Maté,
            for a day of learning and connection.
          </p>
          <Button
            className='mb-3'
            colour='orange'
            link={"https://www.tickettailor.com/events/practiceunbound/940200"}
            large
            inline>
            book your place here
          </Button>
        </div>
      </TopBannerPlain>

      {sessions.map((session, sessionIndex) => {
        if (session.component === "TopBannerPlain") {
          const excludedCircles =
            session.excludedCircles && session.excludedCircles.length > 0
              ? session.excludedCircles
              : []
          return (
            <TopBannerPlain
              key={`${session.component} conference component key, session index: ${sessionIndex}, id:${session.id}, ${session.title}`}
              backgroundColour={session.greyBackground ? "#f5f5f5" : "white"}
              speakerImages={session.speakerImages}
              contentRight={session.contentRight || false}
              excludedCircles={excludedCircles}>
              <div className='py-2'>
                {/* title */}
                <h4
                  className='mb-3 pl-2 text-capitalize'
                  style={{
                    fontWeight: "bold",
                    fontSize: fontSizes.title || "",
                  }}>
                  {session.title}
                </h4>

                {/* time and speakers */}
                <div
                  className={`d-flex align-items-center tw-duration-200 ${
                    session.greyBackground ? "tw-bg-white" : "tw-bg-[#f5f5f5]"
                  } ${
                    session.synopsis &&
                    "hover:tw-cursor-pointer hover:tw-brightness-90"
                  }`}
                  style={{
                    padding: "23px 35px 20px 30px",
                    borderRadius: "100px",
                    minWidth: "200px",
                  }}
                  onClick={() => {
                    setExpanded(current =>
                      current.map((item, i) =>
                        i === sessionIndex ? { isExpanded: true } : item
                      )
                    )
                  }}>
                  <h4
                    className='flex-grow-1'
                    style={{
                      fontWeight: "bold",
                      fontSize: fontSizes.time || "",
                    }}>
                    {session.time}
                  </h4>
                  <div>
                    {session.speakers.map(speaker => (
                      <h5
                        key={`TopBannerPlain capsule ${session.title} ${speaker}`}
                        style={{
                          fontSize: fontSizes.speakers || "",
                          fontWeight: "bold",
                          marginLeft: "1.0rem",
                        }}>
                        {speaker}
                      </h5>
                    ))}
                  </div>
                </div>

                {session.synopsis && (
                  <div className='mt-3 pl-2' style={{}}>
                    <div
                      style={{
                        position: "relative",
                      }}>
                      {session.synopsis.join().length >= maxTextLength &&
                      expanded[sessionIndex]?.isExpanded === false ? (
                        // Collapsed
                        <p style={{ fontSize: fontSizes.synopsis || "" }}>
                          {truncate(
                            session.synopsis.join(),
                            maxTextLength,
                            maxTextLength
                          )}
                          <ExpandButton
                            expandOrCollapse={"expand"}
                            session={session}
                            setExpanded={setExpanded}
                            sessionIndex={sessionIndex}
                            fontSizes={fontSizes}
                          />
                        </p>
                      ) : (
                        <div>
                          <p style={{ fontSize: fontSizes.synopsis || "" }}>
                            {truncate(
                              session.synopsis.join(),
                              maxTextLength,
                              maxTextLength
                            )}
                            <ExpandButton
                              expandOrCollapse={"expand"}
                              session={session}
                              setExpanded={setExpanded}
                              sessionIndex={sessionIndex}
                              fontSizes={fontSizes}
                            />
                          </p>
                          {expanded[sessionIndex]?.isExpanded && (
                            <Modal
                              isOpen={
                                expanded[sessionIndex]?.isExpanded === true
                              }
                              onRequestClose={() => {
                                setExpanded(current =>
                                  current.map((item, i) =>
                                    i === sessionIndex
                                      ? { isExpanded: false }
                                      : item
                                  )
                                )
                              }}
                              title={session.time}
                              color={"blue"}>
                              {/* <h4 className="mb-3" style={{ fontWeight: 'bold', fontSize: fontSizes.title || ''}}>{session.title}</h4> */}
                              <h4
                                className='mb-3 flex-grow-1 text-capitalize'
                                style={{
                                  fontWeight: "bold",
                                  fontSize: fontSizes.title || "",
                                }}>
                                {session.title}
                              </h4>
                              <div>
                                <div
                                  style={{
                                    fontSize: fontSizes.synopsis || "",
                                  }}>
                                  {session.synopsis.map((p, i) => {
                                    return (
                                      <p
                                        key={`TopBannerPlain ${session.title} paragraph ${i}`}>
                                        {p.split(" ")[0] === "<li>" ? (
                                          <span>
                                            <ul>
                                              <li>
                                                {p
                                                  .split(" ")
                                                  .filter(
                                                    (item, index) => index > 0
                                                  )
                                                  .join(" ")}
                                              </li>
                                            </ul>
                                          </span>
                                        ) : (
                                          <span>{p}</span>
                                        )}
                                      </p>
                                    )
                                  })}
                                </div>
                              </div>
                              <hr />
                              {session.speakers.map((speaker, speakerIndex) => {
                                return (
                                  <div
                                    key={`session ${session.id}, ${session.title} speaker section: ${speaker}`}
                                    className='mb-4'>
                                    <div>
                                      <h5
                                        style={{
                                          fontSize: fontSizes.speakers || "",
                                          fontWeight: "bold",
                                        }}>
                                        {session.speakerBiog ? speaker : null}
                                      </h5>
                                      {session.speakers.length === 1
                                        ? // Single speaker session
                                          session.speakerBiog?.map(
                                            (biogArray, biogIndex) => {
                                              const key = `TopBannerPlain single speaker biog ${session.speakers[speakerIndex]} biog paragraph:${biogIndex}`
                                              return biogArray.split(" ")[0] ===
                                                "<li>" ? (
                                                <ul key={key} className='mb-0'>
                                                  <li>
                                                    {biogArray
                                                      .split(" ")
                                                      .filter(
                                                        (item, index) =>
                                                          index > 0
                                                      )
                                                      .join(" ")}
                                                  </li>
                                                </ul>
                                              ) : biogArray.split(" ")[0] ===
                                                "<strong>" ? (
                                                <p key={key}>
                                                  <strong>
                                                    {biogArray
                                                      .split(" ")
                                                      .filter(
                                                        (item, index) =>
                                                          index > 0
                                                      )
                                                      .join(" ")}
                                                  </strong>
                                                </p>
                                              ) : (
                                                <p key={key}>{biogArray}</p>
                                              )
                                            }
                                          )
                                        : // Multiple speaker biogs
                                          session.speakerBiog[speakerIndex].map(
                                            (biogArray, biogIndex) => {
                                              const key = `TopBannerPlain multi speaker biog ${session.speakers[speakerIndex]} biog paragraph:${biogIndex}`
                                              return biogArray.split(" ")[0] ===
                                                "<li>" ? (
                                                <ul key={key} className='mb-0'>
                                                  <li>
                                                    {biogArray
                                                      .split(" ")
                                                      .filter(
                                                        (item, index) =>
                                                          index > 0
                                                      )
                                                      .join(" ")}
                                                  </li>
                                                </ul>
                                              ) : biogArray.split(" ")[0] ===
                                                "<strong>" ? (
                                                <p key={key}>
                                                  <strong>
                                                    {biogArray
                                                      .split(" ")
                                                      .filter(
                                                        (item, index) =>
                                                          index > 0
                                                      )
                                                      .join(" ")}
                                                  </strong>
                                                </p>
                                              ) : (
                                                <p key={key}>{biogArray}</p>
                                              )
                                            }
                                          )}
                                    </div>
                                    <div
                                      className='my-3'
                                      style={{
                                        display: "flex",
                                        margin: "auto",
                                        width: "maxContent",
                                      }}>
                                      {session.speakerLinks &&
                                        session.speakerLinks[speakerIndex].map(
                                          (link, linkIndex) => {
                                            const linkLog = link
                                            return (
                                              // <a
                                              //     key={`${session.component} SocialsIcon key:session id:${session.id}:${session.title},speaker:${speaker}linkIndex:${linkIndex}`}
                                              //     href={`${link.url}`}
                                              // >
                                              //     <h4 className='mr-3'>{link.icon}</h4>
                                              // </a>
                                              <SocialsIcon
                                                key={`${session.component} SocialsIcon key:session id:${session.id}:${session.title},speaker:${speaker}linkIndex:${linkIndex}`}
                                                color='orange'
                                                // social={'globe'}
                                                // link={'https://google.com'}
                                                social={link.icon}
                                                link={link.url}
                                              />
                                            )
                                          }
                                        )}
                                    </div>
                                    <hr />
                                  </div>
                                )
                              })}
                            </Modal>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </TopBannerPlain>
          )
        }

        if (session.component === "TopBannerPlainCircle") {
          return (
            <TopBannerPlain
              key={`${session.component} conference component key, session index: ${sessionIndex}, id:${session.id}, ${session.title}`}
              isPLMConferenceTop
              backgroundColour='#193958'>
              <div
                className='text-white px-1 px-md-1'
                style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                <h4
                  className='mb-4 text-capitalize'
                  style={{
                    fontWeight: "bold",
                    fontSize: fontSizes.title || "",
                  }}>
                  {session.title}
                </h4>
                <div
                  className={`${styles.capsuleWhite}`}
                  style={{
                    backgroundColor: "white",
                    padding: "23px 35px 20px 30px",
                    borderRadius: "50px",
                    minWidth: "200px",
                    cursor: "pointer",
                    color: "black",
                  }}
                  onClick={() => {
                    setExpanded(current =>
                      current.map((item, i) =>
                        i === sessionIndex ? { isExpanded: true } : item
                      )
                    )
                  }}>
                  <h4
                    className='mb-4'
                    style={{
                      fontWeight: "bold",
                      fontSize: fontSizes.time || "",
                    }}>
                    {session.time}
                  </h4>
                  {session.speakers &&
                    session.speakers.map(speaker => (
                      <h5
                        key={`session ${session.id}, ${session.title}, speaker heading ${speaker.name}`}
                        style={{
                          fontSize: fontSizes.speakers || "",
                          fontWeight: "bold",
                        }}>
                        {speaker.name}
                      </h5>
                    ))}
                </div>
                {/* {session.synopsis &&
                                    <div className='mt-3'>  
                                        {session.synopsis.map((p,i) => (
                                            <p style={{fontSize: fontSizes.synopsis || ''}} className="">{session.synopsis[i]}</p>   
                                        ))}
                                                                                                                
                                    </div>                                    
                                }  */}

                {session.synopsis && (
                  <div className='mt-4' style={{}}>
                    {/* <p>Read more... fsdfhsdfhsdfhsdjfhsdfhsdjkfhsdfjhdfjdh</p> */}
                    {/* {session.synopsis.map((p,i) => (<p>{session.synopsis[i]}</p>))} */}

                    <div
                      style={{
                        position: "relative",
                      }}>
                      {session.synopsis.join().length >= maxTextLength &&
                      expanded[sessionIndex]?.isExpanded === false ? (
                        // Collapsed
                        <p style={{ fontSize: fontSizes.synopsis || "" }}>
                          {/* {session.synopsis} */}
                          {truncate(
                            session.synopsis.join(),
                            maxTextLength,
                            maxTextLength
                          )}
                          <ExpandButton
                            expandOrCollapse={"expand"}
                            session={session}
                            setExpanded={setExpanded}
                            sessionIndex={sessionIndex}
                            fontSizes={fontSizes}
                          />
                        </p>
                      ) : (
                        // Expanded
                        // <div style={{
                        // }}>
                        //     <p style={{fontSize: fontSizes.synopsis || ''}}>
                        //         {session.synopsis.map((p,i) => (<span>{session.synopsis[i]}</span>))}
                        //         <ExpandButton expandOrCollapse={'collapse'} session={session} setExpanded={setExpanded} sessionIndex={sessionIndex} fontSizes={fontSizes}/>
                        //     </p>
                        // </div>
                        <div>
                          <p style={{ fontSize: fontSizes.synopsis || "" }}>
                            {/* {session.synopsis} */}
                            {truncate(
                              session.synopsis.join(),
                              maxTextLength,
                              maxTextLength
                            )}
                            <ExpandButton
                              expandOrCollapse={"expand"}
                              session={session}
                              setExpanded={setExpanded}
                              sessionIndex={sessionIndex}
                              fontSizes={fontSizes}
                            />
                          </p>
                          {expanded[sessionIndex]?.isExpanded && (
                            <Modal
                              isOpen={
                                expanded[sessionIndex]?.isExpanded === true
                              }
                              onRequestClose={() => {
                                setExpanded(current =>
                                  current.map((item, i) =>
                                    i === sessionIndex
                                      ? { isExpanded: false }
                                      : item
                                  )
                                )
                              }}
                              title={session.time}
                              color={"blue"}>
                              <div
                              // stop width from changing due to scrollbar appearing on expand
                              // style={{height: '1200vh'}}
                              >
                                <h4
                                  className='mb-3 flex-grow-1 text-capitalize'
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: fontSizes.title || "",
                                  }}>
                                  {session.title}
                                </h4>
                                <div>
                                  <div
                                    style={{
                                      fontSize: fontSizes.synopsis || "",
                                    }}>
                                    {/* biogArray.split(" ")[0] ===
                                                "<li>" ? (
                                                <ul key={key} className='mb-0'>
                                                  <li>
                                                    {biogArray
                                                      .split(" ")
                                                      .filter(
                                                        (item, index) =>
                                                          index > 0
                                                      )
                                                      .join(" ")}
                                                  </li>
                                                </ul>
                                              ) : */}
                                    {session.synopsis.map((p, i) => (
                                      <p
                                        key={`${session.component} ${session.id} ${session.title} synopsis paragraph ${i}`}>
                                        {session.synopsis[i]}
                                      </p>
                                    ))}
                                  </div>
                                </div>
                                <hr />
                                {session.speakers &&
                                  session.speakers.map(
                                    (speaker, speakerIndex) => {
                                      // const speakerObj = {
                                      //     speaker,
                                      //     speakerBiog: session.speakerBiog.filter((biog,biogIndex) => biogIndex === speakerIndex)[0]
                                      // }
                                      return (
                                        <div
                                          key={`${session.component} session ${session.id}: ${session.title}, textExpand component for ${speaker.name}`}
                                          className='mb-2'>
                                          <TextExpand
                                            title={speaker.name}
                                            text={speaker.biog}
                                            chevronColour='white'>
                                            <div
                                              className='my-3'
                                              style={{
                                                display: "flex",
                                                margin: "auto",
                                                width: "maxContent",
                                              }}>
                                              {session.speakerLinks &&
                                                session.speakerLinks[
                                                  speakerIndex
                                                ].map((link, linkIndex) => {
                                                  return (
                                                    <SocialsIcon
                                                      key={`SocialsIcon key: session ${session.id}, ${session.title}, ${speaker.name} ${linkIndex}`}
                                                      color='orange'
                                                      social={link.icon}
                                                      link={link.url}
                                                    />
                                                  )
                                                })}
                                            </div>
                                          </TextExpand>
                                        </div>
                                      )
                                    }
                                  )}
                              </div>
                            </Modal>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </TopBannerPlain>
          )
        }

        if (session.component === "Block") {
          return (
            <DivFade
              className={`tw-bg-[${session.contentBackgroundColor}] tw-text-white `}>
              {/* <DivFade className={`tw-bg-[#113B5B] tw-text-white`}> */}
              <div className='container tw-py-5'>
                <div className='row'>
                  <div className='col-12 col-md-6 tw-px-6'>
                    <h4 className='tw-font-bold'>{session.title}</h4>
                    <div className='tw-py-2' />
                    <h5 className='tw-font-bold'>{session.time}</h5>
                  </div>
                  <div className='col-12 col-md-6 tw-px-6'>
                    <p>{session.synopsis}</p>
                  </div>
                </div>
              </div>
            </DivFade>
          )
        }

        if (session.component === "ImageTextBlock") {
          return (
            <ImageTextBlock
              key={`${session.component} conference component key, session index: ${sessionIndex}, id:${session.id}, ${session.title}`}
              contentBackgroundColor={
                session.contentBackgroundColor || "rgb(25, 57, 88)"
              } //blue
              // contentBackgroundColor='#EB6915' //orange
              contentBackgroundSize='contain'
              contentSide='left'
              imageBackgroundPosition={session.imageLeft ? "left" : "right"}>
              <div
                className='text-white px-5 px-md-4'
                style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                <h4
                  className='mb-4 text-capitalize'
                  style={{
                    fontWeight: "bold",
                    fontSize: fontSizes.title || "",
                  }}>
                  {session.title}
                </h4>
                <h4
                  className=''
                  style={{
                    fontWeight: "bold",
                    fontSize: fontSizes.time || "",
                  }}>
                  {session.time}
                </h4>
                {session.speakers &&
                  session.speakers.map(speaker => (
                    <h5
                      key={`${session.component} session ${session.id}: ${session.title}`}
                      style={{
                        fontSize: fontSizes.speakers || "",
                        fontWeight: "bold",
                      }}>
                      {speaker}
                    </h5>
                  ))}
                {/* {session.synopsis &&
                                    <div className='mt-3'>  
                                        {session.synopsis.map((p,i) => (
                                            <p style={{fontSize: fontSizes.synopsis || ''}} className="">{session.synopsis[i]}</p>   
                                        ))}
                                                                                                                    
                                    </div>                                    
                                }  */}

                {session.synopsis && (
                  <div className='mt-4' style={{}}>
                    {/* <p>Read more... fsdfhsdfhsdfhsdjfhsdfhsdjkfhsdfjhdfjdh</p> */}
                    {/* {session.synopsis.map((p,i) => (<p>{session.synopsis[i]}</p>))} */}

                    <div
                      style={{
                        position: "relative",
                      }}>
                      {session.synopsis.join().length >= maxTextLength &&
                      expanded[sessionIndex]?.isExpanded === false ? (
                        // Collapsed
                        <p style={{ fontSize: fontSizes.synopsis || "" }}>
                          {/* {session.synopsis} */}
                          {truncate(
                            session.synopsis.join(),
                            maxTextLength,
                            maxTextLength
                          )}
                          <ExpandButton
                            expandOrCollapse={"expand"}
                            session={session}
                            setExpanded={setExpanded}
                            sessionIndex={sessionIndex}
                            fontSizes={fontSizes}
                          />
                        </p>
                      ) : (
                        // Expanded
                        // <div style={{
                        // }}>
                        //     <p style={{fontSize: fontSizes.synopsis || ''}}>
                        //         {session.synopsis.map((p,i) => (<span>{session.synopsis[i]}</span>))}
                        //         <ExpandButton expandOrCollapse={'collapse'} session={session} setExpanded={setExpanded} sessionIndex={sessionIndex} fontSizes={fontSizes}/>
                        //     </p>
                        // </div>
                        <div>
                          <p style={{ fontSize: fontSizes.synopsis || "" }}>
                            {/* {session.synopsis} */}
                            {truncate(
                              session.synopsis.join(),
                              maxTextLength,
                              maxTextLength
                            )}
                            <ExpandButton
                              expandOrCollapse={"expand"}
                              session={session}
                              setExpanded={setExpanded}
                              sessionIndex={sessionIndex}
                              fontSizes={fontSizes}
                            />
                          </p>
                          {expanded[sessionIndex]?.isExpanded && (
                            <Modal
                              isOpen={
                                expanded[sessionIndex]?.isExpanded === true
                              }
                              onRequestClose={() => {
                                setExpanded(current =>
                                  current.map((item, i) =>
                                    i === sessionIndex
                                      ? { isExpanded: false }
                                      : item
                                  )
                                )
                              }}
                              title={session.time}
                              color={"blue"}>
                              <h4
                                className='mb-3 flex-grow-1 text-capitalize'
                                style={{
                                  fontWeight: "bold",
                                  fontSize: fontSizes.title || "",
                                }}>
                                {session.title}
                              </h4>
                              <div>
                                <div
                                  style={{
                                    fontSize: fontSizes.synopsis || "",
                                  }}>
                                  {session.synopsis.map((p, i) => (
                                    <p
                                      key={`${session.component} session ${session.id}: ${session.title}, synopsis paragraph ${i}`}>
                                      {session.synopsis[i]}
                                    </p>
                                  ))}
                                </div>
                              </div>
                              <hr />
                              {/* {session.speakers && session.speakers.map((speaker, speakerIndex) => (
                                                                <div>
                                                                    <h5 style={{ fontSize: fontSizes.speakers || '', fontWeight: 'bold' }}>{speaker}</h5>
                                                                    {session.speakerBiog.filter((blah, blahIndex) => blahIndex === speakerIndex).map(thing => <p>{thing}</p>)}
                                                                </div>
                                                            ))} */}
                            </Modal>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </ImageTextBlock>
          )
        }
        return null
      })}
      <DivFade
        style={{
          backgroundColor: "#EB6915",
          color: "white",
          position: "relative",
          overflow: "hidden",
        }}>
        <img
          className='d-none d-md-block'
          alt=''
          src={Circles}
          style={{
            bottom: "-300px",
            left: "-160px",
            position: "absolute",
            filter:
              "brightness(0) invert(87%) sepia(26%) saturate(7240%) hue-rotate(324deg) brightness(99%) contrast(90%)",
          }}
        />
        <img
          alt=''
          src={Circles}
          style={{
            top: "-300px",
            right: "-160px",
            position: "absolute",
            filter:
              "brightness(0) invert(87%) sepia(26%) saturate(7240%) hue-rotate(324deg) brightness(99%) contrast(90%)",
          }}
        />
        <div
          className='container'
          style={{
            position: "relative",
            paddingTop: "96px",
            paddingBottom: "96px",
          }}>
          <Button
            className='mb-3'
            link={"https://www.tickettailor.com/events/practiceunbound/940200"}
            large
            colour='none-outline-blue'
            center
            inline>
            book your place here
          </Button>
        </div>
      </DivFade>

      <SignupForm />
    </div>
  )
}

const ExpandButton = ({
  expandOrCollapse,
  session,
  setExpanded,
  sessionIndex,
  fontSizes,
}) => (
  <button
    className={
      session.greyBackground ? styles.capsuleWhite : styles.capsuleGrey
    }
    style={{
      border: "none",
      borderRadius: "10px",
      padding: ".5px 8px",
      marginLeft: "5px",
    }}
    onClick={() => {
      setExpanded(current =>
        current.map((item, i) =>
          i === sessionIndex
            ? { isExpanded: expandOrCollapse === "expand" ? true : false }
            : item
        )
      )
    }}>
    <span
      style={{
        color: "#EB6915",
        fontWeight: "bold",
        fontSize: fontSizes.synopsis || "",
      }}>
      {expandOrCollapse === "expand" ? "read more" : "read less"}
    </span>
  </button>
)
