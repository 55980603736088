import api from '../config/axios';
import {
    GET_BLOGS,
    GET_BLOG,
    CLEAR_BLOG,
    SET_BLOG_LOADING,
    SET_BLOGS_LOADING,
    GET_BLOG_ERROR,
    CLEAR_BLOG_ERROR,
} from './types';
import isEmpty from '../utils/is-empty'
import { logoutUser } from './authActions'

export const getBlogs = () => dispatch => {
    dispatch({ type: SET_BLOGS_LOADING, payload: true })
    api.platformAPI
        .get('/api/platform/content/blogs')
        .then(res => {
            dispatch({
                type: GET_BLOGS, payload: res.data
            })
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_BLOGS_LOADING, payload: false })
        });
}
export const getBlog = (blogSlug) => dispatch => {
    dispatch({ type: CLEAR_BLOG_ERROR })
    dispatch({ type: SET_BLOG_LOADING, payload: true })
    api.platformAPI
        .get('/api/platform/content/blogs/' + blogSlug)
        .then(res => {
            dispatch({
                type: GET_BLOG, payload: res.data
            })
            dispatch({ type: CLEAR_BLOG_ERROR })
        })
        .catch(err => {
            dispatch({ type: SET_BLOG_LOADING, payload: false })
            dispatch({ type: GET_BLOG_ERROR, payload: err })
            dispatch(handleError(err))
        });
}

export const clearBlog = () => dispatch => {
    dispatch({ type: CLEAR_BLOG })

}

const handleError = (err) => dispatch => {
    //let payload
    console.dir(err)
    //token expired
    if (err.response) {
        if (err.response.statusText === "Unauthorized" && err.response.status === 401 && err.response.data === "Unauthorized") {
            dispatch(logoutUser())
        }
    }

    if (isEmpty(err.response)) {
        // payload = { error: err.message }
    } else {
        if (isEmpty(err.response.data)) {
            //   payload = { error: err.message }
        } else {
            //  payload = err.response.data
        }
    }
    /*
        dispatch({
            type: GET_ORGANISATION_ERRORS,
            payload: payload
        })*/
}
