import React from "react";
import Logo from "../../../img/logo_pathlogy_go@2x.png";
import TabbedContent from "../../common/TabbedContent";
import Tab from "../../common/Tab";
import Card from "../../layout/Card";
import ImageCircleCard from "../../layout/ImageCircleCard";
import Block from "../../layout/Block";
// import Preview from "../../../img/phatology_dashboard_image@3x.png";
import Benefits from './Benefits'
import IconAudit from '../../../img/icon_audit@3x.png'
import IconClinical from '../../../img/icon_clinical@3x.png'
import IconEngage from '../../../img/icon_engage@3x.png'
import IconFlow from '../../../img/icon_flow@3x.png'
import IconLive from '../../../img/icon_live@3x.png'
import IconTraining from '../../../img/icon_training@3x.png'
import Calculator from './Calculator'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons'
library.add(faQuoteLeft)
library.add(faQuoteRight)

// const Quote = props => {
//   return <blockquote style={{ marginTop: '12px' }} className="blockquote mb-5 text-left">
//     <p style={{ fontSize: '18px', letterSpacing: '1.2px' }}>
//       <FontAwesomeIcon className="tc-light-mint mr-3 mb-2" size="3x" icon="quote-left" role="presentation" />
//       {props.children}
//     </p>
//     <div className="blockquote-footer text-right">{props.author}</div>
//   </blockquote>
// }

// const KnowBlock = () => {
//   return (
//     <div>
//       <h4 className="mb-3">
//         <strong>Know your numbers with the Pathology Go dashboard</strong>
//       </h4>
//       <p className="mb-5">
//         Pathology Go can quickly deliver Return on investment (ROI) through more efficient pathology test processing using evidence-based decision aids. Pathology Go’s handy data dashboards demonstrate how the Programme is performing at-a-glance, showing:
//       </p>
//       <Card subtle>
//         <ul className="green-list">
//           <li>Number of test results received</li>
//           <li>
//             Number of test results filed and completed by practice admin staff
//           </li>
//           <li>Number of test results filed by GPs</li>
//           <li>Breakdown of test by type (FBC, UE etc)</li>
//           <li>No. of GP hours saved through implementation</li>
//         </ul>
//       </Card>
//     </div>
//   );
// };

const IconSets = props => <>
  <img src={props.img} alt={props.title} className="img-fluid mb-3" style={{ maxWidth: '60px' }} />
  <h4 className="mb-3">
    <strong>{props.title}</strong>
  </h4>
  <p className="mb-5">
    {props.para}
  </p>
</>

export default function PathProductDetails() {
  return (
    <div>
      <header className="pd-header">
        <Block>
          <div className="pd-header__inner">
            <h2 className="mb-4">
              <strong>Pathology Go Product Details</strong>
            </h2>
            <p>
              Pathology Go is priced on a per-patient model, and contains all
              the resources, training and help you need to get started with
              improved pathology test processing. Your Practice can install,
              train and launch Pathology Go in a matter of weeks.
            </p>
          </div>
          <img src={Logo} className="pd-header__logo" alt="Product Details" />
        </Block>
      </header>
      <TabbedContent
        nav={["Benefits", "Implementation", "What’s included?"]}
      >
        <Tab className="text-center">
          <Benefits />
          <Block>
            <Calculator />
          </Block>
          {/* <Block color='white' largePadding >
            <h3 className="mb-4 text-left">
              <strong>Customer Stories</strong>
            </h3>
            <div className="row">
              <div className="col-sm-6">
                <Quote author="Dr Bahar Maybury, Pathology Pilot Champion and GP Principle Partner at Oxted Health Centre">
                  Initially, I thought if we could just save some GP time throughout the practice it would be quite productive, you can achieve a lot, even if you half that time, that makes a big difference in the GPs life. So, in the ideal world we hoped we could really reduce that time. But what we actually achieved has been far beyond that because it’s not just the time that we have given back to the GP, but we’ve seen improvements to patient care as well as other areas such as clinical coding.
                </Quote>
              </div>
              <div className="col-sm-6">
                <Quote author="Dawn, Administrator, Oxted Health Centre">
                  As this was a pilot, we started with face to face training and spent time going through, the elearning modules which gave me a good foundation. Next we were given the decision aid flow charts to follow, this was a little daunting at first as new to me but with support from our internal team, and GP champion as well as Practice Unbound, I soon felt more confident completing the testing. Overall, I have found the process very interesting and am really enjoying my new role.”
                </Quote>
              </div>
            </div>

          </Block> */}
        </Tab>
        {/* <Tab className="text-center">
          <Block size="medium" largePadding color="white">
            <h3 className="mb-4">
              <strong></strong>
            </h3>

            <div className="mb-5">
              <ImageCircleCard
                color="green"
                header="Pathology Go is available at a price of 30p per registered patient per year"
                buttonText="ENQUIRE NOW"
                buttonLink="/pathology-go-form"
              ></ImageCircleCard>
            </div>
            <KnowBlock />
          </Block>
          <Block size="medium" largePadding>
            <img
              width="100%"
              src={Preview}
              className="mb-2"
              alt="Pathology go preview"
            />
            <p className="px-4 text-center smaller">
              This dashboard displays in real time the positive impact that
              Pathology Go is having in making more efficient use of your GPs time
        </p>
          </Block>
        </Tab> */}
        <Tab className="text-center">
          <Block size="medium" largePadding color="white">
            <h3 className="mb-4">
              <strong>Implementing Pathology Go</strong>
            </h3>
            <p>
              The implementation process has been designed in a specific way to ensure that practice teams are guided through each step and can gain confidence as they move through the programme. The volume of delegation is only increased in-line with verification of compliance and competence.
            </p>
            <p className="mb-5">
              Clinical teams always retain overall control of the whole process. </p>

            <IconSets
              img={IconFlow}
              title={"1. Review Flow and Patient Experience"}
              para={"Before implementing Pathology Go, it is really important to understand the current internal flow of pathology testing through the Practice. Review how engagement and communication with patients is maintained and gain an understand what the current reality of pathology testing is like from a patient perspective."}
            />
            <IconSets
              img={IconEngage}
              title={"2. Engage wider team"}
              para={"Ensure all members of the practice team are briefed in the change in process that is due to take place. Share with them the purpose, benefits, roles and timelines."}
            />

            <IconSets
              img={IconTraining}
              title={"3. Training"}
              para={"Admin staff, Practice Manager and GP Lead complete eLearning modules."}
            />

            <IconSets
              img={IconClinical}
              title={"4. Clinical Governance"}
              para={"The GP Lead needs to understand the governance framework outlined in the training and share this with the wider clinical team. Key areas include adapting and signing off decision aids within your practice and understanding the early implementation phase and audit process."}
            />
            <IconSets
              img={IconLive}
              title={"5. Go Live"}
              para={"Choose a “go live” date. The “go live” date is the day when the administrators will begin processing some Pathology results in line with their training. Review the decision aids and agree which one or ones will be implemented first. Start slowly, to build the administrator’s confidence and ensure the audit and feedback process is running smoothly."}
            />
            <IconSets
              img={IconAudit}
              title={"6. Audit"}
              para={"The audit and feedback process is a critical part of the governance framework and also enables the administrator to build confidence in their abilities."}
            />

          </Block>

          <Block size="medium" largePadding >

            <ImageCircleCard
              header='Request a demo'
              paragraph='Discover how Pathology Go can improve your Practice, and save 1000s of GP hours.'
              buttonLink='/pathology-go-form'
              buttonText='Request a Demo'
              color='green'
            />

          </Block>

        </Tab>
        <Tab className="text-center">
          <Block size="medium" largePadding color="white">
            <h3 className="mb-4">
              <strong>What's included?</strong>
            </h3>
            <p>
              Pathology Go is a blended programme of online learning, resources and data dashboards to help your Practice safely delegate low-risk test processing to administrators. It includes:
            </p>
            <Card subtle className="mb-5">
              <ul className="tick-list">

                <li>
                  <strong>eLearning modules</strong> - to train your admin staff, Practice Managers and GPs in the safe delegation of Pathology
                </li>
                <li>
                  <strong>Decision aids</strong> - evidence based flow charts for administrators to follow that detail processing pathway for B12 Folate, Full Blood Count, Blood Glucose Level, Liver Function, Lipids, Prostate-specific antigen, Renal, Thyroid function, VitD
                </li>

                <li>
                  <strong>Audit tools and reports</strong> - to support the auditing of delegation activities within your clinical system and enable GPs to check that test results are being processed safely
                </li>
                <li>
                  <strong>Data dashboard</strong> - showing activity and impact of the work
                </li>
                <li>
                  <strong>Supporting implementation</strong> - a range of detailed step by step guides and support materials to ensure clear understanding of Pathology Go and successful implementation of delegation into a Practice
                </li>
                <li>
                  <strong>Helpdesk</strong> - with team of transformation coaches to support you with any technical and implementation challenges you face, Monday - Friday 9am - 5pm
                </li>
              </ul>
            </Card>

          </Block>
          <Block
            size="medium"
          >
            <img
              className="img-fluid"
              src="https://practiceunbound.blob.core.windows.net/stock-images/snapshot@3x.png" alt="Screenshot of elearning" />
            <p>
              A snapshot of one of our Pathology Go eLearning modules for administrators.
            </p>
          </Block>
        </Tab>
      </TabbedContent>

    </div>
  );
}
