import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import PracticeUnboundYellow from "../../img/PracticeUnboundYellow.png";
import PracticeUnboundYellowWhite from "../../img/PracticeUnboundYellowWhite.png";
import { clearAuthErrors } from "../../actions/authActions";
import Button from "../common/Button";
import LoginContent from "../auth/LoginContent";
import Dropdown from "../common/DropDown";
import MyProductsIcon from '../common/MyProductsIcon'
import NavbarSmall from "./NavbarSmall";
import Avatar from "../common/Avatar";
// import ProductsMegaMenu from "./ProductsMegaMenu";
import NavItem from './NavItem'
import ProductMenu from './ProductMenu'
import EnviromentBanner from '../auth/EnviromentBanner';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      scrollPosition: 0,
      isLarge: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateScrollPosition = this.updateScrollPosition.bind(this);
    this.navigate = this.navigate.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.updateScrollPosition);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    window.removeEventListener("scroll", this.updateScrollPosition);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight, isLarge: window.innerWidth >= 1200 });
  }

  updateScrollPosition() {
    this.setState({ scrollPosition: window.pageYOffset });
  }

  navigate(url) {
    this.closeDraw();
    this.props.history.push(url);
  }

  render() {
    const onHomepage = this.props.location.pathname === "/" || false
    const isPLM = this.props.location.pathname.includes('/product/prescribinglifestylemedicine') || false
    const buttonColour = (onHomepage || isPLM) ? "white" : "none"


    if (this.state.width <= 768) {
      return <NavbarSmall onHomepage={onHomepage} />;
    }

    const {
      isAuthenticated,
      user,
      avatar,
      ownedOrganisations
    } = this.props.auth;
    const { location } = this.props;
    const { products } = this.props.products;

    const purchasedProducts = products.filter(
      p => p.purchased
    );

    const anonNavLarge = () => {
      if (location.pathname === "/login" || location.pathname === "/sign-up") {
        return;
      }
      return (
        <ul className="navbar-nav">
          <li className="nav-item">
            <Dropdown
              title="login"
              onHomepage={onHomepage || isPLM}
              buttonColour={(onHomepage || isPLM) ? 'none-outline' : 'yellow'}
              right
              preventCloseOnClick
              hideChevron
              button
            >
              <div style={{ width: '290px', padding: '12px' }}>
                <LoginContent />
              </div>
            </Dropdown>
          </li>
        </ul>
      );
    };


    const authNavLarge = (
      <ul className="navbar-nav">
        {ownedOrganisations && ownedOrganisations.length > 0 && (
          <li className="nav-item mr-2">
            <Button colour={buttonColour} noPadding link={"/admin"} icon="settings">
            </Button>
          </li>
        )}
        {purchasedProducts.length > 0 &&
          <Dropdown buttonColour={buttonColour} right title={<MyProductsIcon colour={(onHomepage || isPLM) && 'white'} preventCloseOnClick />}>
            <h5 className="p-2"><strong>My Products</strong></h5>
            <hr style={{ marginTop: '2px', marginBottom: '2px' }} />
            <NavItem items={purchasedProducts.map(p => {
              return {
                link: `/product/${p.name}`,
                image: p.iconUrl,
                title: p.title,
                subtitle: p.subtitle,
                purchased: p.purchased
              }
            })} />
          </Dropdown>
        }
        <Dropdown buttonColour={buttonColour} right title={<Avatar size="small" image={avatar} preventCloseOnClick />}>
          <NavItem
            width={400}
            items={[
              {
                image: avatar,
                link: "/account",
                title: user.firstName + " " + user.lastName,
                subtitle: user.email,
                isAvatar: true,
                viewButton: true
              }

            ]} />
          {this.props.auth.contracts.length > 0 && (
            <>
              <hr style={{ marginTop: '2px', marginBottom: '2px' }} />
              <Button colour="none" inline center link="/account/contracts">Contracts</Button>
            </>
          )}
          <hr style={{ marginTop: '2px', marginBottom: '2px' }} />
          <div>

            <Button fixedWidth className="my-3" inline center link="/logout">Log out</Button>
          </div>
        </Dropdown>
      </ul>
    );

    // enlarge navbar if on homepage and at the top of the screen
    const largeImage = (this.state.scrollPosition === 0 && onHomepage)

    return (
      <>
        <div style={{ height: largeImage ? '92px' : '54px' }} />
        <nav style={{
          // set navbar to match homepage colour if on homepage, otherwise white
          // backgroundColor: onHomepage ? '#193958': ''
          backgroundColor: '#193958'
        }}
          className={`navbar navbar-expand fixed-top ${(onHomepage || isPLM) ? "" : "navbar-light bg-white"}`}
          // className={`navbar navbar-expand fixed-top navbar-light bg-white`}
          id="navbar">
          <div className="container">
            <EnviromentBanner />
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav">
                <li className="nav-item  d-flex align-items-around">
                  <Link to="/">
                    <img
                      alt="PracticeUnbound"
                      className="ml-lg-3"
                      style={{
                        // IE10 fix to prevent logo strethcing bout accounting for top of page and other page
                        maxWidth: largeImage ? "144.73px" : "110px",
                        height: largeImage ? "50px" : "38px",
                        marginBottom: largeImage ? "16px" : "0px",
                        marginTop: largeImage ? "10px" : "0px",
                        WebkitTransition: "height 0.15s, margin 0.15s",
                        transition: "height 0.15s, margin 0.15s"
                      }}
                      src={(onHomepage || isPLM) ? PracticeUnboundYellowWhite : PracticeUnboundYellow}
                    />
                  </Link>
                </li>
              </ul>
              <ul className="navbar-nav mx-auto pl-3">
                <ProductMenu colour={buttonColour} products={products.filter(p => p.comingSoon !== true && (!p.hideUnlessPurchased || p.purchased))} />
                {/* PRODUCTS OPTION WITH MEGA MENU */}
                <li className="nav-item ml-1 mr-2">
                  <Button colour={buttonColour} link={"/customer-stories"}>
                    Customer Stories
                  </Button>
                </li>
                <li className="nav-item mr-2">
                  <Button colour={buttonColour} link={"/blog"}>
                    {/* <Button colour={"white"} link={"/blog"}> */}
                    Blog
                  </Button>
                </li>
                <li className="nav-item mr-2">
                  <Button colour={buttonColour} link={"/contact-us"}>
                    Contact
                  </Button>
                </li>
              </ul>
              <div
                className={this.state.width <= 1140 ? 'd-none' : 'd-block'}
                style={{
                  marginRight: '1.75rem',
                  fontSize: '.8rem'
                }}
              >
                <a style={{ color: '#16C194' }} href="mailto:info@practiceunbound.org.uk">INFO@PRACTICEUNBOUND.ORG.UK</a>
              </div>
              {isAuthenticated ? authNavLarge : anonNavLarge()}
            </div>
          </div>
        </nav >
      </>
    );
  }
}

Navbar.propTypes = {
  auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  products: state.products
});

export default withRouter(
  connect(
    mapStateToProps,
    { clearAuthErrors }
  )(Navbar)
);
