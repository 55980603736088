import React, { useState, useEffect } from 'react'
import logo from "../../img/logo_PLM3x.png";
import Button from '../common/Button';
import DivFade from './DivFade'
import ImageAbsolute from './ImageAbsolute'
import styles from './PLMBanner.module.css'
import Modal from '../common/Modal'
import moment from 'moment'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export default function ProductBanner(props) {

    return (
        <DivFade style={{ overflow: 'hidden', position: 'relative', paddingTop: '58px', paddingBottom: '58px' }}>
            <ImageAbsolute 
                top={0} left={0} right={0} bottom={0}
                backgroundPosition='50% 42%'
                imageSrc={props.backgroundImage} 
            />
            <img alt="product logo" className="d-none d-lg-block" style={{ position: 'absolute', width: '130px', top: '18px', left: '18px' }} src={props.logo} />
            <div className="container py-xl-5">
                <div className="row justify-content-center">
                    <div className="col-md-9 col-sm-12 pt-4 order-last order-md-first text-center">
                        {props.children}
                        {/* <img alt="PLM Logo" className="d-lg-none" style={{ width: '130px' }} src={logo} />
                        <h2 className="mb-2 text-white text-capitalize" style={{
                            fontSize: '2.5rem', fontWeight: 'bold',
                            textShadow: '-1px 0 16px rgba(0,0,0,0.3), 0 1px 16px rgba(0,0,0,0.3), 1px 0 16px rgba(0,0,0,0.3), 0 -1px 16px rgba(0,0,0,0.3)'
                        }}>
                            Prescribing Lifestyle Medicine Live event
                        </h2>
                        <p className="text-white" style={{
                            fontSize: '1.3rem',
                            textShadow: '-1px 0 16px rgba(0,0,0,0.3), 0 1px 16px rgba(0,0,0,0.3), 1px 0 16px rgba(0,0,0,0.3), 0 -1px 16px rgba(0,0,0,0.3)'
                        }}>
                            Saturday 4th March, County Hall, London
                        </p>
                        <Button center colour="orange" link='/product/prescribinglifestylemedicine/event-plm' large inline >book your place here</Button>
                        <div className='py-2' />
                        <h4 className="mt-4 text-white text-capitalize" style={{
                            fontWeight: 'bold',
                            textShadow: '-1px 0 16px rgba(0,0,0,0.3), 0 1px 16px rgba(0,0,0,0.3), 1px 0 16px rgba(0,0,0,0.3), 0 -1px 16px rgba(0,0,0,0.3)'
                        }}>Deliver targeted lifestyle medicine interventions using the principles of personalised care</h4>
                        <div className='py-1' />
                        <Button center colour="orange" link="/product/prescribinglifestylemedicine/enrol-plm" large inline className='mb-3 mr-3'>
                            enrol today
                        </Button> */}
                    </div>
                </div>
            </div>
        </DivFade>
    )
}
