import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getCustomerStoriesContent } from '../../actions/pagesActions'
import ContentBlockPage from '../layout/ContentBlockPage'
import Spinner from '../common/Spinner'

class CustomerStoriesScreen extends Component {
    componentDidMount() {
        if (Object.keys(this.props.pages.customerStoriesContent).length === 0 && this.props.pages.customerStoriesContentLoading === false) {
            this.props.getCustomerStoriesContent()
        }
    }

    render() {
        const { customerStoriesContentLoading, customerStoriesContent } = this.props.pages

        return customerStoriesContentLoading ? <Spinner fullScreen color="yellow" large /> : <ContentBlockPage content={customerStoriesContent} />
    }
}
const mapStateToProps = state => ({
    pages: state.pages
})
export default connect(mapStateToProps, { getCustomerStoriesContent })(CustomerStoriesScreen);