import React, { Component } from 'react'
import { Route } from 'react-router-dom';
import BlogHome from './BlogHome'
import Blog from './Blog'

export default class BlogRouter extends Component {

    render() {
        const { match } = this.props;
        return (
            <div>
                <Route
                    exact
                    path={match.url + '/'}
                    component={BlogHome}
                />
                <Route
                    exact
                    path={match.url + '/:blog'}
                    component={Blog}
                />
            </div>
        )
    }
}
