import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { getPurchaseProduct, getCrmOrganisation, getCrmContract, getOrganisationMemberships, clearPurchaseError } from '../../actions/purchaseActions'
import ContactInfo from './ContactInfo'
import OrganisationSelect from './OrganisationSelect';
import OrganisationConfirm from './OrganisationConfirm';
import PUPanel from '../layout/PUPanel'
import queryString from 'query-string'
import BillsbyCheckout from './BillsbyCheckout'

class PurchaseBillsbyOrganisationHome extends Component {
    constructor() {
        super()
        this.state = {
            contactComplete: false,
            organisationSelectComplete: false,
            organisationConfirmComplete: false,
        }
    }


    render() {
        const { product } = this.props.purchase
        const parsed = queryString.parse(this.props.location.search);
        const hardCodedOrg = !!parsed.organisation



        return (
            <div className="container py-5">
                <PUPanel title={product.title}>
                    {product.name === "prescribinglifestylemedicinepractice" && (
                        <p>
                            You are about to purchase an annual subscription to Prescribing Lifestyle Medicine for your Practice. In order to complete this process you will be set up as an "Organisation Owner" for your Practice on the Practice Unbound platform. If your Practice already has an "Organisation Owner", and this isn't you, please contact them to complete this purchasing process. Or alternatively, <Link to='/contact-us'>call our sales team</Link> for support.
</p>
                    )}

                    <p>
                        Please complete each section of this page using the Edit, Save and Next buttons to right hand side of each section.
                    </p>

                </PUPanel>
                <ContactInfo
                    locked={false}
                    isComplete={this.state.contactComplete}
                    onComplete={() => {
                        this.setState({ contactComplete: true })
                        this.props.clearPurchaseError('contact')
                    }}
                />
                {!hardCodedOrg && (
                    <OrganisationSelect
                        locked={!this.state.contactComplete}
                        isComplete={this.state.organisationSelectComplete}
                        onComplete={() => {
                            this.setState({ organisationSelectComplete: true })
                            this.props.clearPurchaseError('organisations')
                        }}
                    />
                )}

                <OrganisationConfirm
                    locked={hardCodedOrg ? !this.state.contactComplete : !this.state.organisationSelectComplete}
                    isComplete={this.state.organisationConfirmComplete}
                    onComplete={() => {
                        this.setState({ organisationConfirmComplete: true })
                        this.props.clearPurchaseError('organisation')
                        // this.props.history.push(`/purchase/${product.name}/confirm`)
                    }}
                    onUncomplete={() => { this.setState({ organisationConfirmComplete: false }) }}
                />
                <BillsbyCheckout
                    locked={!this.state.organisationConfirmComplete}
                    isComplete={true}

                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    purchase: state.purchase,
})
export default connect(mapStateToProps, { getPurchaseProduct, getCrmOrganisation, getCrmContract, getOrganisationMemberships, clearPurchaseError })(PurchaseBillsbyOrganisationHome);