import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
// import { getPersonalContracts } from '../../actions/authActions'
import Spinner from '../common/Spinner'
import BillsbyContracts from '../purchase/BillsbyContracts'
import PUPanel from '../layout/PUPanel'

class PersonalContractScreen extends Component {
    componentDidMount() {
        // get contracts
        // Moved to the refresh auth datta action
        // this.props.getPersonalContracts()
    }
    // TODO - move this into a comp
    componentDidUpdate() {
        if (window.scanDomBillsby) {
            window.scanDomBillsby();
        }

    }
    render() {
        const { contractsLoading, contracts } = this.props.auth
        if (contractsLoading) return <Spinner block />
        return (
            <div className="container py-5" >
                <div className="row justify-content-center">
                    <PUPanel title="Contracts"
                        col="col-lg-8 col-md-10">
                        {contracts.length === 0 ? (
                            <div>
                                <p className="lead">
                                    Nothing here yet!
    </p>
                                <p><strong>Just made a purchase?</strong> It can take up to 24 hours for new purchases to be activated.</p>
                                <p>
                                    Expecting to see a contract that is not here? Not all of our contracts are yet available for online viewing, but we are working on it.
</p>
                                <p>
                                    If you have any enquiries about your contracts please <Link to="/contact-us">get in touch</Link> with a member of our team.
</p>
                            </div>
                        ) : (
                                <>
                                    <p>Manage your contracts below using the dropdown on the right.</p>
                                    <BillsbyContracts contracts={contracts} />
                                </>
                            )}


                    </PUPanel>



                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
})

export default connect(mapStateToProps, {
    // getPersonalContracts
})(PersonalContractScreen);