import React, { Component } from 'react'
import { connect } from "react-redux";
import Spinner from "../common/Spinner";
import ToDos from '../admin/ToDos'


class LeadsList extends Component {
    render() {
        const { learnerOpmData, learnerOpmDataLoading, product } = this.props.products
        if (learnerOpmDataLoading) {
            return <Spinner block color="yellow" large />
        }
        if (!learnerOpmData) return null
        return (
            <ToDos bottomButton={false} small showLinks={false} organisationProductMembership={learnerOpmData} product={product} />
        )
    }
}
const mapStateToProps = state => ({
    products: state.products,
});
export default connect(mapStateToProps, {})(LeadsList);
