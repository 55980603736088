import React, { useState, useEffect } from 'react'
import { Switch, Link, Route } from 'react-router-dom'
import PrivateRoute from '../../../common/PrivateRoute'
import styles from './Accreditation.module.css'
import Overview from "./Overview";
import Elearning from "./steps/Elearning";
import Community from "./steps/Community";
import Interactions from "./steps/Interactions";
import Assessment from "./steps/Assessment";
import CaseStudy from "./steps/CaseStudy";
import axios from '../../../../config/axios'
import Spinner from '../../../common/Spinner'
import ErrorPanel from '../../ErrorPanel';

const Accreditation = ({ match }) => {

  const [progress, setProgress] = useState({})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errorRes, setErrorRes] = useState({})
  const [postError, setPostError] = useState(false)
  const [postErrorRes, setPostErrorRes] = useState({})
  const [updating, setUpdating] = useState(false)
  const [allValidated, setAllValidated] = useState(false)

  async function getProgress() {
    setLoading(true)
    try {
      const response = await axios.platformAPI.get("/api/platform/accreditation")
      setProgress(response.data)
      setLoading(false)
      return response.data
    } catch (error) {
      setError(true)
      setErrorRes(error.response)
      setLoading(false)
    }
  }

  useEffect(() => {
    getProgress()
  }, [])

  useEffect(() => {
    if (
      progress.elearning_status === 'validated' &&
      progress.community_status === 'validated' &&
      progress.interactions_status === 'validated' &&
      progress.assessment_status === 'validated' &&
      progress.casestudy_status === 'validated'
    )
      setAllValidated(true)
  }, [progress])

  async function updateProgress(section, status, file) {
    setUpdating(true)
    // setPostError(false)
    const statusField = `${section}_status`
    const data = { section, status, file }
    const formData = new FormData();
    for (var key in data) {
      formData.append(key, data[key]);
    }
    try {
      await axios.platformAPI.post("/api/platform/accreditation", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      setProgress(prev => ({
        ...prev,
        [statusField]: status
      }))
    } catch (error) {
      setPostError(true)
      setPostErrorRes(error.response)
      // console.log("🚀 ~ file: Accreditation.js:58 ~ updateProgress ~ error.response:", error.response) 
      return error.response
    }
    finally {
      // setTimeout(() => {

      setUpdating(false)
      // }, 2000);
    }
  }

  if (error) return <ErrorPanel errorRes={errorRes} />

  if (loading) return <Spinner block />

  const sectionProps = {
    progress,
    updateProgress,
    updating,
    setUpdating,
    postError,
    postErrorRes
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
          <div style={{ position: "sticky", zIndex: "300", top: "160px" }}>
            <div className="text-center text-md-left">
              <Link className="res-tab-tab" to={`${match.url}`}>
                overview
              </Link>
              <Link className="res-tab-tab" to={`${match.url}/elearning`}>
                1. complete e-learning modules
              </Link>
              <Link className="res-tab-tab" to={`${match.url}/community`}>
                2. join the plm community
              </Link>
              <Link className="res-tab-tab" to={`${match.url}/interactions`}>
                3. log 85 hours of lifestyle medicine consultations
              </Link>
              <Link className="res-tab-tab" to={`${match.url}/assessment`}>
                4. complete assessment
              </Link>
              <Link className="res-tab-tab" to={`${match.url}/casestudy`}>
                5. submit case study
              </Link>
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10" style={{ maxWidth: '900px' }}>
          {/* <div className="row"> */}
          {/* <div className="col-lg-8 col-xl-9">              */}
          <Route exact path={match.url}>
            <Overview progress={progress} updateProgress={updateProgress} />
          </Route>
          <Route exact path={match.url + '/elearning'}>
            <Elearning {...sectionProps} />
          </Route>
          <Route exact path={match.url + '/community'}>
            <Community {...sectionProps} />
          </Route>
          <Route exact path={match.url + '/interactions'}>
            <Interactions {...sectionProps} />
          </Route>
          <Route exact path={match.url + '/assessment'}>
            <Assessment {...sectionProps} />
          </Route>
          <Route exact path={match.url + '/casestudy'}>
            <CaseStudy {...sectionProps} />
          </Route>
        </div>
      </div>
    </div>
    //   </div>
    // </div>
  )
}


export default Accreditation;
