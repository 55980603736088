import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getPurchaseProduct, getCrmOrganisation, getCrmContract, getOrganisationMemberships, clearPurchaseError } from '../../actions/purchaseActions'
import Spinner from '../common/Spinner'
import ContactInfo from './ContactInfo'
import OrganisationSelect from './OrganisationSelect';
import OrganisationConfirm from './OrganisationConfirm';
import Contracting from './Contracting'
import PUPanel from '../layout/PUPanel'
import queryString from 'query-string'


class PurchaseCRMHome extends Component {
    constructor() {
        super()
        this.state = {
            contactComplete: false,
            organisationSelectComplete: false,
            organisationConfirmComplete: false,
            contractingComplete: false
        }
    }


    render() {
        const { product, productLoading, organisationValid } = this.props.purchase
        if (Object.keys(product).length === 0) return <Spinner fullScreen color='yellow' large />
        if (productLoading) return <Spinner fullScreen color='yellow' large />
        const parsed = queryString.parse(this.props.location.search);
        const hardCodedOrg = !!parsed.organisation



        return (
            <div className="container py-5">
                <PUPanel title={product.title}>
                    <p>
                        Complete each section of this form using edit, save and next buttons on the right hand side of section.
                    </p>

                </PUPanel>
                <ContactInfo
                    locked={false}
                    isComplete={this.state.contactComplete}
                    onComplete={() => {
                        this.setState({ contactComplete: true })
                        this.props.clearPurchaseError('contact')
                    }}
                />
                {!hardCodedOrg && (
                    <OrganisationSelect
                        locked={!this.state.contactComplete}
                        isComplete={this.state.organisationSelectComplete}
                        onComplete={() => {
                            this.setState({ organisationSelectComplete: true })
                            this.props.clearPurchaseError('organisations')
                        }}
                    />
                )}

                <OrganisationConfirm
                    locked={hardCodedOrg ? !this.state.contactComplete : !this.state.organisationSelectComplete}
                    isComplete={this.state.organisationConfirmComplete}
                    onComplete={() => {
                        this.setState({ organisationConfirmComplete: true })
                        this.props.clearPurchaseError('organisation')
                        // this.props.history.push(`/purchase/${product.name}/confirm`)
                    }}
                    onUncomplete={() => { this.setState({ organisationConfirmComplete: false }) }}
                />
                <Contracting
                    locked={!this.state.organisationConfirmComplete || !organisationValid}
                    isComplete={this.state.contractingComplete}
                    onComplete={() => { this.setState({ contractingComplete: true }) }}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    purchase: state.purchase,
})
export default connect(mapStateToProps, { getPurchaseProduct, getCrmOrganisation, getCrmContract, getOrganisationMemberships, clearPurchaseError })(PurchaseCRMHome);