import React from 'react'
import Check from '../common/Check'

export default function PLMTable() {
    return (
        <div className="d-flex justify-content-center">
            <table className="table table-sm" style={{ maxWidth: '500px' }}>
                <thead>
                    <tr>
                        <th style={{ borderTop: 'none' }}>

                        </th>
                        <th style={{ borderTop: 'none' }}>
                            Regular Product
                        </th>
                        <th style={{ borderTop: 'none' }}>
                            UK GP Practice
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>e-Learning modules</td>
                        <td className="text-center"><Check color="green" /></td>
                        <td className="text-center"><Check color="green" /></td>
                    </tr>
                    <tr>
                        <td>Self Assessment reporting tool</td>
                        <td className="text-center"><Check color="green" /></td>
                        <td className="text-center"><Check color="green" /></td>
                    </tr>
                    <tr>
                        <td>Patient resources</td>
                        <td className="text-center"><Check color="green" /></td>
                        <td className="text-center"><Check color="green" /></td>
                    </tr>
                    <tr>
                        <td>Practitioner resources</td>
                        <td className="text-center"><Check color="green" /></td>
                        <td className="text-center"><Check color="green" /></td>
                    </tr>
                    <tr>
                        <td>Access to community</td>
                        <td className="text-center"><Check color="green" /></td>
                        <td className="text-center"><Check color="green" /></td>
                    </tr>
                    <tr>
                        <td>Technical helpdesk</td>
                        <td className="text-center"><Check color="green" /></td>
                        <td className="text-center"><Check color="green" /></td>
                    </tr>
                    <tr>
                        <td>Take part in the data pilot</td>
                        <td className="text-center"></td>
                        <td className="text-center"><Check color="green" /></td>
                    </tr>
                    <tr>
                        <td>Price</td>
                        <td className="text-center font-weight-bold">£395 + VAT</td>
                        <td className="text-center font-weight-bold">£395 + VAT</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td colspan="2" className="text-center text-muted"><small>(UK total price: £474)</small></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
