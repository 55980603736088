import React from 'react'
import Moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons'

library.add(faThumbsUp, faThumbsDown)

const PrevAssessmentQuestionGrouped = ({ assessments, questionNumber, answerIcon }) => {
    return (
        <div className="p-2 pt-3 row">
            <div className="col">
                <p><strong>{assessments.filter(assessm => assessm.questionnumber === questionNumber)[0].questiontext}</strong></p>
                <table className='table'>
                    <thead>
                        <th scope='col'>Date</th>
                        <th scope='col'>Answer</th>
                    </thead>
                    <tbody>
                        {assessments.filter(assessm => assessm.questionnumber === questionNumber).map(assessment => {
                            return (
                                <tr>
                                    <td>{Moment(assessment.eventdate).format("DD MMM YYYY")}</td>
                                    <td>{answerIcon ? <FontAwesomeIcon icon={assessment.answervalue === 'true' ? 'thumbs-up' : 'thumbs-down'} /> : assessment.answervalue}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                {/* {assessments.filter(assessm => assessm.questionnumber === questionNumber).map(assessment => {
                    return (
                        <p>{Moment(assessment.eventdate).format("DD MMM YYYY")}<strong>{assessment.answervalue}</strong></p>
                    )
                })} */}
            </div>
        </div>
    )
}

export default PrevAssessmentQuestionGrouped