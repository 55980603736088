import { Link } from "react-router-dom"
import puColours from "../../../utils/puColours"

const logo = "https://practiceunbound.blob.core.windows.net/stock-images/logo_pathlogy_go@2x.png"

const PathGoLogoComp = () => <>
  <Link to="/product/pathologygo/home" target="_blank">
    <img alt="pathology go logo" className="img-fluid  " src={logo} />
  </Link>
</>

const PriceComp = () => <>
    <div className="tw-relative tw-text-white tw-font-bold" style={{ fontSize: '16px', height: '100%' }}>
        <div className="tw-absolute tw-top-[26px] tw-left-[80px] tw-text-xl">ONLY</div>
        <div className="tw-absolute tw-top-[52px] tw-left-[52px] tw-text-6xl">30p</div>
        <div className="tw-absolute tw-text-center tw-top-[126px] tw-left-[12px]">per registered patient per year</div>
    </div>
</>

const topBannerShapes = [
    {
        color: puColours.lightMint,
        parentClassName: 'position-absolute tw-group tw-w-[122px]',
        childClassName: 'tw-rounded-full', 
        top: '20%',
        left: '80%',
    },
    {
        color: puColours.yellow,
        parentClassName: 'position-absolute tw-group tw-w-[50px]',
        childClassName: 'tw-rounded-full', 
        top: '112%',
        left: '20%',
    },
    {
        color: '',
        parentClassName: 'position-absolute tw-group tw-w-[289px]',
        childClassName: '', 
        content: <PathGoLogoComp />,
        top: '16%',
        left: '13%',
    },
    // {
    //     color: puColours.darkMint,
    //     parentClassName: 'position-absolute tw-group tw-w-[202px]',
    //     childClassName: 'tw-rounded-full', 
    //     content: <PriceComp />,
    //     top: '-1%',
    //     left: '-20%',
    // },
]

export default topBannerShapes
