import React, { Component } from 'react'
import { connect } from 'react-redux';
import { updateAuthProfile, setPromptAuthProfile } from '../../actions/profileActions'
import Button from '../common/Button'
import CheckboxArray from '../common/CheckboxArray'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Modal from '../common/Modal'
import Chevron from '../common/Chevron'

class AuthProfile extends Component {
    constructor() {
        super()
        this.state = {
            roles: [],
            interests: [],
            funding: [],
            currentQuestion: 1,
            numberQuestions: 3,
            forwards: true
        }
        this.onChange = this.onChange.bind(this);
        this.onAuthSubmit = this.onAuthSubmit.bind(this);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.keyPress = this.keyPress.bind(this)
    }
    componentDidMount() {
        this.setState({ roles: this.props.profile.roles })
        this.setState({ interests: this.props.profile.interests })
        this.setState({ funding: this.props.profile.funding })

        document.addEventListener("keydown", this.keyPress, false);
    }

    UNSAFE_componentWillReceiveProps(props) {
        if (props.profile.roles !== this.props.profile.roles) { this.setState({ roles: props.profile.roles }) }
        if (props.profile.interests !== this.props.profile.interests) { this.setState({ interests: props.profile.interests }) }
        if (props.profile.funding !== this.props.profile.funding) { this.setState({ funding: props.profile.funding }) }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.keyPress, false);
    }

    keyPress(e) {
        if (e.key === "ArrowRight") {
            this.next()
        } else if (e.key === "ArrowLeft") {
            this.previous()
        }
    }

    onChange(e) {
        if (e.target.type === "checkbox") {
            this.setState({ [e.target.name]: e.target.checked })
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }

    onAuthSubmit() {
        this.props.updateAuthProfile({
            interests: this.state.interests,
            roles: this.state.roles,
            funding: this.state.funding
        })
    }

    next() {
        if (this.state.currentQuestion < this.state.numberQuestions) {
            this.setState({ currentQuestion: this.state.currentQuestion + 1, forwards: true })
        }
    }
    previous() {
        if (this.state.currentQuestion > 1) {
            this.setState({ currentQuestion: this.state.currentQuestion - 1, forwards: false })
        }
    }

    render() {
        const { promptAuthProfile, rolesOptions, fundingOptions, interestsOptions } = this.props.profile

        const Question = (props) => {
            switch (props.number) {
                case 1: return (
                    <div>
                        <h6>What roles do you play in the health sector?</h6>
                        <CheckboxArray
                            onChange={this.onChange}
                            className="mb-2"
                            name='roles'
                            value={this.state.roles}
                            options={rolesOptions}
                        />
                    </div>
                )
                case 2: return (
                    <div>
                        <h6>What are your interests?</h6>
                        <CheckboxArray
                            onChange={this.onChange}
                            className="mb-2"
                            name='interests'
                            value={this.state.interests}
                            options={interestsOptions}
                        />
                    </div>
                )
                case 3: return (
                    <div>
                        <h6>What funding do you have access to?</h6>
                        <CheckboxArray
                            onChange={this.onChange}
                            className="mb-2"
                            name='funding'
                            value={this.state.funding}
                            options={fundingOptions}
                        />
                        <Button className="mt-5" center onClick={this.onAuthSubmit}>Submit</Button>
                    </div>
                )
                default: return <div></div>
            }
        }

        return (
            <Modal
                isOpen={promptAuthProfile}
                onRequestClose={() => {
                    this.onAuthSubmit()
                    this.props.setPromptAuthProfile(false)
                }}
                title="Tell us about yourself"
                size="small"
            >
                <div style={{ position: 'relative', overflowX: 'hidden', height: '300px' }}>
                    {this.state.currentQuestion > 1 && (
                        <div
                            style={{ position: 'absolute', top: '25%' }}
                            onClick={this.previous}
                        >
                            <Chevron direction="left" />
                        </div>
                    )}
                    <div style={{ paddingLeft: '35px', paddingRight: '35px' }}>
                        < TransitionGroup
                            appear={false}
                        >
                            <CSSTransition
                                key={this.state.currentQuestion}
                                classNames={this.state.forwards ? "fadeLeft" : "fadeRight"}
                                timeout={{ enter: 1000, exit: 1000 }}
                            >
                                <div style={{ position: 'absolute', width: 'calc(100% - 70px)' }}>
                                    <Question number={this.state.currentQuestion} />
                                </div>
                            </CSSTransition>
                        </TransitionGroup >
                    </div>
                    {this.state.currentQuestion < this.state.numberQuestions && (
                        <div
                            style={{ position: 'absolute', top: '25%', right: '0' }}
                            onClick={this.next}
                        >
                            <Chevron direction="right" />
                        </div>
                    )}
                </div>
            </Modal >
        )
    }
}

const mapStateToProps = (state) => ({
    profile: state.profile
})

export default connect(mapStateToProps, { updateAuthProfile, setPromptAuthProfile })(AuthProfile);
