import React, { Component } from 'react'
import { connect } from 'react-redux';
import AuthProfile from './AuthProfile'
import { clearProduct, clearProductMembership } from '../../actions/productActions'
import { getBrowseContent } from '../../actions/pagesActions'
import ContentBlockPage from '../layout/ContentBlockPage'
import Spinner from '../common/Spinner'


class BrowseScreen extends Component {
    componentDidMount() {
        this.props.clearProduct()
        this.props.clearProductMembership()
        if (Object.keys(this.props.pages.browseContent).length === 0 && this.props.pages.browseContentLoading === false) {
            this.props.getBrowseContent()
        }
    }
    render() {
        const { browseContentLoading } = this.props.pages
        return (
            <div>

                <AuthProfile />
                {browseContentLoading ? <Spinner fullScreen color="yellow" large /> : <ContentBlockPage content={this.props.pages.browseContent} />}
            </div >
        )
    }
}
const mapStateToProps = state => ({
    products: state.products,
    pages: state.pages
})
export default connect(mapStateToProps, { clearProduct, clearProductMembership, getBrowseContent })(BrowseScreen);