import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getProduct, getResourcesContentList } from '../../actions/productActions'
import { getProductContent } from '../../actions/pagesActions'
import { getOrganisationsByProduct } from '../../actions/organisationActions'
import { getElearningResults } from '../../actions/elearningActions'
import HomeScreen from './HomeScreen'
import GetStartedScreen from './GetStartedScreen'
import ElearningScreen from './ElearningScreen'
import DataScreen from './DataScreen'
import ResourcesRouter from './ResourcesRouter'
import LearnerNavbar from './LearnerNavbar'
import Spinner from '../common/Spinner'
import ElearningRedirect from './ElearningRedirect'
// import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { Route, Switch, Redirect } from 'react-router-dom';
import PrivateRoute from '../common/PrivateRoute'
import Error404Screen from '../errors/Error404Screen'
import LeadsScreen from './LeadsScreen';
import AssessmentScreen from './AssessmentScreen';
import LearnerSubPages from './LearnerSubPages'
import NLSWorkflowProtocol from './NLSWorkflowProtocol';
import NLSWorkflowTimeline from './NLSWorkflowTimeline';
import About from '../layout/PLM/About';
import Enrol from '../layout/PLM/Enrol';
// import Event from '../layout/PLM/Event';
import Event from '../layout/PLM/Event2024';
import GPBillingSystFeat from '../layout/GPBilling/SystemFeatures';
import SuccessStories from '../layout/GPBilling/SuccessStories/SuccessStories';
import TopBillable from '../layout/GPBilling/TopBillable';
import Organisations from '../layout/PLM/Organisations';
import PLMDataSignup from './PLMDataSignup';
import PLMLeadSignup from '../purchase/PLMLeadSignup';
import PasswordPage from '../common/PasswordPage';
import Pickering from '../layout/GPBilling/SuccessStories/Pickering';
import Riverside from '../layout/GPBilling/SuccessStories/Riverside';
import Northgate from '../layout/GPBilling/SuccessStories/Northgate';
import HallGreen from '../layout/GPBilling/SuccessStories/HallGreen';
import PrimaryIntegrated from '../layout/GPBilling/SuccessStories/Pics';
import AccreditationAuth from '../layout/PLM/Accreditation/AccreditationAuth';
import FrequentQuestions from '../layout/PLM/Accreditation/FrequentQuestions';
import PathGoBenefits from '../layout/PathologyGo/PathGoBenefits';
import PathGoPricing from '../layout/PathologyGo/PathGoPricing';
import PathGoImplementation from '../layout/PathologyGo/PathGoImplementation';
import PathGoWhatsIncluded from '../layout/PathologyGo/PathGoWhatsIncluded';
import PathGoFaqs from '../layout/PathologyGo/PathGoFaqs';

class LearnerRouter extends Component {
    constructor() {
        super()
        this.updateProduct = this.updateProduct.bind(this)
    }
    componentDidMount() {
        this.updateProduct()
    }

    componentDidUpdate(nextProps) {
        if ((nextProps.match.params.product !== this.props.match.params.product)
            || nextProps.auth.isAuthenticated !== this.props.auth.isAuthenticated) {
            this.updateProduct()
        }
    }

    updateProduct() {
        const productCode = this.props.match.params.product
        this.props.getProduct(productCode)
        this.props.getProductContent(productCode)
        this.props.getResourcesContentList(productCode)

        if (this.props.auth.isAuthenticated) {
            this.props.getOrganisationsByProduct(productCode)
            this.props.getElearningResults(productCode);

        }
        sessionStorage.setItem('product', productCode)
    }

    render() {
        const { match, location } = this.props;
        const { productLoading, product, productNotFound } = this.props.products
        if (productNotFound) {
            return <Error404Screen large color="yellow" fullScreen />
        }
        if (productLoading || product === null) {
            return <Spinner large color="yellow" fullScreen />
        }

        if (match.url === '/product/prescribinglifestylemedicine') {
            if (this.props.auth.products.map(p => p.product).includes('prescribinglifestylemedicinepractice')) {
                console.log("PLM Practice Redirect")
                this.props.history.push(location.pathname.replace('prescribinglifestylemedicine', 'prescribinglifestylemedicinepractice'))
            }
        }

        return (
            <div style={{ position: 'relative' }}>
                <Route path={match.url} component={LearnerNavbar} />
                <Route exact path={match.url + '/course/redirect'} component={ElearningRedirect} />
                {/* <TransitionGroup appear={true}>
                    <CSSTransition
                    key={location.key}
                    classNames="fadeRouter-zeros"
                    timeout={300}
                    > */}
                <Switch>
                    <PrivateRoute path={match.url + '/course'} component={ElearningScreen} />
                    <PrivateRoute exact path={match.url + '/data'} component={DataScreen} />
                    <PrivateRoute exact path={match.url + '/assessment'} component={AssessmentScreen} />
                    <Route exact path={match.url + '/home'} component={HomeScreen} />
                    <Route exact path={match.url + '/about-plm'} component={About} />
                    <Route exact path={match.url + '/enrol-plm'} component={Enrol} />
                    <Route exact path={match.url + '/event-plm'} component={Event} />
                    <Route exact path={match.url + '/organisations-plm'} component={Organisations} />
                    <Route exact path={match.url + '/pathgo-benefits'} component={PathGoBenefits} />
                    {/* <Route exact path={match.url + '/pathgo-pricing'} component={PathGoPricing} /> */}
                    <Route exact path={match.url + '/pathgo-implementation'} component={PathGoImplementation} />
                    <Route exact path={match.url + '/pathgo-whatsincluded'} component={PathGoWhatsIncluded} />
                    <Route exact path={match.url + '/pathgo-faqs'} component={PathGoFaqs} />
                    <Route exact path={'/product/gpbilling/system-features'} component={GPBillingSystFeat} />
                    <Route exact path={'/product/gpbilling/success-stories'} component={SuccessStories} />
                    <Route exact path={'/product/gpbilling/success-stories/pickering-medical-practice'} component={Pickering} />
                    <Route exact path={'/product/gpbilling/success-stories/riverside-medical-practice'} component={Riverside} />
                    <Route exact path={'/product/gpbilling/success-stories/northgate-surgery'} component={Northgate} />
                    <Route exact path={'/product/gpbilling/success-stories/hall-green-health'} component={HallGreen} />
                    <Route exact path={'/product/gpbilling/success-stories/primary-integrated-community-services'} component={PrimaryIntegrated} />
                    <Route exact path={'/product/gpbilling/25-top-billable'} component={TopBillable} />
                    <PrivateRoute exact path={match.url + '/get-started'} component={GetStartedScreen} />
                    <PrivateRoute exact path={match.url + '/data-signup'} component={PLMDataSignup} />
                    <PrivateRoute exact path={match.url + '/data-signup-form'} component={PLMLeadSignup} />
                    <PrivateRoute path={match.url + '/resources'} >
                        <ResourcesRouter platformTab='Resources' />
                    </PrivateRoute>
                    <PrivateRoute path={match.url + '/implementation'} >
                        <ResourcesRouter platformTab='Implementation' />
                    </PrivateRoute>
                    <PrivateRoute path={match.url + '/protocol-resources'} >
                        <ResourcesRouter platformTab='Protocol' />
                    </PrivateRoute>
                    <Route path={match.url + '/conference-slides'} >
                        <PasswordPage password="FW4Z2JK">
                            <ResourcesRouter platformTab='Conference-Slides' />
                        </PasswordPage>
                    </Route>
                    <Route path={match.url + '/conference-slides-24'} >
                        <PasswordPage password="JT2765U9">
                            <ResourcesRouter platformTab='Conference-Slides-24' />
                        </PasswordPage>
                    </Route>
                    <Route path={match.url + '/ayan-video'} >
                        <PasswordPage password="UKIHCA">
                            <ResourcesRouter platformTab='ayan-video' />
                        </PasswordPage>
                    </Route>
                    <Route path={match.url + '/accreditation'} component={AccreditationAuth} />
                    <Route path={match.url + '/accreditation-faqs'} component={FrequentQuestions} />
                    <PrivateRoute path={match.url + '/setup'} component={LeadsScreen} />
                    <PrivateRoute path={match.url + '/protocol'} component={NLSWorkflowProtocol} />
                    <PrivateRoute path={match.url + '/timeline'} component={NLSWorkflowTimeline} />
                    <Route exact path={match.url + '/'} render={(props) => {
                        return <Redirect to={props.match.path + 'home'} />
                    }} />
                    <Route path={match.url + '/:pageSlug'} component={LearnerSubPages} />
                    <Route component={Error404Screen} />
                </Switch>
                {/* </CSSTransition>
                </TransitionGroup> */}
            </div>
        )
    }
}
const mapStateToProps = state => ({
    products: state.products,
    auth: state.auth
})
export default connect(mapStateToProps, { getProduct, getProductContent, getResourcesContentList, getOrganisationsByProduct, getElearningResults })(LearnerRouter);