import React, { Component } from "react";

class TabbedContent extends Component {
  constructor(props) {
    super(props);
    const activeTab = props.nav.findIndex((btn) => {
      return "#" + this.convertToKebabCase(btn) === window.location.hash;
    });
    this.state = {
      activeTab: activeTab > -1 ? activeTab : 0,
    };
  }

  changeTab(e) {
    const button = e.target;
    const buttonIndex = [...button.parentElement.children].indexOf(button);
    [...this.refs.nav.querySelectorAll(".tabs-nav__btn")].forEach((btn) => {
      btn.classList.remove("active");
    });
    button.classList.add("active");
    this.setState({ activeTab: buttonIndex });
  }

  convertToKebabCase(string) {
    return string
      .replace(/\s+/g, "-")
      .replace(/[^\w\s]/gi, '')
      .toLowerCase();
  }

  render() {
    return (
      <section>
        <div className="container px-0">
          <nav className="tabs-nav" ref="nav">
            {this.props.nav.map((btn, i) => (
              <a
                href={"#" + this.convertToKebabCase(btn)}
                key={"tab" + i}
                className={
                  "tabs-nav__btn " +
                  (this.props.color === 'teal' ? "tabs-nav__btnteal " : "tabs-nav__btngreen ") +
                  (i === this.state.activeTab ? "active" : "")
                }
                onClick={this.changeTab.bind(this)}
              >
                {btn}
              </a>
            ))}
          </nav>
        </div>
        <section className="tab-content">
          {this.props.children
            .filter((el, i) => this.state.activeTab === i)
            .map((el, i) => el)}
        </section>
      </section>
    );
  }
}

export default TabbedContent;
