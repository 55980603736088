import React from "react";

const Tab = (props) => {
  return (
    <section className={"tab-content " + props.className}>
      {props.children}
    </section>
  );
};

export default Tab;
