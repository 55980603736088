import CircleTextBlock from '../../PLM/Comps/CircleTextBlock'
// import ImageTextBlock from '../PLM/Comps/ImageTextBlock'
import circles from '../../../../img/grey-bubbles.svg'
import styles from './WhatIs.module.css'

const WhatIs = (props) => {

  return (
    <CircleTextBlock
      imageIsCircle
      imageUrl={'https://practiceunbound.blob.core.windows.net/stock-images/Homepage_Block_Shutterstock_1050440903-min.jpg'}
      contentSide="left"
      contentBackgroundColor="white"
      // contentBackgroundColor="#193958"
      contentBackgroundImage={circles}
      contentBackgroundSize='30%'
      contentBackgroundPosition='top left'
      contentBackgroundRight='5%'
      contentBackgroundTop='-15%'
      // circleContent
      circleColour='#193958'
      circleContent = {() => (
        <>
          <div className={`${styles.circleContent}`}>
            <div className='py-3 d-block d-md-none'/>
            <h3 className={`font-weight-bold text-white text-center mb-3`}>On average:</h3>
            <h4 className='font-weight-bold text-white text-center mb-3'><span style={{color: '#60AE4C'}}>1 in 10 bills</span> are missed which results in <span style={{color: '#60AE4C'}}>£5000 of lost revenue</span> per annum.</h4>
            <h4 className='font-weight-bold text-white text-center mb-3'>A practice can <span style={{color: '#60AE4C'}}>save</span> approximately <span style={{color: '#60AE4C'}}>50 days</span> annually.</h4>
            <div className='py-4 d-block d-md-none'/>
          </div>
          {/* <BottomBorder /> */}
        </>
      )}
      >      
      <div className='pt-5 pb-5 pr-5'>
          {props.children}
      </div>  
    </CircleTextBlock>
  )
}

const BottomBorder = () => (
  <div 
    style={{
        position: 'absolute',
        width: '200%',
        height: '2px',
        bottom: '0px',
        left: '-100px',
        right: '0px',
        background: '#60AE4C'
    }}
  />    
)

export default WhatIs