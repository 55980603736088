import { useRef, useEffect, useState } from 'react'
import ImageTextBlock from '../../PLM/Comps/ImageTextBlock'
import greyCircles from '../../../../img/slightly-dark-grey-bubbles-right.svg'
import Quote from '../../../common/Quote'
import BottomBanner from '../../PLM/Comps/BottomBanner'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import Carousel from '../../../common/Carousel'
import {carouselItems} from './SuccessStories'
import picsImg from '../../../../img/02 Success Stories/thumbnail_pics@3x.png'
import Divfade from '../../DivFade'
import Button from '../../../common/Button'
import VideoTextBlock from '../../VideoTextBlock'
import { useHistory } from 'react-router-dom'


const carouselItemsFiltered = carouselItems.filter(i => i.title !== 'Primary Integrated Community Services')

export default function Pics() {
    const history = useHistory()  
    const handleNav = (url) => () => history.push(url)

    const [windowSize, setWindowSize] = useState(getWindowSize());
    
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
      }
  
      window.addEventListener('resize', handleWindowResize);
  
      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }, []);

    function getWindowSize() {
        const {innerWidth} = window;
        return {innerWidth};
      }

  return (

    <div>

        <ImageTextBlock
            imageUrl={picsImg}
            contentSide="left"
            contentBackgroundColor="white"
            contentBackgroundImage={greyCircles}
            contentBackgroundSize='20%'
            contentBackgroundPosition='top left'
            contentBackgroundRight='5%'
            contentBackgroundTop='-35%'
            imageBackgroundSize='contain'
        >
        <div className='py-5'>
            <h3 className='font-weight-bold mb-3'>Primary Integrated Community Services</h3>
            <p className='font-weight-bold'>Successfully embidding GP Billing during the Covid-19 Pandemic</p>
            <p>Primary Integrated Community Services (PICS) is based in Nottinghamshire and is unique because of the variety of community services and primary care it provides for NHS patients. Learn more about how PICS adopted new technology, including GP Billing, to support new ways work of working in response to the pandemic.</p>
        </div>
        </ImageTextBlock>

        <VideoTextBlock
            src="https://www.youtube.com/embed/OmDFfpQPaNY" 
            title="Success Story: PICS - Embedding GP Billing during the Covid-19 Pandemic"
        >
            <>
                <h3 className="font-weight-bold mb-3">INTERVIEW: ALISON ROUNCE, MANAGING DIRECTOR AT PIC</h3>
                <p>Alison talks in detail about how and why general practices have had to adapt during the Covid-19 pandemic.</p>
                <p>Cultures and attitudes toward providing patients services on the front-line have changed. With smart technology practice staff can help release much needed time. GP Billing's paperless features has helped contribute to this positive change.</p>
            </>
        </VideoTextBlock>
        
        <Divfade className='px-4'>
            <div className='container p-5'>
                <Quote
                    author={testimonials[0].author} 
                    quote={testimonials[0].quote} 
                />
            </div>
        </Divfade>

        <VideoTextBlock
            src="https://www.youtube.com/embed/RPw24W955bI"
            title="Success Story: PICS - Embedding GP Billing during the Covid-19 Pandemic"
            contentRight
        >
            <h3 className="font-weight-bold mb-3">INTERVIEW: LISA CHEVALIER-CRAMPTON, RECEPTION MANAGER AT HAMA MEDICAL CENTRE</h3>
            <p>Lisa is an experienced Reception Manager with 19 years at Hama Medical Centre. GP Billing has enabled her team to work smarter by embracing paperless processes. She also discusses how GP Billing enabled patients to make payments without needing to come into practices during lockdown.</p>
            <p>Lisa is confident that the system will continue to provide efficiencies for her team and loves using the system on a day-to-day basis. </p>
        </VideoTextBlock>

        <Divfade className='px-4'>
            <div className='container p-5'>
                <Quote
                    author={testimonials[1].author} 
                    quote={testimonials[1].quote} 
                />
            </div>
        </Divfade>

        <VideoTextBlock
            src="https://www.youtube.com/embed/84s3I1cQ54g"
            title="Success Story: PICS - Embedding GP Billing during the Covid-19 Pandemic"
        >
            <>
                <h3 className="font-weight-bold mb-3">INTERVIEW: DAVID HALE, FINANCE AND CORPORATE MANAGER AT PICS</h3>
                <p>David has found GP Billing particularly useful within his role at PICS. By ensuring that finances and payment are reconciled correctly across sites - he has found the system extremely helpful in tracking payments and debt.</p>
                <p>Data accuracy is extremely important when dealing with information across an estate. GP Billing's automated features have been useful in sending invoices and receipts via email and have been easy to keep track. Cashless payments through the system have also helped to reduce time spent on tasks.</p>
            </>
        </VideoTextBlock>


        <Divfade className='px-4'>
            <div className='container p-5'>
                <Quote
                    author={testimonials[2].author} 
                    quote={testimonials[2].quote} 
                />
            </div>
        </Divfade>

        <ImageTextBlock
            imageUrl={picsImg}
            contentSide="left"
            contentBackgroundColor="white"
            // contentBackgroundImage={greyCircles}
            contentBackgroundSize='70%'
            contentBackgroundPosition='top left'
            contentBackgroundRight='40%'
            contentBackgroundTop='-25%'
            imageBackgroundSize='contain'
        >
            <div className='p-5 my-4'>
                <p>GP Billing would like to thank PICS for their help and support in producing this Success Story and video interviews during what has been and extremely busy time and challenging time. Their passion to share experiences and educate peer practices about efficiencies is commended. For more information about PICS please visit picsnhs.org.uk.</p>
            </div>
        </ImageTextBlock>

        <Divfade style={{backgroundColor: '#0E3A5C', paddingTop: '50px'}} className='pb-5 px-5'>
            <div className='container text-white'>
                <div className='row'>
                    <div className='col-12 col-md-8'>
                        <h2 className='font-weight-bold mb-4'>Find out how GP Billing can help you</h2>
                        <p>Inspiring positive back-office change with streamlined billing software</p>
                    </div>
                    <div className='col-12 col-md-4 mt-4'>
                        <Button link="/contact-us" large colour='otherGreen'>Arrange a demo</Button>
                    </div>
                </div>
            </div>
        </Divfade>

        <div className='d-block d-lg-none'>
            <Carousel
            // carouselContentType='courseCreators'
            backgroundColour={'blue'}
            titleHeight='28px'
            height='310px'
            items={carouselItemsFiltered.map(e => ({...e, navigateFunction: handleNav(e.linkUrl)}))}
            cardColor='leafGreen'            
            />
        </div>

        <div className='d-none d-lg-block'>
            <Carousel
                // carouselContentType='courseCreators'
                backgroundColour={'blue'}
                titleHeight='28px'
                height='310px'
                slidesToShow={2}
                items={carouselItemsFiltered.filter((e,i) => i >=0 && i <=1).map(e => ({...e, navigateFunction: handleNav(e.linkUrl)}))}
                cardColor='leafGreen'    
                paddingBottom='20px'            
            />
            <Carousel
                // carouselContentType='courseCreators'
                backgroundColour={'blue'}
                titleHeight='28px'
                height='310px'
                items={carouselItemsFiltered.filter((e,i) => i >=2 && i <=3).map(e => ({...e, navigateFunction: handleNav(e.linkUrl)}))}
                cardColor='leafGreen'  
                slidesToShow={2}   
                topHalfBackgroundColour='rgb(25, 57, 88)'   
                // items={carouselItems}        
            />
        </div>

        <BottomBanner backgroundColour="leafGreen" header="Questions?">
            {/* <p style={{ fontSize: '18px' }}>Whether you are interested in one of our products or would like to know more about working with Practice Unbound, please give us a call on 0300 303 8145 or email <a style={{ fontWeight: 'bold', color: 'white' }} href="mailto:info@practiceunbound.org.uk">info@practiceunbound.org.uk</a></p> */}
            <p style={{ fontSize: '18px' }}><Link style={{ color: 'white' }} to="/contact-us">Contact the team</Link> or call us on <strong>0300 303 8145</strong></p>
        </BottomBanner>

    </div>   
    
    

 ) 
  
}

const testimonials = [
    {
        quote: 'We\'ve had to adopt new ways of working including supporting technology to help us through the pandemic."',
        author: 'Alison Rounce | Managing Director PICS'
    },
    {
        quote: 'I would highly recommend GP Billing to other practices, the team love using the system… It works brilliantly alongside our clinical system with client integration and ease of use."',
        author: 'Lisa Chevalier-Crampton | Reception Manager at Hama Medical Centre'
    },
    {
        quote: 'Everything is done electronically and managed without difficulty… this also facilitates the use of debit and credit card payment which means in these Covid times we are not having deal with physically going to the bank."',
        author: 'David Hale | Finance and Corporate Manager at PICS'
    },
]