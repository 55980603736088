import React, { Component } from 'react'
import { connect } from 'react-redux';
import { updateCrmOrganisation, subscribeAction, contractAction, setPurchaseSubmitSuccess } from '../../actions/purchaseActions'
import FormSection from '../layout/FormSection'
import moment from 'moment'
import numberWithCommas from '../../utils/numberWithCommas'
import parameters from '../../config/parameters'
import Button from '../common/Button'
import TextFieldGroup from '../common/TextFieldGroup';
import Checkbox from '../common/Checkbox'
import Upload from '../common/Upload'
import Modal from '../common/Modal'

class Contracting extends Component {
    constructor() {
        super()
        this.state = {
            contractConfirmed: false,
            signatureFile: null,
            loadedFile: null,
            useSignature: false,
            showSucccessPopup: false
        }
    }

    componentDidMount() {
        this.props.setPurchaseSubmitSuccess(false)
    }


    componentDidUpdate(prevProps) {
        if (this.props.purchase.submitSuccess && !prevProps.purchase.submitSuccess) {
            setTimeout(() => {
                // Popup
                this.setState({
                    showSucccessPopup: true
                })

            }, 500)
        }
    }

    getSubscriptionCost = () => {
        const { organisation, contract } = this.props.purchase
        if (!organisation) return {}
        if (!contract) return {}
        if (!contract.subscriptionPencePerPatient) return {}
        const numeric = organisation.population * contract.subscriptionPencePerPatient
        return {
            text: '£' + numberWithCommas((numeric).toFixed(2)),
            numeric: numeric,
            textVAT: '£' + numberWithCommas((numeric * 1.2).toFixed(2)),
        }

    }
    getContractCost = () => {
        const { organisation, product } = this.props.purchase
        if (!organisation) return {}
        if (!product) return {}
        if (!product.subscriptionPrice) return {}
        if (!product.contractPrice) return {}
        const subscriptionNumeric = organisation.population * product.subscriptionPrice
        const contractNumeric = organisation.population * product.contractPrice
        return {
            subscriptionText: '£' + numberWithCommas((subscriptionNumeric).toFixed(2)),
            subscriptionNumeric,
            contractText: '£' + numberWithCommas((contractNumeric).toFixed(2)),
            contractNumeric,
            contractTextVAT: '£' + numberWithCommas((contractNumeric * 1.2).toFixed(2)),
        }

    }

    getUrl = (format, type) => {
        const { organisation, product, contract } = this.props.purchase
        const { user } = this.props.auth
        if (!organisation || !product) return ''
        let urlParams = {
            organisationName: organisation.name,
            organisationCode: organisation.code,
            address1: organisation.address1,
            postcode: organisation.postcode,
            role: organisation.jobTitle,
            population: numberWithCommas(organisation.population),
            contact: `${user.firstName} ${user.lastName}`,
        }
        // contract means that a contract is present, so the are buying subscription...
        if (contract) {
            urlParams.subscriptionPencePerPatient = '£' + contract.subscriptionPencePerPatient.toFixed(2)
            urlParams.subscriptionPrice = this.getSubscriptionCost().text
            urlParams.subscriptionPricePlusVAT = this.getSubscriptionCost().textVAT
            urlParams.subscriptionStartDate = moment(contract.subscriptionWouldStartDate).format('DD-MMM-YYYY')
        } else {
            urlParams.contractPencePerPatient = '£' + product.contractPrice.toFixed(2)
            urlParams.contractPrice = this.getContractCost().contractText
            urlParams.contractPricePlusVAT = this.getContractCost().contractTextVAT
            urlParams.subscriptionPencePerPatient = '£' + product.subscriptionPrice.toFixed(2)
            urlParams.subscriptionPrice = this.getContractCost().subscriptionText
            urlParams.contractStartDate = moment().format('DD-MMM-YY')
        }
        const queryString = Object.keys(urlParams).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(urlParams[key])
        }).join('&');
        return `${parameters.contractURI}/api/contracts/${product.name}/${type}/${format}?${queryString}`

    }

    render() {
        const { contractLoading, contract, organisation, organisationLoading, errors, product, submitLoading, submitSuccess } = this.props.purchase

        const contractErrors = errors.contract || {}


        if (contractLoading || organisationLoading || !organisation) {
            return (
                <FormSection
                    title="Contracts"
                    loading={true}
                    locked={true}
                    color="pink"
                />

            )
        }
        if ((!product.contractPrice || !product.subscriptionPrice) || (!!contract && !contract.subscriptionPencePerPatient)) {
            return (
                <FormSection
                    title="Contracts"
                    locked={this.props.locked}
                    error=' Sorry, we are experiencing difficulties loading the pricing information for this product, please try again later.'
                    color="pink"
                />
            )
        }

        if (contract) {
            if ((contract.inSubscription || contract.inContract) && contract.isSubscription && !contract.optOutDate) {
                return (
                    <FormSection
                        title="Contracts"
                        locked={this.props.locked}
                        error='You already have an active subscription for this product'
                        color="pink"
                    />

                )
            }
        }


        return (
            <FormSection
                title="Contracts"
                locked={this.props.locked}
                error={contractErrors.contract}
                color="pink"
            >
                <div className="row justify-content-center">

                    {contract ? (
                        <>
                            <div className="col-12">
                                <p className="lead">
                                    Opting in to a subscription service for {product.title}
                                </p>
                                <p>
                                    Based on your information recorded above, here is a breakdown of the terms of your subscription:
                             </p>
                            </div>
                            <div className="col-sm-6 col-lg-3">
                                <TextFieldGroup name="populationFinal" label="Population Size" value={numberWithCommas(organisation.population)} readOnly />
                            </div>
                            <div className="col-sm-6 col-lg-3">

                                <TextFieldGroup name="PencePerPatient" label="Price per Patient" value={'£' + contract.subscriptionPencePerPatient.toFixed(2)} readOnly />
                            </div>
                            <div className="col-sm-6 col-lg-3">
                                <TextFieldGroup name="priceFinal" label="Annual Cost excl. VAT" value={this.getSubscriptionCost().text + '/year'} readOnly />
                            </div>
                            <div className="col-sm-6 col-lg-3">
                                <TextFieldGroup name="startDate" label="Start Date" value={moment(contract.subscriptionWouldStartDate).format('DD-MMM-YY')} readOnly />
                            </div>
                        </>
                    ) : (
                            <>
                                <div className="col-12">
                                    <p className="lead">
                                        Placing an online order for {product.title}
                                    </p>
                                    <p>
                                        Based on your information recorded above, here is a breakdown of the terms of your contract:
                            </p>
                                </div>

                                <div className="col-sm-6 col-lg-5">
                                    <TextFieldGroup name="populationFinal" label="Population Size" value={numberWithCommas(organisation.population)} readOnly />
                                </div>
                                <div className="col-sm-6 col-lg-5">
                                    <TextFieldGroup name="contractStartDate" label="Contract Start Date" value={moment().format('DD-MMM-YY')} readOnly />
                                </div>
                                <div className="col-sm-6 col-lg-5">

                                    <TextFieldGroup name="ContractPencePerPatient" label="Year 1 price per patient" value={'£' + product.contractPrice.toFixed(2)} readOnly />
                                </div>
                                <div className="col-sm-6 col-lg-5">
                                    <TextFieldGroup name="contractCost" label="Contract Cost excl. VAT" value={this.getContractCost().contractText + '/year'} readOnly />
                                </div>
                                <div className="col-sm-6 col-lg-5">

                                    <TextFieldGroup name="SubscriptionPencePerPatient" label="Subsequent annual price per patient" value={'£' + product.subscriptionPrice.toFixed(2)} readOnly />
                                </div>

                                <div className="col-sm-6 col-lg-5">
                                    <TextFieldGroup name="subscriptionCost" label="Subsequent annual cost excl. VAT" value={this.getContractCost().subscriptionText + '/year'} readOnly />
                                </div>


                            </>
                        )}











                    <div className="col-12">
                        <p>
                            Look through the contract by clicking the preview button below, it will open in a new window. You can optionally print this using the print button that will show in the top right, or by pressing control+p on your keyboard.
                    </p>
                        <p>
                            Once you sign this contract, you will also be emailed a copy.
                    </p>
                        <Button
                            center
                            onClick={() => {
                                window.open(this.getUrl('html', contract ? 'Subscription' : 'New Contract'), 'Contract', "height=900,width=820")
                            }}
                            className="mb-3"
                        >
                            Preview Contract
                    </Button>
                        <div style={{ textAlign: 'center' }}>
                            <p style={{ textAlign: 'left' }}>
                                How would you like to sign this contract? You can choose to either have your name printed or upload an image of your signature.
                               </p>

                            <Button small inline disabled={!this.state.useSignature} onClick={() => this.setState({ useSignature: false })}>Print Name</Button>
                            or
<Button small inline disabled={this.state.useSignature} onClick={() => this.setState({ useSignature: true })}>Upload Signature</Button>
                        </div>

                    </div>
                    {this.state.useSignature ? (
                        <>
                            <div className="col-md-8">
                                <Upload
                                    isImage
                                    placeHolder="Click or drag an image of your signature here"
                                    onChange={e => this.setState({ signatureFile: e })}
                                    onImageLoad={e => this.setState({ loadedFile: e })}
                                    loadedImage={this.state.loadedFile}
                                    value={this.state.signatureFile}
                                    displayImage
                                    hideAfterSelection
                                />
                            </div>
                        </>
                    ) : (
                            <div
                                className="col-12 my-4"
                            >
                                Your name will be printed as <strong>{this.props.auth.user.firstName} {this.props.auth.user.lastName}</strong>
                            </div>
                        )}



                    <div className="col-12">

                        <p>
                            Once you are happy to complete your order, tick the box below and press 'Submit'. Someone will be in touch in the next 48 hours to discuss activating your accounts.
                    </p>
                    </div>
                    <div className="col-12 text-center">

                        <Checkbox
                            icon
                            name="contractConfirmed"
                            value={this.state.contractConfirmed}
                            onChange={e => this.setState({ contractConfirmed: e.target.checked })}
                        >
                            I agree to the terms outlined in this contract
                            </Checkbox>
                        <Button
                            center
                            large
                            loading={submitLoading}
                            done={submitSuccess}
                            fixedWidth
                            disabled={!this.state.contractConfirmed || (this.state.useSignature && !this.state.signatureFile)}
                            onClick={() => {
                                if (contract) {
                                    this.props.subscribeAction(organisation.code, product.name, this.state.useSignature, this.state.loadedFile)
                                } else {
                                    this.props.contractAction(organisation.code, product.name, this.state.useSignature, this.state.loadedFile)
                                }

                            }}
                        >
                            Submit
                            </Button>
                        <small className="text-danger">
                            {contractErrors.message}
                        </small>
                    </div>


                </div>
                < Modal
                    isOpen={this.state.showSucccessPopup}
                    title={!!contract ? "Subscription Successful" : "Purchase Successful"}
                // isOpen={true}
                >
                    <div className="row">
                        {!!contract ? (
                            <div className="col-12">
                                <h4><strong>You are successfully opted into {product.title}!</strong></h4>
                                <p>
                                    It’s a pleasure to have you on board!
                                       </p>
                            </div>
                        ) : (
                                <div className="col-12">
                                    <h4><strong>Thank you for buying {product.title}!</strong></h4>
                                    <p className="lead">What happens next?</p>
                                    <p>
                                        A member of the team will be in touch in the next 24 hours to set you up. If you have any questions before then or any time in the future, just get in touch with our helpdesk team by emailing <a href={`mailto:${parameters.helpdeskEmail}`}>{parameters.helpdeskEmail}</a> or phoning {parameters.contactUsPhone} Mon – Fri 9am – 5pm.
                                        </p>
                                    <p>
                                        Now that your contract is signed, we will email you an invoice that’s payable within 28 days.
                                       </p>
                                    <p>
                                        It’s a pleasure to have you on board and we are looking forward to working with you!
                                       </p>

                                    <h4 className="mt-3"><strong>Get 20% off your next purchase</strong></h4>
                                    <p >
                                        You are now eligible for a 20% discount if you purchase one of our other products within the next 2 months. You should have received an email from us confirming the discount and letting you know how to make use of it. If you have any questions, or would like to explore our other offers
                                    </p>


                                </div>
                            )}

                    </div>
                    <div className="row justify-content-between">
                        <div className="col-sm-8 mb-3">
                            <Button
                                inline
                                colour='yellow'
                                link={'/contact-us'}
                            >
                                Ask us about other products
                    </Button>
                        </div>
                        <div className="col-sm-4 mb-3 text-right">
                            {/* <Button
                                inline
                                link={`/product/${product.name}`}
                            >
                                Go to {product.title}
                            </Button> */}
                            <Button
                                inline
                                link={`/`}
                            >
                                Home
                            </Button>
                        </div>

                    </div>


                </Modal >
            </FormSection>
        )


    }
}
const mapStateToProps = state => ({
    purchase: state.purchase,
    auth: state.auth
})
export default connect(mapStateToProps, { updateCrmOrganisation, subscribeAction, contractAction, setPurchaseSubmitSuccess })(Contracting);