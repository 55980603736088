import React from "react"
import DivFade from "../../DivFade"
import PCIlogo from "../../../../img/PCI_Accredited_Mark_RGB.png"
import CPDLogo from "../../../../img/CPDmember.png"
import UKHCALogo from "../../../../img/ukhca.png"
import CrossfieldsLogo from "../../../../img/crossfields-approved-centre-logo-light-BG.png"

export default function OurAccreditations() {
  const items = [
    {
      maxWidth: "120px",
      link: "https://www.personalisedcareinstitute.org.uk/",
      imageAlt: "PCI logo",
      imageSrc: PCIlogo,
    },
    {
      maxWidth: "65px",
      link: "http://www.rcgp.org.uk/courses-and-events/rcgp-educational-accreditation-for-education-providers.aspx",
      imageAlt: "RCGP Accredited",
      imageSrc:
        "https://practiceunbound.blob.core.windows.net/stock-images/Accreditation-Mark_ 2012JPEG.jpg",
    },
    {
      maxWidth: "100px",
      link: "https://www.ukihca.com/",
      imageAlt: "UKHCA",
      imageSrc:
        UKHCALogo
    },
    {
      maxWidth: "150px",
      link: "https://bslm.org.uk/",
      imageAlt: "BSLM logo",
      imageSrc:
        "https://practiceunbound.blob.core.windows.net/stock-images/britishsocietylifestylemedicinelogo.jpg",
    },
    {
      maxWidth: "150px",
      link: "https://www.imperial.ac.uk/school-public-health/study/undergraduate/lmap/",
      imageAlt: "Imperial Logo",
      imageSrc:
        "https://practiceunbound.blob.core.windows.net/stock-images/Imperial_Logo.png",
    },
    {
      maxWidth: "120px",
      link: "https://cpduk.co.uk/",
      imageAlt: "CPDLogo",
      imageSrc: CPDLogo,
    },
    {
      maxWidth: "100px",
      link: "https://crossfieldsinstitute.com/",
      imageAlt: "CrossfieldsLogo",
      imageSrc: CrossfieldsLogo,
    },
  ]

  return (
    <DivFade style={{ background: "white" }}>
      <div className='container pt-5 pb-5'>
        <h2 className={`mt-3 mt-lg-0 mb-0 mb-lg-4 text-center`}>
          <strong>Accreditations and Quality Marks</strong>
        </h2>
        <div className='tw-py-5 lg:tw-hidden' />
        <div
          className={`tw-grid tw-grid-cols-4 lg:tw-grid-cols-7 lg:tw-grid-rows-1 tw-gap-2 tw-max-w-xl lg:tw-max-w-none tw-mx-auto`}>
          {items.map((item, index) => (
            <div
              key={`accredition: ${index}`}
              className={`tw-p-2 tw-flex tw-flex-row tw-justify-center tw-items-center`}>
              <div style={{ maxWidth: item.maxWidth }} className=''>
                <a
                  rel='noopener noreferrer'
                  href={item.link}
                  target='_blank'
                  className=''>
                  <img
                    className='img-fluid'
                    alt={item.imageAlt}
                    src={item.imageSrc}
                  />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </DivFade>
  )
}
