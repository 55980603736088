import React from 'react'
import Button from '../common/Button'
import Circles from '../../img/circles.svg'
import DivFade from './DivFade'

export default function HomePageDiscount() {

    return (
        <DivFade
            style={{
                color: "white",
                position: 'relative',
                overflow: 'hidden'
            }}
            className="bg-dark-mint"
        >
            <img className='d-none d-md-block' alt="" src={Circles} style={{ bottom: '-430px', left: '-160px', position: 'absolute', filter: 'brightness(0) invert(68%) sepia(33%) saturate(5527%) hue-rotate(121deg) brightness(96%) contrast(83%)' }} />
            <img alt="" src={Circles} style={{ top: '-430px', right: '-160px', position: 'absolute', filter: 'brightness(0) invert(68%) sepia(33%) saturate(5527%) hue-rotate(121deg) brightness(96%) contrast(83%)' }} />
            <div className="text-center container" style={{ position: 'relative', paddingTop: '32px', paddingBottom: '32px' }}>
                <h3 className='mb-0 text-uppercase'><strong>15% off Workflow Go until end of September</strong></h3>
                <p className="mb-0">Email <a className="text-white" href="mailto:info@practiceunbound.org.uk">info@practiceunbound.org.uk</a> to find out more</p>
                <p className="mb-0"><small>(new customers only, 25p +VAT per patient instead of 30p +VAT per patient)</small></p>
                {/* <Button link='/product/prescribinglifestylemedicine/enrol-plm' colour="none-outline-blue" center inline>enrol now</Button> */}
            </div>

        </DivFade>
    )
}
