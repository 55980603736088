import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Quote = (props) => {

    let iconColour
    switch (props.iconColour) {
        case 'leafGreen':
            iconColour = '#60AE4C'
            break;    
        default:
            iconColour = 'rgb(25, 57, 88)'
            break;
    }

    return <blockquote style={{ marginTop: '12px' }} className="blockquote mb-4">
        <p style={{ fontSize: '18px', fontWeight: 'bold' }}>
            <FontAwesomeIcon style={{ color: iconColour }} className="mr-3 mb-2" size="3x" icon="quote-left" role="presentation" />
            {props.quote}
        </p>
        <div style={{ fontWeight: 'bold' }} className="blockquote-footer text-right">{props.author}</div>
    </blockquote>
}

export default Quote