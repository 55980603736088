import React, { Component, useState } from 'react'
import CertificateGenerator from '../common/CertificateGenerator'

const Bar = props => {
    return (
        <div
            className={props.type === "inProgress" ? "bg-light-yellow" : "bg-light-mint"}
            style={{
                position: 'absolute',
                borderRadius: '12px',
                paddingLeft: props.type === "inProgress" ? props.complete + "%" : 0,
                top: 0,
                left: 0,
                height: '100%',
                width: (props.width) + '%',
                transition: 'width 0.6s, padding 0.6s',
                textAlign: 'center',
                overflow: 'hidden',
                zIndex: 1
            }}
        >
            <small style={{
                fontWeight: 'bold',
                // color: 'white',
                opacity: props[props.type] === 0 ? 0 : 1,
                transition: 'opacity 0.3s linear 0.3s'
            }} >{props[props.type] > 4 && parseInt(props[props.type]) + '%'}</small>

        </div>
    )
}

const Key = props => {

    let colour = ""    
    switch(props.type) {
        case "inProgress" :
            colour = "bg-light-yellow"
            break
        case "complete" :
            colour = "bg-light-mint"
            break
        default :
            colour = "bg-light-grey"
    }

    return <div className="clearfix mb-2 px-3">
        <div
            className={`${colour}`}
            style={{ float: 'left', width: '22px', height: '22px', borderRadius: '22px', position: 'relative' }}
        >
            {/* {!props.type && (
                <div
                    className="bg-light-mint"
                    style={{ width: '11px', height: '22px', borderTopLeftRadius: '22px', borderBottomLeftRadius: '22px', position: 'absolute', top: 0, left: 0 }}
                ></div>
            )} */}


        </div>
        <div style={{ float: 'left' }} className="ml-2">
            <small><strong>{props.children}</strong></small>
        </div>

    </div>
}


const ProgressBar = (props) => {

  const [complete] = useState(parseFloat(props.complete) / parseFloat(props.total) * 100)
  const [inProgress] = useState(parseFloat(props.inProgress) / parseFloat(props.total) * 100)

  return (
    <div className="mb-3">
        <div className="row px-3">
            {!props.removeBar &&
            <div
                style={{
                    backgroundColor: '#eaeaea',
                    position: 'relative',
                    width: '100%',
                    height: '24px',
                    borderRadius: '12px',
                    boxShadow: '0 8px 12px -5px rgba(0,0,0,.4)',
                    overflow: 'hidden',
                    zIndex: 0,
                }}
                className={`mb-3 col-12 ${props.reverseOrder && 'order-last'}`}
            >
                <Bar
                    type="inProgress"
                    width={inProgress + complete}
                    complete={complete}
                    inProgress={inProgress}
                    />
                <Bar
                    type="complete"
                    width={complete}
                    complete={complete}
                    inProgress={inProgress}
                />
            </div>
            }
            <div className="col-12 p-0">
                <div className="row mb-3">
                    <Key type="">{props.keyLabels ? props.keyLabels.grey : "Not Started"}</Key>
                    <Key type="inProgress">{props.keyLabels ? props.keyLabels.yellow : "In Progress"}</Key>
                    <Key type="complete">{props.keyLabels ? props.keyLabels.green : "Complete"}</Key>
                    {props.showCertificate && <CertificateGenerator
                        inline
                        title="Download certificate"
                        productName={props.productName}
                        complete={props.complete === props.total}
                        center
                    />}
                </div>
            </div>
        </div>
        {props.showCertificate && <p>Once all modules are complete the rosette icon above will turn pink and your certificate will be available to download</p>}
    </div>
  )
}

export default ProgressBar


// export default class ProgressBar extends Component {
//     constructor() {
//         super()
//         this.state = {
//             complete: 0.0,
//             inProgress: 0.0
//         }
//     }
//     componentDidMount() {
//         this.setState({
//             complete: parseFloat(this.props.complete) / parseFloat(this.props.total) * 100,
//             inProgress: parseFloat(this.props.inProgress) / parseFloat(this.props.total) * 100,
//         })
//     }
//     render() {
//         return (
//             <div className="mb-3">
//                 <div className="row px-3">
//                     <div
//                         style={{
//                             backgroundColor: '#eaeaea',
//                             position: 'relative',
//                             width: '100%',
//                             height: '24px',
//                             borderRadius: '12px',
//                             boxShadow: '0 8px 12px -5px rgba(0,0,0,.4)',
//                             overflow: 'hidden',
//                             zIndex: 0,
//                         }}
//                         className={`mb-3 col-12 ${this.props.reverseOrder && 'order-last'}`}
//                     >
//                         <Bar
//                             type="inProgress"
//                             width={this.state.inProgress + this.state.complete}
//                             {...this.state}
//                         />
//                         <Bar
//                             type="complete"
//                             width={this.state.complete}
//                             {...this.state}
//                         />
//                     </div>
//                     <div className="col-12 p-0">
//                         <div className="row mb-3">
//                             <Key type="">Not Started</Key>
//                             <Key type="inProgress">In Progress</Key>
//                             <Key type="complete">Complete</Key>
//                             {this.props.showCertificate && <CertificateGenerator
//                                 inline
//                                 title="Download certificate"
//                                 productName={this.props.productName}
//                                 complete={this.props.complete === this.props.total}
//                                 center
//                             />}
//                         </div>
//                     </div>
//                 </div>
//                 {this.props.showCertificate && <p>Once all modules are complete the rosette icon above will turn pink and your certificate will be available to download</p>}
//             </div>
//         )
//     }
// }


