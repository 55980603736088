import React from 'react'
import styles from './PathologyGo.module.css'
import PUPanel from '../../../layout/PUPanel'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import PrevAssessmentQuestionGrouped from './PrevAssessmentsQuestionGrouped'
import PrevAssessmentsBarGraph from './PrevAssessmentsBarGraph'
import PrevAssessmentsLineGraph from './PrevAssessmentsLineGraph'

const PrevAssessments = ({ assessments, showPrevAssessments }) => {
    const questionNumbers = assessments.map(assess => assess.questionnumber)
    const distinctQuestionNumbers = [...new Set(questionNumbers)]

    if (showPrevAssessments) {
        return (
            <TransitionGroup appear={true}>
                <CSSTransition
                    key="carousel"
                    classNames="slide-vertical"
                    // key="fade"
                    // classNames={show ? "fadeRight" : "fadeLeft"} 
                    timeout={{ enter: 1000, exit: 1000 }}
                >
                    <div className="row">
                        <PUPanel title="Previous Assessments"
                        // bodyPadding={false}
                        >
                            <div className={styles.formStyle}>
                                {distinctQuestionNumbers.map(questionNumber => {
                                    return (
                                        questionNumber === 1 ?
                                            <PrevAssessmentsBarGraph
                                                assessments={assessments}
                                                showPrevAssessments={showPrevAssessments}
                                            /> :

                                            questionNumber === 2 ?
                                                <PrevAssessmentsLineGraph
                                                    assessments={assessments}
                                                    showPrevAssessments={showPrevAssessments}
                                                /> :

                                                <PrevAssessmentQuestionGrouped
                                                    answerIcon={false || questionNumber === 6}
                                                    assessments={assessments}
                                                    questionNumber={questionNumber}
                                                />
                                    )
                                })}
                            </div>
                        </PUPanel>
                    </div>
                </CSSTransition>
            </TransitionGroup>
        )
    } else return null
}

export default PrevAssessments