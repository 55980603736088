import React, { Component } from "react";
import { connect } from "react-redux";
import Spinner from "../common/Spinner";
import ContentBlockPage from "../layout/ContentBlockPage";
import { Helmet } from "react-helmet";

class HomeScreen extends Component {
  componentDidMount() {
    this.checkPurchasedAndRedirect()
  }
  componentDidUpdate() {
    this.checkPurchasedAndRedirect()
  }
  checkPurchasedAndRedirect = () => {
    const { product } = this.props.products
    const { auth } = this.props
    let launchUrl
    if (product.productLink.length >= 4) {
      if (product.productLink.substring(0, 4) !== "/pro") {
        launchUrl = product.productLink
      }
    }

    if (auth.isAuthenticated && product.purchased === true) {

      if (launchUrl) {
        this.props.history.push(launchUrl)
      } else if (product.showGetStartedTab === true) {
        this.props.history.push('/product/' + product.name + '/get-started')
      } else if (product.showElearningTab) {
        this.props.history.push('/product/' + product.name + '/course')
      } else if (product.showResourcesTab === true) {
        this.props.history.push('/product/' + product.name + '/resources')
      } else if (product.showDataTab === true) {
        this.props.history.push('/product/' + product.name + '/data')

      }
    }

  }



  render() {
    console.log("🚀 ~ file: HomeScreen.js:50 ~ HomeScreen ~ render ~ this.props.products:", this.props.products)

    const { productContentLoading } = this.props.pages;
    return (
      <div>
        {this.props.products.product && (
          <Helmet>
            <meta
              name="twitter:image"
              content={this.props.products.product.image} />
            <meta name="twitter:card" content="summary_large_image" />
          </Helmet>
        )}
        {productContentLoading ? (
          <Spinner block large color="yellow" />
        ) : (
          <ContentBlockPage content={this.props.pages.productContent} />
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  products: state.products,
  pages: state.pages,
  auth: state.auth
});
export default connect(
  mapStateToProps,
  {}
)(HomeScreen);
