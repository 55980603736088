const Circles = (props) => {
    return (
      <svg width={props.width ? props.width : "225"} viewBox="0 0 225 225" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="70.6345" cy="154.795" r="32.4189" fill={`${props.colour ? props.colour : 'E04F9A'}`}/>
        <circle cx="15.458" cy="125.382" r="15.458" fill={`${props.colour ? props.colour : 'E04F9A'}`}/>
        <circle r="7.51431" transform="matrix(-1 0 0 1 123.449 109.709)" fill={`${props.colour ? props.colour : 'E04F9A'}`}/>
        <circle cx="76.646" cy="218.774" r="6.22615" fill={`${props.colour ? props.colour : 'E04F9A'}`}/>
        <circle cx="116.579" cy="205.463" r="11.8082" fill={`${props.colour ? props.colour : 'E04F9A'}`}/>
        <circle cx="174.117" cy="155.224" r="50.8826" fill={`${props.colour ? props.colour : 'E04F9A'}`}/>
        <circle cx="72.7815" cy="55.6059" r="55.6059" fill={`${props.colour ? props.colour : 'E04F9A'}`}/>
      </svg>
    )
  }
  export default Circles