import Button from "../../common/Button"
import BannerFlex from "../BannerFlex"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import puColours from "../../../utils/puColours"
import ImageTextBlock from "../PLM/Comps/ImageTextBlock"
import DivFade from '../../../components/layout/DivFade'
import SocialsIcon from "../../common/SocialsIcon"
import topBannerShapes from "./pricingBubbles"

const femaledoctorstethoscope = "https://practiceunbound.blob.core.windows.net/stock-images/front-view-covid-recovery-center-female-doctor-with-stethoscope.jpg"

const darkBlue = puColours.darkBlue

const PathGoWhatsIncluded = () => {
  return <>
    <BannerFlex
        backgroundCirclesObj={{
            width: '600px',
            left: '-310px',
            className: 'tw-animate-pulse-slow'
        }}
        shapes={topBannerShapes}
        // classNames={{
            //     shapeSection: ''
        // }}
        classNames={{shapesSection: 'tw-pb-36 md:tw-pb-28'}} 
    >
        <div className="tw-flex tw-flex-col tw-gap-3 tw-py-16">
            <section className="tw-pl-2">
                <h4 className="tw-font-bold tw-mb-5">What’s included?</h4>
                <p>Pathology Go is a blended programme of online learning, resources and data dashboards to help your Practice safely delegate low-risk test processing to administrators.</p>
            </section>
            <div className="tw-flex tw-flex-col sm:tw-flex-row tw-gap-4">
                <Button center large colour="yellow-outline">enquire now</Button>
            </div>
        </div>
    </BannerFlex>

    <ImageTextBlock
        imageUrl={femaledoctorstethoscope}
        contentSide="right"
        contentBackgroundColor={puColours.lightGrey}
        // contentBackgroundImage={greyCircles}
        contentBackgroundSize='40%'
        contentBackgroundPosition='top left'
        contentBackgroundRight='5%'
        contentBackgroundTop='-15%'
    >
        <div className="tw-py-10"/>
        <div className="tw-pl-6">
          <h4 className="tw-font-bold" style={{textWrap:'balance'}}>Benefits Include</h4>
          <div className="py-1"/>
          <p>Pathology Go is priced on a per-patient model, and contains all the resources, training and help you need to get started with improved pathology test processing. Benefits includes:</p>
          <ul className="tick-list">
            <li><p><strong>eLearning modules</strong> - to train you admin staff, Practice Managers and GPs in the safe delegation of Pathology.</p></li>
            <li><p><strong>Decisionaids</strong> - evidence based flow charts for administrators to follow that detail processing pathway for B12 Folate, Full Blood Count, Blood Glucose Level, Liver Function, Lipids, Prostate-specific antigen, Eenal, Thyroid function, VitD.</p></li>
            <li><p><strong>Audit tools and reports</strong> - to support the auditing of delegation activities within your clinical system and enable GPs to check that test results are being processed safely.</p></li>
            <li><p><strong>Data dashboard</strong> - showing activity and impact of the work.</p></li>
            <li><p><strong>Supporting implementation</strong> - a range of detailed step by step guides and support materials to ensure clear understanding of Pathology Go and successful implementation of delegation into a Practice.</p></li>
            <li><p><strong>Helpdesk</strong> - with team of transformation coaches to support you with any technical and implementation challenges you face, Monday - Friday 9am - 5pm.</p></li>

          </ul>
        </div>
        <div className="tw-py-10"/>
    </ImageTextBlock>

    <DivFade className="tw-bg-[#169F7B] text-white">
        <div className="container" style={{ paddingTop: '72px', paddingBottom: '72px' }}>
            <div className="row justify-content-between">
                <div className="col-sm-8 mb-3">
                    <h3 className="mb-4" style={{ fontWeight: 'bold' }}>Got questions? Talk to us…</h3>
                    <p>Whether you are interested in one of our products or would like to know more about working with Practice Unbound, please give us a call on 0300 303 8145 or email <a className="tw-text-white tw-underline" href="mailto:info@practiceunbound.org.uk">info@practiceunbound.org.uk</a></p>
                </div>
                <div className="col-sm-2 d-flex justify-content-center align-items-center">
                    <span style={{ fontSize: '32px', marginRight: '16px' }}><SocialsIcon className={`tw-text-white hover:tw-text-[#06523D] tw-mx-2`} link={"https://twitter.com/PracticeUnbound"} social="twitter" /></span>
                    <span style={{ fontSize: '32px' }}><SocialsIcon className={`tw-text-white hover:tw-text-[#06523D] tw-mx-2`} link={"https://www.linkedin.com/company/practice-unbound"} social="linkedin" /></span>
                </div>
            </div>
        </div>
    </DivFade >
  </>
}

export default PathGoWhatsIncluded