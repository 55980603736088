import React, { useState } from 'react'
import Button from '../../../common/Button'
import PrevAssessments from './PrevAssessments'
import PathGoAssessmentForm from './PathGoAssessmentForm'

export default function PathologyGo({ assessments, submitAssessment }) {

    const hasDonePrevAssessment = assessments.length ? true : false
    const [showPrevAssessments] = useState(hasDonePrevAssessment)
    const [showForm, setShowForm] = useState(false)

    // useEffect(() => {
    //     setShowForm(!hasDonePrevAssessment)
    // }, [])

    return (

        <div className="container py-5">

            {/*----------- PREVIOUS ASSESSMENT VISUALS HERE -----------*/}
            {/* ====================================================== */}

            {/* {assessments ? (
                <div className={styles.container}>
                    <Button onClick={() => setShowPrevAssessments(!showPrevAssessments)}>
                        {showPrevAssessments ? 'Hide History' : 'Show History'}
                    </Button>
                </div> 
            ) : null } */}

            {showForm ?
                <PathGoAssessmentForm
                    assessments={assessments}
                    submitAssessment={submitAssessment}
                /> :
                <div className='mb-5 pl-4'>
                    <Button onClick={() => setShowForm(true)}>
                        do it again!
                    </Button>
                </div>
            }

            {!showForm && <>

                {/* <PrevAssessmentsBarGraph 
                        assessments={assessments}
                        show={showPrevAssessments}
                    /> */}
                <PrevAssessments
                    assessments={assessments}
                    showPrevAssessments={showPrevAssessments}
                />
            </>
            }


            {/*-------------------- FORM HERE --------------------*/}
            {/* ================================================= */}



        </div>
    )
}
