import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import Button from '../../common/Button'
import TextFieldGroup from '../../common/TextFieldGroup'
import { sendPathGoContact, clearContactUs } from '../../../actions/pagesActions'
import Card from '../../layout/Card'
import PracticeUnboundYellow from "../../../img/PracticeUnboundYellow.png";
import Checkbox from '../../common/Checkbox'
import Background from '../../../img/circles_bg.svg'
import ReactGA from 'react-ga4';

class PathForm extends Component {
    constructor() {
        super()
        this.state = {
            name: '',
            email: '',
            contact: '',
            organisation: '',
            country: {},
            message: '',
            checkBuy: false,
            checkCall: false,
            checkQuestions: false,
            checkOther: false,
            checkDemo: false,
            checkMarketingOptOut: false,
        }
        this.onChange = this.onChange.bind(this)
        this.sendContact = this.sendContact.bind(this)
    }

    componentDidMount() {
        this.props.clearContactUs()
    }
    componentDidUpdate(prevProps) {
        if (this.props.pages.contactUsSuccess && !prevProps.pages.contactUsSuccess) {
            if (process.env.REACT_APP_GOOGLE_ANALYTICS === "true") {
                ReactGA.send({ hitType: "pageview", page: '/pathology-go-form-complete' });
                console.log('Form submitted')
            }
            setTimeout(() => {
                this.props.history.push('/')
            }, 1000)
        }
    }

    onChange(e) {
        if (e.target.type === "checkbox") {
            this.setState({ [e.target.name]: e.target.checked })
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }

    sendContact(e) {
        e.preventDefault()
        const data = {

            email: this.props.auth.isAuthenticated ? this.props.auth.user.email : this.state.email,
            name: this.props.auth.isAuthenticated && this.props.auth.user.firstName && this.props.auth.user.lastName ? `${this.props.auth.user.firstName} ${this.props.auth.user.lastName}` : this.state.name,
            contact: this.state.contact,
            organisation: this.state.organisation,
            country: this.state.country.value,
            message: this.state.message,
            checkBuy: this.state.checkBuy,
            checkCall: this.state.checkCall,
            checkQuestions: this.state.checkQuestions,
            checkOther: this.state.checkOther,
            checkDemo: this.state.checkDemo,
            checkMarketingOptOut: this.state.checkMarketingOptOut,
        }
        this.props.sendPathGoContact(data)

    }

    render() {
        const { firstName, lastName, email } = this.props.auth.user
        const { isAuthenticated } = this.props.auth
        const { errors, contactUsLoading, contactUsSuccess } = this.props.pages
        return (
            <div style={{
                backgroundImage: 'url("' + Background + '")',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                overflow: 'hidden',
                backgroundPosition: 'center',

            }}>
                <nav
                    className="navbar navbar-light bg-white justify-content-center"
                    id="navbar">

                    <Link to="/">
                        <img
                            alt="PracticeUnbound"
                            className="ml-lg-3"
                            style={{
                                maxWidth: "110px",
                                height: "38px",
                            }}
                            src={PracticeUnboundYellow}
                        />
                    </Link>

                </nav>
                <div className="container pb-5" >
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-10">
                            <Card className="mt-5">
                                <Button
                                    colour="none"
                                    underline
                                    small
                                    icon="back" onClick={this.props.history.goBack}>Back</Button>
                                <h2 className="text-center px-md-4 mt-3 mb-4"><strong>Pathology Go enquiry form</strong></h2>
                                <p className="text-center px-md-4 mb-5">
                                    Interested to know more about Pathology Go? Join over 3000 UK customers who use our products to bring safe efficiency and insight into the running of their practices.
                                </p>
                                <form onSubmit={this.sendContact}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            {isAuthenticated && firstName && lastName ? (
                                                <TextFieldGroup
                                                    label="Your name"
                                                    name='name'
                                                    value={`${firstName} ${lastName}`}
                                                    readOnly
                                                />
                                            ) : (
                                                <TextFieldGroup
                                                    label="Your name"
                                                    name='name'
                                                    value={this.state.name}
                                                    onChange={this.onChange}
                                                    error={errors.name}
                                                />
                                            )}

                                        </div>
                                        <div className="col-md-6">
                                            {isAuthenticated ? (
                                                <TextFieldGroup
                                                    label="Your email"

                                                    name='email'
                                                    value={email}
                                                    readOnly
                                                />
                                            ) : (
                                                <TextFieldGroup
                                                    label="Your email"

                                                    name='email'
                                                    value={this.state.email}
                                                    onChange={this.onChange}
                                                    error={errors.email}
                                                />
                                            )}

                                        </div>
                                        <div className="col-md-6">
                                            <TextFieldGroup
                                                label="Your phone number"
                                                name='contact'
                                                value={this.state.contact}
                                                onChange={this.onChange}
                                                error={errors.contact}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <TextFieldGroup
                                                label="Your practice/organisation"
                                                name='organisation'
                                                value={this.state.organisation}
                                                onChange={this.onChange}
                                                error={errors.organisation}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <p><strong>Please check what applies to you:</strong></p>
                                            <Checkbox
                                                icon

                                                name="checkBuy"
                                                value={this.state.checkBuy}
                                                onChange={this.onChange}
                                            >
                                                I'd like to buy Pathology Go for my practice
                                            </Checkbox>
                                            <Checkbox
                                                icon

                                                name="checkDemo"
                                                value={this.state.checkDemo}
                                                onChange={this.onChange}
                                            >
                                                I'd like to request a demo
                                            </Checkbox>
                                            <Checkbox
                                                icon

                                                name="checkCall"
                                                value={this.state.checkCall}
                                                onChange={this.onChange}
                                            >
                                                I'd like a call back, to find out more about Pathology Go
                                            </Checkbox>

                                            <Checkbox
                                                icon

                                                name="checkQuestions"
                                                value={this.state.checkQuestions}
                                                onChange={this.onChange}
                                            >
                                                I have specific question(s) I'd like answered, which I've asked in the box below
                                            </Checkbox>

                                        </div>

                                        <div className="col-md-12">
                                            <TextFieldGroup
                                                label="Tell us a little more about your practice, and anything specific you would like to talk to us about"
                                                type="textarea"
                                                name='message'
                                                value={this.state.message}
                                                onChange={this.onChange}
                                                error={errors.message}
                                            />
                                        </div>




                                        <div className="col-12 mb-4" >
                                            <Button
                                                fixedWidth
                                                type="submit"
                                                center
                                                large
                                                done={contactUsSuccess}
                                                loading={contactUsLoading}
                                            >Submit</Button>
                                            <small className="text-danger">{errors.error}</small>

                                        </div>
                                        {/* 
                                        <div className="col-12">
                                            <Checkbox
                                                icon
                                                className="mb-3"
                                                name="checkMarketingOptOut"
                                                value={this.state.checkMarketingOptOut}
                                                onChange={this.onChange}
                                            >
                                                Please don't send me any marketing communications
                                                </Checkbox>
                                        </div> */}



                                    </div>
                                </form>
                            </Card>
                        </div>
                    </div>
                </div>

            </div>
        )

    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    pages: state.pages
})
export default connect(mapStateToProps, { sendPathGoContact, clearContactUs })(withRouter(PathForm));