import { useEffect, useRef } from "react"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import ImageTextBlock from "./ImageTextBlock"
import OrangeChecklist from "./OrangeChecklist"
import Button from "../../../common/Button"

export const WhoIsPlmFor = () => {

    const scrollRef = useRef(null)
    const location = useLocation();
    const params = new URLSearchParams(location.hash)
    const executeScroll = () => scrollRef.current.scrollIntoView({ behavior: "smooth" })
    
    useEffect(() => {
        if ([...params.entries()].length > 0) {
            if ([...params.entries()][0][0] === '#who-is-plm-for') {
                executeScroll()
            }
        }
        
    }, [])

  return (
    <ImageTextBlock
        contentBackgroundColor='rgb(243,243,243)'
        imageUrl='https://practiceunbound.blob.core.windows.net/stock-images/AboutPage_WhoIsPLMForBlock_shutterstock_1477336916-min.jpg'
        imageBackgroundPosition='left'
        contentSide='right'
    >
        <div ref={scrollRef} style={{position:'absolute', top:'-25px'}}/>
        <div className='px-5' style={{ paddingTop: '92px', paddingBottom: '92px' }}>
            <h3 style={{ fontWeight: 'bold' }}>Who is Prescribing Lifestyle Medicine for?</h3>
            <p>Prescribing Lifestyle medicine is aimed at clinicians and specialist non clinicians including:</p>
            <OrangeChecklist className="mb-4" 
                ticks={[
                    'Doctors',
                    'Nurses',
                    'Pharmacists',
                    'Health Coaches',
                    'Dentists',
                    'ARRS Roles',
                    'Allied Health Professionals',
                    'Other Health & Care Professions',
                    'Medical Students and Medical Schools'
                ]}
            />
            <Button link="/product/prescribinglifestylemedicine/enrol-plm" className="mr-3 mb-3" inline colour={'hollow-orange'} >Enrol Now</Button>
            <Button link="/product/prescribinglifestylemedicine/organisations-plm" className="mr-3 mb-3" inline colour={'hollow-orange'} >For Organisations</Button>
            <Button link="https://www.practiceunbound.org.uk/plm-bursary" className="mr-3 mb-3" inline colour={'hollow-orange'} >student bursaries</Button>
        </div>
    </ImageTextBlock>
  )
}
