import React from 'react'
import Slider from "react-slick";
import Chevron from '../common/Chevron'
import buttonStyles from '../common/Button.module.css'
import CarouselCard from './CarouselCard';
import classNames from 'classnames';
import Quote from '../common/Quote'


function SampleNextArrow(props) {
    const { style, onClick } = props;
    // const disabled = currentSlide === (slideCount - 1)
    const disabled = false
    
    let buttonColour
    switch (props.buttonColour) {
        case 'leafGreen':
            buttonColour = disabled ? buttonStyles.circleChevronLeafGreenDisabled : buttonStyles.circleChevronLeafGreen
            break
        default:
            buttonColour = disabled ? buttonStyles.circleChevronBlueDisabled : buttonStyles.circleChevronBlue
        break
    }
    
    return (
        <div
        onClick={onClick}
        style={{
                ...style,
                display: "block",
                position: "absolute",
                left: 'calc(50% + 15px)',
                bottom: '-70px',
                width: '43px',
                height: '43px',
                cursor: !disabled ? 'pointer' : 'default'
            }}
        >
            <div
                className={buttonColour}
                style={{
                    opacity: disabled ? '.1' : '1',
                    width: '100%',
                    paddingTop: '100%',
                    borderRadius: '50%',
                    position: 'relative',
                }}
            >
                <Chevron
                    alt="Next"
                    aria-label="Next"
                    direction='right'
                    position='absolute'
                    cursor={disabled || false}
                    />
            </div>
        </div>
    )
}

function SamplePrevArrow(props) {
    const { style, onClick } = props;
    // const disabled = currentSlide === 0
    const disabled = false
    
    let buttonColour
    switch (props.buttonColour) {
        case 'leafGreen':
            buttonColour = disabled ? buttonStyles.circleChevronLeafGreenDisabled : buttonStyles.circleChevronLeafGreen
            break
        default:
            buttonColour = disabled ? buttonStyles.circleChevronBlueDisabled : buttonStyles.circleChevronBlue
        break
    }
    
    return (
        <div
            onClick={onClick}
            style={{
                ...style,
                display: "block",
                position: "absolute",
                left: 'calc(50% - 55px)',
                bottom: '-70px',
                width: '43px',
                height: '43px',
                cursor: !disabled ? 'pointer' : 'default'
            }}
        >
            <div
                className={buttonColour}
                style={{
                    opacity: disabled ? '.1' : '1',
                    width: '100%',
                    paddingTop: '100%',
                    borderRadius: '50%',
                    position: 'relative'
                }}
            >
                <Chevron
                    alt="Previous"
                    aria-label="Previous"
                    direction='left'
                    position='absolute'
                    cursor={disabled || false}
                />
            </div>
        </div>
    )
}

const Carousel = (props) => {
    // const dots = props.autoplay ? false : true
    // let dragging = false
    var settings = {
        infinite: props.infinite,
        autoplay: props.autoplay,
        autoplaySpeed: 3500,
        variableWidth: props.fixedWidth,
        dots: false,
        arrows: props.arrows,
        speed: 500,
        slidesToShow: props.slidesToShow,
        slidesToScroll: 2,
        // beforeChange: () => { dragging = true; },
        // afterChange: () => dragging = false,
        nextArrow: <SampleNextArrow buttonColour={props.buttonColour} />,
        prevArrow: <SamplePrevArrow buttonColour={props.buttonColour} />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: false
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false
                }
            },
            {
                breakpoint: 766,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false
                }
            }
        ]
    };
    
    const settingsConfig = {
        ...props.gpBillingReports ? {
            ...settings,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: false
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: false
                    }
                },
                {
                    breakpoint: 766,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: false
                    }
                }
            ]
        } : props.gpBillingTestimonials ? {
            ...settings,
            slidesToShow: 2,
            slidesToScroll: 1                            
        }
        : {...settings}
    }

    const imageHeight = '220px'

    const colours = {
        background: {
            green: '#16C194',
            grey: '#F3F3F3',
            blue: 'rgb(25, 57, 88)'
        },
        hover: {
            green: '#16C194',
            pink: '#E04F9A',
            blue: 'rgb(25, 57, 88)'
        }
    }

    return (
        <>
            {props.header && (
            <div style={{ backgroundColor: 'white', paddingBottom: '20px', paddingTop: '60px' }}>
                <div className="container">
                    <h2 className={classNames(`mb-${props.headerMarginBottom || '3'}`,{ 'text-center': props.centreHeader })} style={{ fontWeight: 'bold', textWrap:'balance' }}>{props.header}</h2>
                </div>
            </div>
            )}
            <div style={{ position: 'relative', backgroundColor: props.topHalfBackgroundColour ? props.topHalfBackgroundColour : 'white', paddingBottom: props.paddingBottom ? props.paddingBottom : '100px', paddingTop: props.paddingTop ? props.paddingTop : '0px' }}>
                <div
                    style={{
                        backgroundColor: colours.background[props.backgroundColour],
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: imageHeight,
                        width: '100%',
                        bottom: 0
                    }}>
                </div>
                <div className="container" >
                    <Slider {...settingsConfig}>
                        {props.items.map((item, index) => {

                            if(props.gpBillingReports) {                
                                return (
                                    <div key={`${index}: ${item}`} className='container'>
                                        <div className='row'>
                                            <div className='d-none d-md-flex col-6 w-100 flex-column justify-content-center align-items-center'>
                                                <img width={'100%'} src={item.image} />
                                            </div>
                                            <div 
                                                className='col-12 col-md-6 w-100 d-flex flex-column justify-content-center pl-5'
                                            >      
                                                <div className='d-flex flex-column justify-content-center'>
                                                    <h4 className='font-weight-bold'>{item.title}</h4>
                                                    <p>{item.paragraph}</p> 
                                                    {item.listItems &&
                                                    <ul className='leaf-green-tick-list'>
                                                        {item.listItems.map((item, index) => (
                                                            <li key={`report dashboard list item ${index}`}><p><span className='font-weight-bold'>{item}</span></p></li>
                                                        ))}
                                                    </ul>
                                                    }
                                                </div>
                                                <div className='h-25'></div>                                                                             
                                            </div>
                                        </div>
                                    </div>
                                )                                
                            }

                            if(props.gpBillingTestimonials) {
                                return (
                                    <div className='pt-5 pr-5 pb-3 pl-5' key={`${index}: ${item}`}>
                                        <Quote                                             
                                            author={item.title} 
                                            quote={item.paragraph} 
                                            iconColour={props.quoteIconColour}
                                        />
                                    </div>
                                )
                            }

                            return (
                                <CarouselCard
                                    key={`${index}: ${item}`}
                                    item={item}
                                    navigateFunction={props.navigateFunction}
                                    cardColor={props.cardColor}
                                    height={props.height}
                                    titleHeight={props.titleHeight}
                                    paragraphHeight={props.paragraphHeight}
                                />
                            )
                        })}

                    </Slider>
                </div >
            </div>
        </>
    )
}


Carousel.defaultProps = {
    infinite: true,
    autoplay: false,
    arrows: true,
    fixedWidth: false,
    backgroundColor: 'grey',
    cardColor: 'pink',
    height: '290px',
    titleHeight: '50px',
    paragraphHeight: '135px',
    slidesToShow: 3
}
export default Carousel