import React, { Component } from "react";
import Slider from "rc-slider";
import 'rc-slider/assets/index.css';

class ReactSlider extends Component {

  handleChange = (val) => {
    if (this.props.change && this.props.name) {
      this.props.change(this.props.name, val);
    }
  };

  render() {
    const { min, max, step, value, color } = this.props;

    return (
      <Slider
        min={+(min ? min : 0)}
        max={+(max ? max : 100)}
        step={+(step ? step : 1)}
        value={+value}
        className={"slider-db-" + color}
        onChange={this.handleChange}
      // tooltip={false}
      />
    );
  }
}

export default ReactSlider;
