
import React from 'react'
import DownloadIcon from '../components/common/DownloadIcon'
import { Link } from 'react-router-dom'
import PLMTable from '../components/layout/PLMTable';
import WorkflowCapacityCalc from '../components/common/WorkflowCapacityCalc';


const processContent = (content, className) => {
    if (!content) { return null }
    return content.map((c, key) => {
        //These are final stops in the chain
        if (c.nodeType === "text") {

            let markup = []
            if (c.marks) { markup = c.marks.map(mark => mark.type) }
            if (markup.includes("code")) { return <span key={key} dangerouslySetInnerHTML={{ __html: c.value }} /> }
            const styles = {
                fontWeight: markup.includes('bold') && 'bold',
                fontStyle: markup.includes('italic') && 'italic'
            }
            return <span key={key} style={styles}>{(c.value === "" && !content[key + 1]) ? <br /> : c.value}</span>
        }
        if (c.nodeType === "hyperlink") {
            return c.content.map(h => {
                const isRelative = c.data.uri.substring(0, 1) === '/'
                if (isRelative) {
                    return <Link key={key} to={c.data.uri}>{h.value}</Link>
                }
                return <a key={key} href={c.data.uri} target="_blank" rel="noopener noreferrer">{h.value}</a>
            })
        }
        if (c.nodeType === "hr") {
            return <hr key={key} />
        }


        //these are blocks that contain nested content
        if (c.nodeType === "list-item") {
            return (
                <li key={key} className={className}>
                    {processContent(c.content, "mb-0")}
                </li>
            )
        }
        if (c.nodeType === "paragraph") {
            return <p className={className} key={key}>{processContent(c.content)}</p>
        }
        if (c.nodeType === "heading-1") {
            return <h4 key={key}>{processContent(c.content)}</h4>
        }
        if (c.nodeType === "heading-2") {
            return <h4 key={key}>{processContent(c.content)}</h4>
        }
        if (c.nodeType === "heading-3") {
            return <h4 key={key}>{processContent(c.content)}</h4>
        }
        if (c.nodeType === "heading-4") {
            return <h4 key={key}>{processContent(c.content)}</h4>
        }
        if (c.nodeType === "heading-5") {
            return <h4 key={key}>{processContent(c.content)}</h4>
        }
        if (c.nodeType === "heading-6") {
            return <h4 key={key}>{processContent(c.content)}</h4>
        }
        if (c.nodeType === "unordered-list") {
            return <ul className={className} key={key}>{processContent(c.content)}</ul>
        }
        if (c.nodeType === "ordered-list") {
            return <ol key={key}>{processContent(c.content)}</ol>
        }
        if (c.nodeType === "embedded-asset-block") {
            const { fields } = c.data.target
            return <img className="img-fluid d-inline px-3" style={{ float: 'left' }} key={key} src={fields.file.url} alt={fields.title} />
        }
        if (c.nodeType === "embedded-entry-block") {
            const { fields, sys } = c.data.target
            if (sys.contentType.sys.id === "downloadLink") {
                if (!fields.downloadUrl) return null
                // const dotIndex = fields.downloadUrl.split("").reverse().join("").indexOf('.')
                // const extension = fields.downloadUrl.substring(fields.downloadUrl.length - dotIndex)
                return (
                    <a key={key} href={fields.downloadUrl} download target={"_blank"} rel="noopener noreferrer" > <h5><strong><DownloadIcon />{" "}{fields.title}</strong></h5></a >
                )
            }
            if (sys.contentType.sys.id === "componentImage") {
                return (
                    <div key={key}>
                        {fields.circleImage ? (
                            <div style={{
                                backgroundImage: `url(${fields.imageUrl})`,
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                borderRadius: '50%',
                                width: '100%',
                                paddingTop: '100%',
                                maxWidth: fields.maxWdith && `${fields.maxWdith}px`
                            }} />
                        ) : (
                            <div className="d-block w-100 text-center">
                                <img
                                    style={{
                                        maxWidth: fields.maxWdith && `${fields.maxWdith}px`
                                    }}
                                    src={fields.imageUrl}
                                    alt={fields.altText}
                                    className="img-fluid"
                                />
                            </div>
                        )
                        }
                    </div>
                )
            }

            if (sys.contentType.sys.id === "videoEmbed") {
                return (
                    <div key={key}
                        className="embed-responsive embed-responsive-16by9"
                        style={{ maxWidth: '640px' }}
                    >
                        <iframe
                            title={fields.title}
                            className="embed-responsive-item"
                            src={fields.videoUrl}
                            allowFullScreen
                        ></iframe>
                    </div>
                )
            }

            if (sys.contentType.sys.id === "imageEmbed") {
                return (
                    <div
                        key={key}
                        style={{
                            maxWidth: fields.maxWidth
                        }}>
                        <img
                            className="img-fluid"
                            src={fields.imageUrl}
                            alt={fields.title}
                        />
                    </div>
                )
            }

            if (sys.contentType.sys.id === "audioEmbed") {
                return (
                    <div key={key}>
                        <audio controls style={{ width: '100%', maxWidth: '600px' }}>
                            <source src={fields.audioUrl} />
                            Your browser does not support the audio element.
                        </audio>
                    </div>
                )
            }



            if (sys.contentType.sys.id === "hardcodedComponentEmbed") {
                if (fields.component === "WF Calc") {
                    return <div key={key}><WorkflowCapacityCalc /></div>
                }
            }

            if (sys.contentType.sys.id === "blockHardcoded") {
                if (fields.component === "PLM-Table") {
                    return <div key={key}><PLMTable /></div>
                }

            }
            if (sys.contentType.sys.id === "componentVideo") {
                const { fields, } = c.data.target
                return (
                    <div key={key} className="row justify-content-center">
                        <div className={convertColumnWidth(fields.width)}>
                            <div className="embed-responsive embed-responsive-16by9" >
                                <iframe
                                    title={fields.videoUrl}
                                    className="embed-responsive-item"
                                    src={fields.videoUrl}
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>
                    </div>
                )
            }

            return null
        }

        else {
            console.log("Unmapped content", c)
            return <span key={key}>{processContent(c.content)}</span>
        }
    })
}

function convertColumnWidth(width) {
    if (width === "Full Width") return "col-md-12"
    if (width === "Half Width") return "col-md-6"
    if (width === "Third") return "col-md-4 col-sm-6"
    if (width === "Two Thirds") return "col-md-8 col-sm-12"
    if (width === "Quarter") return "col-lg-3 col-md-6"
    return "col-12"
}

export default processContent




