import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getTermsConditionsContent } from '../../actions/pagesActions'
import ContentBlockPage from '../layout/ContentBlockPage'
import Spinner from '../common/Spinner'


class TermsConditionsScreen extends Component {
    componentDidMount() {
        if (Object.keys(this.props.pages.termsConditionsContent).length === 0 && this.props.pages.termsConditionsContentLoading === false) {
            this.props.getTermsConditionsContent()
        }
    }

    render() {
        const { termsConditionsContentLoading, termsConditionsContent } = this.props.pages

        return termsConditionsContentLoading ? (
            <Spinner fullScreen color="yellow" large />
        ) : <ContentBlockPage content={termsConditionsContent} />

    }
}
const mapStateToProps = state => ({
    pages: state.pages
})
export default connect(mapStateToProps, { getTermsConditionsContent })(TermsConditionsScreen);