import React from "react";
import reduceIcon from "../../../img/icon_reduce_time.svg";
import iconEnhance from "../../../img/icon_enhance.svg";
import iconFocus from "../../../img/icon_increase_focus.svg";
import iconProcess from "../../../img/icon_process.svg";
import iconStaff from "../../../img/icon_engage_staff.svg";
import Block from "../Block";
import ImageCircleCard from "../ImageCircleCard";

export default function Benefits() {
    return (
        <Block largePadding color="white" >
            <section className="gridlist text-center">
                <h2 className="mb-5">
                    <strong>Benefits of using Pathology Go</strong>
                </h2>
                <ul className="gridlist__wrap">
                    <li className="gridlist__item">
                        <img src={reduceIcon} alt="" />
                        <h4>
                            Reduce the time your GPs spend looking at low-risk test results.
            </h4>
                        <p>
                            Pathology Go offers practices a great way to cut GP time spent
                            analysing low-risk results.
            </p>
                    </li>
                    <li className="gridlist__item">
                        <img src={iconEnhance} alt="" />
                        <h4>Enhance clinical quality.</h4>
                        <p>
                            Pathology Go’s gold-standard decision aids for administrators
                            means that patient outcomes are safely and consistently delivered
                            to a high standard.
            </p>
                    </li>
                    <li className="gridlist__item">
                        <img src={iconProcess} alt="" />
                        <h4>Increase focus on complex cases.</h4>
                        <p>
                            Using Pathology Go empowers your GPs to spend more time on the
                            cases most in need of clinical analysis and expertise.
            </p>
                    </li>
                    <li className="gridlist__item">
                        <img src={iconFocus} alt="" />
                        <h4>Improve processes in your Practice.</h4>
                        <p>
                            Pathology Go enables practices to optimise use of staff, ensuring the most efficient and cost effective matching of staff member to task, helping to improve financial resilience.
            </p>
                    </li>
                    <li className="gridlist__item">
                        <img src={iconStaff} alt="" />
                        <h4>Engage your staff.</h4>
                        <p>
                            Pathology Go delivers a programme of training, auditing and data
                            monitoring that will engage and develop your admin staff.
            </p>
                    </li>
                    <li className="gridlist__item text-center">
                        <ImageCircleCard
                            color='green'
                            header='Find out more.'
                            paragraph='Get in touch to find out more about how Pathology Go can improve your Practice, and save 1000s of GP hours.'
                            buttonLink='/pathology-go-form'
                            buttonText='Enquire Now'
                        />

                    </li>
                </ul>
            </section>
        </Block>
    );
}
