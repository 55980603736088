import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getSubPageContent } from '../../actions/pagesActions'
import ContentBlockPage from '../layout/ContentBlockPage'
import Spinner from '../common/Spinner'
import Error404Screen from '../errors/Error404Screen'

class SubPageScreen extends Component {
    componentDidMount() {
        const pageType = this.props.pageType || this.props.match.params.pageType
        this.props.getSubPageContent(pageType, this.props.match.params.subPage)
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.subPage !== this.props.match.params.subPage) {
            const pageType = nextProps.pageType || nextProps.match.params.pageType
            this.props.getSubPageContent(pageType, nextProps.match.params.subPage)
        }
    }

    render() {
        const { subPageLoading, subPage } = this.props.pages
        if (subPageLoading === false && Object.keys(subPage).length === 0) { return <Error404Screen /> }
        return subPageLoading ? <Spinner fullScreen color="yellow" large /> : <ContentBlockPage content={subPage} />
    }
}
const mapStateToProps = state => ({
    pages: state.pages
})
export default connect(mapStateToProps, { getSubPageContent })(SubPageScreen);