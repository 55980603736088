import React, { Component } from 'react'
import { connect } from 'react-redux';
import Background from '../../img/circles_bg.svg'
import { fireBillsby } from '../../actions/purchaseActions'
import queryString from 'query-string'
import Spinner from '../common/Spinner'


class BillsbyThankyou extends Component {
    componentDidMount() {
        const { sid } = queryString.parse(this.props.location.search);
        if (sid) {
            this.props.fireBillsby(sid)
        }

        const redirect = () => {
            window.location.replace('/')
        }

        setInterval(redirect, 20000);
    }


    render() {

        const bbProd = this.props.purchase.billsbySuccess.productName
        const prodArr = this.props.products.products.filter(p => p.name === bbProd && p.purchased === true)
        const authArr = this.props.auth.products.filter(p => p.product === bbProd)

        if (bbProd && prodArr.length > 0 && authArr.length > 0) {
            window.location.replace('/product/' + this.props.purchase.billsbySuccess.productName)
        }
        return (
            <div style={{
                backgroundImage: 'url("' + Background + '")',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                overflow: 'hidden',
                backgroundPosition: 'center',

            }}>
                <Spinner fullScreen />
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    purchase: state.purchase,
    auth: state.auth,
    products: state.products
})

export default connect(mapStateToProps, { fireBillsby })(BillsbyThankyou);