import React from 'react'
import styles from './HomePageContact.module.css'
import SocialsIcon from '../common/SocialsIcon'
import DivFade from './DivFade'

export default function HomePageContact(props) {

    let backgroundColour = '#16C194'
    switch (props.backgroundColour) {
        case "leafGreen":
            backgroundColour = '#60AE4C'
        break
        default:
        break
    }
    
    return (
        <DivFade>            
            <div 
                style={{
                    backgroundColor: backgroundColour,
                    padding: '2rem 0.5rem'
                }}
            
            >
                <div className={styles.container}>
                    <div>
                        <h2 className={styles.header}>Got questions? Talk to us…</h2>
                    </div>
                    <div className={styles.flex}>
                        <p className={styles.text}>Whether you are interested in one of our products or would like to know more about working with Practice Unbound, please give us a call on <strong>0300 303 8145</strong> or email <a className={styles.link} href='mailto:info@practiceunbound.org.uk'>info@practiceunbound.org.uk</a></p>
                        <div>
                            <div className={styles.icons}>
                                <SocialsIcon
                                    className={`tw-text-white hover:tw-text-[#067558]`}
                                    social="linkedin"
                                    link={"https://www.linkedin.com/company/practice-unbound./"}
                                />
                                <SocialsIcon
                                    className={`tw-text-white hover:tw-text-[#067558]`}
                                    social="twitter"
                                    link={"https://twitter.com/PracticeUnbound"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DivFade>
    )
}
