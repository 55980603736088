import React from 'react'
import Circles from '../../../../img/circles-darker.svg'
import Button from '../../../common/Button';
import DivFade from '../../DivFade'

const Circle = (props) => {
    return <div style={{
        top: props.top,
        left: props.left,
        width: props.width,
        position: 'absolute'
    }}>
        <div style={{
            width: '100%',
            paddingTop: '100%',
            backgroundColor: props.color,
            backgroundImage: `url(${props.backgroundImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            borderRadius: '100%'
        }} />
    </div >
}

export default function TopBannerButtons(props) {

    const pageContent = {
        headerText: 'What is Prescribing Lifestyle Medicine',
        description: <><p>This course presents a unique framework to deliver targeted lifestyle medicine interventions using the principles of personalised care.</p>
            <p>You will learn fundamental principles but also master how to implement Prescribing Lifestyle Medicine and deliver effective lifestyle interventions for your patients and service users. </p>
            <p>PLM Online will provide you with the framework to help manage conditions such as:</p>
            <div className='tw-flex sm:tw-gap-8 tw-pb-5'>
                <ul className='tw-pl-5 tw-mb-0'>
                    <li>Type 2 Diabetes</li>
                    <li>IrritableBowel Syndrome</li>
                    <li>Anxiety</li>
                    <li>Reflux</li>
                    <li>Arthralgia</li>
                    <li>Depression</li>
                    <li>Brain Fog</li>
                </ul>
                <ul className='tw-pl-5 tw-mb-0'>
                    <li>Fatigue</li>
                    <li>Dermatitis</li>
                    <li>Recurrent Illness</li>
                    <li>Insomnia</li>
                    <li>Back pain</li>
                    <li>High blood pressure</li>
                    <li>And more</li>
                </ul>
            </div>
            </>
            ,
        buttons: [
            { label: 'enrol now', colour: 'orange-outline', link: '/product/prescribinglifestylemedicine/enrol-plm' },
            { label: 'who is it for', colour: 'hollow-orange', link: '/product/prescribinglifestylemedicine/about-plm' },
        ]
    }

    return (
        <DivFade style={{ position: 'relative', overflow: 'hidden', backgroundColor: '#F3F3F3' }}>

            <img alt="" src={Circles}
                style={{ position: 'absolute', width: '400px', left: '-50px', top: '-50px' }}
            />

            <div className="container">

                <div className="row py-4">

                    <div className="col-md-7 col-12">
                        <div className="align-items-center px-3" style={{ paddingTop: '90px', paddingBottom: '60px' }}>

                            {/* Header */}
                            <div>
                                <h3 style={{ fontWeight: 'bold' }}>{pageContent.headerText}</h3>
                            </div>
                            {/* Description */}
                            <div className='my-3'>
                                {pageContent.description}
                            </div>
                            {/* Buttons */}
                            {/* <div className='d-flex  justify-content-center justify-content-md-start'> */}
                            <div className='d-flex justify-content-start'>
                                {pageContent.buttons.map((button, index) => (
                                    <div key={`${index} ${button.label}`} className='mr-2 mt-2'>
                                        <Button colour={button.colour} link={button.link} >{button.label}</Button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>



                    <div className="col-md-5 d-md-block pb-4">
                        <div className="d-flex h-100 align-items-center">
                            <div style={{ position: 'relative', width: '100%', paddingTop: '80%' }}>

                                <Circle color="#0E3A5C" width="29%" top="61%" left="44%" />
                                <Circle color="#EB6915" width="12%" top="25%" left="0%" />
                                <Circle backgroundImage={'https://practiceunbound.blob.core.windows.net/stock-images/AyanHompageTopAndBottomImageCropped-min.jpg'} width="47%" top="0%" left="53%" />
                                <a rel="noreferrer" href='http://www.drayan.co.uk/' target="_blank"><p style={{ position: 'absolute', top: "62%", left: "75%" }}>Dr Ayan Panja</p></a>
                                <Circle backgroundImage={'https://practiceunbound.blob.core.windows.net/stock-images/RanganHomepageTopImage_2017_07_Chatterjee-3852-Edit-3Cropped-min.jpg'} width="65%" top="18%" left="0%" />
                                <a rel="noreferrer" href='https://drchatterjee.com/' target="_blank"><p style={{ position: 'absolute', top: "103%", left: "13%" }}>Dr Rangan Chatterjee</p></a>
                                <Circle color="#0C989D" width="14%" top="0%" left="49%" />

                            </div>
                            {/* <div style={{
                                // border: 'solid red 3px', 
                                position: 'absolute',
                                top: '250px',
                                left: '360px'
                            }}>
                                <p>Dr Ayan Panja</p>
                                <p>Dr Rangan Chatterjee</p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div >
        </DivFade >
    )
}
