import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import OurAccreditations from './Comps/OurAccreditations'
import Testimonials from './Comps/Testimonials';
import PLMBanner from '../PLMBanner';
import Videos from '../Videos';
import Slogan from '../Slogan';
import ImageTextBlock from './Comps/ImageTextBlock';
import BenefitsInclude from './Comps/BenefitsInclude';
import CreatorsCarousel from './Comps/CreatorsCarousel'
import circles from '../../../img/dark-grey-bubbles.svg'
import TopBannerButtons from './Comps/TopBannerButtons';
import BottomBanner from './Comps/BottomBanner';
import PULogo from '../../../img/PracticeUnboundYellow.png'
import CELogo from '../../../img/CE-logo.png'
import LogoBanner from './Comps/LogoBanner';
import HCALogo from '../../../img/hca-logo.jpg'
// import learningTech from '../../../img/Learning-Technologies-Logo.png'
import { Link } from 'react-router-dom';
import puColours from '../../../utils/puColours';
// import LogosBanner from '../../common/LogosBanner';
// import ELMOLogo from '../../../img/ELMO-logo.png'


class Homepage extends Component {

    // Potential components to remove:
    //  - <PLMHomeNLSRedesign /> 
    //  - <PLMHome />
    //  - <DoubleBlockImage />

    componentDidMount() {
        if (window.location.hash === '#plm-intro-video') {
            this.scrollToTag('plm-intro-video')
        }
    }
    scrollToTag(id) {
        if (document.getElementById(id)) {
            document.getElementById(id).scrollIntoView({
                behavior: 'smooth'
            });
        }

    }
    render() {

        // const awardItems = [
        //     {
        //         maxWidth: '100px',
        //         link: "https://htn.co.uk/health-tech-awards-2022/",
        //         imageAlt: "HTN awards logo",
        //         imageSrc: "https://practiceunbound.blob.core.windows.net/stock-images/htn-awards.png"
        //     },
        //     {
        //         maxWidth: '100px',
        //         link: "https://www.learningtechnologies.co.uk/learning-tech-awards",
        //         imageAlt: "Learning Technologies awards logo",
        //         imageSrc: learningTech
        //     },
        // ]

        const partnershipImages = [
            {
                src: PULogo,
                alt: 'Practice Unbound logo',
                maxWidth: '250px',
                link: 'https://www.practiceunbound.org.uk/'
            },
            {
                src: CELogo,
                alt: 'Clinical Education logo',
                maxWidth: '250px',
                link: 'https://www.clinicaleducation.org/'
            },
        ]

        const bannerLogos = [
            {
                src: HCALogo,
                alt: 'HCA logo',
                maxWidth: '160px',
                link: 'https://www.hcahealthcare.co.uk/'
            },
            // {
            //     src: ELMOLogo,
            //     alt: 'european lifestyle medicine organization logo',
            //     maxWidth: '250px',
            //     link: 'https://eulm.org/'
            // },
            {
                src: "https://practiceunbound.blob.core.windows.net/stock-images/IPM23_Logo_2024-min.png",
                alt: 'Integrative & Personalised Medicine (IPM) 2022',
                maxWidth: '150px',
                link: 'https://www.ipmcongress.com/about'
            },
            {
                src: 'https://practiceunbound.blob.core.windows.net/stock-images/Pulse_Logo.png',
                alt: 'Pulse logo',
                maxWidth: '150px',
                link: 'https://www.pulsetoday.co.uk/'
            },
        ]

        const benefits = [
        ]
        
        

        return (
            <>

                <HelmetThing />
                <PLMBanner />
                <OurAccreditations />
                {/* <LogosBanner 
                    // title="our sponsors"
                    items={awardItems}
                    // backgroundColour="#F3F3F3"
                    logoMarginTop="4"
                    logoMarginBottom="5"
                /> */}
                <TopBannerButtons />
                <ImageTextBlock
                    imageUrl={'https://practiceunbound.blob.core.windows.net/stock-images/Homepage_Block_Shutterstock_1050440903-min.jpg'}
                    contentSide="right"
                    contentBackgroundColor="#193958"
                    contentBackgroundImage={circles}
                    contentBackgroundSize='50%'
                    contentBackgroundPosition='top right'
                    contentBackgroundRight='-20%'
                    contentBackgroundTop='-15%'
                >
                    <BenefitsInclude whiteText listItems={[...benefits]}>
                        <li className={"orange-tick"} style={{ marginBottom: 0, fontSize: '14px' }}>RCGP accredited</li>
                        <li className={"orange-tick"} style={{ marginBottom: 0, fontSize: '14px' }}>PCI Accredited</li>
                        <li className={"orange-tick"} style={{ marginBottom: 0, fontSize: '14px' }}>UKIHCA Accredited</li>
                        <li className={"orange-tick"} style={{ marginBottom: 0, fontSize: '14px' }}>BSLM Accredited</li>
                        <li className={"orange-tick"} style={{ marginBottom: 0, fontSize: '14px' }}>Recommended 15 CPD points</li>
                        <li className={"orange-tick"} style={{ marginBottom: 0, fontSize: '14px' }}>An approved basic RCGP qualification for GPs to work in an <span ><a target='_blank' rel="noreferrer" style={{color: puColours.darkOrange, textDecoration: 'underline'}} href='https://www.rcgp.org.uk/your-career/gp-extended-roles/Lifestyle-medicine-framework-develop-maintain#Qualifications-required-to-be-a-GPwER-in-lifestyle'>extended role</a></span> in Lifestyle Medicine</li>
                        <li className={"orange-tick"} style={{ marginBottom: 0, fontSize: '14px' }}>Growing online community for further learning and exclusive content</li>
                        <li className={"orange-tick"} style={{ marginBottom: 0, fontSize: '14px' }}>Built in data capture feature for NHS GPs; EMIS/SystmOne (UK)</li>
                        <li className={"orange-tick"} style={{ marginBottom: 0, fontSize: '14px' }}>Access to additional modules to enhance knowledge and skills</li>
                        <li className={"orange-tick"} style={{ marginBottom: 0, fontSize: '14px' }}>Unique course content and framework</li>
                        <li className={"orange-tick"} style={{ marginBottom: 0, fontSize: '14px' }}>Group discounts available</li>
                        <li className={"orange-tick"} style={{ marginBottom: 0, fontSize: '14px' }}>Option to undertake an advanced Certificate in Lifestyle Medicine qualification (additional enrolment fee)</li>
                    </BenefitsInclude>
                </ImageTextBlock>
                <Testimonials />
                <Videos />
                <LogoBanner images={bannerLogos} paddingTop="30px" paddingBottom="30px" mbImage="mb-3" maxWidth="992px" />
                <Slogan />
                <LogoBanner images={partnershipImages} header="A partnership between" paddingTop="90px" paddingBottom="30px" maxWidth="700px" mbImage="mb-5" />
                <CreatorsCarousel />
                <BottomBanner header="Have more questions? Talk to us" iconHoverColour="#034F52">
                    {/* <p style={{ fontSize: '18px' }}>Whether you are interested in one of our products or would like to know more about working with Practice Unbound, please give us a call on 0300 303 8145 or email <a style={{ fontWeight: 'bold', color: 'white' }} href="mailto:info@practiceunbound.org.uk">info@practiceunbound.org.uk</a></p> */}
                    <p style={{ fontSize: '18px' }}>Whether you are interested in one of our products or would like to know more about working with Practice Unbound, please<Link style={{ color: 'white' }} to="/contact-us"> contact the team</Link></p>
                </BottomBanner>


            </>
        );
    }
}

const HelmetThing = () => {
    return (
        <Helmet>
            <script type="application/ld+json">
                {`{
                    "@context": "https://schema.org",
                    "@type": "VideoObject",
                    "name": "How Prescribing Lifestyle Medicine Can Help You and Your Patients",
                    "description": "Discover how Prescribing Lifestyle Medicine can help you and your patients - shared by Dr Ayan Panja, created by Practice UNBOUND.",
                    "contentUrl": "https://player.vimeo.com/video/512901038",
                    "embedUrl": "https://player.vimeo.com/video/512901038"
                }`}
            </script>
        </Helmet>
    )
}

const mapStateToProps = state => ({
    products: state.products,
})
export default connect(mapStateToProps, {})(Homepage);