import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom'
import { getCareersContent } from '../../actions/pagesActions'
import ContentBlockPage from '../layout/ContentBlockPage'
import Spinner from '../common/Spinner'
import SubPageScreen from './SubPageScreen'

class CareersScreen extends Component {
    componentDidMount() {
        if (Object.keys(this.props.pages.careersContent).length === 0 && this.props.pages.careersContentLoading === false) {
            this.props.getCareersContent()
        }
    }

    render() {
        const { careersContentLoading, careersContent } = this.props.pages

        return (
            <Switch>
                <Route exact path={this.props.match.url + '/'} >
                    {careersContentLoading ? <Spinner fullScreen color="yellow" large /> : <ContentBlockPage content={careersContent} />}
                </Route>
                <Route
                    exact
                    path={this.props.match.url + '/:subPage'}
                    render={(props) => <SubPageScreen {...props} pageType="careers" />}
                />
            </Switch>
        )
    }
}
const mapStateToProps = state => ({
    pages: state.pages
})
export default connect(mapStateToProps, { getCareersContent })(CareersScreen);