import ImageTextBlock from '../../PLM/Comps/ImageTextBlock'
import greyCircles from '../../../../img/slightly-dark-grey-bubbles-right.svg'
import Quote from '../../../common/Quote'
import BottomBanner from '../../PLM/Comps/BottomBanner'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import Carousel from '../../../common/Carousel'
import {carouselItems} from './SuccessStories'
import TopBannerPlain from '../../PLM/Comps/TopBannerPlain'
import whiteCircleShadow from '../../../../img/white-circle-shadow.svg'
import northgateImg from '../../../../img/02 Success Stories/image_northgate_surgery@3x.jpg'
import DivFade from '../../DivFade'
import { useHistory } from 'react-router-dom'

const carouselItemsFiltered = carouselItems.filter(i => i.title !== 'Northgate Surgery')

export default function Northgate() {
    const history = useHistory()  
    const handleNav = (url) => () => history.push(url)
  return (

    <div>

        <ImageTextBlock
            imageUrl={northgateImg}
            contentSide="left"
            contentBackgroundColor="white"
            contentBackgroundImage={greyCircles}
            contentBackgroundSize='20%'
            contentBackgroundPosition='top left'
            contentBackgroundRight='5%'
            contentBackgroundTop='-35%'
        >
        <div className='py-5 my-5'>
            <h3 className='font-weight-bold mb-3'>Northgate Surgery</h3>
            <p>Northgate Surgery is using GP Billing to increase data accuracy and is already raising on average 119 bills per month. With many Non-NHS Services and third-party clinics being provided by the practice, the organisation embraces new technologies and systems that work to streamline their systems and processes.</p>
        </div>
        </ImageTextBlock>

        <DivFade className='px-5'>
            <div className='container p-5'>
                <Quote
                    // key={`${index}: ${item}`} 
                    author={testimonials[0].author} 
                    quote={testimonials[0].quote} 
                    // iconColour={props.quoteIconColour}
                />
            </div>
        </DivFade>

        <TopBannerPlain
            circleLogo={whiteCircleShadow}
            circleContent={
                <div style={{ textAlign: 'center', color: '#113B5B', display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%', paddingTop:'20px' }}>
                    <div>
                        <h1 style={{ fontSize: '75px', lineHeight:'60px'}} className='font-weight-bold'>119</h1>
                        <h2 className='mb-0 font-weight-bold' style={{ fontSize: '25px'}}>Bills Raised</h2>
                        <p className='mb-0'>(On average</p>
                        <p >per month)</p>
                    </div>
                    {/* <div style={{ fontSize: '60px',}} className='font-weight-bold'>119</div>
                    <div style={{ fontSize: '40px'}} className='font-weight-bold'>Bills Raised</div> */}
                </div>
            }
            circleColours={{
                one:"#60AE4C",
                two:"#113B5B",
                three:"#2372B5",
            }} 
        >
            <div className='py-5'>
                <p>With a larger than average patient list size of 12,751, Northgate Surgery in Wakefield, provides a range of vital primary care services to the local community. The practice specialise in running numerous on-site clinics ranging from Diabetes through to Endoscopy and Audiology, 20% of their patients are above 64 years old</p>
            </div>
        </TopBannerPlain>

        <DivFade className='px-5'>
            <div className='container p-5'>
                <Quote
                    // key={`${index}: ${item}`} 
                    author={testimonials[1].author} 
                    quote={testimonials[1].quote} 
                    // iconColour={props.quoteIconColour}
                />
            </div>
        </DivFade>

        <DivFade className='bg-white p-5'>
            <div className='container p-5 bg-white'>
                <div className='mb-5'>
                    <h2 className='font-weight-bold mb-3'>Working towards transformative goals</h2>
                    <p>Improving data accuracy and information governance has given the practice confidence that patient details are correct and synchronised with their clinical system. GP Billing pulls data directly from SystmOne which improves data accuracy. Past issues meant that in some cases, patient details would have been entered incorrectly and/or duplicated.</p>
                    <p>The practice has moved from using a single spreadsheet to a system that can be accessed by the entire admin team, simultaneously - Rebecca shares that “GP Billing gives the practice the option for more people to working in the system at once."</p>
                    <p>Further to the above benefit, only authorised staff have access to the systems and the Management Team has the ability to restrict and amend access levels. With a non-clinical practice team of 9, managing unexpected business change can be quick and easy.</p>
                </div>
                <div className='mb-5'>
                    <h2 className='font-weight-bold mb-3'>A single source of truth for third-party outstanding debt and payments</h2>
                    <p>With various third-party room booking fees and Non-NHS services, Northgate Surgery needed an easier way to record and reconcile fees. Rebecca reveals that GP Billing has empowered the team to do this in a much more efficient and transparent way:</p>
                    <p>"GP Billing gives us an easier way to look at what payments are outstanding so that we can chase payments when necessary and we can send a reminder invoice very easily with GP Billing."</p>
                    <p>The organisation enjoys utilising the in-system capability to send out invoices and receipts via email. This feature is also enabling the practice to meet paperless goals which have been set out by the NHS Long Term Plan.</p>
                </div>
                <div>
                    <h2 className='font-weight-bold mb-3'>GP Billing is helping increase income capture and the ability to trend and analyse</h2>
                    <p>The practice is pleased to report that GP Billing is allowing the Management Team to spot trends and analyse income streams.</p>
                    <p>"We have seen a high increase with all private work, particularly DVLA Private Medicals in Q1 of 2021. They have nearly doubled. We also would say this is now one of our top 5 services this year so far."</p>
                    <p>We also are seeing increases with our room bookings with Endoscopy, Colonoscopy, and Audiology as well as the other typical services you might get in most GP Surgeries.". Rebecca Clegg, Deputy Practice Manager</p>
                </div>
            </div>
        </DivFade>

        <div className='d-block d-lg-none'>
            <Carousel
            // carouselContentType='courseCreators'
            backgroundColour={'blue'}
            titleHeight='28px'
            height='310px'
            items={carouselItemsFiltered.map(e => ({...e, navigateFunction: handleNav(e.linkUrl)}))}
            cardColor='leafGreen'            
            />
        </div>

        <div className='d-none d-lg-block'>
            <Carousel
                // carouselContentType='courseCreators'
                backgroundColour={'blue'}
                titleHeight='28px'
                height='310px'
                slidesToShow={2}
                items={carouselItemsFiltered.filter((e,i) => i >=0 && i <=1).map(e => ({...e, navigateFunction: handleNav(e.linkUrl)}))}
                cardColor='leafGreen'    
                paddingBottom='20px'            
            />
            <Carousel
                // carouselContentType='courseCreators'
                backgroundColour={'blue'}
                titleHeight='28px'
                height='310px'
                items={carouselItemsFiltered.filter((e,i) => i >=2 && i <=3).map(e => ({...e, navigateFunction: handleNav(e.linkUrl)}))}
                cardColor='leafGreen'  
                slidesToShow={2}   
                topHalfBackgroundColour='rgb(25, 57, 88)'   
                // items={carouselItems}        
            />
        </div>

        <BottomBanner backgroundColour="leafGreen" header="Questions?">
            {/* <p style={{ fontSize: '18px' }}>Whether you are interested in one of our products or would like to know more about working with Practice Unbound, please give us a call on 0300 303 8145 or email <a style={{ fontWeight: 'bold', color: 'white' }} href="mailto:info@practiceunbound.org.uk">info@practiceunbound.org.uk</a></p> */}
            <p style={{ fontSize: '18px' }}><Link style={{ color: 'white' }} to="/contact-us">Contact the team</Link> or call us on <strong>0300 303 8145</strong></p>
        </BottomBanner>

    </div>    

 ) 
  
}

const testimonials = [
    {
        quote: `Technology is very important in being able to streamline how we work in order to ensure accuracy, confidentiality, and efficacy."`,
        author: 'Rebbecca Clegg | Deputy Practice Manager'
    },
    {
        quote: 'GB Billing has enabled allowed us to tighten up on missed existing income streams"',
        author: 'Rebbecca Clegg | Deputy Practice Manager'
    },
]