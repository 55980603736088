import React, { useState, useEffect } from "react"
import logo from "../../img/logo_PLM3x.png"
import Button from "../common/Button"
import DivFade from "./DivFade"
import ImageAbsolute from "./ImageAbsolute"
import styles from "./PLMBanner.module.css"
import Modal from "../common/Modal"
import moment from "moment"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import puColours from "../../utils/puColours"

export default function PLMBanner(props) {
  const [offerModalOpen, setOfferModalOpen] = useState(true)

  useEffect(() => {
    const bannerDismissed = localStorage.getItem("dismissPlmOfferBanner")
    setOfferModalOpen(bannerDismissed || !offerOpen ? false : true)
  }, [])

  function handleOfferClose() {
    setOfferModalOpen(false)
    localStorage.setItem("dismissPlmOfferBanner", true)
  }

  const offerOpen = moment().format("ll") < "Feb 26, 2023" || false

  return (
    <DivFade
      style={{
        overflow: "hidden",
        position: "relative",
        paddingTop: "58px",
        paddingBottom: "58px",
      }}>
      <ImageAbsolute
        top={0}
        left={0}
        right={0}
        bottom={0}
        backgroundPosition='50% 42%'
        imageSrc={
          "https://practiceunbound.blob.core.windows.net/stock-images/shutterstock_1548602135-min.jpg"
        }
      />
      <img
        alt='PLM Logo'
        className='d-none d-lg-block'
        style={{
          position: "absolute",
          width: "130px",
          top: "18px",
          left: "18px",
        }}
        src={logo}
      />
      <div className='tw-container tw-mx-auto tw-text-white md:tw-pb-20'>
        <div className='md:tw-py-20' />
        <h1
          className='tw-font-bold tw-text-center tw-uppercase tw-text-6xl'
          style={{
            fontSize: 'clamp(40px,10vw,60px',
            textWrap: "balance",
            textShadow:
              "-1px 0 16px rgba(0,0,0,0.3), 0 1px 16px rgba(0,0,0,0.3), 1px 0 16px rgba(0,0,0,0.3), 0 -1px 16px rgba(0,0,0,0.3)",
          }}>
          prescribing lifestyle medicine
        </h1>
        <div className='tw-py-6' />
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-3 tw-pb-2">
          <h2
            className='tw-font-bold tw-text-center tw-italic tw-max-w-2xl tw-mx-auto'
            style={{
              fontSize: 'clamp(20px,4vw,28px',
              color: "rgb(255 139 65)",
              textWrap: "balance",
              textShadow:
                "-1px 0 16px rgba(0,0,0,0.3), 0 1px 16px rgba(0,0,0,0.3), 1px 0 16px rgba(0,0,0,0.3), 0 -1px 16px rgba(0,0,0,0.3)",
            }}>
            Deliver targeted lifestyle interventions and personalised care using a
            unique framework
          </h2>
          <h3
            className='tw-font-bold tw-text-center tw-max-w-2xl tw-mx-auto'
            style={{
              fontSize: 'clamp(16px,2vw,24px',
              color: "rgb(230 230 230)",
              textWrap: "balance",
              textShadow:
                "-1px 0 16px rgba(0,0,0,0.3), 0 1px 16px rgba(0,0,0,0.3), 1px 0 16px rgba(0,0,0,0.3), 0 -1px 16px rgba(0,0,0,0.3)",
            }}>
            NEW: An RCGP approved course for GPs to work in an Extended Role in Lifestyle Medicine
          </h3>
        </div>
        <div className='tw-py-5 md:tw-py-12' />
        <div className='tw-grid tw-gap-5 lg:tw-gap-20 tw-grid-cols-1 md:tw-grid-cols-2 tw-px-2 sm:tw-px-10 md:tw-px-20'>
          {[
            {
              text: "Prescribing Lifestyle Medicine Online",
              link: "/product/prescribinglifestylemedicine/about-plm",
            },
            {
              text: "Certificate in Lifestyle Medicine",
              link: "/product/prescribinglifestylemedicine/accreditation",
              new: true,
            }
          ].map((e, i) => (
            <div
              className='tw-relative tw-h-32 sm:tw-h-28  md:tw-h-44 min-[992px]:tw-h-36 tw-col-span-1 tw-group '

              key={`${(e, i)}`}>
              <div className={`tw-absolute tw-inset-0 tw-opacity-30 ${e.link && 'group-hover:tw-opacity-60'} min-[577px]:tw-rounded-xl  tw-bg-black tw-transition-all tw-duration-200`} />
              <div className='tw-border-y-[1px] tw-border-x-0  tw-border-solid tw-border-[#bd4a00] tw-absolute tw-inset-0 min-[577px]:tw-rounded-xl min-[577px]:tw-border-x-[1px]'>
                {e.link ? (
                  <Link to={e.link} style={{ textDecoration: "none" }} className='tw-cursor-pointer'>
                    <div className='tw-py-2' />
                    <h4
                      className='tw-font-bold tw-text-center tw-no-underline tw-text-white tw-mb-4 tw-leading-8 tw-px-5'
                      style={{ textWrap: "balance" }}>
                      {!e.new ? (
                        e.text
                      ) : (
                        <>
                          <span className='tw-bg-[#0C989D] tw-rounded-md tw-py-1 tw-px-2'>
                            NEW
                          </span>
                          <span> {e.text}</span>
                        </>
                      )}
                    </h4>
                    <p
                      className='tw-font-bold tw-text-center '
                      style={{ color: "rgb(255 99 0)" }}>
                      find out more
                    </p>
                  </Link> ) : (
                  <div style={{ textDecoration: "none" }}>
                    <div className='tw-py-2' />
                    <h4
                      className='tw-font-bold tw-text-center tw-no-underline tw-text-white tw-mb-4 tw-leading-8 tw-px-5'
                      style={{ textWrap: "balance" }}>
                      {!e.new ? (
                        e.text
                      ) : (
                        <>
                          <span className='tw-bg-[#0C989D] tw-rounded-md tw-py-1 tw-px-2'>
                            NEW
                          </span>
                          <span> {e.text}</span>
                        </>
                      )}
                    </h4>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal
        // isOpen={offerModalOpen}
        isOpen={false}
        onRequestClose={handleOfferClose}
        // onRequestClose={() => setOfferModalOpen(false)}
        title={"Special Offer"}
        color={"blue"}>
        <h3 className='font-weight-bold mb-4'>
          Save 20% until 25th February on PLM Online and PLM LIVE!
        </h3>
        <div className='mb-4'>
          <h5 className='font-weight-bold'>
            Save 20% off PLM Online Course using code WEB20
          </h5>
          <p>(£260 + VAT instead of £325 + VAT)</p>
        </div>
        <div className='mb-4'>
          <h5 className='font-weight-bold'>
            Save 20% off PLM LIVE on 4th March at County Hall London using code
            WEB20
          </h5>
          <p>(£200 + VAT instead of £250 + VAT)</p>
        </div>
        <div
          className='bg-light-blue text-white'
          style={{
            padding: "1.4rem 1.4rem .5rem 1.4rem",
            borderRadius: "4px",
            boxShadow: "0 1px 4px 0 rgba(0,0,0,.18)",
            position: "relative",
            marginTop: "25px",
          }}>
          <div>
            <h4 className='font-weight-bold'>Want to buy both?</h4>
            {/* <div className='py-1'/> */}
            <p>
              Enrol onto the PLM Online Course before 25th February and get 20%
              off the Course AND get your event ticket for just £145 (inc’ VAT)
              instead of £250
            </p>
          </div>
          <div className='row pb-3 pb-md-0'>
            <div className='mt-4 mb-md-4 col-12 col-md-6'>
              <Button
                link='/product/prescribinglifestylemedicine/enrol-plm'
                colour='orange-outline'>
                Enrol onto PLM Online
              </Button>
            </div>
            <div className='mt-4 mb-md-4 col-12 col-md-6'>
              <a
                style={{ textDecoration: "none" }}
                href='https://www.tickettailor.com/events/practiceunbound/760168'>
                <Button center colour='orange-outline'>
                  Book your PLM Live ticket
                </Button>
              </a>
            </div>
          </div>
        </div>
      </Modal>
    </DivFade>
  )

  return (
    <>
      {!props.itemsSwapped ? (
        <DivFade
          style={{
            overflow: "hidden",
            position: "relative",
            paddingTop: "58px",
            paddingBottom: "58px",
          }}>
          <ImageAbsolute
            top={0}
            left={0}
            right={0}
            bottom={0}
            backgroundPosition='50% 42%'
            imageSrc={
              "https://practiceunbound.blob.core.windows.net/stock-images/shutterstock_1548602135-min.jpg"
            }
          />
          <img
            alt='PLM Logo'
            className='d-none d-lg-block'
            style={{
              position: "absolute",
              width: "130px",
              top: "18px",
              left: "18px",
            }}
            src={logo}
          />
          <div className='container py-xl-5'>
            <div className='row justify-content-center'>
              <div className='col-md-9 col-sm-12 pt-4 order-last order-md-first text-center'>
                <img
                  alt='PLM Logo'
                  className='d-lg-none'
                  style={{ width: "130px" }}
                  src={logo}
                />

                <h2
                  className='mb-4 text-white'
                  style={{
                    marginTop: "90px",
                    fontSize: "2.2rem",
                    fontWeight: "bold",
                    textShadow:
                      "-1px 0 16px rgba(0,0,0,0.3), 0 1px 16px rgba(0,0,0,0.3), 1px 0 16px rgba(0,0,0,0.3), 0 -1px 16px rgba(0,0,0,0.3)",
                  }}>
                  Prescribing Lifestyle Medicine Online Course
                  <br />
                  <Link
                    style={{ color: "rgb(255 123 38)" }}
                    to='/product/prescribinglifestylemedicine/about-plm'>
                    find out more
                  </Link>
                </h2>
                <h2
                  className='mb-4 text-white'
                  style={{
                    fontSize: "2.2rem",
                    fontWeight: "bold",
                    textShadow:
                      "-1px 0 16px rgba(0,0,0,0.3), 0 1px 16px rgba(0,0,0,0.3), 1px 0 16px rgba(0,0,0,0.3), 0 -1px 16px rgba(0,0,0,0.3)",
                  }}>
                  <span className={`tw-bg-[#0C989D] tw-rounded-lg tw-p-3`}>
                    New!
                  </span>{" "}
                  Certificate in Lifestyle Medicine (CertLM) <br />{" "}
                  <Link
                    style={{ color: "rgb(255 123 38)" }}
                    to='/product/prescribinglifestylemedicine/accreditation'>
                    find out more
                  </Link>
                </h2>
                <h2
                  className='mb-5 text-white'
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    textShadow:
                      "-1px 0 16px rgba(0,0,0,0.3), 0 1px 16px rgba(0,0,0,0.3), 1px 0 16px rgba(0,0,0,0.3), 0 -1px 16px rgba(0,0,0,0.3)",
                  }}>
                  <em>
                    Deliver targeted lifestyle interventions and personalised
                    care using a unique framework
                  </em>
                </h2>
                {/* <h2 className="mb-4 text-white" style={{
                                    fontSize: '2.5rem', fontWeight: 'bold',
                                    textShadow: '-1px 0 16px rgba(0,0,0,0.3), 0 1px 16px rgba(0,0,0,0.3), 1px 0 16px rgba(0,0,0,0.3), 0 -1px 16px rgba(0,0,0,0.3)'
                                }}>
                                    Deliver targeted lifestyle interventions and personalised care using a unique framework
                                </h2> */}

                {/* </div> */}
                {/* {props.products.product.preorderMode ? (
                                <Button colour="yellow" link="/contact-us?pre=plm" large inline className='mb-3 mr-3'>
                                    Pre-Register
                                </Button>
                            ) : (
                                <Button colour="yellow" link="/plm-next-step" large inline className='mb-3 mr-3'>
                                    enrol now
                                </Button>
                            )} */}
                <Button
                  center
                  colour='orange'
                  link='/product/prescribinglifestylemedicine/enrol-plm'
                  large
                  inline
                  className='mb-3 mr-3'>
                  enrol now
                </Button>
                {/* <h4 className="mt-4 text-white" style={{
                                    fontSize: '1.8rem',
                                    fontWeight: 'bold',
                                    textShadow: '-1px 0 16px rgba(0,0,0,0.3), 0 1px 16px rgba(0,0,0,0.3), 1px 0 16px rgba(0,0,0,0.3), 0 -1px 16px rgba(0,0,0,0.3)'
                                }}>Prescribing Lifestyle Medicine Live</h4>
                                <p className="text-white" style={{
                                    fontSize: '1.3rem',
                                    textShadow: '-1px 0 16px rgba(0,0,0,0.3), 0 1px 16px rgba(0,0,0,0.3), 1px 0 16px rgba(0,0,0,0.3), 0 -1px 16px rgba(0,0,0,0.3)'
                                }}>
                                    Tickets Now Available
                                </p>
                                <Button center colour="orange" link='/product/prescribinglifestylemedicine/event-plm' large inline >Book Now</Button> */}
              </div>
              {/* <div className="col-md-3 col-sm-12 order-first order-md-last d-flex justify-content-center">
                            <div className="h-100 d-flex align-items-center" style={{ maxWidth: '375px' }}>
                                <img
                                    className="img-fluid"
                                    src={logo}
                                    alt="PLM Logo"
                                />
                            </div>
                        </div> */}
            </div>
          </div>
        </DivFade>
      ) : (
        <DivFade
          style={{
            overflow: "hidden",
            position: "relative",
            paddingTop: "58px",
            paddingBottom: "58px",
          }}>
          <ImageAbsolute
            top={0}
            left={0}
            right={0}
            bottom={0}
            backgroundPosition='50% 42%'
            imageSrc={
              "https://practiceunbound.blob.core.windows.net/stock-images/shutterstock_1548602135-min.jpg"
            }
          />
          <img
            alt='PLM Logo'
            className='d-none d-lg-block'
            style={{
              position: "absolute",
              width: "130px",
              top: "18px",
              left: "18px",
            }}
            src={logo}
          />
          <div className='container py-xl-5'>
            <div className='row justify-content-center'>
              <div className='col-md-9 col-sm-12 pt-4 order-last order-md-first text-center'>
                <img
                  alt='PLM Logo'
                  className='d-lg-none'
                  style={{ width: "130px" }}
                  src={logo}
                />

                {/* <div style={{maxHeight: '100px'}}> */}
                <h2
                  className='mb-2 text-white text-capitalize'
                  style={{
                    fontSize: "2.5rem",
                    fontWeight: "bold",
                    textShadow:
                      "-1px 0 16px rgba(0,0,0,0.3), 0 1px 16px rgba(0,0,0,0.3), 1px 0 16px rgba(0,0,0,0.3), 0 -1px 16px rgba(0,0,0,0.3)",
                  }}>
                  Prescribing Lifestyle Medicine Live event
                </h2>
                <p
                  className='text-white'
                  style={{
                    fontSize: "1.3rem",
                    textShadow:
                      "-1px 0 16px rgba(0,0,0,0.3), 0 1px 16px rgba(0,0,0,0.3), 1px 0 16px rgba(0,0,0,0.3), 0 -1px 16px rgba(0,0,0,0.3)",
                  }}>
                  Saturday 4th March, County Hall, London
                </p>
                {/* </div> */}
                {/* {props.products.product.preorderMode ? (
                                <Button colour="yellow" link="/contact-us?pre=plm" large inline className='mb-3 mr-3'>
                                    Pre-Register
                                </Button>
                            ) : (
                                <Button colour="yellow" link="/plm-next-step" large inline className='mb-3 mr-3'>
                                    enrol now
                                </Button>
                            )} */}
                <Button
                  center
                  colour='orange'
                  link='/product/prescribinglifestylemedicine/event-plm'
                  large
                  inline>
                  book your place here
                </Button>
                <div className='py-2' />
                <h4
                  className='mt-4 text-white text-capitalize'
                  style={{
                    // fontSize: '1.8rem',
                    fontWeight: "bold",
                    textShadow:
                      "-1px 0 16px rgba(0,0,0,0.3), 0 1px 16px rgba(0,0,0,0.3), 1px 0 16px rgba(0,0,0,0.3), 0 -1px 16px rgba(0,0,0,0.3)",
                  }}>
                  Deliver targeted lifestyle medicine interventions using the
                  principles of personalised care
                </h4>
                <div className='py-1' />
                <Button
                  center
                  colour='orange'
                  link='/product/prescribinglifestylemedicine/enrol-plm'
                  large
                  inline
                  className='mb-3 mr-3'>
                  enrol today
                </Button>
              </div>
            </div>
          </div>
          {offerOpen && (
            <div className={`${styles.circle} `}>
              <section
                style={{ cursor: "pointer" }}
                onClick={() => setOfferModalOpen(true)}>
                <div>
                  <h3 className='text-uppercase font-weight-bold'>Save 20%</h3>
                  <p className='text-uppercase font-weight-bold'>
                    Limited Time Offer
                  </p>
                  <p>Click here to find out more</p>
                </div>
              </section>
            </div>
          )}

          <Modal
            isOpen={offerModalOpen}
            onRequestClose={handleOfferClose}
            // onRequestClose={() => setOfferModalOpen(false)}
            title={"Special Offer"}
            color={"blue"}>
            <h3 className='font-weight-bold mb-4'>
              Save 20% until 25th February on PLM Online and PLM LIVE!
            </h3>
            <div className='mb-4'>
              <h5 className='font-weight-bold'>
                Save 20% off PLM Online Course using code WEB20
              </h5>
              <p>(£260 + VAT instead of £325 + VAT)</p>
            </div>
            <div className='mb-4'>
              <h5 className='font-weight-bold'>
                Save 20% off PLM LIVE on 4th March at County Hall London using
                code WEB20
              </h5>
              <p>(£200 + VAT instead of £250 + VAT)</p>
            </div>
            <div
              className='bg-light-blue text-white'
              style={{
                padding: "1.4rem 1.4rem .5rem 1.4rem",
                borderRadius: "4px",
                boxShadow: "0 1px 4px 0 rgba(0,0,0,.18)",
                position: "relative",
                marginTop: "25px",
              }}>
              <div>
                <h4 className='font-weight-bold'>Want to buy both?</h4>
                {/* <div className='py-1'/> */}
                <p>
                  Enrol onto the PLM Online Course before 25th February and get
                  20% off the Course AND get your event ticket for just £145
                  (inc’ VAT) instead of £250
                </p>
              </div>
              <div className='row pb-3 pb-md-0'>
                <div className='mt-4 mb-md-4 col-12 col-md-6'>
                  <Button
                    link='/product/prescribinglifestylemedicine/enrol-plm'
                    colour='orange-outline'>
                    Enrol onto PLM Online
                  </Button>
                </div>
                <div className='mt-4 mb-md-4 col-12 col-md-6'>
                  <a
                    style={{ textDecoration: "none" }}
                    href='https://www.tickettailor.com/events/practiceunbound/760168'>
                    <Button center colour='orange-outline'>
                      Book your PLM Live ticket
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </Modal>
        </DivFade>
      )}
    </>
  )
}
