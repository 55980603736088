import React from 'react'
import ImageTextBlock from '../../PLM/Comps/ImageTextBlock'
// import circles from '../../../img/leaf-green-bubbles.svg'
import circles from '../../../../img/leaf-green-bubbles.svg'


const Benefits = (props) => {
  return (
    <ImageTextBlock
        imageUrl={'https://practiceunbound.blob.core.windows.net/stock-images/she-s-looking-forward-day-spa-min.jpg'}
        contentSide="left"
        contentBackgroundColor="#193958"
        contentBackgroundImage={circles}
        contentBackgroundSize='30%'
        contentBackgroundPosition='top right'
        contentBackgroundRight='-15%'
        contentBackgroundTop='3%'
        contentColWidth='6'
    >
        {props.children}
    </ImageTextBlock>
  )
}

export default Benefits