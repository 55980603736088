import React from 'react'
import styles from './CarouselCard.module.css'

function CarouselCard({ item, cardColor, paragraphHeight, height, titleHeight }) {
    const cardStyle = styles[cardColor]
    return (
        <div style={{ paddingTop: '20px' }}>
            <div
                onClick={item.navigateFunction}
                className={`${item.navigateFunction && cardStyle}`}
                style={{
                    width: '330px',
                    backgroundColor: 'white',
                    marginBottom: '20px',
                    margin: 'auto',
                    borderRadius: '2rem',
                    cursor: item.navigateFunction && 'pointer'
                }}
            >
                <div
                    style={{
                        borderTopLeftRadius: '2rem',
                        borderTopRightRadius: '2rem',
                        height: '200px',
                        backgroundImage: `url(${item.image})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        position: 'relative'
                    }}
                >
                    {item.discountPercentage && (
                        <div className="bg-light-pink" style={{
                            position: 'absolute',
                            top: '-20px',
                            right: '-20px',
                            height: '70px',
                            width: '70px',
                            borderRadius: '50%',
                            textAlign: 'center',
                            color: 'white',
                            fontWeight: 'bold'
                        }}>
                            <div style={{ fontSize: '24px', lineHeight: '24px', marginTop: '15px' }}>{item.discountPercentage}%</div>
                            <div style={{ fontSize: '16px', lineHeight: '16px' }}>OFF</div>
                        </div>
                    )}

                </div>
                <div style={{ height: height }} className="p-4 tw-flex tw-flex-col tw-justify-start tw-items-start">
                    <div>
                        <h4 className="mb-0 d-flex align-items-center" style={{ height: titleHeight, fontWeight: 'bold', fontSize: '19px' }}>
                            {item.iconUrl && <img alt={`${item.title} logo`} className={styles.productIcon} src={item.iconUrl} height='30px' width='30px' style={{ marginRight: '.7rem', }} />}
                            <span>
                                {item.title}
                            </span>
                        </h4>
                    </div>
                    {item.subtitle && <div><p style={{ fontSize: '14px', color: '#0C989D' }} className='mb-0'>{item.subtitle}</p></div>}
                    {item.paragraph && (
                        <div className='tw-grow tw-flex tw-flex-col tw-justify-center tw-items-center'>
                            <p className="mb-0 mt-3" style={{ fontSize: '14px' }}>
                                {item.paragraph}
                            </p>
                        </div>
                    )}
                    {item.linkUrl && (
                        <p className="mb-0 mt-3" style={{ fontSize: '14px' }}>
                            <a className={styles.productLink} href={item.linkUrl}>{item.linkName}</a>
                        </p>
                    )}

                </div>
            </div>
        </div >
    )
}


export default CarouselCard