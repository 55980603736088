import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getContactUsContent } from '../../actions/pagesActions'
import ContentBlockPage from '../layout/ContentBlockPage'
import Spinner from '../common/Spinner'
import BannerFlex from '../layout/BannerFlex';
import ImageTextBlock from '../layout/PLM/Comps/ImageTextBlock';
import TextFieldGroup from '../common/TextFieldGroup';
import Select from '../common/Select';

class ContactUsScreen extends Component {
    componentDidMount() {
        if (Object.keys(this.props.pages.contactUsContent).length === 0 && this.props.pages.contactUsContentLoading === false) {
            this.props.getContactUsContent()
        }
    }
    render() {
        const { contactUsContentLoading, contactUsContent } = this.props.pages
        console.log("🚀 ~ file: ContactUsScreen.js:15 ~ ContactUsScreen ~ render ~ contactUsContent:", contactUsContent)



        return contactUsContentLoading ? <Spinner fullScreen color="yellow" large /> : <ContentBlockPage content={contactUsContent} />
    }
}
const mapStateToProps = state => ({
    pages: state.pages
})
export default connect(mapStateToProps, { getContactUsContent })(ContactUsScreen);