import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import Carousel from '../common/Carousel'
import DivFade from './DivFade'

class ProductCarousel2 extends Component {

    render() {
        const { productsLoading, products } = this.props.products
        let productRange = []
        if (this.props.range === "coming soon") {
            productRange = products.filter(p => (p.range === this.props.range || p.tags.includes(this.props.range)))
        } else if (this.props.range === "my products") {
            productRange = products.filter(p => (p.purchased))
        } else if (this.props.range === "active") {
            productRange = products.filter(p => !p.comingSoon)
        } else {
            productRange = products.filter(p => (!p.tags.includes("coming soon") && (p.range === this.props.range || p.tags.includes(this.props.range))))
        }

        if (productRange.length === 0 && productsLoading === false) {

            return (
                <div className="text-center">
                    <h6 className="text-muted my-5">Nothing here yet!</h6>
                </div>
            )
        }
        return (
            <DivFade>
                <Carousel
                    backgroundColour={this.props.backgroundColour}
                    header={this.props.title}
                    loading={productsLoading}
                    cardColor='pink'
                    height='290px'
                    titleHeight='50px'
                    paragraphHeight='135px'
                    items={
                        productRange.map(p => ({
                            key: p.name,
                            paragraph: p.subtitle,
                            iconUrl: p.iconUrl,
                            title: p.title,
                            image: p.image,
                            linkUrl: p.productLink,
                            linkName: `View ${p.title}`,
                            discountPercentage: p.tags.includes('15% off') && 15,
                            navigateFunction: () => this.props.history.push(p.productLink)
                        }))
                    }
                />
            </DivFade>

        )
    }
}


const mapStateToProps = state => ({
    products: state.products,
})
export default withRouter(connect(mapStateToProps, {})(ProductCarousel2));