import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getPageContent } from '../../actions/pagesActions'
import ContentBlockPage from '../layout/ContentBlockPage'
import Spinner from '../common/Spinner'
import Error404Screen from '../errors/Error404Screen'

class PageScreen extends Component {
    componentDidMount() {
        this.props.getPageContent(this.props.match.params.pageSlug)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.pageSlug !== this.props.match.params.pageSlug) {
            this.props.getPageContent(nextProps.match.params.pageSlug)
        }
    }

    render() {
        const { pageLoading, page } = this.props.pages
        if (pageLoading === false && Object.keys(page).length === 0) { return <Error404Screen /> }
        return pageLoading ? <Spinner fullScreen color="yellow" large /> : <ContentBlockPage content={page} />
    }
}
const mapStateToProps = state => ({
    pages: state.pages
})
export default connect(mapStateToProps, { getPageContent })(PageScreen);