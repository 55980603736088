import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ImageTextBlock from "./Comps/ImageTextBlock";
import Circles from "../../../img/circles.svg";
import OrangeChecklist from "./Comps/OrangeChecklist";
import Button from "../../common/Button";
import BottomBanner from "./Comps/BottomBanner";
import TopBannerPlain from "./Comps/TopBannerPlain";
import { WhoIsPlmFor } from "./Comps/WhoIsPlmFor";
import BannerFlex from "../BannerFlex";
import puColours from "../../../utils/puColours";
import plmLogo from "../../../img/plm-logo-no-bg.png";
import backgroundCircles from "../../../img/circles.svg";

class About extends Component {
  componentDidMount() {
    this.checkPurchasedAndRedirect();
  }
  componentDidUpdate() {
    this.checkPurchasedAndRedirect();
  }
  checkPurchasedAndRedirect = () => {
    const { product } = this.props.products;
    const { auth } = this.props;
    let launchUrl;
    if (product.productLink.length >= 4) {
      if (product.productLink.substring(0, 4) !== "/pro") {
        launchUrl = product.productLink;
      }
    }

    if (auth.isAuthenticated && product.purchased === true) {
      if (launchUrl) {
        this.props.history.push(launchUrl);
      } else if (product.showGetStartedTab === true) {
        this.props.history.push("/product/" + product.name + "/get-started");
      } else if (product.showElearningTab) {
        this.props.history.push("/product/" + product.name + "/course");
      } else if (product.showResourcesTab === true) {
        this.props.history.push("/product/" + product.name + "/resources");
      } else if (product.showDataTab === true) {
        this.props.history.push("/product/" + product.name + "/data");
      }
    }
  };

  render() {
    const bannerShapes = [
      {
        color: puColours.darkBlue,
        parentClassName: "position-absolute tw-group tw-w-[100px]",
        childClassName: "",
        top: "10%",
        left: "72%",
      },
      {
        color: puColours.darkOrange,
        parentClassName: "position-absolute tw-group tw-w-[40px]",
        childClassName: "",
        top: "75%",
        left: "20%",
      },
      {
        color: "white",
        parentClassName: "position-absolute tw-group tw-w-[200px]",
        childClassName:
          "group-hover:tw-drop-shadow-xl tw-transition-all tw-duration-500",
        top: "5%",
        left: "15%",
        content: <PlmLogoContent />,
      },
      {
        color: puColours.teal,
        parentClassName:
          "position-absolute tw-group tw-w-[142px] tw-animate-throb",
        childClassName:
          "group-hover:tw-shadow-xl tw-transition-all tw-duration-500",
        top: "-9%",
        left: "-16%",
        content: <OfferContent />,
      },
    ];
    const pageDescription =
      "This course presents a unique framework to deliver targeted lifestyle medicine interventions using the principles of personalised care.";
    return (
      <>
        <Helmet>
          <title>
            Prescribing Lifestyle Medicine | Practice UNBOUND | About
          </title>
          <meta name="description" content={pageDescription} />
          <meta name="twitter:description" content={pageDescription} />
        </Helmet>

        <BannerFlex
          shapes={bannerShapes}
          backgroundCirclesObj={{
            width: "800px",
            left: "-410px",
            image: backgroundCircles,
            className: "tw-animate-pulse-slow",
          }}
          classNames={{ shapesSection: "d-none d-md-block" }}
        >
          <div className="py-5">
            <h3
              className="mb-3"
              style={{ fontWeight: "bold", textWrap: "balance" }}
            >
              What is Prescribing Lifestyle Medicine and who is it for?
            </h3>
            <p>
              This course presents a unique framework to deliver targeted
              lifestyle medicine interventions using the principles of
              personalised care.
            </p>
            <p className="mb-4">
              You will learn fundamental principles but also master how to
              implement Prescribing Lifestyle Medicine and deliver effective
              lifestyle interventions for your patients and service users.
            </p>
            <p className="mb-4">
              PLM Online is an approved basic RCGP course for GPs to work in an{" "}
              <span>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.rcgp.org.uk/your-career/gp-extended-roles/Lifestyle-medicine-framework-develop-maintain#Qualifications-required-to-be-a-GPwER-in-lifestyle"
                >
                  extended role
                </a>
              </span>{" "}
              in Lifestyle Medicine. Learners can go on to achieve a Certificate
              in Lifestyle Medicine demonstrating your commitment in practice
              and receive post-nominals, CertLM.
            </p>            
            <Button
              className="mt-4"
              colour="orange"
              link={"/product/prescribinglifestylemedicine/enrol-plm"}
              large
              inline
            >
              enrol now
            </Button>
          </div>
        </BannerFlex>

        {/* <TopBannerPlain isAboutPage>
                <div className='py-5'>
                    <h3 className="mb-3" style={{ fontWeight:'bold', textWrap:'balance' }}>What is Prescribing Lifestyle Medicine and who is it for?</h3>
                    <p>
                        This course presents a unique framework to deliver targeted lifestyle medicine interventions using the principles of personalised care.
                    </p>
                    <p className='mb-4'>
                        You will learn fundamental principles but also master how to implement Prescribing Lifestyle Medicine and deliver effective lifestyle interventions for your patients and service users.
                    </p>
                    <Button className="mt-4" colour="orange" link={'/product/prescribinglifestylemedicine/enrol-plm'} large inline >
                        enrol now
                    </Button>
                </div>
            </TopBannerPlain> */}

        <ImageTextBlock
          // contentBackgroundColor='rgb(25, 57, 88)' //blue
          contentBackgroundColor="#EB6915" //orange
          contentBackgroundSize="contain"
          imageUrl="https://practiceunbound.blob.core.windows.net/stock-images/AboutPage_TheCourseBlock_shutterstock_1323802892-min.jpg"
          contentSide="right"
        >
          <div
            className="text-white px-5"
            style={{ paddingTop: "92px", paddingBottom: "92px" }}
          >
            <h3 className="mb-4" style={{ fontWeight: "bold" }}>
              The Course
            </h3>
            <p className="mb-4">
              We have designed a comprehensive course that shares the research,
              evidence, science and strategies that will help you deliver
              results that will benefit both practitioner and patient in your
              field of practice.
            </p>
            <p style={{ fontWeight: "bold" }} className="mb-4">
              Content includes:
            </p>
            <ul className="raleway">
              <li>
                4 eLearning modules that will teach you how to implement a
                lifestyle medicine approach in a practical way. You will learn
                what lifestyle medicine is, what the benefits are and how to use
                The Personal Framework.
              </li>
              <li>
                We introduce you to real life case studies and demonstrate how
                to incorporate lifestyle medicine in your daily practice
              </li>
              <li>
                eLearning includes video and audio content and transcripts
              </li>
              <li>
                Throughout the e-learning you will be directed to write down
                your thoughts, reflections or learning points.
              </li>
              <li>
                Course completion enables CPD points to be claimed during the
                RCGP appraisal and revalidation processes.
              </li>
            </ul>
          </div>
        </ImageTextBlock>

        <ImageTextBlock
          contentBackgroundColor="white"
          contentBackgroundImage={Circles}
          contentBackgroundSize="cover"
          contentBackgroundPosition="bottom left"
          contentBackgroundRight="-60%"
          contentBackgroundTop="-30%"
          imageUrl="https://practiceunbound.blob.core.windows.net/stock-images/AboutPage_AlsoIncludesBlock_shutterstock_1477336916-min.jpg"
          contentSide="left"
        >
          <div
            className="px-5"
            style={{ paddingTop: "92px", paddingBottom: "92px" }}
          >
            <p style={{ fontWeight: "bold" }}>The course also includes:</p>

            <OrangeChecklist
              className="mb-4"
              ticks={[
                "Self-assessment which allows the user to track confidence, opportunity, motivation, and behaviours in relation to PLM are changing over time.",
                "Community access: our community gives you access to like-minded peers who are implementing PLM in their personal practice.",
                "Resources: practitioner resources, patient resources, and further scientific reading, all available as downloadable documents to support you in your implementation, as well as a Video and Audio Library. There are also additional resources to help you dive deeper into lifestyle medicine.",
              ]}
            />
          </div>
        </ImageTextBlock>

        {/* <ImageTextBlock
                contentBackgroundColor='rgb(243,243,243)'
                imageUrl='https://practiceunbound.blob.core.windows.net/stock-images/AboutPage_WhoIsPLMForBlock_shutterstock_1477336916-min.jpg'
                imageBackgroundPosition='left'
                contentSide='right'>
                <div className='px-5' style={{ paddingTop: '92px', paddingBottom: '92px' }}>
                    <h3 style={{ fontWeight: 'bold' }}>Who is Prescribing Lifestyle Medicine for?</h3>
                    <p>Prescribing Lifestyle medicine is aimed at clinicians and specialist non clinicians including:</p>
                    <OrangeChecklist className="mb-4" 
                        ticks={[
                            'Doctors',
                            'Nurses',
                            'Pharmacicts',
                            'Health Coaches',
                            'Dentists',
                            'ARRS Roles',
                            'Allied Health Professionals',
                            'Other Health & Care Professions',
                            'Medical Students and Medical Schools'
                        ]}
                    />
                    <Button link="/product/prescribinglifestylemedicine/enrol-plm" className="mr-3 mb-3" inline colour={'hollow-orange'} >Enrol Now</Button>
                    <Button link="/product/prescribinglifestylemedicine/organisations-plm" className="mr-3 mb-3" inline colour={'hollow-orange'} >For Organisations</Button>
                    <Button link="https://www.practiceunbound.org.uk/plm-bursary" className="mr-3 mb-3" inline colour={'hollow-orange'} >student bursaries</Button>
                </div>
            </ImageTextBlock> */}
        <WhoIsPlmFor />

        <BottomBanner
          header="Not a Clinician, or a Specialist Clinician?"
          iconHoverColour="#034F52"
        >
          <p style={{ fontSize: "18px" }}>
            If you are not a Clinician, or a Specialist Clinician, but feel your
            role is still applicable, please
            <Link style={{ color: "white" }} to="/contact-us">
              {" "}
              contact the team
            </Link>
          </p>
        </BottomBanner>
      </>
    );
  }
}

const OfferContent = () => (
  <>
    <Link
      to="/product/prescribinglifestylemedicine/enrol-plm"
      style={{
        textAlign: "center",
        color: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        textDecoration: "none",
      }}
    >
      <div style={{ fontSize: "10px", lineHeight: "10px" }}>ONLY</div>
      <div style={{ fontWeight: "bold", fontSize: "30px", lineHeight: "26px" }}>
        £395
      </div>
      <div
        style={{ fontSize: "10px", lineHeight: "10px", marginBottom: "3px" }}
      >
        +VAT
      </div>
      <div
        className="tw-font-normal"
        style={{ fontSize: "14px", lineHeight: "14px", textWrap: "balance" }}
      >
        for 12 months subscription <br />
        to the course and
        <br />
        materials
      </div>
    </Link>
  </>
);

const PlmLogoContent = () => (
  <Link to="/product/prescribinglifestylemedicine/enrol-plm">
    <img
      alt="PLM logo"
      className="img-fluid tw-rounded-full tw-shadow-md"
      src={plmLogo}
    />
  </Link>
);

const mapStateToProps = (state) => ({
  products: state.products,
  auth: state.auth,
});
export default connect(mapStateToProps, {})(About);
