import React from 'react'
import DivFade from './DivFade'

export default function Videos() {

    const content = {
        id: "plm-intro-video",
        header: "Find out more",
        description: "Hear from our course creators Dr Ayan Panja and Dr Rangan Chatterjee how Prescribing Lifestyle Medicine can help you and your patients."
    }
    const pageContent = content


    return (
        <DivFade className="container" style={{ paddingTop: '80px', paddingBottom: '80px' }}>
            <div className="row justify-content-center text-center" id={pageContent.id}>
                <div className="col-12 col-md-7">
                    <h2 className="mb-3" >
                        <strong>
                            {pageContent.header}
                        </strong>
                    </h2>
                    <p>{pageContent.description}</p>
                </div>
            </div>
            <div className='row justify-content-around'>
                <div className="col-md-6">
                    <div className="video-wrap">
                        <iframe
                            src="https://player.vimeo.com/video/558190748"
                            frameBorder="0"
                            allow="autoplay; fullscreen"
                            allowFullScreen
                            title="Welcome to Prescribing Lifestyle Medicine"
                        ></iframe>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="video-wrap">
                        <iframe
                            src="https://player.vimeo.com/video/512901038"
                            frameBorder="0"
                            allow="autoplay; fullscreen"
                            allowFullScreen
                            title="What is Prescribing Lifestyle Medicine"
                        ></iframe>
                    </div>
                </div>
            </div>
        </DivFade>
    )
}
