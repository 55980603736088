import React, { Component } from "react";
import Card from "../Card";
import Slider from "../../common/Slider";
import Button from "../../common/Button";

export default class PathHomeB3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slide1value: 4,
      slide2value: 60,
      slide3value: 80,
      showResults: false,
      recalc: false,
      
      hoursPerGpPerMonth: 0,
      hoursPerMonthByPractise: 0,
      savingsPerYear: 0,
    };
  }
  componentDidUpdate(prevProps, nextState) {
    if (
      nextState.slide1value === this.state.slide1value &&
      nextState.slide2value === this.state.slide2value &&
      nextState.slide3value === this.state.slide3value &&
      nextState.showResults === this.state.showResults
    )
      return;
    const wholeTime = +nextState.slide1value; // X
    const timePerDayProcessing = +(nextState.slide2value / 60); // Y
    const testDelegationPercentage = +(nextState.slide3value / 100); // Z

    const dayPerMonth = 18; // A
    const monthlySalary = 10000; // B
    const monthsInYear = 12; // C
    const workingHours = 134; // D
    
    const hoursPerGpPerMonth =
    timePerDayProcessing * testDelegationPercentage * dayPerMonth; // H
    const hoursPerMonthByPractise = wholeTime * hoursPerGpPerMonth; // J
    const savingsPerYear =
      hoursPerMonthByPractise * (monthlySalary / workingHours) * monthsInYear; // K
      this.setState({
        hoursPerGpPerMonth: hoursPerGpPerMonth,
        hoursPerMonthByPractise: hoursPerMonthByPractise,
        savingsPerYear: savingsPerYear,
    });
  }
  formatSlide2(time) {
    return !(time % 60) ? time / 60 + " hr" : time + " min";
  }
  updateValue(key, val) {
    this.setState({ [key]: val });
  }
  formatter(num) {
    const f = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "GBP",
      minimumFractionDigits: 2,
    });
    return f.format(num);
  }
  
  render() {
    return (
      <section className="calculate-block">
        <div className={`text-center`} style={{color:this.props.textColour ? this.props.textColour : ''}}>
          <h2 className="mb-5 ">
            <strong>Calculate the benefits</strong>
          </h2>
          <p className="mb-5 " style={{fontWeight:'normal'}}>
            Work out for yourself how much time you could save your practice GPs
            using Pathology Go.
          </p>

          {!this.state.showResults && (
            <div className="calculate-block__cards fi">
              <Card className="text-center">
                <p className="tw-text-black">Number of whole time equivalent GPs in your practice</p>
                <Slider
                  name="slide1value"
                  change={this.updateValue.bind(this)}
                  value={this.state.slide1value}
                  color="orange"
                  min="1"
                  max="10"
                />
                <input
                  type="number"
                  className="calculate-block__output"
                  value={this.state.slide1value}
                  onChange={(e) => {}}
                />
              </Card>
              <Card className="text-center">
                <p className="tw-text-black">Average time per day GP spends processing results</p>
                <Slider
                  name="slide2value"
                  change={this.updateValue.bind(this)}
                  value={this.state.slide2value}
                  color="purple"
                  min="45"
                  max="90"
                  step="5"
                />
                <input
                  type="text"
                  value={this.formatSlide2(this.state.slide2value)}
                  className="calculate-block__output"
                  onChange={(e) => {}}
                />
              </Card>
              <Card className="text-center">
                <p className="tw-text-black">Percentage of tests that can be safely delegated?</p>
                <Slider
                  name="slide3value"
                  min="60"
                  max="80"
                  value={this.state.slide3value}
                  change={this.updateValue.bind(this)}
                  color="green"
                />
                <input
                  type="text"
                  value={this.state.slide3value}
                  className="calculate-block__output"
                  onChange={(e) => {}}
                />
              </Card>
            </div>
          )}
          {this.state.showResults && (
            <div className="tw-pb-7">
              <Card className="text-center fi mb-5">
                <div className=" container-medium py-3">
                  <p style={{ fontSize: "1.25em" }} className="tw-text-black">
                    Your practice could save{" "}
                    {Math.trunc(this.state.hoursPerMonthByPractise)} hours per
                    month, equivalent to{" "}
                    {Math.trunc(this.state.hoursPerGpPerMonth)} hours per GP. This
                    gives you a cost saving of{" "}
                    {this.formatter(this.state.savingsPerYear)} per year.
                  </p>
                  <p className="mb-0 tw-text-black">
                    Our experience is that an average practices saves £7,250 per
                    year per GP once Pathology Go is implemented.
                  </p>
                </div>
              </Card>
            </div>
          )}
          <Button
            colour={this.props.buttonColour ? this.props.buttonColour : 'none-outline'}
            large
            className="fi"
            onClick={(e) => {
              e.preventDefault();
              this.setState({
                showResults: !this.state.showResults,
                recalc: !this.state.recalc,
              });
            }}
          >
            {!this.state.recalc ? (
              <span>CALCULATE</span>
            ) : (
              <span>RECALCULATE</span>
            )}
          </Button>
        </div>
      </section>
    );
  }
}
