import React from 'react'
import TopBannerPlain from '../PLM/Comps/TopBannerPlain'
import ImageTextBlock from '../PLM/Comps/ImageTextBlock'
import greenCircles from '../../../img/leaf-green-bubbles.svg'
import greyCircles from '../../../img/slightly-dark-grey-bubbles-right.svg'
import Carousel from '../../common/Carousel'
import CircleTextBlock from '../PLM/Comps/CircleTextBlock.js'
import styles from './SystemFeatures.module.css'
import DivFade from '../../layout/DivFade'
import BottomBanner from '../PLM/Comps/BottomBanner'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import gpbillingLogo from '../../../img/03 Top Billable GP Services 2020/gp_billing_logo_circle.png'
import pay360Logo from '../../../img/pay360-logo.png'
import clinSystIntImg from '../../../img/clinical_system_integration_min.jpg'
import recAndReceipts from '../../../img/records_and_receipts_min.jpg'

export default function SystemFeatures() {
  return (
    <DivFade>

      <TopBannerPlain 
        backgroundCircles
        circleColours={{
          one:"#60AE4C",
          two:"#113B5B",
          three:"#2372B5",
        }}  
        circleLogo={gpbillingLogo} 
      >
        <h3 className='font-weight-bold text-capitalize'>system features:</h3>
        <div className='py-2'/>
        <h5 className='font-weight-bold text-capitalize'>pricing and templates</h5>
        <div className='py-2'/>
        <ul className='leaf-green-tick-list'>
          <li><p><span className='font-weight-bold'>A pre-defined NHS fees template</span> allows the practice to amend terminology, set the fees and choose whether to inlude VAT</p></li>
          <li><p><span className='font-weight-bold'>Prices can be amended on individual bills and accounts</span> so a service can be applied as one off</p></li>
          <li><p><span className='font-weight-bold'>It is possible to add public and private notes</span> on a bill for future references</p></li>
        </ul>
      </TopBannerPlain>

      <ImageTextBlock
          imageUrl={clinSystIntImg}
          contentSide="right"
          contentBackgroundColor="#193958"
          contentBackgroundImage={greenCircles}
          contentBackgroundSize='40%'
          contentBackgroundPosition='top right'
          contentBackgroundRight='-10%'
          contentBackgroundTop='-20%'
      >
        <div className='text-white p-5'>
          <h3 className='font-weight-bold text-capitalize'>clinical system integration</h3>
          <div className='py-2'/>
          <ul className='leaf-green-tick-list'>
            <li><p><span className='font-weight-bold'>Through integration with clinical systems,</span> the patient account is automatically created when a patient is synchronised</p></li>
            <li><p><span className='font-weight-bold'>Bills can be pre-populated with services provided to the patient</span> using the clinical codes on the consultation record in a matter of seconds</p></li>
            <li><p><span className='font-weight-bold'>A notification is recorded on the patient care record</span> confirming when bills have been raised and payments are received. This is customisable for each service offered by the practice</p></li>
          </ul>
        </div>
      </ImageTextBlock>

      <ImageTextBlock
          imageUrl={recAndReceipts}
          contentSide="left"
          contentBackgroundColor="#F3F3F3"
          contentBackgroundImage={greyCircles}
          contentBackgroundSize='30%'
          contentBackgroundPosition='top left'
          contentBackgroundRight='5%'
          contentBackgroundTop='-20%'
      >
        <div className='py-5 pr-5'>
          <h3 className='font-weight-bold text-capitalize'>records and receipts</h3>
          <div className='py-2'/>
          <ul className='leaf-green-tick-list'>
            <li><p><span className='font-weight-bold'>Invoices can be printed or emailed as a PDF</span> to the patient or company via Microsoft Outlook, saving the practice time and money.</p></li>
            <li><p><span className='font-weight-bold'>Patient details can be withheld, </span>and a purchase order number added if third party company requests this.</p></li>
            <li><p><span className='font-weight-bold'>There is a full transactional history</span> for each patient, third party and all other businesses.</p></li>
          </ul>
        </div>
      </ImageTextBlock>

      <Carousel                
        gpBillingReports={true}
        backgroundColour='white'
        header='Dashboards'
        headerMarginBottom='5'
        centreHeader={true}
        loading={false}
        cardColor='pink'
        height='290px'
        titleHeight='50px'
        paragraphHeight='135px'
        buttonColour='leafGreen'
        items={dashboardCarouselItems}
      />
      
      <DivFade style={{backgroundColor: '#193958'}}>
        <div className='container pt-5 pb-5 px-3'>

          <div className='d-block d-md-none'>
            <div className='text-white row'>
                <h3 className='col font-weight-bold text-capitalize mb-4 text-center'>virtual terminal</h3>
                <p className='mb-4 px-2'>GP-Billing can integrate with Capita Pay360 enabling a virtual terminal to be displayed when taking card payments. Payments are processed and authorised through a merchant account reducing the need for PDQ machines</p>
            </div> 
            <div className='d-flex flex-row justify-content-center'>
              <img src={pay360Logo} style={{width: '150px'}}/>
            </div>
          </div>

          <div className='d-none d-md-block'>
            <div className='text-white row'>
              <div className='col-12 col-md-6'>
                  <h3 className='font-weight-bold text-capitalize mb-4 text-center text-md-left'>virtual terminal</h3>
                  <p className='mb-4'>GP-Billing can integrate with Capita Pay360 enabling a virtual terminal to be displayed when taking card payments. Payments are processed and authorised through a merchant account reducing the need for PDQ machines</p>
              </div> 
              <div className='col-12 col-md-6 d-flex flex-row justify-content-center'>
                <div className='position-md-absolute'>
                  <img className={`position-absolute ${styles.circle}`} src={pay360Logo}/>
                </div>
              </div>
            </div>
          </div>

        </div>
      </DivFade>

      <BottomBanner backgroundColour="leafGreen" header="Questions?">
        {/* <p style={{ fontSize: '18px' }}>Whether you are interested in one of our products or would like to know more about working with Practice Unbound, please give us a call on 0300 303 8145 or email <a style={{ fontWeight: 'bold', color: 'white' }} href="mailto:info@practiceunbound.org.uk">info@practiceunbound.org.uk</a></p> */}
        <p style={{ fontSize: '18px' }}><Link style={{ color: 'white' }} to="/contact-us">Contact the team</Link> or call us on <strong>0300 303 8145</strong></p>
      </BottomBanner>

    </DivFade>
  )
}

const dashboardCarouselItems = [
  {
    key: 1,
    title: 'The Billing Dashboard',
    subtitle: '',
    image: 'https://practiceunbound.blob.core.windows.net/stock-images/gp-billing-home-dashboard-min.png',
    paragraph: 'The home dashboard has quick access views and is the most used dashboard',
    listItems: ['view draft bills', 'view outstanding bills']
    // listItems: 'view draft bills'
  },
  {
    key: 2,
    title: 'Administration',
    subtitle: '',
    image: 'https://practiceunbound.blob.core.windows.net/stock-images/gp-billing-reconciliation-dashboard-min.png',
    paragraph: 'The administration dashboard allows the software to be tailored to each practice',
    listItems: [
      'Add branch sites', 
      'Customise practice headers with logos and specific payment information',
      'Manage users and permissions for access profiles'
    ]
  },
  {
    key: 3,
    title: 'Reporting',
    subtitle: '',
    image: 'https://practiceunbound.blob.core.windows.net/stock-images/gp-billing-report-dashboard-min.png',
    paragraph: 'The reporting dashboard provides a set of reports that help to ensure and provide the following:',
    listItems: [
      'Accuracy of the information recorded',
      'Analysis of income sources',
      'Manage aged debtors and report on accounting',
      'VAT information',
      'Reports can be filtered, sorted and exported to Microsoft Excel for further analysis'
    ]
  },
]
