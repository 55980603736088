import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getPrivacyNoticeContent } from '../../actions/pagesActions'
import ContentBlockPage from '../layout/ContentBlockPage'
import Spinner from '../common/Spinner'


class PrivacyNoticeScreen extends Component {
    componentDidMount() {
        if (Object.keys(this.props.pages.privacyNoticeContent).length === 0 && this.props.pages.privacyNoticeContentLoading === false) {
            this.props.getPrivacyNoticeContent()
        }
    }

    render() {
        const { privacyNoticeContentLoading, privacyNoticeContent } = this.props.pages

        return privacyNoticeContentLoading ? (
            <Spinner fullScreen color="yellow" large />
        ) : <ContentBlockPage content={privacyNoticeContent} />

    }
}
const mapStateToProps = state => ({
    pages: state.pages
})
export default connect(mapStateToProps, { getPrivacyNoticeContent })(PrivacyNoticeScreen);