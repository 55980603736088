import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import ImageTextBlock from './Comps/ImageTextBlock'
import OrangeChecklist from './Comps/OrangeChecklist'
import BottomBanner from './Comps/BottomBanner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TopBannerPlain from './Comps/TopBannerPlain'
import { Link } from 'react-router-dom'
import DivFade from '../DivFade'
import BannerFlex from '../BannerFlex';
import plmLogo from '../../../img/plm-logo-no-bg.png'
import puColours from '../../../utils/puColours';

class Organisations extends Component {
    componentDidMount() {
        this.checkPurchasedAndRedirect()
    }
    componentDidUpdate() {
        this.checkPurchasedAndRedirect()
    }
    checkPurchasedAndRedirect = () => {
        const { product } = this.props.products
        const { auth } = this.props
        let launchUrl
        if (product.productLink.length >= 4) {
            if (product.productLink.substring(0, 4) !== "/pro") {
                launchUrl = product.productLink
            }
        }

        if (auth.isAuthenticated && product.purchased === true) {

            if (launchUrl) {
                this.props.history.push(launchUrl)
            } else if (product.showGetStartedTab === true) {
                this.props.history.push('/product/' + product.name + '/get-started')
            } else if (product.showElearningTab) {
                this.props.history.push('/product/' + product.name + '/course')
            } else if (product.showResourcesTab === true) {
                this.props.history.push('/product/' + product.name + '/resources')
            } else if (product.showDataTab === true) {
                this.props.history.push('/product/' + product.name + '/data')

            }
        }

    }
    render() {
        const pageDescription = 'This course presents a unique framework to deliver targeted lifestyle medicine interventions using the principles of personalised care.'
        return (
            <>
                <Helmet>
                    <title>Prescribing Lifestyle Medicine | Practice UNBOUND | For Organisations</title>
                    <meta name="description" content={pageDescription} />
                    <meta name="twitter:description" content={pageDescription} />
                </Helmet>

                {/* <TopBannerPlain>
                    <h3 className='mb-3' style={{ fontWeight: 'bold' }}>Information for organisations</h3>
                    <p>Are you an organisation looking to purchase the PLM online course and/or the Certificate in Lifestyle Medicine for multiple users?</p>
                    <p>
                        Our prescribing lifestyle medicine course is applicable to a wide range of healthcare roles and can offer organisations the opportunity to improve health and wellbeing of citizens and deliver personalised care.
                    </p>
                    <p>For information about group discounts, please contact us at <a href="mailto:info@practiceunbound.org.uk">info@practiceunbound.org.uk</a></p>
                </TopBannerPlain> */}

                <BannerFlex
                    shapes={bannerShapes}
                    classNames={{shapesSection: 'd-none d-md-block', contentSection: 'mb-3'}}
                >
                    <h3 className='mb-3' style={{ fontWeight: 'bold' }}>Information for organisations</h3>
                    <p>Are you an organisation looking to purchase the PLM online course and/or the Certificate in Lifestyle Medicine for multiple users?</p>
                    <p>Our prescribing lifestyle medicine course is applicable to a wide range of healthcare roles and can offer organisations the opportunity to improve health and wellbeing of citizens and deliver personalised care.</p>
                    <p>For information about group discounts, please contact us at <a href="mailto:info@practiceunbound.org.uk">info@practiceunbound.org.uk</a></p>
                </BannerFlex>

                <ImageTextBlock
                    contentBackgroundColor='rgb(25, 57, 88)'
                    contentBackgroundSize='contain'
                    imageUrl='https://practiceunbound.blob.core.windows.net/stock-images/OrganisationPage_shutterstock_1477336916-min.jpg'
                    contentSide='right'>
                    <div className='text-white px-5' style={{ paddingTop: '80px', paddingBottom: '80px' }}>
                        <h3 className="mb-5" style={{ fontWeight: 'bold' }}>We are already working with:</h3>
                        <OrangeChecklist ticks={[
                            'GP Practices',
                            'Primary Care Networks',
                            'Clinical Commissioning Groups',
                            'Private Healthcare Companies',
                            'Borough Councils',
                            'Foundation Trusts',
                            'Universities',
                            'Associations',
                        ]}
                        />

                    </div>
                </ImageTextBlock>
                <DivFade className="container" style={{ paddingTop: '72px', paddingBottom: '72px' }}>
                    <div className="row justify-content-center">
                        <div className="col-md-9">
                            <blockquote style={{ marginTop: '12px' }} className="blockquote mb-4">
                                <p style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                    <FontAwesomeIcon style={{ color: 'rgb(25, 57, 88)' }} className="mr-3 mb-2" size="3x" icon="quote-left" role="presentation" />
                                    Myself and our coaches have all completed the course and we all thoroughly enjoyed it! The Prescribing Lifestyle Medicine course has been invaluable to myself and the team. It covers all the common symptoms/conditions that residents are coming to us for support with such as low mood, anxiety, excess weight and long-term health conditions. I really like the strength-based tone of the programme which focuses on improving health and wellbeing rather than treating illness and The Personal Framework is a really effective tool in creating person centred lifestyle prescriptions for residents. Would highly recommend!”
                                </p>
                                <div style={{ fontWeight: 'bold' }} className="blockquote-footer text-right">Hannah Short | Public Health Improvement Officer, Doncaster Borough Council</div>
                            </blockquote>
                        </div>
                    </div>

                </DivFade>
                <BottomBanner header="Have more questions? Talk to us" iconHoverColour="#034F52">
                    {/* <p style={{ fontSize: '18px' }}>Whether you are interested in one of our products or would like to know more about working with Practice Unbound, please give us a call on 0300 303 8145 or email <a style={{ fontWeight: 'bold', color: 'white' }} href="mailto:info@practiceunbound.org.uk">info@practiceunbound.org.uk</a></p> */}
                    <p style={{ fontSize: '18px' }}>Whether you are interested in one of our products or would like to know more about working with Practice Unbound, please<Link style={{ color: 'white' }} to="/contact-us"> contact the team</Link></p>
                </BottomBanner>
            </>
        )
    }
}

const PlmLogoContent = () => <Link to="/product/prescribinglifestylemedicine/enrol-plm"><img alt="PLM logo" className="img-fluid tw-rounded-full tw-shadow-md" src={plmLogo} /></Link>

const bannerShapes = [
    {
        color: puColours.darkBlue,
        parentClassName: 'position-absolute tw-group tw-w-[100px] tw-animate-throb',
        childClassName: '',  
        top: '10%',
        left: '72%',
    },
    {
        color: puColours.darkOrange,
        parentClassName: 'position-absolute tw-group tw-w-[40px] tw-animate-throb',
        childClassName: '',  
        top: '75%',
        left: '20%',
    },
    {
        color: puColours.teal,
        parentClassName: 'position-absolute tw-group tw-w-[55px] tw-animate-throb',
        childClassName: '',  
        top: '-5%',
        left: '5%',
    },
    {
        color: 'white',
        parentClassName: 'position-absolute tw-group tw-w-[200px]',
        childClassName: 'group-hover:tw-drop-shadow-xl tw-transition-all tw-duration-500',  
        top: '5%',
        left: '15%',
        content: <PlmLogoContent />
    },
]

const mapStateToProps = state => ({
    products: state.products,
    auth: state.auth
})
export default connect(mapStateToProps, {})(Organisations);