import React, { Component } from 'react'
import { connect } from 'react-redux';
import { billsbyODSId } from '../../config/parameters'
import styles from '../common/Button.module.css'
import classnames from 'classnames'


class BillsbyCheckout extends Component {
    constructor() {
        super()
        this.state = {
            planPostGoLive: {},
            selectedCycle: ''
        }
    }
    componentDidMount() {
        this.updateBillsby()
    }
    componentDidUpdate(prevProps) {
        this.updateBillsby()
        // if (prevProps.locked && !this.props.locked) {
        //     document.getElementById("bb-checkout").click();
        // }
    }

    updateBillsby() {
        if (!window.billsbyData) window.billsbyData = {}
        let customFields = []
        if (this.props.purchase.organisation) {
            customFields.push({
                customFieldId: billsbyODSId,//ODS Code
                value: this.props.purchase.organisation.code
            })
        }
        window.billsbyData.customFields = customFields
        if (window.scanDomBillsby) {
            window.scanDomBillsby();
        }
    }

    render() {
        const { product } = this.props.purchase
        if (!product.billsby) return <div>Billsby Error</div>
        if (product.billsby.length === 0) return <div>No Billsby Plans</div>

        let alreadyPurchased = false
        let boughtProduct = this.props.auth.products.filter(p => p.product === product.name)
        if (boughtProduct.length > 0) {
            if (this.props.purchase.organisation) {
                if (boughtProduct[0].organisations.includes(this.props.purchase.organisation.code)) {
                    alreadyPurchased = true
                }
            } else {
                alreadyPurchased = true
            }
        }

        return (
            <div className="text-center">
                <button
                    id="bb-checkout"
                    disabled={this.props.locked || alreadyPurchased}
                    className={
                        classnames(styles.btn,
                            styles.colorStandard,
                            styles.sizeXL,
                            { [styles.disabled]: this.props.locked || alreadyPurchased }
                        )}
                    data-billsby-redirect={`/purchase/${product.name}/bb/thankyou`}
                    data-billsby-type="checkout"
                    data-billsby-product={product.billsbyProductId}
                >
                    Start Checkout
        </button>
                {alreadyPurchased && !this.props.locked && (
                    <div className="text-danger mt-3">According to our records you have already purchased this product. Try refreshing your page and looking under the 'my products' menu in the top right - otherwise please contact our support team.</div>
                )}
            </div>

        )
    }
}

const mapStateToProps = state => ({
    purchase: state.purchase,
    auth: state.auth
})
export default connect(mapStateToProps, {})(BillsbyCheckout);