import React from 'react'
import { useState } from 'react'
import TextFieldGroup from '../../../common/TextFieldGroup'
import Select from '../../../common/Select'
import RadioButton from '../../../common/RadioButton'
import Button from '../../../common/Button'
import PUPanel from '../../../layout/PUPanel'
import { animateScroll as scroll } from "react-scroll";
import styles from './PathologyGo.module.css'

export default function PathGoAssessmentForm({ submitAssessment }) {

    const [inputData, setInputData] = useState({
        questionOne: {
            text: 'In the past year, how frequently have you done something that is relevant to this this particular survey?',
            answer: '',
            visualType: 'barGraph'
        },
        questionTwo: {
            text: 'On a scale of 1-10, considering your complete experience with our facilities, how likely would you be to recommend us to a colleague?',
            answer: '',
            visualType: ''
        },
        questionThree: {
            text: 'Do you prefer The Thing or The Really Good Thing?',
            answer: '',
            visualType: ''
        },
        questionFour: {
            text: 'How often did you receive conflicting information from different things to do with the professionals on a global scale?',
            answer: '',
            visualType: ''
        },
        questionFive: {
            text: 'Which one of these is your favourite Pokemon?',
            answer: '',
            visualType: ''
        },
        questionSix: {
            text: 'Overall, are you satisfied?',
            answer: null,
            visualType: ''
        },
    })

    const [validationErr, setValidationErr] = useState(false)

    // ------- Create array to assign form components name based on question number -------
    // ====================================================================================
    const questionNames = ['', ...Object.keys(inputData)]

    // ============ Submit form =============
    // ======================================
    const onSubmit = () => {
        const inputDataArr = Object.values(inputData)
        const answers = inputDataArr.map((answer, index) => ({
            questionNumber: index + 1,
            questionText: answer.text,
            answerValue: answer.answer
        }))

        const submit = () => {
            submitAssessment('pathologygo', {
                eventDate: new Date(),
                answers
            })
            scroll.scrollToTop(100)
        }

        const formIsComplete = answers.map(answer => answer.answerValue).every(item => item)

        !formIsComplete ? setValidationErr(true) : submit()

    }

    const sharedRadioProps = {
        buttons: true,
        boldLabel: true
    }

    return (
        <div>
            {/* <TransitionGroup>
                <CSSTransition
                    key="carousel"
                    classNames="slide-vertical"
                    // timeout={{ enter: 1000, exit: 1000 }}
                > */}

            <PUPanel title="Assessment Form Title">
                <div className={styles.formStyle}>

                    {/* -------------- Question 1 -------------- */}
                    {/* ======================================== */}
                    <section className="py-2 my-2">
                        <TextFieldGroup
                            name={inputData.questionOne.text}
                            labelMargin
                            paddingX
                            maxWidth='100px'
                            className
                        /*--questionText --*/ label={inputData.questionOne.text}
                            info="This is info text, this can be used to provide any further clarification about the question above. This paragraph is quite long just so I can test to see if it looks right on various different screen sizes."
                            placeHolder="Placeholder text"
                            type="number"
                            // value={inputData.questionOne.answer}
                            onChange={e => {
                                const { value } = e.target
                                setInputData(prevInputData => ({
                                    ...prevInputData,
                                    questionOne: {
                                        text: inputData.questionOne.text,
                                        answer: value
                                    }
                                }))
                            }}
                        />
                    </section>

                    {/* -------------- Question 2 -------------- */}
                    {/* ======================================== */}
                    <section className="px-3 py-2 my-2" /*style={{backgroundColor: "#f2f2f2"}}*/>
                        <Select
                            creatable
                            margin
                            labelMargin
                            className="mb-1"
                            name={questionNames[2]}
                            // label = {<p><strong>{inputData.questionTwo.text}</strong></p>}
                            label={inputData.questionTwo.text}
                            placeholder="Choose one"
                            onChange={(event) => {
                                const { value } = event
                                setInputData(prevInputData => ({
                                    ...prevInputData,
                                    questionTwo: {
                                        text: inputData.questionTwo.text,
                                        answer: value
                                    }
                                }))
                            }}
                            options={[
                                { label: '1 - No thank you!', value: '1' },
                                { label: '2', value: '2' },
                                { label: '3', value: '3' },
                                { label: '4', value: '4' },
                                { label: '5 - Maybe', value: '5' },
                                { label: '6', value: '6' },
                                { label: '7', value: '7' },
                                { label: '8', value: '8' },
                                { label: '9', value: '9' },
                                { label: '10 - Definitely!', value: '10' },
                            ]}
                        />
                    </section>

                    {/* -------------- Question 3 -------------- */}
                    {/* ======================================== */}
                    <section className="px-3 py-2 my-2">
                        <RadioButton
                            {...sharedRadioProps}
                            selectedValue={inputData.questionThree.answer}
                            name={questionNames[3]}
                            label={inputData.questionThree.text}
                            onChange={e => {
                                const { value } = e.target
                                setInputData(prevInputData => ({
                                    ...prevInputData, questionThree: {
                                        text: inputData.questionThree.text,
                                        answer: value
                                    }
                                }))
                            }}
                            // center
                            options={[
                                { label: 'The Thing', value: 'a' },
                                { label: 'The Really Good Thing', value: 'b' },
                            ]}
                        />
                    </section>

                    {/* -------------- Question 4 -------------- */}
                    {/* ======================================== */}
                    <section className="px-3 py-2 my-2">
                        <RadioButton
                            {...sharedRadioProps}
                            selectedValue={inputData.questionFour.answer}
                            name={questionNames[4]}
                            label={inputData.questionFour.text}
                            onChange={e => {
                                const { value } = e.target
                                setInputData(prevInputData => ({
                                    ...prevInputData, questionFour: {
                                        text: inputData.questionFour.text,
                                        answer: value
                                    }
                                }))
                            }}
                            // center
                            options={[
                                { label: 'Always', value: 'a' },
                                { label: 'Sometimes', value: 'b' },
                                { label: 'Never', value: 'c' },
                            ]}
                        />
                    </section>

                    {/* -------------- Question 5 -------------- */}
                    {/* ======================================== */}
                    <section className="px-3 py-2 my-2">
                        <RadioButton
                            {...sharedRadioProps}
                            selectedValue={inputData.questionFive.answer}
                            name={questionNames[5]}
                            label={inputData.questionFive.text}
                            onChange={e => {
                                const { value } = e.target
                                setInputData(prevInputData => ({
                                    ...prevInputData, questionFive: {
                                        text: inputData.questionFive.text,
                                        answer: value
                                    }
                                }))
                            }}
                            // center
                            options={[
                                { label: 'Tangela', value: 'a' },
                                { label: 'Espeon', value: 'b' },
                                { label: 'Tauros', value: 'c' },
                                { label: 'Phanpy', value: 'd' },
                                { label: 'Pichu', value: 'e' },
                                { label: 'Omastar', value: 'f' },
                                { label: 'Swinub', value: 'g' },
                                { label: 'Horsea', value: 'h' },
                            ]}
                        />
                    </section>

                    {/* -------------- Question 6 -------------- */}
                    {/* ======================================== */}
                    <section className="px-3 py-2 my-2">
                        <RadioButton
                            {...sharedRadioProps}
                            selectedValue={inputData.questionSix.answer}
                            name={questionNames[6]}
                            label={inputData.questionSix.text}
                            onChange={e => {
                                const { value } = e.target
                                setInputData(prevInputData => ({
                                    ...prevInputData, questionSix: {
                                        text: inputData.questionSix.text,
                                        answer: value
                                    }
                                }))
                            }}
                            // center
                            options={[
                                { label: 'Yes', value: true },
                                { label: 'No', value: false }
                            ]}
                        />
                    </section>

                    {/* -------------- Question 6 -------------- */}
                    {/* ======================================== */}
                    {/* <section className="px-3 pt-2 pb-4 my-2">
                    <p><strong>{`${inputData.questionSix.text}`}</strong></p>
                    <Checkbox          
                        label='Question Six Name'
                        icon
                        inline
                        name={questionNames[6]}
                        onChange={e => {
                            const {checked } = e.target
                            setInputData(prevInputData => ({...prevInputData, questionSix: {
                                text: inputData.questionSix.text,
                                answer: checked
                            }})) 
                        }}
                        value={inputData.questionSix.answer}
                    ></Checkbox>
                </section> */}

                </div>

            </PUPanel>
            <div>
                <div className='row justify-content-center' style={{ height: '2rem' }}>
                    <div className='col'>
                        {validationErr && <p style={{ color: 'red', textAlign: 'center' }}>Oops! Please answer all of the questions.</p>}
                    </div>
                </div>
                <Button
                    onClick={() => {
                        onSubmit()
                    }}
                    // onClick={() => setValidationErr(!validationErr)}
                    center
                    large
                >Submit</Button>
            </div>
            {/* </CSSTransition>
            </TransitionGroup> */}
        </div>
    )
}
