import React, { Component } from 'react'
import Background from '../../img/circles_bg.svg'
import queryString from 'query-string'
import Button from '../common/Button'


export default class CancelBillsbyScreen extends Component {
    componentDidMount() {
        this.openBB()
    }
    componentDidUpdate() {
        this.openBB()
    }

    openBB() {


        if (window.scanDomBillsby) {
            window.scanDomBillsby();
        }
        window.setTimeout(() => {
            if (this.refs.bbBtn) {
                this.refs.bbBtn.click()
            }
        }, 1000)

    }

    render() {
        const { cid, sid } = queryString.parse(this.props.location.search);

        return (
            <div style={{
                backgroundImage: 'url("' + Background + '")',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                overflow: 'hidden',
                backgroundPosition: 'center',

            }}>
                <div className="container" >
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-10">
                            <div style={{ height: '100vh' }} className="d-flex flex-column justify-content-center align-items-center">
                                <Button large icon="back" className="mb-4" onClick={
                                    () => { window.location.replace("/") }
                                }>Go Back</Button>
                                <button
                                    ref="bbBtn"
                                    className="d-block billsbyBtn"
                                    data-billsby-type="account"
                                    data-billsby-action="cancelplan"
                                    data-billsby-customer={cid}
                                    data-billsby-subscription={sid}
                                >Cancel Plan</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div >
        )
    }
}


