import React from 'react'
// import logo from "../../../../img/PLM-LIVE-CENTRAL.jpg";
// import logo from "../../../../img/PLM-LIVE-CENTRAL.png";
import logo from "../../../../img/logo_PLM3x.png";
import liveLogo from "../../../../img/PLM-live-logo-min.png";
// import liveLogo from "../../../../img/Group 11(1).png";
import backgroundCircles from '../../../../img/circles.svg'
import DivFade from '../../DivFade'
import CPDLogo from '../../../../img/CPDmember.png'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const Circle = (props) => {
    return (
        <div 
            style={{
                top: props.top,
                left: props.left,
                width: props.width,
                position: 'absolute'
            }}
        >
            <div style={{
                width: '100%',
                paddingTop: '100%',
                backgroundColor: props.color,
                backgroundImage: `url(${props.backgroundImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                borderRadius: props.circleIsSquare ? '' : '100%'
            }} />
            {props.children && (
                <div style={{
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    position: 'absolute',
                }}>
                    {props.children}
                </div>
            )}
        </div>
    ) 
}

const Content = ({ content, speakerImages, width }) => (
    <div className={`col-12 ${width ? `col-md-${width}` : 'col-md-7'}`}>
        <div className={`h-100 align-items-center px-4 ${speakerImages ? 'pt-4 pb-3' : 'py-4'}`}>
            <div>
                {content}
            </div>
        </div>
    </div>
)

const Image = ({ circles, speakerImages, width, secondImage, imageLink }) => {
    const linkIsInternal = imageLink && imageLink.split('')[0] ? true : false

    let logo
    let altTag
    switch (secondImage) {
        case "CPDLogo":
            logo = CPDLogo
            altTag = "CPDLogo"
            break
        default:
            break
    }

    return (
            <div className={`${width ? `col-md-${width}` : 'col-md-5'} d-none d-md-block`}>
                <div style={{ maxWidth: '280px' }} className="mx-auto d-flex flex-column h-100 align-items-center justify-content-center">
                    {imageLink ?
                        linkIsInternal ? 
                            <Link to={imageLink} style={{ position: 'relative', width: '100%', paddingTop: '80%' }}>
                                {circles}
                            </Link> 
                            :
                            <a href={imageLink} target='_blank'  style={{ position: 'relative', width: '100%', paddingTop: '80%' }}>
                                {circles}
                            </a> 
                        :
                        <div style={{ position: 'relative', width: '100%', paddingTop: '80%' }}>
                            {circles}
                        </div>
                    }
                    {secondImage &&
                        <div style={{ position: 'relative', width: '100%' }} className="d-flex justify-content-center">
                            <img
                                style={{ height: "75px", Width: "75px" }}
                                src={logo}
                                alt={altTag}
                                className="img-fluid"
                            />
                        </div>
                    }
                </div>
            </div>
    )
}

const Layout = (props) => {
    return (
        <DivFade
            style={{
                position: 'relative',
                overflow: 'hidden',
                backgroundColor: props.backgroundColour || 'white',
                paddingTop: props.paddingTop,
                paddingBottom: props.paddingBottom
            }}
            // className={`pt-${props.topPadding || ''} pb-${props.bottomPadding || ''}`}
        >
            {props.backgroundCircles &&
                <div style={{ position: 'absolute', width: '500px', left: '-180px', top: '-330px' }}>
                    <div style={{ paddingTop: '100%', backgroundImage: `url(${backgroundCircles})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }} />
                </div>
            }

            <div className="container">
                <div className="row">
                    {
                        props.noImage === true ?
                            <>
                                <Content
                                    content={props.children}
                                    speakerImages={props.speakerImages || false}
                                    width={props.contentWidth}
                                />
                            </>
                            :

                            props.contentRight ?
                                <>
                                    <Image
                                        circles={props.circles}
                                        speakerImages={props.speakerImages || false}
                                        width={props.imageWidth}
                                        secondImage={props.secondImage}
                                        imageLink={props.imageLink}
                                    />
                                    <Content
                                        content={props.children}
                                        speakerImages={props.speakerImages || false}
                                        width={props.contentWidth}
                                    />
                                </> :
                                <>
                                    <Content
                                        content={props.children}
                                        speakerImages={props.speakerImages || false}
                                        width={props.contentWidth}
                                    />
                                    <Image
                                        circles={props.circles}
                                        speakerImages={props.speakerImages || false}
                                        width={props.imageWidth}
                                        secondImage={props.secondImage}
                                        imageLink={props.imageLink}
                                    />
                                </>
                    }
                </div>
            </div>
        </DivFade>
    )
}

export default function TopBannerPlain(props) {
    const circleColours = props.circleColours || { one: "#0C989D", two: "#0E3A5C", three: "#EB6915" }
    const circleLogo = props.circleLogo || logo

    // const excludedCircles = props.excludedCircles
    let multipleSpeakers = false
    if (props.speakerImages && props.speakerImages.length > 1) { multipleSpeakers = true }

    if (props.isAboutPage) {
        return (
            <Layout
                paddingTop={props.paddingTop}
                paddingBottom={props.paddingBottom}
                secondImage={props.secondImage}
                noImage={props.noImage}
                backgroundCircles
                circles={[
                    <Circle key={1} color="#0E3A5C" width="45%" top="0%" left="74%" />,
                    <Circle key={2} color="#EB6915" width="20%" top="70%" left="25%" />,
                    <Circle key={3} backgroundImage={logo} width="70%" top="5%" left="25%" />,
                    <Circle key={4} color="#0C989D" width="45%" top="5%" left="0%">
                        <div style={{ textAlign: 'center', color: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                            <div style={{ fontSize: '10px', lineHeight: '10px' }}>ONLY</div>
                            <div style={{ fontWeight: 'bold', fontSize: '30px', lineHeight: '26px' }}>£395</div>
                            <div style={{ fontSize: '10px', lineHeight: '10px', marginBottom: '3px' }}>+VAT</div>
                            <div style={{ fontSize: '9px', lineHeight: '9px' }}>for 12 months subscription <br />to the course and<br />materials</div>
                        </div>
                    </Circle>
                ]}
            >
                {props.children}
            </Layout>
        )
    }
    
    if (props.smallCircleContent) {
        const filteredCircles = [
            { component: <Circle key={1} color={circleColours.one} width="45%" top="-8%" left="-40%" >{props.smallCircleContent}</Circle>, key: 1 },
            { component: <Circle key={2} color={circleColours.two} width="35%" top="10%" left="72%" />, key: 2 },
            { component: <Circle key={3} color={circleColours.three} width="12%" top="75%" left="20%" />, key: 3 },
            { component: <Circle circleIsSquare={props.circleIsSquare} key={4} backgroundImage={props.isLiveLogo ? liveLogo : circleLogo} width="70%" top="5%" left="15%" ></Circle>, key: 4 },
        ].filter(circle => ![...props.excludedCircles].includes(circle.key)).map(circle => circle.component)
        return (
            <Layout
                paddingTop={props.paddingTop}
                paddingBottom={props.paddingBottom}
                backgroundCircles={props.backgroundCircles}
                secondImage={props.secondImage}
                contentWidth={props.contentWidth}
                imageWidth={props.imageWidth}
                imageLink={props.imageLink}
                backgroundColour={props.backgroundColour}
                noImage={props.noImage}
                contentRight={props.contentRight}
                circles={filteredCircles}
            >
                {props.children}
            </Layout>
        )
    }

    if (props.circleContent) {
        return (
            <Layout
                paddingTop={props.paddingTop}
                paddingBottom={props.paddingBottom}
                backgroundCircles={props.backgroundCircles}
                secondImage={props.secondImage}
                contentWidth={props.contentWidth}
                imageWidth={props.imageWidth}
                backgroundColour={props.backgroundColour}
                noImage={props.noImage}
                contentRight={props.contentRight}
                circles={[
                    <Circle key={1} color={circleColours.one} width="20%" top="-5%" left="5%" />,
                    <Circle key={2} color={circleColours.two} width="35%" top="10%" left="72%" />,
                    <Circle key={3} color={circleColours.three} width="12%" top="75%" left="20%" />,
                    <Circle key={4} backgroundImage={props.isLiveLogo ? liveLogo : circleLogo} width="70%" top="5%" left="15%" >
                        {props.circleContent}
                    </Circle>
                ]}
            >
                {props.children}
            </Layout>
        )
    }

    if (props.isPLMConferenceTop) {

        return (
            <Layout
                secondImage={props.secondImage}
                noImage={props.noImage}
                backgroundColour={props.backgroundColour}
                contentRight={props.contentRight}
                backgroundCircles={props.backgroundCircles ? true : false}
                topPadding={props.topPadding}
                circles={[
                    <Circle key={1} color="#0C989D" width="20%" top="-5%" left="5%" />,
                    <Circle key={2} color="#0E3A5C" width="35%" top="10%" left="72%" />,
                    <Circle key={3} color="#EB6915" width="12%" top="75%" left="20%" />,
                    <Circle key={4} backgroundImage={liveLogo} width="70%" top="5%" left="15%" />
                ]}
            >
                {props.children}
            </Layout>
        )
    }
    
    if (props.speakerImages) {
        let filteredCircles = [
            { component: <Circle key={1} color="#0C989D" width="18%" top="1%" left="5%" />, key: 1 },
            { component: <Circle key={2} width="30%" color="#0E3A5C" top="8%" left="67%" />, key: 2 },
            { component: <Circle key={3} color="#EB6915" width="12%" top="70%" left="22%" />, key: 3 },
            { component: <Circle key={4} color={props.speakerImages[0] === '' ? "#0E3A5C" : ""} backgroundImage={props.speakerImages[0]} width="60%" top="10%" left="20%" />, key: 4 },
        ].filter(circle => ![...props.excludedCircles].includes(circle.key)).map(circle => circle.component)
        if (multipleSpeakers) {
            filteredCircles = [
                { component: <Circle key={1} color="#0C989D" width="20%" top="-5%" left="0%" />, key: 1 },
                { component: <Circle key={2} backgroundImage={props.speakerImages[1]} width={"45%"} color="#0E3A5C" top="17%" left="55%" />, key: 2 },
                { component: <Circle key={3} color="#EB6915" width="12%" top="82%" left="10%" />, key: 3 },
                { component: <Circle key={4} color={props.speakerImages[0] === '' ? "#0E3A5C" : ""} backgroundImage={props.speakerImages[0]} width="60%" top="12%" left="0%" />, key: 4 },
            ].filter(circle => ![...props.excludedCircles].includes(circle.key)).map(circle => circle.component)
        }

        return (
            <Layout
                secondImage={props.secondImage}
                noImage={props.noImage}
                speakerImages
                backgroundColour={props.backgroundColour}
                contentRight={props.contentRight}
                circles={filteredCircles}
            >
                {props.children}
            </Layout>
        )
    }

    if(props.excludedCircles) {
        const filteredCircles = [
            { component: <Circle key={1} color={circleColours.one} width="20%" top="-5%" left="5%" />, key: 1 },
            { component: <Circle key={2} color={circleColours.two} width="35%" top="10%" left="72%" />, key: 2 },
            { component: <Circle key={3} color={circleColours.three} width="12%" top="75%" left="20%" />, key: 3 },
            { component: <Circle key={4} backgroundImage={props.isLiveLogo ? liveLogo : circleLogo} width="70%" top="5%" left="15%" ></Circle>, key: 4 },
        ].filter(circle => ![...props.excludedCircles].includes(circle.key)).map(circle => circle.component)
        return (
            <Layout
                paddingTop={props.paddingTop}
                paddingBottom={props.paddingBottom}
                backgroundCircles={props.backgroundCircles}
                secondImage={props.secondImage}
                contentWidth={props.contentWidth}
                imageWidth={props.imageWidth}
                imageLink={props.imageLink}
                backgroundColour={props.backgroundColour}
                noImage={props.noImage}
                contentRight={props.contentRight}
                circles={filteredCircles}
            >
                {props.children}
            </Layout>
        )
    }

    return (
        <Layout
            paddingTop={props.paddingTop}
            paddingBottom={props.paddingBottom}
            backgroundCircles={props.backgroundCircles}
            secondImage={props.secondImage}
            contentWidth={props.contentWidth}
            imageWidth={props.imageWidth}
            imageLink={props.imageLink}
            backgroundColour={props.backgroundColour}
            noImage={props.noImage}
            contentRight={props.contentRight}
            circles={[
                <Circle key={1} color={circleColours.one} width="20%" top="-5%" left="5%" />,
                <Circle key={2} color={circleColours.two} width="35%" top="10%" left="72%" />,
                <Circle key={3} color={circleColours.three} width="12%" top="75%" left="20%" />,
                <Circle key={4} backgroundImage={props.isLiveLogo ? liveLogo : circleLogo} width="70%" top="5%" left="15%" ></Circle>
            ]}
            // circles={filteredCircles}
        >
            {props.children}
        </Layout>
    )
}