import React from 'react'
import Block from '../../Block'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import Quote from '../../../common/Quote'

const Quote = (props) => {
    return <blockquote style={{ marginTop: '12px' }} className="blockquote mb-4">
        <p style={{ fontSize: '18px', fontWeight: 'bold' }}>
            <FontAwesomeIcon style={{ color: 'rgb(25, 57, 88)' }} className="mr-3 mb-2" size="3x" icon="quote-left" role="presentation" />
            {props.quote}
        </p>
        <div style={{ fontWeight: 'bold' }} className="blockquote-footer text-right">{props.author}</div>
    </blockquote>
}

export default function Testimonials() {

    const quotes = [
        {
            quote: "The Prescribing Lifestyle Medicine course has been invaluable to me and the team. It covers all the common symptoms/conditions that residents are coming to us for support with such as low mood, anxiety, excess weight and long-term health conditions. I really like the strength-based tone of the programme focussing on improving health and wellbeing rather than treating illness and The Personal Framework is a really effective tool in creating person centred lifestyle prescriptions for residents. Would highly recommend!",
            author: "Hannah Short | Public Health Improvement Officer, Doncaster Borough Council"
        },
        {
            quote: "I love the Prescribing Lifestyle Medicine course. I have been attempting to work within the scope of lifestyle medicine for months now, and the course has provided me with some additional tools that I find very helpful. My job satisfaction has definitely improved since using tools learnt in the course. Thank you.",
            author: "Eliska Felcmanova | Nurse Practitioner, Sullivan Way Surgery"
        }

    ]

    return (
        <Block color="white">
            <div className="col-12 my-4 text-center">
                <h2><strong>Testimonials</strong></h2>
            </div>
            <div className="row">
                {quotes.map((quote, index) => (
                    <div className="col-sm-6" key={`testimonial quote ${index}`}>
                        <Quote class="tc-dark-teal" quote={quote.quote} author={quote.author} />
                    </div>
                ))}
            </div>
        </Block>
    )
}
