import React from 'react'
import { connect } from 'react-redux'
import Block from './Block'
import ProductCarousel from './ProductCarousel'
import Card from './Card'
import classnames from 'classnames'
import ImageCircleCard from '../layout/ImageCircleCard'
import LatestBlogs from './LatestBlogs'
import Contact from './Contact'
import Grid from './Grid'
import PUPanel from './PUPanel'
import SubPageList from '../pages/SubPageList'
import ProductComparisonTable from './ProductComparisonTable'
import Button from '../common/Button'
import parameters from '../../config/parameters'
import detectIe from '../../utils/detectIe'
import processRichText from '../../utils/processRichText'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons'
import HomeBanner from './NLS/HomeBanner'
import PathHome from './NLS/PathHome'
import PathProductDetails from './NLS/PathProductDetails'
import PLMBtns from './PLMBtns'
import PathFAQs from './NLS/PathFAQs'
import LeadsList from '../learner/LeadsList';
import PathForm from './NLS/PathForm'
import PLMDetails from './NLS/PLMDetails'
// import PLMHome from './NLS/PLMHome'
import BackgroundCircles from '../../img/circles_bg.svg'
import GCPricingTable from './GCPricingTable';
import GCHeader from './GCHeader'
import PLMSuitable from './PLMSuitable'
import TicketTailor from './TicketTailor'
import ProductCarousel2 from './ProductCarousel2'
import HomePageDots from './HomePageDots'
import HomepageImage from './HomepageImage'
import HomePageContact from './HomePageContact'
import HomepageBannerThree from './HomepageBannerThree'
import DoubleBlock from './DoubleBlock'
import WorkflowCapacityCalc from '../common/WorkflowCapacityCalc'
import Homepage from './PLM/Homepage'
import PLMConfPage from './PLM/PLMConfPage24'
import HomePageDiscount from './HomePageDiscount'
import GPBillingHomePage from './GPBilling/GPBillingHomePage'
import PathGoHomepage from './PathologyGo/PathGoHomepage'
import WorkflowGoHomepage from './WorkflowGo/WorkflowGoHomepage'

library.add(faQuoteLeft)
library.add(faQuoteRight)

const ieVersion = detectIe();

function ContentBlockPage(props) {

    

    if (!props.content) {
        return null
    }
    if (!props.content.fields) {
        return null
    }
    if (!props.content.fields.blocks) {
        return null
    }

    const { blocks, title, pageDescription, background } = props.content.fields

    // GPBilling
    if (props.content.sys.id === '4DBagojqjf1FlbTzAVL03u') {
        return (
            <>
                <Helmet>
                    {title && <title>{title}</title>}
                    {pageDescription && <meta name="description" content={pageDescription} />}
                    {pageDescription && <meta name="twitter:description" content={pageDescription} />}
                </Helmet>
                <GPBillingHomePage content={blocks.map(b => b.fields)} />
            </>
        )
    }

    // PathologyGo
    if (props.content.sys.id === '2L5RY0xcUrmVhmHKwlkovD') {     
        // if (props.content.sys.id === '4B2KNf1DrCtA3hjc44kYLN') {
            return (
            <>
                <Helmet>
                    {title && <title>{title}</title>}
                    {pageDescription && <meta name="description" content={pageDescription} />}
                    {pageDescription && <meta name="twitter:description" content={pageDescription} />}
                </Helmet>
                <PathGoHomepage />
            </>
        )
    }

    // WorkflowGo
    if (props.content.sys.id === '1QXK7SSU1btDBVkej1d13Q') {  
            return (
            <>
                <Helmet>
                    {title && <title>{title}</title>}
                    {pageDescription && <meta name="description" content={pageDescription} />}
                    {pageDescription && <meta name="twitter:description" content={pageDescription} />}
                </Helmet>
                <WorkflowGoHomepage />
            </>
        )
    }



    return (
        <div style={{
            height: '100%',
            minHeight: 'inherit',
            backgroundImage: background === 'circles' && 'url("' + BackgroundCircles + '")',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            overflow: 'hidden',
            backgroundPosition: 'center',

        }}>
            <Helmet>
                {title && <title>{title}</title>}
                {pageDescription && <meta name="description" content={pageDescription} />}
                {pageDescription && <meta name="twitter:description" content={pageDescription} />}
            </Helmet>
            <TransitionGroup>
                {blocks.map((block) => {
                    if (!block.sys.contentType) { return null }
                    const type = block.sys.contentType.sys.id

                    let content = null
                    if (type === "blockHeaderText") {
                        content = <BlockHeaderText fields={block.fields} />
                    }
                    if (type === "blockProductCarousel") {
                        content = <BlockProductCarousel fields={block.fields} />
                    }
                    if (type === "blockImageParagraph") {
                        content = <BlockImageParagraph fields={block.fields} />
                    }
                    if (type === "blockCardParagraph") {
                        content = <BlockCardParagraph fields={block.fields} />
                    }
                    if (type === "blockHeaderImageRow") {
                        content = <BlockHeaderImageRow fields={block.fields} />
                    }
                    if (type === "blockHeroCard") {
                        content = <BlockHeroCard fields={block.fields} />
                    }
                    if (type === "blockAudioPlayer") {
                        content = <BlockAudioPlayer fields={block.fields} />
                    }
                    if (type === "blockHardcoded") {
                        content = <BlockHardcoded fields={block.fields} />
                    }
                    if (type === "blockMultiType") {
                        content = <BlockMultiType fields={block.fields} />
                    }
                    if (type === "blockSubpageList") {
                        content = <BlockSubpageList fields={block.fields} />
                    }


                    let showComponent = true
                    if (block.fields.hideOnLogin) {
                        if (block.fields.hideOnLogin === true && props.auth.isAuthenticated) {
                            showComponent = false
                        }
                    }

                    if (block.fields.showOnLogin) {
                        if (block.fields.showOnLogin === true && props.auth.isAuthenticated === false) {
                            showComponent = false
                        }
                    }

                    if (showComponent) {
                        return (
                            <CSSTransition
                                key={block.sys.id}
                                classNames="horizontalCollapse"
                                timeout={400}
                            >
                                <div>{content}</div>
                            </CSSTransition>
                        )
                    }
                    return null

                }
                )}
            </TransitionGroup>
        </div>
    )
}


const BlockHardcoded = ({ fields }) => {
    let content

    if (fields.component === "NLS-Path-Form") { content = <PathForm /> }
    if (fields.component === "Contact Us") {
        content = (
            <Block>
                <div className="row">
                    <div className="col-12">
                        <h4><strong>Send us a message</strong></h4>
                        <p>Whether you are interested in one of our products or would like to know more about working with Practice Unbound, fill in this form and we’ll be back in touch.</p>
                    </div>
                    <div className="col-lg-6 order-3 order-lg-0 text-center text-lg-left">
                        <h4><strong>Call us</strong></h4>
                        <p><a href={`tel:${parameters.contactUsPhone}`}>{parameters.contactUsPhone}</a></p>

                        <h4><strong>Email us</strong></h4>
                        <p><a href={`mailto:${parameters.contactUsEmail}`}>{parameters.contactUsEmail}</a></p>

                        <h4><strong>Helpdesk query?</strong></h4>
                        <p><a href={`mailto:${parameters.helpdeskEmail}`}>{parameters.helpdeskEmail}</a></p>
                    </div>
                    <div className="col-lg-6 mb-5">
                        <Contact />
                    </div>
                </div>
            </Block>
        )
    }
    if (fields.component === "GC-Pricing") content = <GCPricingTable />
    if (fields.component === "PLMSuitable") {
        content = (<PLMSuitable />)
    }
    if (fields.component === "NLS-Path-FAQs") {
        return <PathFAQs />
    }
    if (fields.component === "Latest Blogs") {
        return <LatestBlogs title={fields.header} backgroundColour={fields.backgroundColour} />
    }
    if (fields.component === "Leads screen admin list") {
        return <LeadsList />
    }
    if (fields.component === "NLS-Home") {
        return <HomeBanner />
    }
    if (fields.component === "NLS-Path-Home-Block1") {
        return <PathHome />
    }
    if (fields.component === "NLS-Path-ProductDetails") {
        return <PathProductDetails />
    }
    if (fields.component === "NLS-PLM-Home") {
        return <Homepage />
    }
    if (fields.component === "NLS-PLM-Details") {
        return <PLMDetails />
    }
    if (fields.component === "PLM-Btns") {
        return <PLMBtns />
    }
    if (fields.component === "GCHeader") {
        return <GCHeader />
    }
    if (fields.component === "HomePageDots") {
        return <HomePageDots />
    }
    if (fields.component === "HomePageContact") {
        return <HomePageContact />
    }
    if (fields.component === "HomePageBanner") {
        return <HomepageBannerThree />
    }
    if (fields.component === "DoubleBlock") {
        return <DoubleBlock />
    }
    if (fields.component === "PLM-Conf-Page") {
        return <PLMConfPage />
    }
    if (fields.component === "WF-15PercentOff") {
        return <HomePageDiscount />
    }

    return (
        <Block
            backsplash={fields.headerBackground === true && true}
            image={fields.backgroundImageUrl}
            color={fields.backgroundColour}
            textColor={fields.textColour}
        >
            {fields.header && (
                <div className={classnames("row justify-content-center",
                    { 'text-center': fields.textAlign === "Center" },
                    { 'text-right': fields.textAlign === "Right" },
                )}>
                    <div className={classnames(
                        { "col-lg-12": fields.textAlign === "Center" },
                        { "col-12": fields.textAlign !== "Center" },
                    )}>
                        <h2 className="mb-5">{fields.boldTitle ? <strong>{fields.header}</strong> : fields.header}</h2>
                    </div>
                </div>
            )}

            <div className={classnames("row justify-content-center",
                { 'text-center': fields.textAlign === "Center" },
                { 'text-right': fields.textAlign === "Right" },
            )}
            >
                <div className={classnames(
                    { "col-lg-12": fields.textAlign === "Center" },
                    { "col-12": fields.textAlign !== "Center" },
                )}>
                    {content}
                </div>
            </div>

        </Block>
    )
}




const BlockHeaderImageRow = ({ fields }) => {
    return (
        <Block color={fields.backgroundColour}>
            <h2 className="text-center mb-4">{fields.boldTitle ? <strong>{fields.header}</strong> : fields.header}</h2>
            <div className="row justify-content-center">
                {fields.imageUrLs.map(i => (
                    <div key={i} className="col-5 col-sm-3 col-lg-2 mb-4" style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        justifyContent: 'center'
                    }}>
                        <div className="d-block w-100 text-center">
                            <img src={i} alt="logo" className="img-fluid" style={{ maxHeight: '72px' }} />
                        </div>
                    </div>
                ))}
            </div>

        </Block >
    )
}

const BlockHeaderText = ({ fields }) => {
    return (
        <Block
            backsplash={fields.headerBackground === true && true}
            image={fields.backgroundImageUrl}
            color={fields.backgroundColour}
            textColor={fields.textColour}
            hideTopPadding={fields.hideTopPadding}
            hideBottomPadding={fields.hideBottomPadding}
        > {fields.showAsPanel === true ? (
            <div className="row">
                <PUPanel color={fields.textColour && fields.textColour.toLowerCase()} title={fields.header}>
                    {fields.paragraph && processRichText(fields.paragraph.content)}
                </PUPanel>
            </div>

        ) : (
            <>
                <div className={classnames("row justify-content-center",
                    { 'text-center': fields.textAlign === "Center" },
                    { 'text-right': fields.textAlign === "Right" }
                )}>
                    <div className={classnames(
                        { "col-lg-8": fields.textAlign === "Center" },
                        { "col-12": fields.textAlign !== "Center" },
                    )}>
                        {fields.header && <h1 className="mb-4">{fields.boldTitle ? <strong>{fields.header}</strong> : fields.header}</h1>}
                    </div>
                </div>
                <div className={classnames("row justify-content-center",
                    { 'text-center': fields.textAlign === "Center" },
                    { 'text-right': fields.textAlign === "Right" }
                )}
                >
                    <div className={classnames(
                        { "col-lg-8": fields.textAlign === "Center" },
                        { "col-12": fields.textAlign !== "Center" },
                    )}>
                        <span>{fields.paragraph && processRichText(fields.paragraph.content)}</span>
                    </div>
                </div>
            </>
        )}


        </Block>
    )
}

const BlockHeroCard = ({ fields }) => {
    return (
        <Block
            image={fields.backgroundImageUrl}
            backgroundImagePosition={fields.backgroundImagePosition}
            accent
            accentColor="green"
        >
            <div className="row">
                <div className="col-md-10 col-lg-7 offset-lg-1">
                    <Card trans>
                        <div className="p-sm-5">
                            {fields.header && <h1 className="mb-4"><strong>{fields.header}</strong></h1>}
                            <span>{fields.paragraph && processRichText(fields.paragraph.content)}</span>
                            {fields.buttonLink && (
                                <Button className="mt-4" inline center large link={fields.buttonLink} colour={fields.buttonColour}>{fields.buttonText}</Button>
                            )}
                        </div>

                    </Card>
                </div>
            </div>

        </Block>
    )
}


const BlockProductCarousel = ({ fields }) => {
    if (!fields.productRange) { return null }
    if (fields.nlsRedesign) return <ProductCarousel2 title={fields.header} backgroundColour={fields.backgroundColour} range={fields.productRange} />
    return (
        <Block
            color={fields.backgroundColour || 'white'}
            image={fields.backgroundImageUrl}
        >
            <h2 className="mb-4"><strong>{fields.header}</strong></h2>
            <span>{fields.paragraph && processRichText(fields.paragraph.content)}</span>
            <ProductCarousel range={fields.productRange} />
        </Block>
    )
}

const BlockImageParagraph = ({ fields }) => {
    const image = <img src={fields.imageUrl} alt="logo" className="img-fluid px-5 mx-auto"
        style={{ width: '100%', maxWidth: '400px' }} />
    return (
        <Block color={fields.backgroundColour}>
            <div className="row">
                <div className="col-md-6" style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap'
                }}>
                    {fields.imageLink ? (
                        <a href={fields.imageLink}>{image}</a>
                    ) : (
                        image
                    )}
                </div>
                <div className={classnames("col-md-6 px-4", { "order-md-first": fields.imagePosition === "Right" })}>
                    <div className="d-flex align-items-center h-100">
                        <div>
                            {processRichText(fields.paragraph.content)}
                        </div>
                    </div>

                </div>
                {fields.extraParagraph && (
                    <div className="col-12 p-4">
                        {processRichText(fields.extraParagraph.content)}
                    </div>
                )}
            </div>
        </Block>
    )
}



const BlockCardParagraph = ({ fields }) => {
    return (
        <Block>
            <div className="row">
                <div className="col-lg-6 col-md-6">
                    <Card
                        title={fields.imageTitle}
                        color={fields.colour.toLowerCase()}
                        image={fields.imageUrl}
                    />
                </div>
                <div className={classnames("col-lg-6 col-md-6 p-4", { "order-md-first": fields.imagePosition === "Right" })}>
                    {processRichText(fields.paragraph.content)}
                </div>
            </div>
        </Block>
    )
}

const BlockAudioPlayer = ({ fields }) => {
    return (
        <Block>
            <div className="row d-flex align-items-center">
                <div className="col-sm-4 text-center">
                    <img alt="blog" src={fields.imageUrl} className="img-fluid mb-3" style={{ maxHeight: '300px' }} />
                </div>
                <div className="col-sm-8">
                    <div>
                        <h4 className="text-center"><strong>{fields.header}</strong></h4>
                        <audio controls style={{ width: '100%' }}>
                            <source src={fields.audioUrl} />
                            Your browser does not support the audio element.
                        </audio>
                    </div>
                </div>
            </div>
        </Block>
    )
}
const BlockSubpageList = ({ fields }) => {
    return (
        <Block>
            <h2 className="mb-4"><strong>{fields.header}</strong></h2>
            <SubPageList pageType={fields.pageType} />
        </Block>
    )
}

const BlockMultiType = ({ fields }) => {
    const convertColumnWidth = (width) => {
        return classnames(
            { "col-md-12": width === "Full Width" },
            { "col-md-6": width === "Half Width" },
            { "col-md-4 col-sm-6": width === "Third" },
            { "col-md-8 col-sm-12": width === "Two Thirds" },
            { "col-lg-3 col-md-6": width === "Quarter" },
            "mb-4"
        )
    }

    const processComponent = (c) => {
        if (!c.sys) { return null }
        if (!c.sys.contentType) { return null }
        if (!c.sys.contentType.sys) { return null }
        if (c.sys.contentType.sys.id === "componentImageCircleCard") {

            return (
                <ImageCircleCard
                    showImageAsSquare={c.fields.showImageAsSquare}
                    horizontal={c.fields.horizontal}
                    buttonText={c.fields.buttonText}
                    buttonLink={c.fields.buttonLink}
                    header={c.fields.header}
                    subHeader={c.fields.subHeader}
                    image={c.fields.imageUrl}
                    textAlign={c.fields.textAlign}
                    color={c.fields.backgroundColour}
                    paragraph={c.fields.paragraph && processRichText(c.fields.paragraph.content)}
                    linkedIn={c.fields.linkedInUrl}
                    twitter={c.fields.twitterUrl}
                    embed={c.fields.embedJshtml}
                    height100={c.fields.height100}
                    circleCardDescription={c.fields.circleCardDescription}
                />
            )
        }

        if (c.sys.contentType.sys.id === "componentList") {

            const list = <ul className={c.fields.icon === 'Green Dot' ? 'green-list raleway' : "tick-list raleway"}>
                {c.fields.listItem && c.fields.listItem.map((o) =>
                    <li key={o}>{o}</li>
                )}
            </ul>
            return (
                <div style={{ marginTop: '12px' }}>
                    {c.fields.paragraphAbove && (<div
                        className={classnames(
                            "py-3",
                            { "text-center": c.fields.textAlign === "center" },
                            { "text-right": c.fields.textAlign === "right" },
                        )}
                    >{processRichText(c.fields.paragraphAbove.content)}</div>
                    )}
                    {c.fields.card === false ? (
                        list
                    ) : (
                        <Card>
                            {list}
                        </Card>
                    )}

                    {c.fields.paragraphBelow && (<div
                        className={classnames(
                            { "text-center": c.fields.textAlign === "center" },
                            { "text-right": c.fields.textAlign === "right" },
                        )}
                    >{processRichText(c.fields.paragraphBelow.content)}</div>
                    )}
                </div>
            )
        }
        if (c.sys.contentType.sys.id === "componentQuotation") {
            return (
                <blockquote style={{ marginTop: '12px' }} className="blockquote mb-5">
                    <p style={{ fontSize: '20px', fontWeight: 'bold', letterSpacing: '1.2px' }}>
                        <FontAwesomeIcon className="tc-light-pink mr-3 mb-2" size="3x" icon="quote-left" role="presentation" />
                        {c.fields.quote}
                    </p>
                    {(c.fields.author || c.fields.authorDetail) && <div className="blockquote-footer text-right">{c.fields.author}, {c.fields.authorDetail}</div>}
                </blockquote>
            )
        }
        if (c.sys.contentType.sys.id === "componentVideo") {
            return (
                <div>
                    {c.fields.paragraphAbove && (<div
                        className={classnames(
                            "py-3",
                            { "text-center": c.fields.textAlign === "center" },
                            { "text-right": c.fields.textAlign === "right" },
                        )}
                    >{processRichText(c.fields.paragraphAbove.content)}</div>
                    )}
                    <div className="embed-responsive embed-responsive-16by9" >
                        <iframe
                            title={c.fields.videoUrl}
                            className="embed-responsive-item"
                            src={c.fields.videoUrl}
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
            )
        }
        if (c.sys.contentType.sys.id === "componentParagraph") {
            return (
                <div className={classnames(
                    { "col-lg-8": c.fields.textAlign === "center" },
                    { "col-12": c.fields.textAlign !== "center" },
                )}>
                    <div className={classnames(
                        { "text-center": c.fields.textAlign === "center" },
                        { "text-right": c.fields.textAlign === "right" },
                        "mb-5"
                    )}
                    >{processRichText(c.fields.paragraph.content)}</div>
                </div>
            )
        }
        if (c.sys.contentType.sys.id === "componentImage") {
            return (
                <div className="px-3">
                    {c.fields.circleImage ? (
                        <div style={{
                            backgroundImage: `url(${c.fields.imageUrl})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            borderRadius: '50%',
                            width: '100%',
                            paddingTop: '100%',
                            maxWidth: c.fields.maxWdith && `${c.fields.maxWdith}px`
                        }} />
                    ) : (
                        <div className="d-block w-100 text-center">
                            <img
                                style={{
                                    maxWidth: c.fields.maxWdith && `${c.fields.maxWdith}px`
                                }}
                                src={c.fields.imageUrl}
                                alt={c.fields.altText}
                                className="img-fluid"
                            />
                        </div>
                    )
                    }
                </div>
            )
        }
        if (c.sys.contentType.sys.id === "componentHardcoded") {
            if (c.fields.componentType === "Workflow Comparison Table") {
                return (
                    <ProductComparisonTable
                        titleRow={[{}, {
                            title: 'Workflow Go',
                            link: '/product/workflowgo'
                        }, {
                            title: 'Workflow',
                        }, {
                            title: 'Workflow Network',
                            link: '/product/workflownetwork'
                        }]}
                        rows={[
                            ['Number of licences', 'Unlimited', 'Unlimited', 'Unlimited'],
                            ['Licence Duration', '1 year', '1 year', '1 year'],
                            ['Technical Support Service', 'tick', 'tick', 'tick'],
                            ['e-Learning Course', 'tick', 'tick', 'tick'],
                            ['Governance Structures and Processes', 'tick', 'tick', 'tick'],
                            ['Clinical System Data entry template to enhance coding and data quality', 'tick', 'tick', 'tick'],
                            ['Data Dashboard to support implementation and measure impact for Practices / CCGs', 'tick', 'tick', 'tick'],
                            ['1:1 Implementation Support Coach', '', 'tick', 'tick'],
                            ['Face-to-face Workshop', '', 'tick', 'tick'],
                            ['Primary Care Network Consultancy', '', '', 'tick'],
                        ]}
                    />
                )
            }
            if (c.fields.componentType === "Workflow Go Comparison Table") {
                return (
                    <ProductComparisonTable
                        titleRow={[{}, {
                            title: 'Workflow Go',
                        }, {
                            title: 'Workflow',
                            link: '/product/workflow'
                        }, {
                            title: 'Workflow Network',
                            link: '/product/workflownetwork'
                        }]}
                        rows={[
                            ['Number of licences', 'Unlimited', 'Unlimited', 'Unlimited'],
                            ['Licence Duration', '1 year', '1 year', '1 year'],
                            ['Technical Support Service', 'tick', 'tick', 'tick'],
                            ['e-Learning Course', 'tick', 'tick', 'tick'],
                            ['Governance Structures and Processes', 'tick', 'tick', 'tick'],
                            ['Clinical System Data entry template to enhance coding and data quality', 'tick', 'tick', 'tick'],
                            ['Data Dashboard to support implementation and measure impact for Practices / CCGs', 'tick', 'tick', 'tick'],
                            ['1:1 Implementation Support Coach', '', 'tick', 'tick'],
                            ['Face-to-face Workshop', '', 'tick', 'tick'],
                            ['Primary Care Network Consultancy', '', '', 'tick'],
                        ]}
                    />
                )
            }
            if (c.fields.componentType === "Workflow Network Comparison Table") {
                return (
                    <ProductComparisonTable
                        titleRow={[{}, {
                            title: 'Workflow Go',
                            link: '/product/workflowgo'
                        }, {
                            title: 'Workflow',
                            link: '/product/workflow'
                        }, {
                            title: 'Workflow Network',
                        }]}
                        rows={[
                            ['Number of licences', 'Unlimited', 'Unlimited', 'Unlimited'],
                            ['Licence Duration', '1 year', '1 year', '1 year'],
                            ['Technical Support Service', 'tick', 'tick', 'tick'],
                            ['e-Learning Course', 'tick', 'tick', 'tick'],
                            ['Governance Structures and Processes', 'tick', 'tick', 'tick'],
                            ['Clinical System Data entry template to enhance coding and data quality', 'tick', 'tick', 'tick'],
                            ['Data Dashboard to support implementation and measure impact for Practices / CCGs', 'tick', 'tick', 'tick'],
                            ['1:1 Implementation Support Coach', '', 'tick', 'tick'],
                            ['Face-to-face Workshop', '', 'tick', 'tick'],
                            ['Primary Care Network Consultancy', '', '', 'tick'],
                        ]}
                    />
                )
            }
            if (c.fields.componentType === "Leads screen admin list") {
                return <LeadsList />
            }

            if (c.fields.componentType === "PLM-Conf") {
                return <TicketTailor />
            }

            if (c.fields.componentType === "HomepageImage") {
                return <HomepageImage />
            }

            if (c.fields.componentType === "WF Calc") {
                return <WorkflowCapacityCalc />
            }

            if (c.fields.componentType === "DoubleBlock") {
                return <DoubleBlock />
            }

            return null
        }
        return null
    }

    let components
    if (fields.direction === "Masonary") {
        components = (
            <Grid>
                {fields.components && fields.components.map(c => {
                    return (
                        <div key={c.sys.id}
                            data-aos={(ieVersion === false || ieVersion > 10) && "fade"}
                            className="mb-5">
                            {processComponent(c)}
                        </div>
                    )
                })
                }
            </Grid>
        )
    } else {
        components = (
            <div className={classnames("row",
                { "align-items-center": fields.verticalAlign === 'Center' },
                { 'justify-content-center': fields.justifyColumns === 'Center' },
                { 'justify-content-around': fields.justifyColumns !== 'Center' })}
            >
                {fields.components && fields.components.map(c => {
                    if (c.fields) {
                        return (
                            < div key={c.sys.id} className={convertColumnWidth(c.fields.width)} >                            {processComponent(c)}
                            </div>
                        )
                    } else {
                        return null
                    }

                })
                }
            </div>
        )
    }
    return (
        <Block
            largePadding={fields.contentfulTitle === "Path Go Bottom Row"}
            backsplash={fields.headerBackground === true && true}
            image={fields.backgroundImageUrl}
            color={fields.contentfulTitle === "Path Go Bottom Row" ? 'white' : fields.backgroundColour}
            textColor={fields.textColour}
            hideTopPadding={fields.hideTopPadding}
            hideBottomPadding={fields.hideBottomPadding}
        >
            {fields.showAsPanel === true ? (
                <div className="row">
                    <PUPanel
                        color={fields.textColour && fields.textColour.toLowerCase()}
                        title={fields.header}>
                        {fields.paragraph && processRichText(fields.paragraph.content)}
                        {components}
                        {fields.paragraphBelow && processRichText(fields.paragraphBelow.content)}
                        {fields.buttonLink && (
                            <Button
                                center
                                colour="yellow"
                                inline
                                link={fields.buttonLink}
                            >{fields.buttonText}</Button>
                        )}
                    </PUPanel>
                </div>
            ) : (
                <>
                    {fields.header && (
                        <div className={classnames("row justify-content-center",
                            { 'text-center': fields.textAlign === "center" },
                            { 'text-right': fields.textAlign === "right" }
                        )}>
                            <div className={classnames(
                                { "col-lg-8": fields.textAlign === "center" },
                                { "col-12": fields.textAlign !== "center" },
                            )}>
                                <h2 className="mb-5">{fields.boldTitle ? <strong>{fields.header}</strong> : fields.header}</h2>
                            </div>
                        </div>
                    )}
                    {fields.paragraph && (
                        <div className={classnames("row justify-content-center",
                            { 'text-center': fields.textAlign === "center" },
                            { 'text-right': fields.textAlign === "right" }
                        )}
                        >
                            <div className={classnames(
                                { "col-lg-8": fields.textAlign === "center" },
                                { "col-12": fields.textAlign !== "center" },
                            )}>

                                <div className="mb-5">{fields.paragraph && processRichText(fields.paragraph.content)}</div>


                            </div>
                        </div>
                    )}
                    {components}
                    {fields.paragraphBelow && (
                        <div className={classnames("row justify-content-center",
                            { 'text-center': fields.textAlign === "center" },
                            { 'text-right': fields.textAlign === "right" }
                        )}
                        >
                            <div className={classnames(
                                { "col-lg-8": fields.textAlign === "center" },
                                { "col-12": fields.textAlign !== "center" },
                            )}>

                                <div className="mb-5">{fields.paragraphBelow && processRichText(fields.paragraphBelow.content)}</div>


                            </div>
                        </div>
                    )}
                    {fields.buttonLink && (
                        <Button
                            center
                            colour="yellow"
                            inline
                            link={fields.buttonLink}
                        >{fields.buttonText}</Button>
                    )}
                </>
            )}
        </Block>
    )
}

const mapStateToProps = (state) => ({
    auth: state.auth,
})

export default connect(mapStateToProps)(ContentBlockPage)