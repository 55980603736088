import ImageTextBlock from '../../PLM/Comps/ImageTextBlock'
import greyCircles from '../../../../img/slightly-dark-grey-bubbles-right.svg'
import Quote from '../../../common/Quote'
import BottomBanner from '../../PLM/Comps/BottomBanner'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import Carousel from '../../../common/Carousel'
import {carouselItems} from './SuccessStories'
import riversideImg from '../../../../img/02 Success Stories/image_riverside_medical_practice@3x.jpg'
import DivFade from '../../DivFade'
import { useHistory } from 'react-router-dom'

const carouselItemsFiltered = carouselItems.filter(i => i.title !== 'Riverside Medical Practice')

export default function Riverside() {

    const history = useHistory()  
    const handleNav = (url) => () => history.push(url)

  return (

    <div>

        <ImageTextBlock
            imageUrl={riversideImg}
            contentSide="left"
            contentBackgroundColor="white"
            contentBackgroundImage={greyCircles}
            contentBackgroundSize='40%'
            contentBackgroundPosition='top left'
            contentBackgroundRight='5%'
            contentBackgroundTop='5%'
        >
        <div className='py-5 my-5'>
            <h3 className='font-weight-bold mb-3'>Riverside Medical Practice</h3>
            <p>Based in Wakefield, Riverside Medical Practice has a total patient list size of 11,500 within the town of Castleford. With a diverse population and an increased focus within the practice more efficiently, the pactice sought a smarther way to systemise how they process third-party and private fees.</p>
        </div>
        </ImageTextBlock>

        <DivFade className='px-4'>
            <div className='container p-5'>
                <Quote
                    // key={`${index}: ${item}`} 
                    author={testimonials[0].author} 
                    quote={testimonials[0].quote} 
                    // iconColour={props.quoteIconColour}
                />
            </div>
        </DivFade>

        <DivFade className='bg-white p-5'>
            <div className='container p-3 bg-white'>
                <p>Having recently adopted GP Billing as their billing system of choice, the practice is already saving time and working smarter with GP Billing. The practice was using a variety of word processing tools to raise invoices before implementing their new billing system.</p>
            </div>
        </DivFade>

        <DivFade className='px-4'>
            <div className='container p-5'>
                <Quote
                    // key={`${index}: ${item}`} 
                    author={testimonials[1].author} 
                    quote={testimonials[1].quote} 
                    // iconColour={props.quoteIconColour}
                />
            </div>
        </DivFade>
        
        <DivFade className='bg-white px-5 pt-3'>
            <div className='container p-5 bg-white'>
                <div className='mb-5'>
                    <p>This culminated into a large piece of data work for Sharon, "I had to trander these onto an Excel spreadsheet, and update this quarterly, to see how much was outstanding for our private work.", this time demanding work resulted in any outstanding fees to be chased periodically.</p>
                </div>
                <div className='mb-5'>
                    <h2 className='font-weight-bold mb-3'>Smarter billing software can make a huge impact</h2>
                    <p>Riverside Medical Practice has greatly reduced the time spent in generating an invoice and feedback from across the Secretariat has been extremely positive especially with the clinical system integration feature.</p>
                    <p>As Sharon reveals, "the time spent on generating an invoice for the private work has been freatly reduced as auto-populating the invoice and logging on to SystmOne are done at the push of a button. The secretaries are more than happy in the way it is working now".</p>
                </div>
                <div className='mb-5'>
                    <h2 className='font-weight-bold mb-3'>Data accuracy and reporting</h2>
                    <p>Improving data accuracy and information governance has given the practice confidence that patient details are correct.</p>
                    <p>Sharon finds that "keeping track of payments received is much easier, as the references of Invoice Numbers are being used by payees as their nymber in bank transfers - so they can be easily found and matched."</p>
                    <p>The practice team at Riverside Medical Practice are big fans of the price list feature in the system whcih means any global changes to pricing are instantaneous and alleviates the need to keep this on a separate document.</p>
                </div>
                <div>
                    <h2 className='font-weight-bold mb-3'>Looking to the future and Year-End </h2>
                    <p>As a newly onboarded practice, data trends are yet to be seen – however, the practice has been very complimentary of the training and support they have received. This has supported Riverside Medical Centre to embed the system in their day-to-day. </p>
                    <p>“The 'help chat' is very useful for quick answers and we have no complaints about the speed at which any issues are resolved.”</p>
                    <p>The practice is anticipating Year End to be much smoother next year, as the data and on-demand reporting will be extremely useful for the 2021 accounting period. </p>
                </div>
            </div>
        </DivFade>

        <DivFade className='p-4'>
            <div className='container py-3 px-5'>
                <Quote
                    // key={`${index}: ${item}`} 
                    author={testimonials[2].author} 
                    quote={testimonials[2].quote} 
                    // iconColour={props.quoteIconColour}
                />
            </div>
        </DivFade>

        <div className='d-block d-lg-none'>
            <Carousel
            // carouselContentType='courseCreators'
            backgroundColour={'blue'}
            titleHeight='28px'
            height='310px'
            items={carouselItemsFiltered.map(e => ({...e, navigateFunction: handleNav(e.linkUrl)}))}
            cardColor='leafGreen'            
            />
        </div>

        <div className='d-none d-lg-block'>
            <Carousel
                // carouselContentType='courseCreators'
                backgroundColour={'blue'}
                titleHeight='28px'
                height='310px'
                slidesToShow={2}
                items={carouselItemsFiltered.filter((e,i) => i >=0 && i <=1).map(e => ({...e, navigateFunction: handleNav(e.linkUrl)}))}
                cardColor='leafGreen'    
                paddingBottom='20px'            
            />
            <Carousel
                // carouselContentType='courseCreators'
                backgroundColour={'blue'}
                titleHeight='28px'
                height='310px'
                items={carouselItemsFiltered.filter((e,i) => i >=2 && i <=3).map(e => ({...e, navigateFunction: handleNav(e.linkUrl)}))}
                cardColor='leafGreen'  
                slidesToShow={2}   
                topHalfBackgroundColour='rgb(25, 57, 88)'   
                // items={carouselItems}        
            />
        </div>

        <BottomBanner backgroundColour="leafGreen" header="Questions?">
            {/* <p style={{ fontSize: '18px' }}>Whether you are interested in one of our products or would like to know more about working with Practice Unbound, please give us a call on 0300 303 8145 or email <a style={{ fontWeight: 'bold', color: 'white' }} href="mailto:info@practiceunbound.org.uk">info@practiceunbound.org.uk</a></p> */}
            <p style={{ fontSize: '18px' }}><Link style={{ color: 'white' }} to="/contact-us">Contact the team</Link> or call us on <strong>0300 303 8145</strong></p>
        </BottomBanner>

    </div>    

 ) 
  
}

const testimonials = [
    {
        quote: 'We were looking at an easier and more time-efficient way of keeping track of invoicing/balancing/outstanding items of private fees and business invoices. We had been working the same way for many years and felt the need for a more efficient way of working".',
        author: 'Sharon Clement | Finance Manager'
    },
    {
        quote: `Before GP Billing we had 'manual log' for the Secretaries/Admin team to input invoice details for private work. They would generate a word document for invoices individually filling in each one as necessary"`,
        author: 'Sharon Clement | Finance Manager'
    },
    {
        quote: `Overall, I think a great start to GP Biling - we await February 2022 once our year-end is done but 'so far so good."`,
        author: 'Sharon Clement | Finance Manager'
    },
]