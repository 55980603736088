import ImageTextBlock from '../../PLM/Comps/ImageTextBlock'
import greyCircles from '../../../../img/slightly-dark-grey-bubbles-right.svg'
import Quote from '../../../common/Quote'
import BottomBanner from '../../PLM/Comps/BottomBanner'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import Carousel from '../../../common/Carousel'
import {carouselItems} from './SuccessStories'
import pickeringImg from '../../../../img/02 Success Stories/image_pickering_medical_practice@3x.jpg'
import DivFade from '../../DivFade'
import { useHistory } from 'react-router-dom'

const carouselItemsFiltered = carouselItems.filter(i => i.title !== 'Pickering Medical Practice')

export default function Pickering() {

  const history = useHistory()  
  const handleNav = (url) => () => history.push(url)

  return (

    <div>

        <ImageTextBlock
            imageUrl={pickeringImg}
            contentSide="left"
            contentBackgroundColor="white"
            contentBackgroundImage={greyCircles}
            contentBackgroundSize='40%'
            contentBackgroundPosition='top left'
            contentBackgroundRight='5%'
            contentBackgroundTop='-15%'
        >
        <div className='py-5'>
            <h3 className='font-weight-bold mb-3'>Pickering Medical Practice</h3>
            <p>Pickering Mecial Practice switched their paper-based billing processes to GP-Billing and the practice is now successfully capturing otherwise missed income.</p>
            <p>Located in the historic market town of Pickering on the edge of the North Yorkshire moors, the medical practice runs two sites with a combined patient list size of 10,500.</p>
            <p>Prior to implementing digital billing software, Pickering Medical Practice were relient on separate technologies to raise bills, such as paper-based receipt books, typed word processing documents and paper worksheets. Different departments across the surgery had to utilise non-centralised systems nad manually record income for services such as travel injections and private letters.</p>
            <p>The introduction of GP-Billing has allowed the practice to work in a more efficient and transparent way. Clinical systme integration has further enables the practice to accurately sync payment details to the patient record.</p>
        </div>
        </ImageTextBlock>

        <DivFade className='px-4'>
            <div className='container p-5'>
                <Quote
                    // key={`${index}: ${item}`} 
                    author={testimonials[0].author} 
                    quote={testimonials[0].quote} 
                    // iconColour={props.quoteIconColour}
                />
            </div>
        </DivFade>

        <DivFade className='bg-white px-5 py-3'>
            <div className='container p-5 bg-white'>
                <h2 className='font-weight-bold mb-4' style={{ textWrap:'balance'}}>Improving governance through consistency</h2>
                <p>Accuracy of information is extremely important for the practice as it helps to minimise any governance risk whilst the reporting dashboard helps temas to easily manage and check the status of payments</p>
            </div>
        </DivFade>

        <DivFade className='px-4'>
            <div className='container p-5'>
                <Quote
                    // key={`${index}: ${item}`} 
                    author={testimonials[1].author} 
                    quote={testimonials[1].quote} 
                    // iconColour={props.quoteIconColour}
                />
            </div>
        </DivFade>
        
        <DivFade className='bg-white px-5'>
            <div className='container p-5 bg-white'>
                <div className='mb-5'>
                    <p>Pickering Medical Practice introduced a published price list as a result of successfully embidding the system, the price list is now reviewed annually.</p>
                </div>
                <div className='mb-5'>
                    <h2 className='font-weight-bold mb-3' style={{ textWrap:'balance'}}>Capturing otherwise missed payments</h2>
                    <p>Prior to GP-Billing, a centralised log wasn't kept up to date making it difficult for the teams to get a 369 view of outstanding payments before patient tasks were processed. GP Billing now "facilitates the secretaries in confirming payments have been received before sending out certain reports", said Carol Nabb.</p>
                </div>
                <div>
                    <h2 className='font-weight-bold mb-3'>Data on-demand</h2>
                    <p>The Transaction and Aged Debt Reports have proced useful to the practice to enable them to identify and chase up outstanding payments. Further to this , payment types such as BACS and cards can be easily reported and confirmed using the system, making it easy to reconcile and match tansactions to patient payments.</p>
                    <p>GP-Billing has helped improve the billing workflow for Pickering Medical Practice and allowed the organisation to keep track of outstanding patments all whilst improving governance and reducing unput errors.</p>
                </div>
            </div>
        </DivFade>

        {/* <Carousel
            header="Related Success Stories"
            // carouselContentType='courseCreators'
            backgroundColour={'blue'}
            titleHeight='28px'
            height='310px'
            items={carouselItemsFiltered}        
        /> */}

        <div className='d-block d-lg-none'>
            <Carousel
            // carouselContentType='courseCreators'
            backgroundColour={'blue'}
            titleHeight='28px'
            height='310px'
            items={carouselItemsFiltered.map(e => ({...e, navigateFunction: handleNav(e.linkUrl)}))}  
            cardColor='leafGreen'          
            />
        </div>

        <div className='d-none d-lg-block'>
            <Carousel
                // carouselContentType='courseCreators'
                backgroundColour={'blue'}
                titleHeight='28px'
                height='310px'
                slidesToShow={2}
                items={carouselItemsFiltered.filter((e,i) => i >=0 && i <=1).map(e => ({...e, navigateFunction: handleNav(e.linkUrl)}))}   
                cardColor='leafGreen' 
                paddingBottom='20px'            
            />
            <Carousel
                // carouselContentType='courseCreators'
                backgroundColour={'blue'}
                titleHeight='28px'
                height='310px'
                items={carouselItemsFiltered.filter((e,i) => i >=2 && i <=3).map(e => ({...e, navigateFunction: handleNav(e.linkUrl)}))} 
                cardColor='leafGreen' 
                slidesToShow={2}   
                topHalfBackgroundColour='rgb(25, 57, 88)'   
                // items={carouselItems}        
            />
        </div>

        <BottomBanner backgroundColour="leafGreen" header="Questions?">
            {/* <p style={{ fontSize: '18px' }}>Whether you are interested in one of our products or would like to know more about working with Practice Unbound, please give us a call on 0300 303 8145 or email <a style={{ fontWeight: 'bold', color: 'white' }} href="mailto:info@practiceunbound.org.uk">info@practiceunbound.org.uk</a></p> */}
            <p style={{ fontSize: '18px' }}><Link style={{ color: 'white' }} to="/contact-us">Contact the team</Link> or call us on <strong>0300 303 8145</strong></p>
        </BottomBanner>

    </div>    

 ) 
  
}

const testimonials = [
    {
        quote: 'The clinical system integration is very important as it helps stop oatinet names being misspelt and entered more than once. It also gives accureate details to look up the patient on the clinical system should there be any further information needed."',
        author: 'Carol Nabb | Finance Assistant'
    },
    {
        quote: 'The ability to set up price lists within the system improves accuracy as it allows an item to be pre-set with retards to whether or note to charge VAT. The price lists on the system also helps to keep charges consistent across the Practice."',
        author: 'Carol Nabb | Finance Assistant'
    },
]