import React from 'react'
import styles from './CheckboxArray.module.css'
import classnames from 'classnames'

const CheckboxArray = ({
    value,
    onChange,
    options,
    name,
    inline,
    className
}) => {

    let checkedOptions = value || []

    const onCheck = (changeValue) => {
        const index = checkedOptions.indexOf(changeValue)
        if (index === -1) {
            checkedOptions.push(changeValue)
        } else {
            checkedOptions.splice(index, 1)
        }
        onChange({
            target: {
                name: name,
                value: checkedOptions
            }
        })
    }

    return (
        <div className={classnames(
            className,
            { 'd-flex justify-content-around': inline }
        )}>
            {options.map(o =>
                <Checkbox
                    key={o.value}
                    checked={checkedOptions.indexOf(o.value) === -1 ? false : true}
                    label={o.label}
                    value={o.value}
                    onCheck={onCheck}
                />
            )}

        </div>
    )
}
export default CheckboxArray




const Checkbox = (props) => {
    return (
        <div
            className={classnames(
                "mb-1",
                styles.card,
                { [styles.checked]: props.checked },
            )}
            onClick={() => { props.onCheck(props.value) }}
        >
            <input
                type="checkbox"
                checked={props.checked}
                readOnly
                style={{ cursor: 'pointer', marginRight: '6px' }}
            />
            <label htmlFor={props.value} className="form-check-label" style={{ cursor: 'pointer' }}>
                {props.label}
            </label>
        </div>
    )
}
