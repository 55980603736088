import React, { Component } from 'react'
import ImageCircleCard from './ImageCircleCard'
import Button from '../common/Button'
import Modal from '../common/Modal'

export default class PLMSuitable extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
    }

    render() {
        return (
            <>
                <div id="plm-suitable"
                    style={{
                        display: 'block',
                        position: 'relative',
                        top: '-120px',
                        visibility: 'hidden'
                    }}
                />
                <div className="row justify-content-center" >
                    <div className="col-md-8 col-sm-12">
                        <ImageCircleCard
                            header="Who is the course suitable for?"
                            color="teal"
                            paragraph={
                                <div className="pt-3">
                                    <Button
                                        colour="teal"
                                        center
                                        inverted
                                        large
                                    >Find Out More</Button>
                                </div>
                            }
                        >

                        </ImageCircleCard>
                    </div>
                    <Modal
                        color="yellow"
                        isOpen={this.state.isOpen} onRequestClose={() => { this.setState({ isOpen: false }) }}
                        title="Who is this course for?"
                    >
                        <p>Prescribing Lifestyle medicine is aimed at clinicians and specialist non clinicians including:</p>
                        <ul>
                            <li><p className="mb-0">Doctors</p></li>
                            <li><p className="mb-0">Nurses</p></li>
                            <li><p className="mb-0">Pharmacicts</p></li>
                            <li><p className="mb-0">Health Coaches</p></li>
                            <li><p className="mb-0">Dentists</p></li>
                            <li><p className="mb-0"><a href="https://www.england.nhs.uk/gp/expanding-our-workforce/" target="_blank" rel="noopener noreferrer">ARRS Roles</a></p></li>
                            <li><p className="mb-0"><a href="https://www.england.nhs.uk/ahp/role/" target="_blank" rel="noopener noreferrer">Allied Health Professionals</a></p></li>
                            <li><p className="mb-0"><a href="https://www.hcpc-uk.org/about-us/who-we-regulate/the-professions/" target="_blank" rel="noopener noreferrer">Other Health & Care Professions</a></p></li>
                            <li><p className="mb-0">Medical Students and Medical Schools</p></li>
                        </ul>

                        <p>If you are not a Clinician, or a Specialist Clinician, but feel your role is still applicable, <a href="mailto:info@practiceunbound.org.uk?subject=PLM%20Job%20Role%20Enquiry%20">contact info@practiceunbound.org.uk</a></p>

                    </Modal>
                </div>
            </>
        )
    }
}
