import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faAngleRight, faAngleLeft, faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
library.add(faAngleRight);
library.add(faAngleLeft);
library.add(faAngleDown);
library.add(faAngleUp);

export default function ChevronBox(props) {

  const { direction } = props;

  return (

    <FontAwesomeIcon
      icon={direction === "left" ? "angle-left" : direction === "up" ? "angle-up" : direction === "down" ? "angle-down" : "angle-right"}
      size={props.large ? "3x" : "2x"}
      role="img"
      aria-label={direction === "left" ? "previous" : "next"}
      alt={props.alt}
      style={{
        cursor: props.cursor || "pointer",
        //slight adjustments to position chevron centrally within circle
        position: props.position || 'static',
        inset: direction === 'left' ? '15% 30% 0 30%' : direction === 'right' ? '15% 30% 0 35%' : direction === 'down' ? '15% 30% 0 35%' : '',
        top: props.top && props.textExpand && props.top,
        left: props.left && props.textExpand && props.left,
      }}
    />

  )
}
