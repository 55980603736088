import React, { Component } from 'react'
import { Route } from 'react-router-dom';
import PurchaseSubRouter from './PurchaseSubRouter'
import detectIe from '../../utils/detectIe'
import Modal from '../common/Modal'

export default class PurchaseRouter extends Component {

    render() {
        const ieVersion = detectIe();
        const { match } = this.props;
        return (
            <div>
                {ieVersion && ieVersion <= 11 && (
                    <Modal color="pink" isOpen title="Incompatible Browser">
                        <p>The internet browser that you are currently using is too out of date to support purchasing online. We suggest <a href="https://www.google.com/chrome/">downloading Google Chrome</a> or another modern browser in order to continue.</p>
                        <a href='/'>Back to home page</a>

                    </Modal>
                )}
                <Route
                    exact
                    path={match.url + '/:product'}
                    component={PurchaseSubRouter}
                />
                {/* The thank you is in app.js */}
            </div>
        )
    }
}
