import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { getPurchaseProduct, getCrmOrganisation, getCrmContract, getOrganisationMemberships, clearPurchaseError } from '../../actions/purchaseActions'
import Spinner from '../common/Spinner'
import PUPanel from '../layout/PUPanel'
import queryString from 'query-string'
import PurchaseCRMHome from './PurchaseCRMHome';
import PurchaseBillsbyOrganisationHome from './PurchaseBillsbyOrganisationHome';
import PurchaseBillsbyNonOrganisationHome from './PurchaseBillsbyNonOrganisationHome';

class PurchaseSubRouter extends Component {
    componentDidMount() {
        this.props.getPurchaseProduct(this.props.match.params.product)
        this.props.getOrganisationMemberships()
    }
    componentDidUpdate(prevProps) {
        if (prevProps.purchase.productLoading && !this.props.purchase.productLoading) {
            const parsed = queryString.parse(this.props.location.search);
            if (parsed.organisation) {
                this.props.getCrmOrganisation(parsed.organisation)
                this.props.getCrmContract(parsed.organisation, this.props.purchase.product.name)
            }
        }

    }

    render() {
        const { product, productLoading } = this.props.purchase
        const { match } = this.props;
        if (Object.keys(product).length === 0) return <Spinner fullScreen color='yellow' large />
        if (productLoading) return <Spinner fullScreen color='yellow' large />


        if (!product.allowOnlineContracting) {
            return (
                <div className="container py-5">
                    <PUPanel title={"Purchase error"}>
                        <p>
                            Sorry, this product has not been set up for online purchasing.
                </p>
                    </PUPanel>
                </div>
            )
        }


        if (product.onlineContractingType === 'Billsby Organisation') return <Route
            exact
            path={match.url}
            component={PurchaseBillsbyOrganisationHome}
        />
        if (product.onlineContractingType === 'Billsby Non Organisation') return <Route
            exact
            path={match.url}
            component={PurchaseBillsbyNonOrganisationHome}
        />

        return <Route
            exact
            path={match.url}
            component={PurchaseCRMHome}
        />
    }
}

const mapStateToProps = state => ({
    purchase: state.purchase,
})
export default connect(mapStateToProps, { getPurchaseProduct, getCrmOrganisation, getCrmContract, getOrganisationMemberships, clearPurchaseError })(PurchaseSubRouter);