import React from "react";
import PracticeUnboundYellow from "../../img/PracticeUnboundYellow.png";
// import HereLogo from "../../img/powered-by-here-logo.png";
import CPDmember from "../../img/CPDmember.png";
import BusinessWomenLogo from "../../img/BusinessWomenLogo.png";
import EmployeeOwnedLogo from "../../img/EmployeeOwnedLogo.png";
import LinkedInLogo from "../../img/LinkedInLogo.png";
import HealthTechAwards from "../../img/HealthTechAwards.png";
import CoalitionLogo from "../../img/CoalitionForCareLogo.png";
import TwitterLogo from "../../img/TwitterLogo.png";
import RCGPLogo from "../../img/Accreditation-Mark_ 2012JPEG.png"
import PCILogo from "../../img/PCI_Accredited_Mark_RGB.png"
import Button from "../common/Button"
import puColours from "../../utils/puColours";

export default function BigFooter(props) {

  const images = {
    hereLogo: "https://practiceunbound.blob.core.windows.net/stock-images/Here_LogoLockup_PoweredBy_RGB min.png"
  }

  return props.nlsRedesign ? (
    <div>
      <div className="container-fluid" role="complementary" aria-label="footer navigation" style={{ backgroundColor: "white" }}>
        <div className="container">
          <div className="row justify-content-center pt-5">
            <div 
              className="col-12 col-sm-8 col-md-6 col-lg-5 text-center mb-3" 
            >
              <div className="row">
                <div className="col-md-6 text-left p-0">
                  <Button colour="none" link="/our-story" compact lowercase bold alignLeft>
                    Our story
                  </Button>
                  <Button colour="none" link="/careers" compact lowercase bold alignLeft>
                    Careers
                  </Button>
                  <Button colour="none" link="/faq" compact lowercase bold alignLeft>
                    FAQs
                  </Button>
                </div>  
                <div className="col-12 col-md-6 p-0">           
                  <Button colour="none" link="/privacy-notice" compact lowercase bold alignLeft>
                    Privacy Policy
                  </Button>
                  <Button colour="none" link="/terms-conditions" compact lowercase bold alignLeft>
                    Terms & Conditions
                  </Button>
                  <Button colour="none" link="/login" compact lowercase bold alignLeft>
                    Login
                  </Button>
                </div>   
              </div>
              <div className="tw-py-5 " />
            </div>
            <div 
              className="col-12 col-lg-4 text-center mb-3" 
            >
              <div className="row justify-content-center">
                <div className="row">
                  {[
                    { src: CoalitionLogo, alt: "Coalition for Collaborative Care logo", maxWidth: "100px" },
                    { src: CPDmember, alt: "CPD membership", maxWidth: "100px" },
                    { src: RCGPLogo, alt: "RCPG Accredited logo", maxWidth: "100px" },
                    { src: PCILogo, alt: "PCI logo", maxWidth: "100px" },
                  ].map((logo, i) => (
                  <div key={`${i}:${logo.alt}`} className="col-3 tw-flex tw-flex-col tw-justify-center tw-items-center text-center mb-md-4">
                    <div className={`tw-max-w-[80px]`}>
                      <img
                        src={logo.src}
                        alt={logo.alt}
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  ))}
                </div>
              </div>
            <div className="tw-py-5 md:tw-py-0" />
            </div>
            <div 
              className="col-lg-3 text-center mb-2 d-flex align-items-center justify-content-left order-lg-first pl-0" 
            >
                <div className="col tw-flex tw-flex-col tw-justify-center tw-items-center">
                  <img
                    style={{ maxHeight: "42px", marginBottom: '1rem' }}
                    src={PracticeUnboundYellow}
                    alt="Practice Unbound Logo"
                    className="img-fluid"
                  />
                  <div className={`tw-w-32`}>
                    <a
                      href="http://hereweare.org.uk/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      <img
                        // style={{ maxHeight: "42px" }}
                        src={images.hereLogo}
                        alt="Here Logo"
                        className="img-fluid"
                      />
                    </a>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div >
    </div>
  )

  :

  (
    <div>
      <div className="container-fluid" role="complementary" aria-label="organisation logos" style={{ backgroundColor: "#E8E8E8" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="row w-100">
              <div className="col-12 col-md-3 text-center mt-4 mb-2 mb-md-4">
                <img
                  style={{ height: "94px", paddingLeft: '20px' }}
                  src={CoalitionLogo}
                  alt="Coalition for Collaborative Care logo"
                  className="img-fluid"
                />
              </div>
              <div className="col-12 col-sm-4 col-md-2 text-center my-4">
                <img
                  style={{ height: "94px", Width: "94px", verticalAlign: 'middle' }}
                  src={CPDmember}
                  alt="CPD membership"
                  className="img-fluid"
                />
              </div>
              <div className="col-6 col-sm-4 col-md-2 text-center my-4">
                <img
                  style={{ height: "94px", Width: "94px" }}
                  src={BusinessWomenLogo}
                  alt="Business Woman of the Year"
                  className="img-fluid"
                />
              </div>
              <div className="col-6 col-sm-4 col-md-2 text-center my-4">
                <img
                  style={{ height: "94px", Width: "10 4px" }}
                  src={HealthTechAwards}
                  alt="Health Tech Awards"
                  className="img-fluid"
                />
              </div>

              <div className="col-12 col-md-3 text-center align-self-center mt-2 mt-md-2 mb-4">
                <img
                  style={{ maxHeight: "44px", }}
                  src={EmployeeOwnedLogo}
                  alt="Employee Owned Organisation"
                  className="img-fluid "
                />
              </div>
            </div>
          </div>
        </div>
      </div >
      <div className="container-fluid" role="complementary" aria-label="footer navigation" style={{ backgroundColor: "#dbdbdb" }}>
        <div className="container">
          <div className="row justify-content-center pt-5">
            <div className="col-sm-4 col-md-3 text-center mb-2 d-flex align-items-center justify-content-center">
              <div className="row w-100">
                <div className="col-6 col-sm-12">
                  <img
                    style={{ maxHeight: "42px" }}
                    src={PracticeUnboundYellow}
                    alt="Practice Unbound Logo"
                    className="img-fluid"
                  />
                </div>
                <div className="col-6 col-sm-12">
                  <small className="text-muted d-block">brought to you by</small>
                  <a
                    href="http://hereweare.org.uk/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <img
                      style={{ maxHeight: "42px" }}
                      src={images.hereLogo}
                      alt="Here Logo"
                      className="img-fluid"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-9 col-sm-8 col-md-6 text-center mb-3">
              <div className="row">
                <div className="col-sm-6">
                  <Button colour="none" link="/customer-stories">
                    Customer Stories
                  </Button>
                  <Button colour="none" link="/our-story">
                    Our story
                  </Button>
                  <Button colour="none" link="/careers">
                    Careers
                  </Button>
                  {/* <Button colour="none" link="/newsletter">
                    Newsletter
                  </Button> */}
                </div>
                <div className="col-sm-6">
                  <Button colour="none" link="/login">
                    Login
                  </Button>
                  <Button colour="none" link="/faq">
                    FAQ
                  </Button>
                  <Button colour="none" link="/privacy-notice">
                    Privacy
                  </Button>
                  <Button colour="none" link="/terms-conditions">
                    T&C
                  </Button>
                </div>
              </div>
            </div>


          </div>
          <div className="row  mt-3">
            <div className="col-12 mb-5 text-center">
              <a
                href="https://twitter.com/PracticeUnbound"
                target="_blank"
                rel="noopener noreferrer"
                className="mx-2"
              >
                <img
                  style={{ maxHeight: "32px" }}
                  src={TwitterLogo}
                  alt="Twitter"
                  className="img-fluid"
                />
              </a>
              {/* <a
                href="https://www.instagram.com/practiceunbound"
                target="_blank"
                rel="noopener noreferrer"
                className="mx-2"
              >
                <img
                  style={{ maxHeight: "32px" }}
                  src={InstagramLogo}
                  alt="Instagram"
                  className="img-fluid"
                />
              </a> */}
              <a
                href="https://www.linkedin.com/company/10549233"
                target="_blank"
                rel="noopener noreferrer"
                className="mx-2"
              >
                <img
                  style={{ maxHeight: "32px" }}
                  src={LinkedInLogo}
                  alt="LinkedIn"
                  className="img-fluid"
                />
              </a>
              {/* <a
                href="https://www.youtube.com/channel/UC6_s8RwHdZxxk5rz1qR_crw"
                target="_blank"
                rel="noopener noreferrer"
                className="mx-2"
              >
                <img
                  style={{ maxHeight: "32px" }}
                  src={YoutubeLogo}
                  alt="YouTube"
                  className="img-fluid"
                />
              </a> */}
            </div>
          </div>
        </div>
      </div >
    </div>
  );
}
