import styles from './CircleTextBlock.module.css'
import ImageFadeIn from '../../ImageFadeIn'
import DivFade from '../../DivFade'

export default function CircleTextBlock(props) {

    return (
        <DivFade
            style={{
                position: 'relative',
                overflow: 'hidden'
                // height: `${props.imageIsCircle && '450px'}`
            }}
            className="d-flex flex-column justify-content-center align-items-center"
        >

            <div style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            }}>
                <div className="d-flex h-100 overflow-hidden" style={{ backgroundColor: props.contentBackgroundColor }}>
                    <div className={`${styles.colWidth75} ${props.contentSide === 'right' && 'order-last'}`}
                        style={{
                            position: 'relative',
                            overflow: 'hidden',
                            backgroundColor: props.contentBackgroundColor,
                        }}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                top: props.contentBackgroundTop,
                                left: props.contentBackgroundLeft,
                                right: props.contentBackgroundRight,
                                bottom: props.contentBackgroundBottom,
                                width: '100%',
                                height: '100%',
                                backgroundRepeat: 'no-repeat',
                                backgroundImage: `url(${props.contentBackgroundImage})`,
                                backgroundSize: props.contentBackgroundSize,
                                backgroundPosition: props.contentBackgroundPosition
                            }} />
                    </div>


                    {props.imageIsCircle ?

                        <div className={`${styles.imageGrow} d-none d-md-block position-relative`}>
                            <div
                                className={`${styles.imageCircle}`}
                                style={{
                                    // bottom: '104px',
                                    // right: '-136px', 
                                    // left: '0px',                
                                }}
                            >
                                <div
                                    style={{
                                        width: '100%',
                                        paddingTop: '100%',
                                        borderRadius: '50%',
                                        background: '#193958'
                                    }}
                                >
                                </div>
                            </div>
                        </div>

                        :

                        <div className={`${styles.imageGrow} d-md-block d-none`}>
                            <ImageFadeIn
                                imageSrc={props.imageUrl}
                                style={{
                                    backgroundRepeat: 'no-repeat',
                                    backgroundImage: `url(${props.imageUrl})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: props.imageBackgroundPosition
                                }}
                            />
                        </div>

                    }

                </div>
            </div>


            <div className="container pl-sm-0 pl-md-3" >
                <div className="row">
                    <div className={`col-md-6 ${props.contentSide === 'right' && 'order-last'}`} >
                        <div className='pt-md-5' />
                        {props.children}
                        <div className='pt-md-5' />
                    </div>


                    {/* {props.circleContent &&
                    <div className="col-md-6 blah">
                        {props.circleContent()}
                    </div>
                    } */}

                    {props.circleContent &&
                        <div className="col-12 col-md-6" >
                            <div className='d-block d-md-none'>
                                <div
                                    style={{
                                        background: '#193958',
                                        position: 'absolute',
                                        inset: '-29px 0px -147px -204px',
                                        width: '200%',
                                        borderRadius: '50%'
                                    }}
                                />
                            </div>
                            <div>
                                {props.circleContent()}
                            </div>
                        </div>
                    }

                </div>
            </div>
        </DivFade>
    )
}




CircleTextBlock.defaultProps = {
    contentSide: 'left',
    contentBackgroundColor: 'white',
    contentBackgroundSize: 'cover',
    contentBackgroundImage: undefined,
    contentBackgroundTop: undefined,
    contentBackgroundLeft: undefined,
    contentBackgroundRight: undefined,
    contentBackgroundBottom: undefined,
    contentBackgroundPosition: '0% 0%',
    imageUrl: undefined,
    imageBackgroundPosition: 'center'
};