import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom'
import { getFAQContent } from '../../actions/pagesActions'
import ContentBlockPage from '../layout/ContentBlockPage'
import Spinner from '../common/Spinner'
import SubPageScreen from './SubPageScreen'

class FAQScreen extends Component {
    componentDidMount() {
        if (Object.keys(this.props.pages.faqContent).length === 0 && this.props.pages.faqContentLoading === false) {
            this.props.getFAQContent()
        }
    }

    render() {
        const { faqContentLoading, faqContent } = this.props.pages

        return (
            <Switch>
                <Route exact path={this.props.match.url + '/'} >
                    {faqContentLoading ? <Spinner fullScreen color="yellow" large /> : <ContentBlockPage content={faqContent} />}
                </Route>
                <Route
                    exact
                    path={this.props.match.url + '/:subPage'}
                    render={(props) => <SubPageScreen {...props} pageType="faqs" />}
                />
            </Switch>
        )
    }
}
const mapStateToProps = state => ({
    pages: state.pages
})
export default connect(mapStateToProps, { getFAQContent })(FAQScreen);