import React from 'react'
import TopBannerPlain from '../../PLM/Comps/TopBannerPlain'
import Carousel from '../../../common/Carousel'
import BottomBanner from '../../PLM/Comps/BottomBanner'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import gpbillingLogo from '../../../../img/03 Top Billable GP Services 2020/gp_billing_logo_circle.png'
import hallGreenSmallImg from '../../../../img/02 Success Stories/thumbnail_hall_green_health@3x.jpg'
import northgateSmallImg from '../../../../img/02 Success Stories/thumbnail_northgate_surgery@3x.jpg'
import pickeringSmallImg from '../../../../img/02 Success Stories/thumbnail_pickering_medical_practice@3x.jpg'
import picsSmallImg from '../../../../img/02 Success Stories/thumbnail_pics@3x.jpg'
import riversideSmallImg from '../../../../img/02 Success Stories/thumbnail_riverside_medical_practice@3x.jpg'
import { useHistory } from 'react-router-dom'


export default function SuccessStories() {

  const history = useHistory()  
  const handleNav = (url) => () => history.push(url)
  
  return (
    

    <div>
      
      <TopBannerPlain 
        circleColours={{
          one:"#60AE4C",
          two:"#113B5B",
          three:"#2372B5",
        }}  
        circleLogo={gpbillingLogo}
        backgroundCircles 
        paddingTop='50px' 
        paddingBottom='30px'   
      >
        <h3 className='font-weight-bold text-capitalize'>success stories</h3>
        <div className='py-2'/>
        <p>Learn how practices are using GP Billing to meet demand, reduce pressures and save time.</p>
      </TopBannerPlain>

      <div className='d-block d-lg-none'>
        <Carousel
          // carouselContentType='courseCreators'
          backgroundColour={'blue'}
          titleHeight='28px'
          height='310px'
          items={carouselItems.map(e => ({...e, navigateFunction: handleNav(e.linkUrl)}))}  
          cardColor='leafGreen'          
        />
      </div>

      <div className='d-none d-lg-block'>
        <Carousel
          // carouselContentType='courseCreators'
          backgroundColour={'blue'}
          titleHeight='28px'
          height='310px'
          items={carouselItems.filter((e,i) => i >=0 && i <=2).map(e => ({...e, navigateFunction: handleNav(e.linkUrl)}))}   
          paddingBottom='20px'            
          cardColor='leafGreen'
        />
        <Carousel
          // carouselContentType='courseCreators'
          backgroundColour={'blue'}
          titleHeight='28px'
          height='310px'
          items={carouselItems.filter((e,i) => i >=3 && i <=4).map(e => ({...e, navigateFunction: handleNav(e.linkUrl)}))}  
          slidesToShow={2}   
          topHalfBackgroundColour='rgb(25, 57, 88)'   
          cardColor='leafGreen'
          // items={carouselItems}        
        />
      </div>


      <BottomBanner backgroundColour="leafGreen" header="Questions?">
        {/* <p style={{ fontSize: '18px' }}>Whether you are interested in one of our products or would like to know more about working with Practice Unbound, please give us a call on 0300 303 8145 or email <a style={{ fontWeight: 'bold', color: 'white' }} href="mailto:info@practiceunbound.org.uk">info@practiceunbound.org.uk</a></p> */}
        <p style={{ fontSize: '18px' }}><Link style={{ color: 'white' }} to="/contact-us">Contact the team</Link> or call us on <strong>0300 303 8145</strong></p>
      </BottomBanner>

    </div>    

 ) 
  
}

export const carouselItems = [
    {
      key: 1,
      title: 'Pickering Medical Practice',
      image: pickeringSmallImg,
      paragraph: 'Pickering Medical Practice switched their paper-based billing processes to GP-Billing and the practice is now successfully capturing otherwise missed income.',
      linkName: 'Read more',
      linkUrl: '/product/gpbilling/success-stories/pickering-medical-practice'

    },
    {
      key: 2,
      title: 'Riverside Medical Practice',
      image: riversideSmallImg,
      paragraph: 'Riverside Medical Practice has a total patient list asize of 11,500. With a diverse popoulateion and an increased focus within the practice to work more efficiently',
      linkName: 'Read more',
      linkUrl: '/product/gpbilling/success-stories/riverside-medical-practice'

    },
    {
      key: 3,
      title: 'Northgate Surgery',
      image: northgateSmallImg,
      paragraph: 'Northgate Surgery is using GP Billing to increase data accuracy and is already. The organisation embraces new technologies and systems that work to streamline their processes.',
      linkName: 'Read more',
      linkUrl: '/product/gpbilling/success-stories/northgate-surgery'

    },
    {
      key: 4,
      title: 'Hall Green Health',
      image: hallGreenSmallImg,
      paragraph: 'Hall Green Health have transformed their private and business income using GP-Billing. The practice os now saving 73 days per annum and is capturing more income than ever',
      linkName: 'Read more',
      linkUrl: '/product/gpbilling/success-stories/hall-green-health'

    },
    {
      key: 5,
      title: 'Primary Integrated Community Services',
      image: picsSmallImg,
      paragraph: 'Learn more about how PICS adopted new technology, including GP Billing, to support new of working in response to the pandemic',
      linkName: 'Read more',
      linkUrl: '/product/gpbilling/success-stories/primary-integrated-community-services'

    }
  ]

// const carouselItemsBottom = [
//     {
//       key: 1,
//       title: 'Pickering Medical Practice',
//       image: 'https://practiceunbound.blob.core.windows.net/stock-images/AyanHompageTopAndBottomImageCarousel-min.jpg',
//       paragraph: 'Pickering Medical Practice switched their paper-based billing processes to GP-Billing and the practice is now successfully capturing otherwise missed income.'
//     },
//     {
//       key: 2,
//       title: 'Dr Rangan Chatterjee',
//       subtitle: 'BSc (Hons) MRCP MRCGP',
//       image: 'https://practiceunbound.blob.core.windows.net/stock-images/RanganHomepageTopImage_2017_07_Chatterjee-3852-Edit-3Carousel-min.jpg',
//       paragraph: 'He has a keen interest in the relationship of the gut microbiome to human health and his BSc Honours degree in Immunology helps him navigate this complex and exciting new field. As a leader in this field, he advocates internationally about lifestyle medicine which he believes should be personalised, participatory and progressive.'
//     },
//   ]