import React from 'react';
import styles from './HomePageDots.module.css'
import DivFade from './DivFade'

export default function HomePageDots() {

    const dots = [
        { number: '3k', plus: '+', label: 'Practices using our programmes' },
        { number: '10k', plus: '+', label: 'Practice staff trained' },
        { number: '1m', plus: '+', label: 'GP hours released' },
        // { number: '1m', plus: '+', label: 'GP hours released' },
        // { number: '1m', plus: '+', label: 'GP hours released' },
    ]

    return (
        // <div style={{backgroundColor: 'blue'}}>
        //     {/* <div className={styles.dotsContainer}> */}
        //         {/* <div className={styles.dotsChildContainer}> */}
        //             <div className={`row m-4 px-5`} style={{border: 'solid purple 2px', borderRadius: '20px', backgroundColor: 'grey'}}>
        //                 {dots.map(dot => (
        //                     <div className={`${styles.dot} col-sm-4`} style={{border: 'solid red 2px'}}>
        //                         <div className={styles.circleSquare}>
        //                             <div className={styles.circle}>
        //                                 <h1 
        //                                     className={styles.number} style={{fontWeight: 'bold', color: 'white', letterSpacing: '0.04rem'}}>{dot.number}<span style={{color: 'orange'}}>{dot.plus}</span></h1>
        //                             </div>
        //                         </div>
        //                         <h2 style={{maxWidth: '150px'}}>{dot.label}</h2>
        //                     </div>
        //                 ))}
        //             </div>
        //         {/* </div> */}
        //     {/* // </div> */}
        // </div>
        <DivFade>
            <div className={styles.container}>
                {/* <div className={styles.dotsContainer}> */}
                    {/* <div className={styles.dotsChildContainer}> */}
                        <div className={styles.dotBox}>
                            {dots.map((dot, index) => (
                                <div className={styles.dot} key={`${index}: ${dot.label}`}>
                                    <div className={styles.circleSquare}>
                                        <div className={styles.circle}>
                                            <h1 
                                                className={styles.number} 
                                                style={{
                                                    fontWeight: 'bold', 
                                                    color: 'white',
                                                    fontSize: '1.5rem',
                                                    letterSpacing: '-0.05em',
                                                    margin: '1.5rem .8rem 1.5rem .8rem',
                                                }}
                                            >{dot.number}
                                                <span style={{color: 'orange'}}>{dot.plus}</span>
                                            </h1>
                                        </div>
                                    </div>
                                    <h2 
                                        style={{
                                            fontSize: '20px',
                                            fontWeight: 'bold',
                                            marginTop: '.5rem',
                                            marginLeft: '1rem',
                                            marginBottom: '1rem',
                                            maxWidth: '160px'
                                        }}
                                    >{dot.label}</h2>
                                </div>
                            ))}
                        </div>
                    {/* </div> */}
                {/* // </div> */}
            </div>
        </DivFade>
    )
}
