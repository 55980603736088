import React from 'react'
import ImageAbsolute from './ImageAbsolute'

export default function Hero(props) {
    return (
        <>
            <div
                style={{ position: 'relative', overflow: 'hidden', zIndex: '0', backgroundColor: "rgb(42,42,42)" }}
            >
                <ImageAbsolute
                    imageSrc={props.image}
                    backgroundPosition='50% 30%'
                    backgroundImage='linear-gradient(to right,rgba(42, 42, 42, 1) 0%, rgba(42, 42, 42, 0.8)20%,rgba(42, 42, 42, 0.3)60%,rgba(42, 42, 42, 0.3)80%, rgba(42, 42, 42, 1) 100%)'
                    marginLeft='auto'
                    marginRight='auto'
                    left='0'
                    right='0'
                    maxWidth='1200px'
                    height={props.shrunk ? '0px' : '100%'}
                    WebkitTransition='height 0.15s'
                    transition='height 0.15s'
                    backgroundRepeat='no-repeat'
                    backgroundSize='cover'
                />
                <div className="container"
                    style={{
                        height: props.shrunk ? '0px' : '450px',
                        WebkitTransition: 'height 0.15s',
                        transition: 'height 0.15s',
                    }}
                >
                    <div className="row h-100"
                        style={{
                            // color: props.shrunk ? 'black' : 'white',
                            color: 'white'
                        }}
                    >
                        <div className="col-12 col-sm-12 col-md-9 col-lg-7 col-xl-6">
                            < div className="d-flex flex-column justify-content-center h-100" >
                                {props.shrunk === false ? props.children : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}
