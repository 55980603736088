import Button from "../../common/Button"
import BannerFlex from "../BannerFlex"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import puColours from "../../../utils/puColours"
import ImageTextBlock from "../PLM/Comps/ImageTextBlock"
import DivFade from '../../../components/layout/DivFade'
import Block from "../Block"
import Calculator from '../../../components/layout/NLS/Calculator'
import SocialsIcon from "../../common/SocialsIcon"
import TicklistItem from "../../common/TicklistItem"
import Circles from "../../common/Circles"
import topBannerShapes from "./pricingBubbles"

const happyDoctor = "https://practiceunbound.blob.core.windows.net/stock-images/happy-doctor-holding-medical-paperwork-while-communicating-with-patient-during-medical-appointment-hospital.jpg"
const happyMatureWoman = "https://practiceunbound.blob.core.windows.net/stock-images/happy-mature-woman-talking-her-doctor-medical-clinic.jpg"

const PathGoBenefits = () => {
  return <>
    <BannerFlex
        backgroundCirclesObj={{
            width: '600px',
            left: '-310px',
            className: 'tw-animate-pulse-slow'
        }}
        shapes={topBannerShapes}
        // classNames={{
            //     shapeSection: ''
        // }}
        classNames={{shapesSection: 'tw-pb-36 md:tw-pb-28'}} 
    >
        <div className="tw-flex tw-flex-col tw-gap-3 tw-py-10">
            <section className="tw-pl-2">
                <h4 className="tw-font-bold tw-mb-5">Pathology Go Product Details</h4>
                <p>Pathology Go is priced on a per-patient model, and contains all the resources, training and help you need to get started with improved pathology test processing. Your Practice can install, train and launch Pathology Go in a matter of weeks.</p>
            </section>
            <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-4">
                <Button inline large colour="yellow-outline" link="/contact-us?contactType=sales">enquire now</Button>
            </div>
        </div>
    </BannerFlex>

    <ImageTextBlock
        imageUrl={happyDoctor}
        contentSide="right"
        contentBackgroundColor={puColours.darkMint}
        // contentBackgroundImage={greyCircles}
        contentBackgroundSize='40%'
        contentBackgroundPosition='top left'
        contentBackgroundRight='5%'
        contentBackgroundTop='-15%'
    >
        <div className="tw-py-10"/>
        <div className="tw-pl-6">

            <ul className="tw-text-white ">
                <TicklistItem iconColour={puColours.lightMint}>
                    <h5 className="tw-font-bold">Reduce the time your GPs spend looking at low-risk test results</h5>
                    <p className="tw-text-sm">Pathology Go offers practices a great way to cut GP time spent analysing low-risk results</p>
                </TicklistItem>
            <div className="tw-py-4"/>
                <TicklistItem iconColour={puColours.lightMint}>
                    <h5 className="tw-font-bold">Enhance clinical quality</h5>
                    <p className="tw-text-sm">Pathology Go's gold-standard decision aids for administrators means that patient outcomes are safely and consistently delivered to a high standard</p>
                </TicklistItem>
            </ul>
        </div>
        <div className="tw-py-10"/>
    </ImageTextBlock>

    <ImageTextBlock
        imageUrl={happyMatureWoman}
        contentSide="left"
        contentBackgroundColor={"white"}
        // contentBackgroundImage={greyCircles}
        contentBackgroundSize='40%'
        contentBackgroundPosition='top left'
        contentBackgroundRight='5%'
        contentBackgroundTop='-15%'
    >
        <div className="tw-py-10"/>
        <div className="tw-pl-6">
            <ul>
                <TicklistItem iconColour={puColours.lightMint}>
                    <h5 className="tw-font-bold">Increase focus on complex cases</h5>
                    <p className="tw-text-sm">Using Pathology Go empowers your GPs to spend more time on the cases most in need of clinical analysis and expertise</p>
                </TicklistItem>
            <div className="tw-py-4"/>
                <TicklistItem iconColour={puColours.lightMint}>
                    <h5 className="tw-font-bold">Improve processes in your Practice</h5>
                    <p className="tw-text-sm">Pathology Go enables practices to optimise use of staff, ensuring the most efficient and cost effective matching of staff member to task, helping to improve financial resilience</p>
                </TicklistItem>
            <div className="tw-py-4"/>
                <TicklistItem iconColour={puColours.lightMint}>
                    <h5 className="tw-font-bold">Engage your staff</h5>
                    <p className="tw-text-sm">Pathology Go delivers a programme of training, auditing and data monitoring that will engage and develop your admin staff</p>
                </TicklistItem>
            </ul>
        </div>
        <div className="tw-py-10"/>
    </ImageTextBlock>

    <DivFade style={{
        backgroundColor: puColours.darkMint,
        color: "white",
        position: 'relative',
        overflow: 'hidden'
    }}>
        {/* <img className='d-none d-md-block tw-animate-slow-spin' alt="" src={circles} style={{ bottom: '-300px', left: '-160px', position: 'absolute', filter: 'brightness(0) invert(87%) sepia(26%) saturate(7240%) hue-rotate(324deg) brightness(99%) contrast(90%)' }} /> */}
        {/* <img className='tw-animate-slow-reverse-spin' alt="" src={circles} style={{ top: '-300px', right: '-160px', position: 'absolute', filter: 'brightness(0) invert(87%) sepia(26%) saturate(7240%) hue-rotate(324deg) brightness(99%) contrast(90%)' }} /> */}
        <div className="container" style={{ position: 'relative', paddingTop: '75px', paddingBottom: '75px' }}>
            <div className="row">
                <div className="col-2" />
                <div className="col-9 col-md-8">
                    <h3 className='text-center mb-4 font-weight-bold'>Find out more</h3>
                    <p className='text-center mb-4' style={{textWrap: "balance"}}>Get in touch to find out more about how Pathology Go can improve your Practice, and save 1000s of GP hours.</p>
                    <Button inline center colour='none-outline' link="/contact-us?contactType=sales">enquire now</Button>
                </div>
                <div className="col-2" />
            </div>
        </div>
    </DivFade>    

    <Block textColor="black">
        <div className="tw-relative">
            <div
                className={`tw-absolute tw-top-0 tw-left-0 -tw-z-10`}
            >
                <Circles width='325' colour='#ECECED' />
            </div>
        </div>
        <Calculator buttonColour="yellow"/>
    </Block>

    <DivFade className="tw-bg-[#169F7B] text-white">
        <div className="container" style={{ paddingTop: '72px', paddingBottom: '72px' }}>
            <div className="row justify-content-between">
                <div className="col-sm-8 mb-3">
                    <h3 className="mb-4" style={{ fontWeight: 'bold' }}>Got questions? Talk to us…</h3>
                    <p>Whether you are interested in one of our products or would like to know more about working with Practice Unbound, please give us a call on 0300 303 8145 or email <a className="tw-text-white tw-underline" href="mailto:info@practiceunbound.org.uk">info@practiceunbound.org.uk</a></p>
                </div>
                <div className="col-sm-2 d-flex justify-content-center align-items-center">
                    <span style={{ fontSize: '32px', marginRight: '16px' }}><SocialsIcon className={`tw-text-white hover:tw-text-[#06523D] tw-mx-2`} link={"https://twitter.com/PracticeUnbound"} social="twitter" /></span>
                    <span style={{ fontSize: '32px' }}><SocialsIcon className={`tw-text-white hover:tw-text-[#06523D] tw-mx-2`} link={"https://www.linkedin.com/company/practice-unbound"} social="linkedin" /></span>
                </div>
            </div>
        </div>
    </DivFade >
  </>
}

export default PathGoBenefits