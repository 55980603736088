import React, { Component } from 'react'
import Button from '../common/Button'
import Modal from '../common/Modal'
import { Link } from 'react-router-dom'


export default class PLMBtns extends Component {
    constructor() {
        super()
        this.state = {
            modalOpen: false
        }
    }
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 mb-3">
                        <Button
                            link='/purchase/prescribinglifestylemedicinepractice'
                            inline center xl>
                            <span className="d-sm-none">UK GP Practice</span>
                            <span className="d-none d-sm-inline-block">I'm employed by a UK GP Practice</span>
                        </Button>
                    </div>
                    <div className="col-lg-6 mb-3">
                        <Button
                            link='/purchase/prescribinglifestylemedicine'

                            inline center xl>
                            <span className="d-sm-none">Not a UK GP Practice</span>
                            <span className="d-none d-sm-inline-block">I'm not employed by a UK GP Practice</span>
                        </Button>
                    </div>
                    <div className="col-12 mb-5 mt-3">
                        <Button onClick={() => { this.setState({ modalOpen: true }) }} inline center underline colour="none">Why does it matter?</Button>
                    </div>
                </div>
                <Modal
                    title="Why does it matter?"
                    isOpen={this.state.modalOpen}
                    onRequestClose={() => { this.setState({ modalOpen: false }) }}
                >
                    <strong>I'm employed by a UK GP Practice</strong>
                    <p>If you are employed by a UK GP practice you will be able to purchase up to 10 individual licenses for yourself and/or other practice staff. These accounts are all linked to your chosen organisation and are offered at a reduced price per license when purchased together. You will be asked how many licenses you wish to purchase for yourself and / or other practice staff employed by your organisation.</p>
                    <p>
                        If you are employed by a UK GP Practice, and would like to purchase more than 10 licenses, please <Link to='/contact-us'>contact our sales team</Link>.
</p>


                    <strong>I'm not employed by a UK GP Practice</strong>
                    <p>If you are not employed by a UK GP practice you will be purchasing an individual license that is not linked to any particular organisation. </p>
                    <p>
                        If you are not employed by a UK GP Practice and would like to purchase more than 1 license, please <Link to='/contact-us'>contact our sales team</Link>.
</p>


                </Modal>
            </div >
        )
    }
}
