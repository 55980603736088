import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getBlog, clearBlog } from '../../actions/blogActions'
import ContentBlockPage from '../layout/ContentBlockPage'
import Block from '../layout/Block'
import Spinner from '../common/Spinner'
import { Helmet } from "react-helmet";
import Error404Screen from '../errors/Error404Screen'

class Blog extends Component {
    componentDidMount() {
        this.props.getBlog(this.props.match.params.blog)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.blog !== this.props.match.params.blog) {
            this.props.getBlog(nextProps.match.params.blog)
        }
    }

    componentWillUnmount() {
        this.props.clearBlog()
    }
    render() {
        if (this.props.blogs.errors.response) {
            if (this.props.blogs.errors.response.status === 404) {
                return <Error404Screen />
            }
        }
        if (!this.props.blogs.blog) { return <Spinner fullScreen color="yellow" large /> }
        if (!this.props.blogs.blog.fields) { return <Spinner fullScreen color="yellow" large /> }
        if (this.props.blogs.blogLoading) { return <Spinner fullScreen color="yellow" large /> }

        return (
            <div>
                {this.props.blogs.blog.fields.cardImage && (
                    <Helmet>
                        <meta
                            name="twitter:image"
                            content={this.props.blogs.blog.fields.cardImage} />
                        <meta name="twitter:card" content="summary_large_image" />
                    </Helmet>
                )}
                <Block backsplash>
                    <h1 className="text-center"><strong>{this.props.blogs.blog.fields.header}</strong></h1>
                </Block>
                <ContentBlockPage content={this.props.blogs.blog} />
            </div>

        )
    }
}
const mapStateToProps = state => ({
    blogs: state.blogs
})
export default connect(mapStateToProps, { getBlog, clearBlog })(Blog);

