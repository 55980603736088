import React from "react";
import Check from "../common/Check";
import { Link } from 'react-router-dom'

const ProductComparisonTable = props => {

  return (
    <div className="table-responsive">
      <table
        style={{
          width: "100%",
          maxWidth: "100%",
          marginBottom: "1rem",
          backgroundColor: "transparent"
        }}
      >
        <thead>
          <tr>
            {props.titleRow &&
              props.titleRow.map((title, index) => {
                return (
                  <th
                    key={index}
                    style={{
                      verticalAlign: "top",
                      padding: ".75rem",
                      borderBottom: "2px solid #dee2e6",
                      borderLeft: index > 0 && "1px solid #dee2e6",
                      textAlign: index > 0 && "center"
                    }}
                  >
                    {title.link ? (
                      <Link to={title.link || ''}>{title.title}</Link>
                    ) : title.title
                    }

                  </th>
                );
              })}
          </tr>
        </thead>
        <tbody>
          {props.rows &&
            props.rows.map((row, index) => {
              return (
                <tr key={index}>
                  {row.map((item, index) => {
                    return (
                      <td
                        key={index}
                        style={{
                          padding: ".2rem",
                          verticalAlign: "top",
                          borderLeft: index > 0 && "1px solid #dee2e6",

                          textAlign: index > 0 && "center"
                        }}
                      >
                        {item === "tick" ? (
                          <Check plain color="green" />
                        ) : (
                          <small>{item}</small>
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};
export default ProductComparisonTable