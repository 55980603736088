import React from 'react'
import { useState, useEffect } from 'react'
import Button from '../../../common/Button'
import OrangeChecklist from './OrangeChecklist'
import DivFade from '../../DivFade'
// import plmData from '../../../../img/plm-data.png'
// import plmCourse from '../../../../img/plm-course.png'
import puColours from '../../../../utils/puColours'
import { useScreenSize } from '../../../../utils/hooks'

const Card = props => {
    return (
        <div className="text-white mb-4 relative" style={{ border: props.border ?? null, maxWidth: '400px', paddingTop: '38px', paddingBottom: '38px', paddingLeft: '28px', paddingRight: '28px', fontWeight: 'bold', backgroundColor: '#0E3A5C', borderRadius: '15px', boxShadow: '0px 3px 6px #00000029' }} >
            {props.circle &&
                <Circle
                    color={puColours.teal}
                    width="200px"
                    top="-10%"
                    left="30%"
                >
                    <span className='font-weight-bold' style={{ fontSize: '40px', color: 'white' }}>NEW!</span>
                </Circle>
            }
            {props.children}
        </div>
    )
}
const Circle = (props) => {
    return <div style={{
        top: props.top,
        left: props.left,
        right: props.right,
        width: props.width,
        position: 'absolute',
        zIndex: '-1'
    }}>
        <div style={{
            width: '100%',
            paddingTop: '100%',
            backgroundColor: props.color,
            backgroundImage: `url(${props.backgroundImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            borderRadius: '100%'
        }} />
        {props.children && (
            <div style={{
                top: '10%',
                left: '25%',
                right: '0%',
                bottom: '0%',
                position: 'absolute',
                width: '20%',
                height: '20px'
                // color: 'white'
            }}>
                {props.children}
            </div>
        )}
    </div >
}
export default function ChooseProductCards() {

    const screenSize = useScreenSize()

    return (
        <DivFade style={{ position: 'relative', backgroundColor: '#F3F3F3', paddingTop: '80px', overflowX: 'hidden' }}>
            <div style={{ position: 'absolute', top: 0, left: 0, right: 0, height: '150px', backgroundColor: 'white', zIndex: '-2' }} />
            <div className="container pb-5" style={{ position: 'relative' }}>
                <Circle color={puColours.darkBlue} width="350px" top="335px" left="-60px" />
                {/* <Circle color="#0E3A5C" width="200px" top="500px" right="20px" /> */}
                <Circle color="#EB6915" width="500px" top="60px" right="50px" />

                <div className="row justify-content-center">


                    <div className="col-lg-5 col-md-6">
                        <div className="d-flex justify-content-center">
                            <Card
                                circle
                                border={`solid ${puColours.teal} 10px`}
                            >
                                <div style={{ fontSize: '24px' }} className='text-center mb-4'>Certificate in Lifestyle Medicine
                                    (includes Prescribing Lifestyle Medicine Online Course)
                                </div>
                                <div>
                                    <OrangeChecklist circles ticks={[
                                        "All benefits from Prescribing Lifestyle Medicine Online",
                                        "An additional recommended 15 CPD points",
                                        "Postnominal letters: CertLM",
                                        "Completion Certificate",
                                        "Assessment feedback, certification tools, resources and materials",
                                        "Demonstrates to healthcare organisations and patients your distinct skills and learning within the field of Lifestyle Medicine."
                                    ]} />
                                </div>
                                <div className=" mb-4 text-center">
                                    <span style={{ fontSize: '24px' }}>£{695} + VAT</span>{' '}
                                    <span style={{ fontSize: '13px', fontWeight: 'normal' }}>(UK total price: £{834})</span>
                                </div>
                                <div>
                                    <Button center colour="orange" link={"/purchase/plmaccreditationandcourse"} large inline >
                                        enrol now
                                    </Button>
                                </div>
                            </Card>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-6 ">
                        <div className="d-flex justify-content-center">
                            <Card>
                                <div style={{ fontSize: '24px' }} className='text-center mb-4'>Prescribing Lifestyle Medicine Online Course</div>
                                <div style={{ marginBottom: '43px' }}>
                                    <OrangeChecklist
                                        circles
                                        ticks={[
                                            'RCGP, PCI and BSLM accredited',
                                            'Recommended 15 CPD points',
                                            'e-Learning',
                                            'Self Assessment reporting tool',
                                            'Patient resources',
                                            'Access to likeminded community',
                                            'Technical helpdesk',
                                            'Data Pilot: UK GP practices using SystmOne & EMIS Web (Vision coming soon)',
                                            'Exclusive access to discounted tickets to live events'
                                        ]}
                                    />
                                </div>
                                <div className=" mb-4 text-center">
                                    <span style={{ fontSize: '24px' }}>£{395} + VAT</span>{' '}
                                    <span style={{ fontSize: '13px', fontWeight: 'normal' }}>(UK total price: £{474})</span>
                                </div>
                                <div>
                                    <Button center colour="orange" link={"/purchase/prescribinglifestylemedicine"} large inline >
                                        enrol now
                                    </Button>
                                </div>
                            </Card>
                        </div>
                    </div>
                    {/* <div className="d-none d-md-flex col-md-5 align-items-center flex-column justify-content-center">
                        <img alt="e-learning preview" src={plmCourse} className="img-fluid" />
                        <img alt="data dashboard preview" src={plmData} className="img-fluid" />
                    </div> */}

                </div>

            </div>
        </DivFade>
    )
}
