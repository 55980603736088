import React from 'react'
import moment from 'moment'
import classnames from 'classnames'

export default function BillsbyContracts({ contracts }) {
    return (
        <table className="table">
            {/* <thead>
                <th>Product</th>
                <th>Status</th>
                <th>Manage</th>
            </thead> */}
            <tbody>
                {contracts.map(c =>
                    <tr key={c.productname}>
                        <td className="align-middle">
                            <strong>{c.productname}</strong>
                        </td>

                        <td className="align-middle">
                            <span style={{ fontSize: '100%' }}
                                className={classnames(
                                    "badge",
                                    "d-block",
                                    "d-md-inline",
                                    "mx-2",
                                    { "badge-success": c.billsbyStatus === 'Active' },
                                    { "badge-danger": c.billsbyStatus !== 'Active' }
                                )}
                            >{c.billsbyStatus}</span>
                            {
                                c.billsbyStatus === 'Active' ? (

                                    <div class="dropdown d-block mx-2 d-md-inline">
                                        <button class="btn  btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Manage
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">


                                            <button className="dropdown-item"
                                                data-billsby-type="account"
                                                data-billsby-action="personaldetails"
                                                data-billsby-customer={c.billsbyCustomerId}
                                                data-billsby-subscription={c.billsbySubscriptionId}
                                            >Billing Administrator Details</button>

                                            <button className="dropdown-item"
                                                data-billsby-type="account"
                                                data-billsby-action="address"
                                                data-billsby-customer={c.billsbyCustomerId}
                                                data-billsby-subscription={c.billsbySubscriptionId}
                                            >Update address</button>

                                            <button className="dropdown-item"
                                                data-billsby-type="account"
                                                data-billsby-action="paymentdetails"
                                                data-billsby-customer={c.billsbyCustomerId}
                                                data-billsby-subscription={c.billsbySubscriptionId}
                                            >Update Payment Details</button>

                                            <button className="dropdown-item"
                                                data-billsby-type="account"
                                                data-billsby-action="billinghistory"
                                                data-billsby-customer={c.billsbyCustomerId}
                                                data-billsby-subscription={c.billsbySubscriptionId}
                                            >Billing History</button>

                                            <a className="dropdown-item" href={`/bb/cancel?cid=${c.billsbyCustomerId}&sid=${c.billsbySubscriptionId}`}>Cancel</a>
                                        </div>
                                    </div>

                                ) : (
                                    <div>
                                        End Date: {c.contractEndDate && moment(c.contractEndDate).format('DD-MMM-yy')}
                                    </div>
                                )
                            }
                        </td>

                    </tr>
                )}
            </tbody>
        </table>


    )
}
