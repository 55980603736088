import React from 'react'
import { connect } from 'react-redux'
import Hero from './Hero'
import Button from '../common/Button'

function GCHeader(props) {

    if (!props.products.product) return null
    const { product } = props.products
    return (
        <div className="bg-white">
            <div>

                <Hero
                    color={product.color}
                    shrunk={false}
                    image={product.image2}
                >
                    <div style={{ height: '450px', position: 'relative' }}>
                        <div style={{
                            position: 'absolute',
                            top: '50%',
                            MsTransform: 'translateY(-50%)',
                            transform: 'translateY(-50%)'
                        }}>
                            <h1 className="display-4 my-0"
                                style={{ fontSize: '56px' }}
                            >{product.title}</h1>
                            <p className="lead mt-0">{product.subtitle}</p>
                            <Button inline onClick={() => {
                                if (document.getElementById('gc-pricing')) {
                                    document.getElementById('gc-pricing').scrollIntoView({
                                        behavior: 'smooth'
                                    });
                                }
                            }} large >Pricing</Button>
                            <Button inline link='/contact-us?contactType=sales&product=groupconsultations' large >Enquire</Button>
                        </div>
                    </div>
                </Hero>
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    products: state.products
})
export default connect(mapStateToProps, {})(GCHeader)