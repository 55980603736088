import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Helmet } from "react-helmet"
// import SignupForm from './Comps/SignupForm'
// import BottomBanner from './Comps/BottomBanner'
import TopBannerPlain from "./Comps/TopBannerPlain"
import DivFade from "../DivFade"
import Button from "../../common/Button"
import OrangeChecklist from "./Comps/OrangeChecklist"
// import Slogan from '../Slogan';
// import Card from '../Card'
// import OurAccreditations from './Comps/OurAccreditations';
// import PCIlogo from '../../../../img/PCI_Accredited_Mark_RGB.png'
import CPDLogo from "../../../img/CPDmember.png"
// import ddi from "../../../img/ddi_logo min.png"
import genova from "../../../img/GENOVA_EUROPE_LOGO_2013.jpg"
import pureEncaps from "../../../img/pure-encaps.jpg"
// import nutrilink from '../../../img/nl2016-weblogo-chosen.png'
// import regenerus from "../../../img/regenerus_logo min.png"
import Block from "../Block"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import BannerFlex from "../BannerFlex"
import puColours from "../../../utils/puColours"
import { useScreenSize } from "../../../utils/hooks"
import gabor from "../../../img/gabor.png"
import ImageTextBlock from "./Comps/ImageTextBlock"
import TicklistItem from "../../common/TicklistItem"
import BottomBanner from "./Comps/BottomBanner"
// import vivoLogo from "../../../img/VivoLogo min.png"

const images = {
  ranganHeadshot:
    "https://practiceunbound.blob.core.windows.net/stock-images/RanganHomepageTopImage_2017_07_Chatterjee-3852-Edit-3Cropped-min.jpg",
  ayanHeadshot:
    "https://practiceunbound.blob.core.windows.net/stock-images/AyanHompageTopAndBottomImageCropped-min.jpg",
  peopleLunch:
    "https://practiceunbound.blob.core.windows.net/stock-images/plm-live-2023-people-lunch-min.jpg",
  ranganAndAttendee:
    "https://practiceunbound.blob.core.windows.net/stock-images/plm-live-2023-rangan-and-attendee-min.jpg",
  peopleChatting:
    "https://practiceunbound.blob.core.windows.net/stock-images/plm-live-2023-people-chatting-min.jpg",
  vivoLogo:
    "https://practiceunbound.blob.core.windows.net/stock-images/vivo-logo-min.png",
  regenerusLogo:
    "https://practiceunbound.blob.core.windows.net/stock-images/regenerus-logo-min.png",
  ddiLogo:
    "https://practiceunbound.blob.core.windows.net/stock-images/ddi-logo-min.png",
  bantLogo:
    "https://practiceunbound.blob.core.windows.net/stock-images/bant-logo-tx77az-min.png",
  anpLogo:
    "https://practiceunbound.blob.core.windows.net/stock-images/anp-logo-2019-teal-hi-res-min.jpg",
  nnaLogo: "https://practiceunbound.blob.core.windows.net/stock-images/nna-min.jpg",
}

const Event = props => {
  useEffect(() => {
    checkPurchasedAndRedirect()
  }, [props])

  const screenSize = useScreenSize()

  function checkPurchasedAndRedirect() {
    const { product } = props.products
    const { auth } = props
    let launchUrl
    if (product.productLink.length >= 4) {
      if (product.productLink.substring(0, 4) !== "/pro") {
        launchUrl = product.productLink
      }
    }
  }

  const pageDescription =
    "Our next in-person Prescribing Lifestyle Medicine event will take place on Saturday 26th November 2022 at ETC Venue County Hall"

  return (
    <>
      <Helmet>
        <title>
          Prescribing Lifestyle Medicine | Practice UNBOUND | In Person Event
        </title>
        <meta name='description' content={pageDescription} />
        <meta name='twitter:description' content={pageDescription} />
      </Helmet>

      <BannerFlex
        backgroundColour={puColours.darkBlue}
        shapes={[
          {
            parentClassName: "position-absolute tw-group tw-w-[330px]",
            childClassName:
              "group-hover:tw-drop-shadow-xl tw-rounded-full tw-transition-all tw-duration-500",
            content: <HeadShot imgSrc={gabor} altTag='Gabor headshot' />,
            top: screenSize > 768 ? "-50%" : screenSize > 550 ? "0%" : "0%",
            left: screenSize > 768 ? "0%" : screenSize > 550 ? "15%" : "15%",
          },
          {
            parentClassName: "position-absolute tw-group tw-w-[125px]",
            childClassName:
              "group-hover:tw-drop-shadow-xl tw-rounded-full tw-transition-all tw-duration-500",
            content: (
              <HeadShot
                imgSrc={images.ranganHeadshot}
                altTag='Rangan headshot'
              />
            ),
            top: screenSize > 768 ? "114%" : screenSize > 550 ? "0%" : "34%",
            left: "16%",
          },
          {
            parentClassName: "position-absolute tw-group tw-w-[125px]",
            childClassName:
              "group-hover:tw-drop-shadow-xl tw-rounded-full tw-transition-all tw-duration-500",
            content: (
              <HeadShot imgSrc={images.ayanHeadshot} altTag='Ayan headshot' />
            ),
            top: screenSize > 768 ? "115%" : screenSize > 550 ? "0%" : "34%",
            left: "55%",
          },
        ]}
        colSpan={{
          contentSection: "col-12 col-md-7",
          shapesSection: "col-md-5",
        }}
        classNames={{
          block: "tw-pb-12 sm:tw-pb-0",
          contentSection: `pt-5 pl-lg-5 ${screenSize > 768 && "mb-5"}`,
          shapesSection: "tw-hidden md:tw-block",
        }}>
        <div className='tw-text-white'>
          <div className=''>
            <h3
              className='text-capitalize'
              style={{ fontWeight: "bold", textWrap: "balance" }}>
              <span className='tw-uppercase'>PLM Live</span>: Mental Health and
              Trauma with Dr Gabor Maté
            </h3>
          </div>
          <p className='font-weight-bold'>
            Saturday 9th March 2024, County Hall, London
          </p>
          <div className='tw-py-2' />
          <p>
            Keynote address and exclusive UK workshop on the psychotherapeutic
            method of Compassionate Inquiry™
          </p>
          <p>
            During this event, attendees will hear Dr Maté talk about Trauma,
            Illness and Healing in his keynote presentation followed by an
            exclusive three-hour workshop focused on psychotherapeutic
            Compassionate Inquiry™ techniques for consultations.
          </p>
          <p>
            We invite you to join Dr Rangan Chatterjee and Dr Ayan Panja as they
            welcome world renowned trauma and addiction expert Dr Gabor Maté,
            for a day of learning and connection.
          </p>
          <div className='tw-py-4' />
          <div>
            <div className='tw-flex tw-flex-col md:tw-flex-row tw-gap-7'>
              {/* <div className='tw-py-40 sm:tw-py-20 md:tw-py-0' /> */}
              <Button
                className='mb-3'
                colour='orange'
                link={
                  "https://www.tickettailor.com/events/practiceunbound/940200"
                }
                large
                inline>
                book tickets
              </Button>
              <Button
                className='mb-3'
                colour='orange'
                link={
                  "/product/prescribinglifestylemedicine/conference"
                }
                large
                inline>
                see the agenda
              </Button>
              {/* <p className='tw-font-bold lg:tw-pt-3 cap'>
                Full agenda coming soon...
              </p> */}
            </div>
          </div>
        </div>
      </BannerFlex>

      <ImageTextBlock
        imageUrl={images.peopleLunch}
        contentSide='right'
        contentBackgroundColor={puColours.darkOrange}>
        <div className='tw-max-w-2xl tw-pl-5 tw-py-10 md:tw-py-20 md:tw-pl-20 tw-text-white'>
          <div>
            <h4 className='tw-font-bold tw-mb-5 tw-uppercase'>case studies</h4>
            <p>
              The day will also feature real-life case studies presented by
              practitioners using the PLM framework in a clinical setting,
              demonstrating how these methods can effectively trigger positive
              behaviour change among patients experiencing trauma-related
              issues.
            </p>
            <p>
              Your ticket also includes a delicious high-quality lunch and an
              Evening Drinks Reception for you to relax and discuss the day.
            </p>
          </div>
        </div>
      </ImageTextBlock>

      <Block>
        <div className='tw-flex tw-flex-col tw-items-center tw-justify-center tw-pb-8'>
          <h4 className='tw-font-bold tw-text-center tw-uppercase'>
            This event is for:
          </h4>
          <div className='tw-py-3' />
          <div className="tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-gap-5">
            <ul className='tw-grid sm:tw-grid-cols-2 tw-max-w-2xl tw-gap-2 md:tw-gap-5 lg:tw-gap-3 tw-mb-0'>
              {[
                "Healthcare professionals",
                "Clinicians and specialist \n non clinicians",
                "Doctors",
                "Nurses",
                "Pharmacists",
                "Health Coaches",
                "ARRS Roles",
                "Allied Health Professionals",
                "Other Health Care Professions",
                "Medical Students",
              ].map((e, i) => (
                  <li key={`${(i, e)}`} className='tw-m-0 tw-leading-5'>{e}</li>
              ))}
            </ul>
            <div className="sm:tw-py-3"/>
            <div className='tw-relative'>
              <iframe
                  className=''
                  style={{
                      width: '100%',
                  }}
                  src="https://player.vimeo.com/video/908214617?h=772e1e46ce&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                  title="Prescribing Lifestyle Medicine Event"
              ></iframe>
              <caption className="tw-absolute tw-left-0 tw-right-0">
                <p className="tw-text-center tw-font-bold">Take a look at our 2023 event video here</p>
              </caption>
            </div>
          </div>
        </div>
      </Block>

      <ImageTextBlock
        contentBackgroundColor={puColours.darkBlue}
        imageUrl={images.ranganAndAttendee}>
        <div className='tw-text-white tw-px-5 md:tw-px-0 tw-py-10 lg:tw-py-20'>
          <h4 className='tw-font-bold tw-mb-5 tw-uppercase'>
            additional benefits
          </h4>
          <ul>
            <TicklistItem iconColour={puColours.darkOrange}>
              <p>
                Located at County Hall London with spectacular views of the city
              </p>
            </TicklistItem>
            <TicklistItem iconColour={puColours.darkOrange}>
              <p>A delicious and healthy lunch included</p>
            </TicklistItem>
            <TicklistItem iconColour={puColours.darkOrange}>
              <p>Breakfast and refreshments included</p>
            </TicklistItem>
            <TicklistItem iconColour={puColours.darkOrange}>
              <p>A drinks and networking opportunity to reflect on the day</p>
            </TicklistItem>
            <TicklistItem iconColour={puColours.darkOrange}>
              <p>Access to seminar footage after the event</p>
            </TicklistItem>
          </ul>
        </div>
      </ImageTextBlock>

      <DivFade className='tw-bg-white'>
        <div className='container tw-pt-10 md:tw-py-14 '>
          <div>
            <div className='col-12'>
              <h4 className='tw-text-center tw-uppercase'>
                <strong>our sponsors</strong>
              </h4>
            </div>
            <div className='tw-py-3' />
            <div className='tw-grid tw-grid-cols-2 md:tw-grid-cols-3 md:tw-flex align-items-center'>
              {[
                {
                  link: "https://www.doctorsdata.com/",
                  imageAlt: "Doctors Data logo",
                  imageSrc: images.ddiLogo,
                  className: "tw-max-w-[150px] tw-order-1  md:tw-order-last",
                },
                {
                  link: "https://www.vivobarefoot.com/uk/",
                  imageAlt: "Vivo Barefoot logo",
                  imageSrc: images.vivoLogo,
                  className:
                    "tw-bg-black tw-p-4 tw-rounded-md tw-max-w-[275px] tw-col-span-2 tw-mb-5 md:tw-order-1",
                },
                {
                  link: "https://regeneruslabs.com/",
                  imageAlt: "Regenerus Labs logo",
                  imageSrc: images.regenerusLogo,
                  className: "tw-max-w-[150px]",
                },
              ].map((item, index) => {
                return (
                  <div
                    key={`sponsor: ${index}`}
                    className={`tw-mx-auto ${item.className}`}>
                    <a
                      rel='noopener noreferrer'
                      href={item.link}
                      target='_blank'>
                      <img
                        className='img-fluid'
                        alt={item.imageAlt}
                        src={item.imageSrc}
                      />
                    </a>
                  </div>
                )
              })}
            </div>
            <div className='tw-py-4 md:tw-py-0' />
          </div>
        </div>
      </DivFade>

      <ImageTextBlock
        contentBackgroundColor={puColours.darkBlue}
        imageUrl={images.peopleChatting}
        contentSide='right'>
        <div className='tw-text-white tw-pl-5 tw-py-10 md:tw-pl-20 md:tw-py-20'>
          <h4 className='tw-font-bold tw-mb-5 tw-uppercase'>Pricing</h4>
          <p>
            Full session information will be available soon, but in the
            meantime, you can{" "}
            <a
              className={`tw-text-[${puColours.darkOrange}]`}
              href='https://www.tickettailor.com/events/practiceunbound/940200'>
              book your ticket for just £279 + VAT
            </a>
            . Concessions available for students.
          </p>
          <p>
            Contact{" "}
            <a
              className={`tw-text-[${puColours.darkOrange}]`}
              href='mailto:events@practiceunbound.org.uk'>
              events@practiceunbound.org.uk
            </a>{" "}
            for more information.
          </p>
        </div>
      </ImageTextBlock>

      <DivFade className='tw-bg-white'>
        <div className='container tw-py-10 md:tw-pt-10 md:tw-pb-16'>
          <div>
            <div className='col-12'>
              <div className={`tw-mx-auto tw-max-w-[150px]`}>
                <a
                  rel='noopener noreferrer'
                  href={"https://cpduk.co.uk/"}
                  target='_blank'>
                  <img className='img-fluid' alt='CPD logo' src={CPDLogo} />
                </a>
              </div>
            </div>
            <div className='tw-py-5' />
            <div className='col-12'>
              <h4
                className='tw-text-center tw-uppercase tw-max-w-xl tw-mx-auto'
                style={{ textWrap: "balance" }}>
                This course has been accredited 6.25 hours of CPD by the
                following institutions
              </h4>
            </div>
            <div className='tw-py-3' />
            <div className='tw-grid tw-grid-cols-2 tw-gap-7 md:tw-grid-cols-3 md:tw-flex align-items-center'>
              {[
                {
                  link: "https://theanp.co.uk/",
                  imageAlt: "ANP logo",
                  imageSrc: images.anpLogo,
                  className: "tw-max-w-[150px]",
                },
                {
                  link: "https://bant.org.uk/",
                  imageAlt: "BANT logo",
                  imageSrc: images.bantLogo,
                  className: "tw-max-w-[150px] md:tw-order-last",
                },
                {
                  link: "https://nna-uk.com/",
                  imageAlt: "NNA logo",
                  imageSrc: images.nnaLogo,
                  className: "tw-max-w-[100px] tw-col-span-2",
                },
              ].map((item, index) => {
                return (
                  <div
                    key={`sponsor: ${index}`}
                    className={`tw-mx-auto ${item.className}`}>
                    <a
                      rel='noopener noreferrer'
                      href={item.link}
                      target='_blank'>
                      <img
                        className='img-fluid'
                        alt={item.imageAlt}
                        src={item.imageSrc}
                      />
                    </a>
                  </div>
                )
              })}
            </div>
            <div className='tw-py-4 md:tw-py-0' />
          </div>
        </div>
      </DivFade>

      <BottomBanner
        header='Have more questions? Talk to us'
        iconHoverColour='#034F52'
        paddingBottom='40px'
        paddingTop='50px'>
        <p style={{ fontSize: "18px" }}>
          Whether you are interested in one of our products or would like to
          know more about working with Practice Unbound, please
          <Link style={{ color: "white" }} to='/contact-us'>
            {" "}
            contact the team
          </Link>
        </p>
      </BottomBanner>
    </>
  )
}

const HeadShot = props => (
  <img
    alt={props.altTag}
    src={props.imgSrc}
    className='img-fluid tw-rounded-full tw-shadow-md'
  />
)

const mapStateToProps = state => ({
  products: state.products,
  auth: state.auth,
})
export default connect(mapStateToProps, {})(Event)
